/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContainsFileExtension } from "../utils/systemInfo";

import FakeUser from "../FakeUser.png";

export const setUsersData = createAsyncThunk(
  "users/setUsersData",
  async (inputData) => {
    let users = [];
    if (inputData === null) {
      return null;
    }
    users = PopulateUsers(inputData?.json);
    if (inputData?.newSearchText?.length && users && users.length) {
      const searchText = inputData.newSearchText.toLowerCase();
      return users.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );
    }
    return users;
  }
);
function PopulateUsers(json) {
  let users = [];
  const UsersArray = json.person_profile;
  for (let index in UsersArray) {
    let userDataObj = {
      firstname: "",
      lastname: "",
      fullname: "",
      contact: "",
      uid: "",
      signinLogger: "",
      profile_id: "",
      user_type_uid: "",
      public_url: "",
      email: "",
      mobile: "",
    };
    userDataObj.fullname =
      UsersArray[index].firstname + " " + UsersArray[index].lastname;
    userDataObj.firstname = UsersArray[index].firstname;
    userDataObj.lastname = UsersArray[index].lastname;
    userDataObj.contact = UsersArray[index].contact;
    userDataObj.uid = UsersArray[index].profile_uid;
    userDataObj.signinLogger = UsersArray[index].signin_logger;
    userDataObj.profile_id = UsersArray[index].profile_id;
    userDataObj.user_type_uid = UsersArray[index].user_type_uid;

    userDataObj.public_url = FakeUser;
    const User_Image_Array = json.person_profile[index].image;
    if (User_Image_Array !== undefined)
      if (User_Image_Array.length > 0) {
        for (let i in User_Image_Array) {
          if (
            User_Image_Array[i].image_type === "profile_image" &&
            User_Image_Array[i].status === "active"
          ) {
            if (ContainsFileExtension(User_Image_Array[i].public_url)) {
              userDataObj.public_url = User_Image_Array[i].public_url;
              break;
            }
          }
        }
      }
    if (UsersArray[index]?.contact?.length) {
      for (let i in UsersArray[index].contact) {
        if (
          UsersArray[index].contact[i].contact_type === "email_address" &&
          UsersArray[index].contact[i].primary_contact === true
        ) {
          userDataObj.email = UsersArray[index].contact[i].value;
        }
        if (
          UsersArray[index].contact[i].contact_type === "tel_mobile" &&
          UsersArray[index].contact[i].primary_contact === true
        ) {
          userDataObj.mobile = UsersArray[index].contact[i].value;
        }
      }
    }
    users.push(userDataObj);
  }
  return users;
}

const initialState = null;

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUsersData.fulfilled, (state, action) => action.payload);
  },
});

export const selectUser = ({ users }) => users;

export default usersSlice.reducer;
