import React from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Box, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function EditButton({ onClick, small }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Tooltip title={t("Edit")}>
      <Box
        onClick={onClick}
        sx={{
          backgroundColor: theme.palette.custom.buttonColor,
          borderRadius: "4px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          // display: "flex",
          // justifyContent: "center",
          width: small ? "28px" : "46px",
          height: small ? "26px " : "36px",
          padding: small ? "2px 4px" : "5px 10px",
        }}
      >
        <EditNoteIcon
          sx={{
            color: theme.palette.custom.buttonTextColor,
            fontSize: 24,
          }}
        />
      </Box>
    </Tooltip>
  );
}
EditButton.propTypes = {
  onClick: PropTypes.func,
  small: PropTypes.bool,
};

export default EditButton;
