import React, { useState, useEffect, useMemo } from "react";
import { Box, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Breadcrumbs from "../common/breadcrumbs";
import DevicesService from "../api/devices";
import CommonDateTimePicker from "../common/commonDateTimePicker";
import {
  GetDateTimeStringToday,
  GetDateTimeString7DaysAgo,
  getUrlVars,
  GetMachineTypeIcon,
} from "../utils/systemInfo";
import DashboardsListMenu from "../common/dashboardsListMenu";
import { useLocation } from "react-router-dom";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Dashboards", href: "dashboards" },
];
const StyleMachineSpan = styled((props) => <span {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
  })
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.light,
  },
}));
function Dashboard() {
  const theme = useTheme();
  const organziation = useSelector(({ organziation }) => organziation);
  const [machineDeviceName, setMachineDeviceName] = useState("Fleet");
  const [machineDeviceArray, setMachineDeviceArray] = useState(["Fleet"]);
  const [anchorMachine, setAnchorMachine] = useState(null);
  // const [loadingValue, setLoadingValue] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      DevicesService.getDevices({
        deviceID: "",
        deviceType: [
          "nerohub",
          "nanohub",
          "cyberhub",
          "stratahub",
          "nerotrax",
          "train",
          "autonomous hub",
        ],
        apiKey,
        historyFromDST: GetDateTimeString7DaysAgo(),
        historyToDST: GetDateTimeStringToday(),
      })
        .then((json) => {
          if (json && json.device_profile && json.device_profile.length > 0) {
            let machineTypesArray = ["Fleet"];
            for (let index = 0; index < json.device_profile.length; index++) {
              if (
                machineTypesArray.indexOf(
                  json.device_profile[index].vehicle_type
                ) === -1
              ) {
                machineTypesArray.push(json.device_profile[index].vehicle_type);
              }
            }

            setMachineDeviceArray(machineTypesArray);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOpenMachineMenu = (event) => {
    setAnchorMachine(event.currentTarget);
  };
  const handleCloseMachineMenu = () => {
    setAnchorMachine(null);
  };
  const handleChooseMachine = (item) => {
    // setLoadingValue(true);
    setMachineDeviceName(item);
    setAnchorMachine(false);
    // setLoadingValue(false);
  };
  const handleBreadCrumbsArray = (breadcrumbs) => {
    // if (breadcrumbs.length === 3) {
    //     return breadcrumbs
    // }
    const Dashboard = getUrlVars()["Dashboard"];
    const deviceType = getUrlVars()["DeviceTitleDataName"];
    breadcrumbs[2] = {
      id: 2,
      name: Dashboard,
      href: `/dashboard?Dashboard=${Dashboard}&DeviceTitleDataName=${deviceType}&timerange`,
      noTranslation: true,
    };
    return breadcrumbs;
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: `100%`,
        height: "100%",
      }}
    >
      <Box sx={{ width: "100%", height: { xs: "fit-content", sm: `88px` } }}>
        {useMemo(
          () => (
            <Breadcrumbs breadcrumbs={handleBreadCrumbsArray(breadcrumbs)} />
          ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [location]
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexFlow: { xs: "column", sm: "row" },
            alignItems: "center",
            height: { xs: "fit-content", sm: "64px" },
          }}
        >
          <Box sx={{ display: { xs: "block", sm: "flex" } }}>
            {<DashboardsListMenu pageName={getUrlVars()["Dashboard"]} />}
          </Box>
          <>
            <Tooltip title="Open settings">
              <Button
                variant="text"
                onClick={handleOpenMachineMenu}
                endIcon={
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: "45px", color: theme.palette.custom.text }}
                  />
                }
              >
                <StyleMachineSpan
                  sx={{
                    fontSize: { xs: "25px", sm: "40px" },
                  }}
                >
                  {machineDeviceName}
                </StyleMachineSpan>
              </Button>
            </Tooltip>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorMachine}
              open={Boolean(anchorMachine)}
              onClose={handleCloseMachineMenu}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {machineDeviceArray &&
                machineDeviceArray.map((item) => (
                  <MenuItem
                    key={item}
                    onClick={() => handleChooseMachine(item)}
                  >
                    <ListItemIcon>
                      {item !== "Fleet" && (
                        <div
                          style={{ width: "30px" }}
                          dangerouslySetInnerHTML={{
                            __html: GetMachineTypeIcon(item).replace(
                              "theme.palette.custom.text",
                              theme.palette.custom.text
                            ),
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <Typography textAlign="center">
                      {item ? item : ""}
                    </Typography>
                  </MenuItem>
                ))}
            </StyledMenu>
          </>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          height: { xs: "100%", sm: `calc(100% - 94px)` },
        }}
      >
        <CommonDateTimePicker machineDeviceName={machineDeviceName} />
      </Box>
    </Box>
  );
}

export default Dashboard;
