import Utils from "../utils/utils";

class DashboardsService extends Utils.EventEmitter {
  getGrafanaDashboardOperationLink(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          grafana_dashboard_link_id: inputData.grafana_dashboard_link_id,
          operation_profile_id: inputData.operation_profile_id,
          grafana_dashboard_id: inputData.grafana_dashboard_id,
          order_by: "",
          status: inputData.status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/grafana_dashboard/operation/link/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetGrafanaDashboard(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          grafana_dashboard_id: inputData.grafana_dashboard_id,
          name: inputData.name,
          name_backend: inputData.name_backend,
          name_tab: inputData.name_tab,
          name_type: inputData.name_type,
          description: "",
          status: inputData.status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/grafana_dashboard/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetGrafanaDashboard(
    grafana_dashboard_id = "",
    name = "",
    name_backend = "",
    name_tab = "",
    name_type = "",
    description = "",
    status = "active",
    api_key
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            grafana_dashboard_id: grafana_dashboard_id,
            name: name,
            name_backend: name_backend,
            name_tab: name_tab,
            name_type: name_type,
            description: description,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: api_key,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/grafana_dashboard/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetGrafanaDashboardOperationLink(
    grafana_dashboard_link_id = "",
    operation_profile_id = "",
    grafana_dashboard_id = "",
    status = "active",
    order_by = "",
    api_key
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            grafana_dashboard_link_id: grafana_dashboard_link_id,
            operation_profile_id: operation_profile_id,
            grafana_dashboard_id: grafana_dashboard_id,
            order_by: order_by,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: api_key,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/grafana_dashboard/operation/link/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new DashboardsService();

export default instance;
