import React, { useState, useEffect, useMemo } from "react";
import { Box, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { capitalize } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  GetDateTimeStringToday,
  GetDateTimeStringYesterday,
  GetDateTimeString7DaysAgo,
  GetDateTimeString14DaysAgo,
  GetDateTimeString30DaysAgo,
  GetDateTimeString90DaysAgo,
  GetDateTimeString2DaysAgo,
  GetDateTimeString60DaysAgo,
  GetDateTimeString180DaysAgo,
  GetDateTimeString28DaysAgo,
  GetMachineTypeIcon,
  getComparator,
  stableSort,
} from "../utils/systemInfo";
import moment from "moment";
import MaintenanceService from "../api/maintenance";
import DevicesService from "../api/devices";
import OpsumService from "../api/opsum";
import HomePagesMenu from "../common/HomePagesMenu";
import Loading from "../common/loading";
import NumberTypography from "../common/NumberTypography";
import EnhancedTableHead from "../common/EnhancedTableHead";
import MaintenanceTableRow from "./maintenanceTableRow";
import { setMaintenanceData } from "../store/maintenanceSlice";
import Breadcrumbs from "../common/breadcrumbs";

import SearchInput from "../common/searchInput.js";
import StyledTypography from "../common/StyledTypography.js";
import handleNavigate from "../common/customFunctions/handleNavigate.js";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Maintenance", href: "maintenance" },
];

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.light,
  },
}));
const StyleSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
}));
const StyleMachineSpan = styled((props) => <span {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    fontSize: "40px",
  })
);
const timespanList = [
  {
    id: 0,
    label: "Day on Day",
    secondLabel: "Last 24 hours",
    timespan: "24 hours",
  },
  {
    id: 1,
    label: "Week on Week",
    secondLabel: "Last 7 days",
    timespan: "168 hours",
  },
  {
    id: 2,
    label: "Fortnight on Fortnight",
    secondLabel: "Last 14 days",
    timespan: "336 hours",
  },
  {
    id: 3,
    label: "Month on Month",
    secondLabel: "Last 30 days",
    timespan: "720 hours",
  },
  {
    id: 4,
    label: "Quarter on Quarter",
    secondLabel: "Last 90 hours",
    timespan: "2160 hours",
  },
];
const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const headCells = [
  {
    id: "DeviceName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "MaintenanceStatus",
    numeric: false,
    disablePadding: false,
    label: "MaintenanceStatus",
    sort: true,
  },
  {
    id: "DeviceLastSeen",
    numeric: false,
    disablePadding: false,
    label: "LastSeen",
    sort: true,
  },
  {
    id: "NamedLocationOriginal",
    numeric: false,
    disablePadding: false,
    label: "LastLocation",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
function Maintenance() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const maintenance = useSelector(({ maintenance }) => maintenance);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorMachine, setAnchorMachine] = useState(null);
  const [machineDeviceName, setMachineDeviceName] = useState("Fleet");
  const [opsumInputData, setOpsumInputData] = useState(undefined);
  const [machineDeviceArray, setMachineDeviceArray] = useState(["Fleet"]);
  const [dateTimeStringFrom, setDateTimeStringFrom] = useState(
    GetDateTimeString7DaysAgo()
  );
  const [dateTimeStringFromBefore, setDateTimeStringFromBefore] = useState(
    GetDateTimeString14DaysAgo()
  );
  const [dateTimeStringTo, setDateTimeStringTo] = useState(
    GetDateTimeStringToday()
  );
  const [dateRangeString, setDateRangeString] = useState("Last 7 days");
  const [timespanString, setTimespanString] = useState("168 hours");
  const [timeSpanMenuLabel, setTimespanMenuLabel] = useState("Week on Week");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");
  const [maintenanceTypes, setMaintenanceTypes] = useState(undefined);
  const [maintUIDValue, setMaintUID] = useState(undefined);
  const [maintCountValue, setMaintCount] = useState(undefined);
  const [maintenanceSchedule, setMaintenanceSchedule] = useState(undefined);

  const [selected, setSelected] = useState([]);
  const [loadingValue, setLoadingValue] = useState(true);

  useEffect(() => {
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
    if (apiKey) {
      //  const apiKey = organziation?.api_key_list?.[0]?.key;
      setOpsumInputData(null);

      DevicesService.getDevices({
        deviceID: "",
        deviceType: [
          "nerohub",
          "nanohub",
          "cyberhub",
          "stratahub",
          "nerotrax",
          "train",
          "autonomous hub",
        ],
        apiKey,
        historyFromDST: GetDateTimeString7DaysAgo(),
        historyToDST: GetDateTimeStringToday(),
      })
        .then((json) => {
          if (json && json.device_profile && json.device_profile.length > 0) {
            let machineTypesArray = ["Fleet"];
            for (let index = 0; index < json.device_profile.length; index++) {
              if (
                machineTypesArray.indexOf(
                  json.device_profile[index].vehicle_type
                ) === -1
              ) {
                machineTypesArray.push(json.device_profile[index].vehicle_type);
              }
            }

            setMachineDeviceArray(machineTypesArray);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
      let count = 0;
      let maintType = [];

      MaintenanceService.getDeviceMaintenanceTypes({
        maintenanceTypeId: "",
        apiKey,
      })
        .then((jsonTypes) => {
          setMaintenanceTypes(jsonTypes);
          MaintenanceService.getDeviceMaintenances({
            deviceProfileId: "",
            name: "",
            status: "active",
            apiKey,
          })
            .then((jsonSchedules) => {
              setMaintenanceSchedule(jsonSchedules);
              OpsumService.getDeviceOpsumQuery({
                deviceID: "",
                deviceType: [
                  "nerohub",
                  "nanohub",
                  "cyberhub",
                  "stratahub",
                  "nerotrax",
                  "train",
                  "autonomous hub",
                ],
                apiKey,
                historyFromDST: GetDateTimeString7DaysAgo(),
                historyToDST: GetDateTimeStringToday(),
              })
                .then((jsonCurrent) => {
                  setOpsumInputData({
                    jsonCurrent,
                    maintenanceSchedules:
                      jsonSchedules.device_maintenance_schedule,
                    machineTypeFilter: "",
                    historyFromDST: GetDateTimeString7DaysAgo(),
                    historyToDST: GetDateTimeStringToday(),
                  });
                  dispatch(
                    setMaintenanceData({
                      jsonCurrent,
                      maintenanceSchedules:
                        jsonSchedules.device_maintenance_schedule,
                      machineTypeFilter: "",
                      historyFromDST: GetDateTimeString7DaysAgo(),
                      historyToDST: GetDateTimeStringToday(),
                    })
                  );
                  setLoadingValue(false);
                })
                .catch((errorr) => {
                  if (process.env.NODE_ENV === "development")
                    console.log("getUserTypePermissionserrorr", errorr);
                });
              for (let i in jsonTypes.device_maintenance_type) {
                for (let j in jsonSchedules.device_maintenance_schedule) {
                  if (
                    jsonTypes.device_maintenance_type[i].name ===
                      jsonSchedules.device_maintenance_schedule[j]
                        .maintenance_type_id &&
                    jsonSchedules.device_maintenance_schedule[j].status ===
                      "active"
                  ) {
                    if (
                      new Date(
                        jsonSchedules.device_maintenance_schedule[j].date_from
                      ) <= new Date(GetDateTimeString7DaysAgo()) &&
                      new Date(
                        jsonSchedules.device_maintenance_schedule[j].date_to
                      ) >= new Date(GetDateTimeStringToday())
                    ) {
                      maintType.push(
                        jsonSchedules.device_maintenance_schedule[j]
                          .maintenance_type_id
                      );
                      count++;
                    }
                  }
                }
              }
              if (count > 0) {
                let maintUID = [];
                let maintCount = [];
                maintType.forEach((e) => {
                  if (maintUID.includes(e) !== true) {
                    maintUID.push(e);
                    maintCount[maintUID.indexOf(e)] = 1;
                  } else {
                    maintCount[maintUID.indexOf(e)] =
                      maintCount[maintUID.indexOf(e)] + 1;
                  }
                });
                setMaintUID(maintUID);
                setMaintCount(maintCount);
              }
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenMachineMenu = (event) => {
    setAnchorMachine(event.currentTarget);
  };
  const handleCloseMachineMenu = () => {
    setAnchorMachine(null);
  };
  const handleChooseTimespan = (item) => {
    setLoadingValue(true);
    setSearchText(null);
    setTimespanMenuLabel(item.label);
    setTimespanString(item.timespan);
    setDateRangeString(item.secondLabel);
    let DataFrom;
    let DataFromBefore;
    const DataTo = GetDateTimeStringToday();
    switch (item.id) {
      case 0:
        DataFrom = GetDateTimeStringYesterday();
        DataFromBefore = GetDateTimeString2DaysAgo();
        break;
      case 1:
      default:
        DataFrom = GetDateTimeString7DaysAgo();
        DataFromBefore = GetDateTimeString28DaysAgo();
        break;
      case 2:
        DataFrom = GetDateTimeString14DaysAgo();
        DataFromBefore = GetDateTimeStringYesterday();
        break;
      case 3:
        DataFrom = GetDateTimeString30DaysAgo();
        DataFromBefore = GetDateTimeString60DaysAgo();
        break;
      case 4:
        DataFrom = GetDateTimeString90DaysAgo();
        DataFromBefore = GetDateTimeString180DaysAgo();
        break;
    }
    setDateTimeStringFromBefore(DataFromBefore);
    setDateTimeStringFrom(DataFrom);
    setDateTimeStringTo(DataTo);
    if (maintenanceTypes && maintenanceSchedule) {
      if (organziation) {
        const apiKey = organziation?.api_key_list?.[0]?.key;
        OpsumService.getDeviceOpsumQuery({
          deviceID: "",
          deviceType: [
            "nerohub",
            "nanohub",
            "cyberhub",
            "stratahub",
            "nerotrax",
            "train",
            "autonomous hub",
          ],
          apiKey,
          historyFromDST: DataFrom,
          historyToDST: DataTo,
        })
          .then((jsonCurrent) => {
            setOpsumInputData({
              jsonCurrent,
              maintenanceSchedules:
                maintenanceSchedule.device_maintenance_schedule,
              machineTypeFilter:
                machineDeviceName === "Fleet" ? "" : machineDeviceName,
              historyFromDST: DataFrom,
              historyToDST: DataTo,
            });
            dispatch(
              setMaintenanceData({
                jsonCurrent,
                maintenanceSchedules:
                  maintenanceSchedule.device_maintenance_schedule,
                machineTypeFilter:
                  machineDeviceName === "Fleet" ? "" : machineDeviceName,
                historyFromDST: DataFrom,
                historyToDST: DataTo,
              })
            );
            setLoadingValue(false);
          })
          .catch((errorr) => {
            if (process.env.NODE_ENV === "development")
              console.log("getUserTypePermissionserrorr", errorr);
          });
      }
      let count = 0;
      let maintType = [];
      for (let i in maintenanceTypes) {
        for (let j in maintenanceSchedule.device_maintenance_schedule) {
          if (
            maintenanceTypes[i].name ===
              maintenanceSchedule.device_maintenance_schedule[j]
                .maintenance_type_id &&
            maintenanceSchedule.device_maintenance_schedule[j].status ===
              "active"
          ) {
            if (
              new Date(
                maintenanceSchedule.device_maintenance_schedule[j].date_from
              ) <= new Date(DataFrom) &&
              new Date(
                maintenanceSchedule.device_maintenance_schedule[j].date_to
              ) >= new Date(DataTo)
            ) {
              maintType.push(
                maintenanceSchedule.device_maintenance_schedule[j]
                  .maintenance_type_id
              );
              count++;
            }
          }
        }
      }
      if (count > 0) {
        let maintUID = [];
        let maintCount = [];
        maintType.forEach((e) => {
          if (maintUID.includes(e) !== true) {
            maintUID.push(e);
            maintCount[maintUID.indexOf(e)] = 1;
          } else {
            maintCount[maintUID.indexOf(e)] =
              maintCount[maintUID.indexOf(e)] + 1;
          }
        });
        setMaintUID(maintUID);
        setMaintCount(maintCount);
      }
    }
    setAnchorElUser(false);
  };
  const handleChooseMachine = (item) => {
    setLoadingValue(true);
    let opsumInputObj = opsumInputData;
    opsumInputObj.machineTypeFilter = item;
    opsumInputObj.newSearchText = undefined;
    // dispatch(setOpsumData(opsumInputObj))
    dispatch(setMaintenanceData(opsumInputObj));
    setMachineDeviceName(item);
    setAnchorMachine(false);
    setLoadingValue(false);
  };
  const handleArrowIcon = (newValue, OldValue, reverse, fontSize) => {
    if (newValue > OldValue) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue < OldValue) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let opsumInputObj = opsumInputData;
    opsumInputObj.machineTypeFilter =
      machineDeviceName === "Fleet" ? "" : machineDeviceName;
    opsumInputObj.newSearchText = newSearchText;
    dispatch(setMaintenanceData(opsumInputObj));
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (maintenance && maintenance.length > 0) {
        return stableSort(maintenance, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, maintenance]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  function handleView(event, row) {
    switch (row.DeviceTitleDataName) {
      case "Device":
        handleNavigate(
          event,
          navigate,
          `/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}`
        );
        // if (event.ctrlKey || event.button === 1) {
        //   window.open(
        //     `${window.location.origin}/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}`,
        //     "_blank"
        //   );
        // } else if (event.type === "click") {
        //   navigate(

        //   );
        // }

        break;
      case "Machine":
      default:
        handleNavigate(
          event,
          navigate,
          `/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}`
        );
        // if (event.ctrlKey || event.button === 1) {
        //   window.open(
        //     `${window.location.origin}/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}`,
        //     "_blank"
        //   );
        // } else if (event.type === "click") {
        //   navigate(

        //   );
        // }
        break;
    }
  }
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: { xs: "block", sm: "center" },
            height: "64px",
          }}
        >
          <Box sx={{ display: { xs: "block", sm: "flex" }, height: "inherit" }}>
            <HomePagesMenu pageName={"Maintenance"} />
          </Box>
          <>
            <Tooltip title="Open settings">
              <Button
                variant="text"
                onClick={handleOpenMachineMenu}
                endIcon={
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: "45px", color: theme.palette.custom.text }}
                  />
                }
              >
                <StyleMachineSpan>{machineDeviceName}</StyleMachineSpan>
              </Button>
            </Tooltip>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorMachine}
              open={Boolean(anchorMachine)}
              onClose={handleCloseMachineMenu}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {machineDeviceArray &&
                machineDeviceArray.map((item) => (
                  <MenuItem
                    key={item}
                    onClick={() => handleChooseMachine(item)}
                  >
                    <ListItemIcon>
                      {item !== "Fleet" && (
                        <div
                          style={{ width: "30px" }}
                          dangerouslySetInnerHTML={{
                            __html: GetMachineTypeIcon(item).replace(
                              "theme.palette.custom.text",
                              theme.palette.custom.text
                            ),
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <Typography textAlign="center">
                      {item ? item : ""}
                    </Typography>
                  </MenuItem>
                ))}
            </StyledMenu>
          </>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <Box
        component="main"
        sx={{
          p: "12px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "16px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          height: { xs: "fit-content", sm: "48px" },
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            {t("DataSummary").toLocaleUpperCase()} &nbsp;|&nbsp;
          </Typography>
          <NumberTypography variant="subtitle2">
            {t(dateRangeString)}
            {` (${moment(dateTimeStringFrom).format("YYYY/MM/DD")} - ${moment(
              dateTimeStringTo
            ).format("YYYY/MM/DD")})`}
          </NumberTypography>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            &nbsp;|&nbsp;{t("Timespan")}:{timespanString}
          </Typography>
        </Box>
        <>
          <Tooltip title="Open settings">
            <Button
              variant="text"
              onClick={handleOpenUserMenu}
              endIcon={
                <KeyboardArrowDownIcon
                  sx={{ color: theme.palette.custom.text }}
                />
              }
            >
              <StyleSpan>{timeSpanMenuLabel}</StyleSpan>
            </Button>
          </Tooltip>
          <StyledMenu
            id="menu-appbar"
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {timespanList &&
              timespanList.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => handleChooseTimespan(item)}
                >
                  <Typography textAlign="center">
                    {item.label ? item.label : ""}
                  </Typography>
                </MenuItem>
              ))}
          </StyledMenu>
        </>
      </Box>
      <Box
        component="main"
        sx={{
          mt: "10px",
          p: "12px",
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
          textAlign: "center",
          borderRadius: "16px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          height: { xs: "fit-content", sm: "180px" },
        }}
      >
        {loadingValue ? (
          <Box sx={{ pt: "20px" }}>
            <Loading />
          </Box>
        ) : maintUIDValue && maintCountValue && maintCountValue.length > 0 ? (
          maintUIDValue.map((e) => (
            <>
              <NumberTypography variant="subtitle2">{t(e)}</NumberTypography>
              <Typography
                sx={{ color: theme.palette.custom.text }}
                variant="h2"
                gutterBottom
              >
                {maintCountValue[maintUIDValue.indexOf(e)]}{" "}
                <RemoveCircleOutlineRoundedIcon
                  sx={{
                    minHeight: 48,
                    color: "grey",
                    fontSize: 40,
                  }}
                />
              </Typography>
            </>
          ))
        ) : (
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            {t("No Scheduled Maintenance")}
          </Typography>
        )}
      </Box>
      <SearchInput searchText={searchText} handleSearch={handleSearch} />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //size={'medium'}
          >
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {maintenance && maintenance.length > 0 && visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.DeviceName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <MaintenanceTableRow
                      key={index}
                      row={row}
                      index={index}
                      handleClick={handleClick}
                      handleArrowIcon={handleArrowIcon}
                      dateFromBefore={dateTimeStringFromBefore}
                      dateFrom={dateTimeStringFrom}
                      dateto={dateTimeStringTo}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleView={handleView}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={maintenance ? maintenance.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default Maintenance;
