import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { capitalize } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
// import HomePagesMenu from '../common/HomePagesMenu';
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import ServerHubConfigTableRow from "./serverHubConfigTableRow";
import { setHubServerConfig } from "../store/hubServerConfigSlice";
import Breadcrumbs from "../common/breadcrumbs";
import SearchInput from "../common/searchInput";
import { useNavigate } from "react-router-dom";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV";
import StyledTypography from "../common/StyledTypography";
import handleNavigate from "../common/customFunctions/handleNavigate";
import HubServerInfoService from "../api/hubServerInfo";
import { getComparator, stableSort } from "../utils/systemInfo";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "HubServerConfig", href: "hubServerInfoApi" },
];

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  minWidth: "fit-content",
  width: "150px",
  height: "32px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    sort: true,
  },
  {
    id: "serverId",
    numeric: false,
    disablePadding: false,
    label: "ServerID",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
function HubServerConfig() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const hubServerConfigSliceData = useSelector(
    ({ hubServerConfig }) => hubServerConfig
  );
  const [hubServerInputData, setHubServerInputData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState([]);
  const [loadingValue, setLoadingValue] = useState(true);

  useEffect(() => {
    //   let currentorganization;
    // if (organziation && organziation !== currentorganization) {
    //  currentorganization = organziation;
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
    if (apiKey) {
      setHubServerInputData(null);
      HubServerInfoService.GetHUBServerInfo({
        hub_server_info_id: "",
        name: "",
        status: "",
        apiKey,
      })
        .then((json) => {
          console.log(json);
          console.log(json.data.hub_server_info);
          if (
            json &&
            json.data.hub_server_info &&
            json.data.hub_server_info.length > 0
          ) {
            const infoArray = [];

            for (
              let index = 0;
              index < json.data.hub_server_info.length;
              index++
            ) {
              const jsonObject = json.data.hub_server_info[index];
              HubServerInfoService.GetHUBServerInfoAttribute({
                hub_server_info_attribute_id: "",
                hub_server_info_id: jsonObject.hub_server_info_id,
                name: "",
                status: "",
                apiKey,
              })
                .then((jsonAttribute) => {
                  // console.log(jsonAttribute)
                  if (
                    jsonAttribute.data &&
                    jsonAttribute.data.hub_server_info_attributes &&
                    jsonAttribute.data.hub_server_info_attributes.length > 0
                  ) {
                    infoArray.push({
                      info: json.data.hub_server_info[index],
                      attribute:
                        jsonAttribute.data.hub_server_info_attributes.find(
                          (x) => x.name === "ServerID"
                        ),
                    });
                  }

                  if (index + 1 === json.data.hub_server_info.length) {
                    // console.log("jsonAttribute.data", infoArray);
                    setHubServerInputData({
                      jsonCurrent: infoArray,
                      newSearchText: "",
                    });
                    dispatch(
                      setHubServerConfig({
                        jsonCurrent: infoArray,
                        newSearchText: "",
                      })
                    );
                    setLoadingValue(false);
                  }
                })
                .catch((errorr) => {
                  setLoadingValue(false);
                  if (process.env.NODE_ENV === "development")
                    console.log("GetHUBServerInfoAttributeerrorr", errorr);
                });
            }
          }
        })
        .catch((errorr) => {
          setLoadingValue(false);
          if (process.env.NODE_ENV === "development")
            console.log("GetHUBServerInfoerrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  }, [organziation]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // navigate(`/device?device=${uid}&DeviceTitleDataName=${DeviceTitleDataName}`)
    setSelected(newSelected);
  };
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let inputObj = hubServerInputData;
    inputObj.newSearchText = newSearchText;
    dispatch(setHubServerConfig(inputObj));
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (hubServerConfigSliceData && hubServerConfigSliceData.length > 0) {
        return stableSort(
          hubServerConfigSliceData,
          getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, hubServerConfigSliceData]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ width: "100%" }}>
              {
                //<HomePagesMenu pageName={'Maintenance'} />
              }
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <Typography
                sx={{
                  fontSize: "45px",
                  mb: 0,
                  color: theme.palette.custom.text,
                }}
                variant="h2"
                gutterBottom
              >
                {
                  // {capitalize(t('Devices'))}
                }
                {t("HubServerConfig")}
              </Typography>
            </Box>
          </Box>
          <StyledButton
            variant="contained"
            sx={{
              height: "36px",
              backgroundColor: theme.palette.custom.buttonColor,
              color: theme.palette.custom.buttonTextColor,
              "&:hover": {
                backgroundColor: theme.palette.custom.buttonHoverColor,
                color: theme.palette.custom.buttonHoverTextColor,
                boxShadow: "none",
              },
              float: "right",
              whiteSpace: "nowrap",
              padding: "1rem",
            }}
            onClick={(event) => {
              handleNavigate(event, navigate, "/addEditHubServerConfig");
              // navigate();
            }}
          >
            {t("AddNewHubServerConfig")}
          </StyledButton>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <SearchInput
        searchText={searchText}
        handleSearch={handleSearch}
        autoFocus
        sx={{
          width: { xs: "100%", sm: "20%" },
          borderColor: theme.palette.custom.borderColor,
          borderRadius: "11px",
          backgroundColor: theme.palette.custom.light,
        }}
      />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {hubServerConfigSliceData &&
              hubServerConfigSliceData.length > 0 &&
              visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <ServerHubConfigTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={hubServerConfigSliceData ? hubServerConfigSliceData.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.buttonColor,
          color: theme.palette.custom.buttonTextColor,
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          exportTableToCSV(headCells, visibleRows, "Organizations List")
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}

export default HubServerConfig;
