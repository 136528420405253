import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, TableCell, TableRow, Collapse, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PropTypes from "prop-types";
import StyledTypography from "../common/StyledTypography";
import { useNavigate } from "react-router-dom";
import ViewButton from "../common/viewButton";
import DevicesService from "../api/devices";
import PersonService from "../api/person";
import CollapseButton from "../common/collapseButton";
import { setOrganization } from "../store/organziationSlice";
import { setPermissions } from "../store/permissionsSlice";
import handleNavigate from "../common/customFunctions/handleNavigate";

export default function OrganizationsTableRow({
  row,
  labelId,
  isSelected,
  isProfile,
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(({ user }) => user);
  const [totalDev, setTotalDev] = useState("0");
  const [totalUs, setTotalUs] = useState("0");

  function handleOpenCollapse() {
    if (!open) {
      DevicesService.GetDevicesCount({
        DeviceID: row.DeviceID,
        DeviceType: [
          "nerohub",
          "nanohub",
          "cyberhub",
          "stratahub",
          "nerotrax",
          "train",
          "autonomous hub",
        ],
        apiKey: row.apiKey,
      })
        .then((jsonDeviceCount) => {
          setTotalDev(jsonDeviceCount);
          PersonService.GetUserCount({
            apiKey: row.apiKey,
          })
            .then((jsonUserCount) => {
              setTotalUs(jsonUserCount);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    setOpen(!open);
  }
  function handleViewOrganization(event) {
    const userOrganization = user.organizations?.find(
      (org) => org?.operation_profile_info?.uid === row.id
    );
    if (userOrganization) {
      handleChooseOrganization(userOrganization);
      handleNavigate(event, navigate, "/organization");
      window.location.reload();
      // navigate();
    } else {
      // show error
    }
  }
  function handleViewOrganizationConfig(event) {
    handleNavigate(
      event,
      navigate,
      `/organizationConfig?id=${row.id}&name=${row.name}`
    );
  }
  const handleChooseOrganization = (userOrgObj) => {
    const operationProfileInfo = userOrgObj.operation_profile_info;
    localStorage.setItem(
      "defaultOrganization",
      JSON.stringify(operationProfileInfo)
    );
    localStorage.setItem(
      "apiKey",
      operationProfileInfo?.api_key_list?.[0]?.key
    );
    dispatch(setOrganization(operationProfileInfo));
    // window.location.reload();
    const operationPersonProfileInfo =
      userOrgObj.operation_person_profile_link_info;
    const apiKey = operationProfileInfo.api_key_list[0].key;
    const userType = operationPersonProfileInfo.user_type_uid;
    PersonService.getUserTypePermissions({ apiKey, userType })
      .then((json) => {
        const UserPermissions = [];
        if (
          json &&
          json.app_user_type_permission_link &&
          json.app_user_type_permission_link.length > 0
        ) {
          for (let i in json.app_user_type_permission_link)
            UserPermissions.push(
              json.app_user_type_permission_link[i].permission_secondary
                .permission_secondary_uid
            );
          localStorage.setItem("permissions", JSON.stringify(UserPermissions));
          dispatch(setPermissions(UserPermissions));
        }
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === "development")
          console.log("getUserTypePermissionserrorr", errorr);
      });
  };
  console.log("OrganizartionRow", row);
  return (
    <Fragment key={row.DeviceID}>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        key={row.DeviceID}
        selected={isSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <CollapseButton handleOpenCollapse={handleOpenCollapse} open={open} />
        </TableCell>
        <TableCell
          sx={{ height: "100%", color: theme.palette.custom.text }}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <span
              className="pfpSpan"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(${row.iconPublicURL})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                border: "0px solid",
                borderRadius: "20px",
              }}
            ></span>
            <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
              {row.name}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.emailAddress}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.mobileNumber}
          </StyledTypography>
        </TableCell>
        {!isProfile && (
          <TableCell align="left">
            <StyledTypography variant="subtitle2">
              {row.website}
            </StyledTypography>
          </TableCell>
        )}

        <TableCell align="left">
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <Tooltip title={`${t("open Config")}`}>
              <Box
                onClick={handleViewOrganizationConfig}
                onMouseDown={handleViewOrganizationConfig}
                sx={{
                  //marginRight: marginRight ?? 0,
                  backgroundColor: theme.palette.custom.buttonColor,
                  borderRadius: "4px",
                  border: `1px solid ${theme.palette.custom.borderColor}`,
                  marginRight: "4px",
                  width: "46px",
                  height: "36px",
                  padding: "5px 10px",
                  "&:hover": {
                    backgroundColor: theme.palette.custom.buttonHoverColor,
                    color: theme.palette.custom.buttonHoverTextColor,
                    boxShadow: "none",
                  },
                }}
              >
                <SettingsIcon
                  onClick={handleViewOrganizationConfig}
                  onMouseDown={handleViewOrganizationConfig}
                  sx={{
                    color: theme.palette.custom.buttonTextColor,
                    fontSize: 24,
                    "&:hover": {
                      color: theme.palette.custom.buttonHoverTextColor,
                      boxShadow: "none",
                    },
                  }}
                />
              </Box>
            </Tooltip>
            <ViewButton onClick={handleViewOrganization} />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow
        key={row.DeviceID}
        sx={{ backgroundColor: theme.palette.custom.backgroundColor2 }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                sx={{ color: theme.palette.custom.text, fontWeight: "bold" }}
                variant="h6"
                gutterBottom
                component="span"
              >
                {`${t("ORGANIZATION")} ${t("DATA")} ${t("FOR")}:`}
              </Typography>
              <Typography
                sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
                variant="h6"
                gutterBottom
                component="span"
              >
                {" "}
                {row.name}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.custom.borderColor,
                  fontWeight: "bold",
                }}
                variant="h6"
                gutterBottom
                component="span"
              >
                {" "}
                {"SUMMARY"}
              </Typography>
              <Box
                sx={{
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>{`${t("Total")} ${t("Machines")}`}</Box>
                  <Box
                    sx={{
                      color: theme.palette.custom.text,
                      fontWeight: "bold",
                    }}
                    variant="h6"
                    gutterBottom
                    component="span"
                  >
                    {totalDev}
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>{`${t("Total")} ${t("Users")}`}</Box>
                  <Box
                    sx={{
                      color: theme.palette.custom.text,
                      fontWeight: "bold",
                    }}
                    variant="h6"
                    gutterBottom
                    component="span"
                  >
                    {totalUs}
                  </Box>
                </Box>
                {/* <Box sx={{ textAlign: "center", width: '100%', display: "flex", flexDirection: "column" }}>
                                    <Box>{`${t('Tax')} ${t('Number')}`}</Box>
                                    <Box sx={{ color: theme.palette.custom.text, fontWeight: "bold" }} variant="h6" gutterBottom component="span">{row.tax_number ? row.tax_number : "N/A"}</Box>
                                </Box>
                                <Box sx={{ textAlign: "center", width: '100%', display: "flex", flexDirection: "column" }}>
                                    <Box>{`${t('VAT')} ${t('Number')}`}</Box>
                                    <Box sx={{ color: theme.palette.custom.text, fontWeight: "bold" }} variant="h6" gutterBottom component="span">{row.vat_number ? row.vat_number : "N/A"}</Box>
                                </Box> */}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
OrganizationsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  labelId: PropTypes.string,
  isSelected: PropTypes.bool,
  isProfile: PropTypes.bool,
};
