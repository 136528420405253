import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { Suspense } from "react";
// import defaultTheme from './theme'
import Theme from "./utils/themeService";
import store from "./store";
import GetRouter from "./utils/router";

function App() {
  const [router, setRouter] = useState(
    GetRouter(Boolean(localStorage.getItem("auth_token")))
  );
  if (Number(localStorage.getItem("rowsPerPage")) < 5) {
    localStorage.setItem("rowsPerPage", 10);
  }
  if (!localStorage.getItem("color_theme")) {
    localStorage.setItem("color_theme", "dark");
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setRouter(GetRouter(Boolean(localStorage.getItem("auth_token"))));
    };

    window.addEventListener("authToken", handleStorageChange);

    return () => {
      window.removeEventListener("authToken", handleStorageChange);
    };
  }, []);
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <Suspense fallback={<h2>🌀 Loading...</h2>}> */}
        <Theme>
          <CssBaseline />

          <div style={{ minHeight: "100vh" }}>
            <RouterProvider router={router} />
          </div>
        </Theme>
        {/* </Suspense> */}
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
