import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import TicketsService from "../api/tickets";
import OrganizationService from "../api/organization";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import CustomTextField from "../common/CustomTextField";
import SelectFields from "../common/selectFields";
import { useNavigate } from "react-router-dom";
import { showMessage } from "../store/messageSlice";



const statuses = [
    {
        value: "active",
        label: "active"
    },
    {
        value: "removed",
        label: "removed"
    }
];


export default function NewTicketTemplate() {
    const theme = useTheme();
    const { t } = useTranslation();
    const organziation = useSelector(({ organziation }) => organziation);
    const template = useSelector(({ template }) => template);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [orderBy, setorderBy] = useState("");
    const [depID, setDepID] = useState("");
    const [departments, setDepartments] = useState([]);
    const [status, setStatus] = useState("active");
    const [description, setDescription] = useState("");
    const [apiKey, setApiKey] = useState("");

    useEffect(() => {
        setApiKey(organziation?.api_key_list?.[0]?.key);
        OrganizationService.GetOperationDepartment("", "", "", "active", apiKey).then(json => {
            const deps = [];
            for (let i in json.department) {
                deps.push({
                    value: json.department[i].department_id,
                    label: json.department[i].name
                });
            }
            setDepartments(deps)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setName(template.name);
        setorderBy(template.order_by);
        setDepID(template.department_id);
        setStatus(template.status);
        setDescription(template.description);
    }, [template]);

    const handleChangeDepartment = (value) => {
        setDepID(value);
    };
    const handleChangeStatus = (value) => {
        setStatus(value);
    };
    function handleSave() {
        TicketsService.SetTicketTemplateHeaders(template.template_header_uid, name, description, orderBy, depID, status, apiKey).then(json => {
            if (json?.ticket_template_header?.length) {
                dispatch(
                    showMessage({
                        message: t("Template Added Successfully"), //text or html
                        autoHideDuration: 10000, //ms
                        anchorOrigin: {
                            vertical: "top", //top bottom
                            horizontal: "center", //left center right
                        },
                        variant: "success", //success error info warning null
                    })
                );
                navigate("/templates");
            }
            else {
                dispatch(
                    showMessage({
                        message: t("error occurred"), //text or html
                        autoHideDuration: 10000, //ms
                        anchorOrigin: {
                            vertical: "top", //top bottom
                            horizontal: "center", //left center right
                        },
                        variant: "error", //success error info warning null
                    })
                );
            }
        })
    }
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{
                height: "100%"
            }}>
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                <Typography sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.borderColor }} variant="h2" gutterBottom>
                    {t("New Ticket Template")}:
                </Typography>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
            <Box sx={{ display: { xs: "block", sm: "flex" }, width: "100%", flexDirection: "row", color: "#FFFFFF" }}>
                <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row", width: "100%", alignItems: "center" }}>
                    <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "column", width: "50%" }}>
                        <CustomTextField
                            id="txtName"
                            label={t("Template Name")}
                            variant="filled"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{
                                width: { xs: "98%", sm: "98%" },
                                borderColor: theme.palette.custom.borderColor,
                                borderRadius: "11px",
                                backgroundColor: theme.palette.custom.light,
                                marginBottom: "5px"
                            }}
                        />
                        <CustomTextField
                            id="txtOrder"
                            label={t("Display Order")}
                            variant="filled"
                            value={orderBy}
                            onChange={(e) => setorderBy(e.target.value)}
                            sx={{
                                width: { xs: "98%", sm: "98%" },
                                borderColor: theme.palette.custom.borderColor,
                                borderRadius: "11px",
                                backgroundColor: theme.palette.custom.light,
                                marginBottom: "5px",
                                marginTop: "10px"
                            }}
                        />
                    </Box>
                    <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "column", width: "50%" }}>
                        <CustomTextField
                            id="txtDesc"
                            label={t("Description")}
                            variant="filled"
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            sx={{
                                width: { xs: "98%", sm: "98%" },
                                borderColor: theme.palette.custom.borderColor,
                                borderRadius: "11px",
                                backgroundColor: theme.palette.custom.light,
                                marginBottom: "5px"
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center", gap: "0.5rem" }}>
                <SelectFields
                    title={t("Status")}
                    value={status}
                    fields={statuses}
                    onChange={(value) => {
                        handleChangeStatus(value);
                    }}
                />
                <SelectFields
                    title={t("Department")}
                    value={depID}
                    fields={[{ value: "", label: "No Department" }, ...departments]}
                    onChange={(value) => {
                        handleChangeDepartment(value);
                    }}
                />
            </Box>
            <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
                <Button variant="contained" sx={{
                    "backgroundColor": theme.palette.custom.borderColor,
                    "color": "white",
                    '&:hover': {
                        backgroundColor: theme.palette.custom.buttonHoverColor,
                        color: theme.palette.custom.buttonHoverTextColor,
                        boxShadow: 'none',
                    }
                }} onClick={handleSave}>
                    {t("Save")}
                </Button>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
        </Box>
    );
}
