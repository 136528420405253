import Utils from "../utils/utils";

class ScheduledTimeService extends Utils.EventEmitter {
  GetScheduledTimeProfile(profileID, orgID, orgName, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          scheduled_time_profile_id: profileID,
          operation_profile_uid: orgID,
          department_id: "",
          name: orgName,
          description: "",
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/scheduled/time/profile/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetScheduledTimeProfile(
    scheduled_time_profile_id,
    operation_profile_uid,
    name,
    description,
    status
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            scheduled_time_profile_id: scheduled_time_profile_id,
            operation_profile_uid: operation_profile_uid,
            name: name,
            description: description,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/scheduled/time/profile/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetScheduledTimeRoster(config_id, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          scheduled_time_roster_id: "",
          scheduled_time_config_id: config_id,
          time_start: "",
          time_end: "",
          monday: "",
          tuesday: "",
          wednesday: "",
          thursday: "",
          friday: "",
          saturday: "",
          sunday: "",
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/scheduled/time/roster/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetScheduledTimeRoster(
    scheduled_time_config_id,
    scheduled_time_roster_id,
    time_start,
    time_end,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    status,
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            scheduled_time_config_id: scheduled_time_config_id,
            scheduled_time_roster_id: scheduled_time_roster_id,
            time_start: time_start,
            time_end: time_end,
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/scheduled/time/roster/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetScheduledTimeConfig(profileID, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          scheduled_time_profile_id: profileID,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/scheduled/time/config/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetScheduledTimeConfig(
    scheduled_time_config_id,
    scheduled_time_profile_id,
    name,
    description,
    status,
    default_interval
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            scheduled_time_config_id: scheduled_time_config_id,
            scheduled_time_profile_id: scheduled_time_profile_id,
            name: name,
            description: description,
            status: status,
            default_interval: default_interval,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/scheduled/time/config/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetOrganizationShiftTimes(shiftTimeInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          shift_time_config_uid: shiftTimeInfo.shift_time_config_uid,
          operation_uid: shiftTimeInfo.operation_profile_uid,
          name: shiftTimeInfo.name,
          day_of_week: "",
          status: shiftTimeInfo.status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: shiftTimeInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/shift/time/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetOrganizationShiftTimes(shiftTimeInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            shift_time_config_uid: shiftTimeInfo.shift_time_config_uid,
            operation_uid: shiftTimeInfo.operation_uid,
            name: shiftTimeInfo.name,
            day_of_week: "1",
            start_time: shiftTimeInfo.start_time,
            end_time: shiftTimeInfo.end_time,
            status: shiftTimeInfo.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: shiftTimeInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/shift/time/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetExceptions(
    exception_id,
    config_id,
    name,
    description,
    date,
    datetime_start,
    datetime_end,
    status
  ) {
    return new Promise((resolve, reject) => {
      let body = "";
      if (datetime_start !== "" && datetime_end !== "") {
        body = JSON.stringify({
          post: [
            {
              scheduled_time_exception_id: exception_id,
              scheduled_time_config_id: config_id,
              name: name,
              description: description,
              datetime_start: datetime_start,
              datetime_end: datetime_end,
              status: status,
            },
          ],
        });
      } else {
        body = JSON.stringify({
          post: [
            {
              scheduled_time_exception_id: exception_id,
              scheduled_time_config_id: config_id,
              name: name,
              description: description,
              date: date,
              status: status,
            },
          ],
        });
      }
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/scheduled/time/exception/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetExceptions(exceptionInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          scheduled_time_exception_id: exceptionInfo.exception_id,
          scheduled_time_config_id: exceptionInfo.config_id,
          name: "",
          description: "",
          datetime_start: {
            f: "",
            t: "",
          },
          datetime_end: {
            f: "",
            t: "",
          },
          status: exceptionInfo.status,
          updated_timestamp: {
            f: "",
            t: "",
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: exceptionInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/config/scheduled/time/exception/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}

const instance = new ScheduledTimeService();

export default instance;
