/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setTemplate = createAsyncThunk(
  "template/setTemplate",
  async (data) => {
    return data;
  }
);

const initialState = {
  template_header_uid: "",
  name: "",
  description: "",
  order_by: "",
  department_id: "",
  status: "active",
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setTemplate.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectTemplate = ({ template }) =>
  template;

export default templateSlice.reducer;
