import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export default function StyledColumnBox({ children }) {
    return (
        <Box sx={{
            textAlign: "center", display: 'flex', flexFlow: 'column'
        }}>
            {children}
        </Box>
    );
}
StyledColumnBox.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ]),
};