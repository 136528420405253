import Utils from "../utils/utils";

class PesronService extends Utils.EventEmitter {
  UserAuthValidate(userObject) {
    if (
      userObject.email.length === 10 &&
      userObject.email.charAt(0) === "0" &&
      userObject.email.includes("@") === false
    ) {
      // replace only the first zero if the first char in string is a '0' and has length 10 - most likely a mobile number
      userObject.email = userObject.email.replace("0", "27");
    }

    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        auth: {
          contact_uid: "",
          person_profile_uid: "",
          contact_type: "",
          value: userObject.email,
          primary_contact: "",
          status: "active",
          response: false,
        },
      });
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/person/auth/validate/contact`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetUserpassword(userObject) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            person_profile_uid: userObject.id,
            password: userObject.password,
            status: "active",
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/person/profile/login/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  getUserTypePermissions(user) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          user_type_uid: user.userType,
          name: "",
          status: "active",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: user.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/app/user/type/permission/get`,
        {
          method: "post",
          headers: headers,
          body: body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetUserCount(user) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          profile_id: "",
          app_profile_uid: "",
          firstname: "",
          lastname: "",
          tel_primary: "",
          email_primary: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: user.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          let Organization_Total_Users = 0;
          if (json.success === true && json.count > 0)
            Organization_Total_Users = json.count;
          resolve(Organization_Total_Users);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetUserOrganizations({ operation_uid, person_id, apiKey }) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_uid,
          person_id,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/operation/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new PesronService();

export default instance;
