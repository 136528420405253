import Utils from "../utils/utils";

class TicketsService extends Utils.EventEmitter {
  GetOperationDepartment(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          department_id: inputData.department_id,
          operation_profile_uid: inputData.operation_profile_uid,
          name: inputData.name,
          status: inputData.status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/operation/department/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetTickets(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          ticket_header_uid: "",
          app_profile_uid: "",
          operation_profile_uid: "",
          ticket_template_header_uid: "",
          status: "",
          created_by_person_uid: inputData.created_by_person_uid,
          updated_by_person_uid: "",
          created_timestamp_from: "",
          created_timestamp_to: "",
          updated_timestamp_from: "",
          updated_timestamp_to: "",
          assigned_to_person_uid: inputData.assigned_to_person_uid,
          department_id: inputData.department_id,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/ticket/header/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetTicketTemplateInputs(apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          input_requirement_config_uid: "",
          requirement: "",
          request: "",
          input_type: "",
          order_by: "",
          status: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/app/config/input/requirement/get`,
        {
          method: "POST",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetTicketTemplateHeaders(
    template_header_uid,
    department_id,
    status = "active",
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          template_header_uid: template_header_uid,
          department_id: department_id,
          name: "",
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/ticket/template/header/get`,
        {
          method: "POST",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetTemplateInputs(template_header_uid, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          template_header_uid: "" + template_header_uid + "",
          name: "",
          status: "active",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/ticket/template/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetTicketDashboardInfo(apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          ticket_header_uid: "",
          app_profile_uid: "",
          operation_profile_uid: "",
          ticket_template_header_uid: "",
          status: "",
          created_by_person_uid: "",
          updated_by_person_uid: "",
          created_timestamp_from: "",
          created_timestamp_to: "",
          updated_timestamp_from: "",
          updated_timestamp_to: "",
          assigned_to_person_uid: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/ticket/header/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  // GetTicketTemplateHeaders(inputData) {
  //     return new Promise((resolve, reject) => {
  //         const body = JSON.stringify({
  //             "get": {
  //                 "template_header_uid": inputData.template_header_uid,
  //                 "department_id": inputData.department_id,
  //                 "name": "",
  //                 "status": inputData.status
  //             }
  //         });
  //         const headers = {
  //             "Content-Type": 'application/json',
  //             "apiKey": inputData.apiKey,
  //             "apiSession": localStorage.getItem("auth_token")
  //         };
  //         fetch(`${window.env.REACT_APP_API_IP}/ns-core/ticket/template/header/get`, {
  //             method: 'post',
  //             headers,
  //             body
  //         }).then((response) => response.json())
  //             //axios
  //             // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

  //             .then(json => {
  //                 resolve(json);

  //             }).catch((err) => {
  //                 if (process.env.NODE_ENV === 'development') console.log('err ', err)
  //                 reject(err.response !== undefined ? err.response.data.error_description : null)
  //             });
  //     });
  // }

  SaveTicketAPI(data, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [data],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/ticket/post`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetTicketTemplateHeaders(
    template_header_uid,
    name,
    description,
    order_by,
    department_id,
    status = "active",
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            template_header_uid,
            name,
            description,
            order_by,
            department_id,
            status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/ticket/template/header/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetTicketTemplateRequirementHeaders(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            template_requirement_uid: inputData.template_requirement_uid,
            template_header_uid: "" + inputData.template_header_uid + "",
            name: inputData.name,
            description: inputData.description,
            order_by: inputData.order_by,
            status: inputData.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/ticket/template/requirement/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetTemplateInput(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            template_requirement_input_uid:
              inputData.template_requirement_input_uid,
            template_requirement_uid: inputData.template_requirement_uid,
            input_requirement_config_uid:
              inputData.input_requirement_config_uid,
            name: inputData.name,
            description: inputData.description,
            order_by: inputData.order_by,
            status: inputData.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/ticket/template/requirement/input/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SaveInputChooseItems(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            template_requirement_input_select_uid:
              inputData.template_requirement_input_select_uid,
            input_requirement_config_uid:
              inputData.input_requirement_config_uid,
            template_requirement_input_uid:
              inputData.template_requirement_input_uid,
            description: inputData.description,
            name: inputData.name,
            status: inputData.status,
            order_by: inputData.order_by,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/ticket/template/requirement/input/select/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}

const instance = new TicketsService();

export default instance;
