import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {Box, Divider, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import LdapService from "../api/Ldap.js";
import {useDispatch, useSelector} from "react-redux";
import TemplateTable from "../common/Layout/templatePages/templateTable";
import handleNavigate from "../common/customFunctions/handleNavigate";
import {setLdapUser} from "../store/ldapUserSlice";
import {useNavigate} from "react-router-dom";

function LdapUsers() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const organziation = useSelector(({organziation}) => organziation);
    const [loadingValue, setLoadingValue] = useState(true);
    const [ldapUsers, setLdapUsers] = useState([]);

    useEffect(() => {
        if (organziation) {
            const apiKey = organziation?.api_key_list?.[0]?.key;
            LdapService.GetLdapUsers({
                // operation_id: organziation.uid,
                apiKey
            })
                .then((jsonCurrent) => {
                    if (jsonCurrent?.person_ldap_user?.length) {
                        setLdapUsers(jsonCurrent?.person_ldap_user);
                    }
                    setLoadingValue(false);
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === "development") console.log("error", errorr);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButton = (event, buttonType, row) => {
        if (buttonType === "view") {
            handleView(event, row);
        }
    };
    const handleView = (event, row) => {
        dispatch(setLdapUser(row));
        event.stopPropagation();
        handleNavigate(event, navigate, `/viewLdapUser?id=${row.ldap_user_id}`);
    };
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`,
                backgroundColor: theme.palette.custom.backgroundColor
            }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: {xs: "block", sm: "center"},
                    gap: "1rem",
                    mb: "1rem"
                }}>
                <Typography sx={{fontSize: "45px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("LdapUsers")}
                </Typography>
            </Box>
            <Divider sx={{mb: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <TemplateTable
                itemsList={ldapUsers}
                headCells={[
                    {id: "name"},
                    {id: "user_principal_name", label: "Email"},
                    // { id: "mail", label: "Email" },
                    // { id: "display_name", label: "Display Name" },
                    // { id: "given_name", label: "Given Name" },
                    {
                        id: "created_timestamp",
                        label: "Created",
                        attributeType: "dateTime"
                    },
                    {
                        id: "updated_timestamp",
                        label: "Updated",
                        attributeType: "dateTime"
                    },
                    {
                        id: "action",
                        buttons: ["view"]
                    }
                ]}
                handleButton={handleButton}
                itemId="ldap_user_id"
                loading={loadingValue}
                searchAttributes={["name", "user_principal_name"]}
                title="Server Users"
                allowExport
                exportTitle={t("LdapServerUsers")}
            />
        </Box>
    );
}

export default LdapUsers;
