import React, {useEffect, useState} from "react";
import {styled, useTheme} from "@mui/material/styles";
import {Box, Button, Dialog, DialogActions, DialogTitle, Divider, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import LdapService from "../api/Ldap";
import {useDispatch, useSelector} from "react-redux";
import TemplateTable from "../common/Layout/templatePages/templateTable";
import handleNavigate from "../common/customFunctions/handleNavigate";
import {setLdapServer} from "../store/ldapServerSlice";
import {useNavigate} from "react-router-dom";
import StyledTypography from "../common/StyledTypography";
import Loading from "../common/loading";
import AlertDialog from "../common/AlertDialog";

const StyledButton = styled((props) => <Button {...props} />)(({theme}) => ({
    "height": "36px",
    "backgroundColor": theme.palette.custom.buttonColor,
    "color": theme.palette.custom.buttonTextColor,
    "&:hover": {
        backgroundColor: theme.palette.custom.buttonHoverColor,
        color: theme.palette.custom.buttonHoverTextColor,
        boxShadow: "none"
    },
    "float": "right",
    "width": "min-content",
    "whiteSpace": "nowrap",
    "padding": "1rem"
}));
function LdapServers() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const organziation = useSelector(({organziation}) => organziation);
    const [loadingValue, setLoadingValue] = useState(true);
    const [ldapServers, setLdapServers] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [testConnectionResult, setTestConnectionResult] = useState([]);

    useEffect(() => {
        getServers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function getServers() {
        if (organziation) {
            const apiKey = organziation?.api_key_list?.[0]?.key;
            LdapService.GetLdapServers({
                OrganizationId: organziation.uid,
                apiKey
            })
                .then((jsonCurrent) => {
                    if (jsonCurrent?.person_ldap_server) {
                        // console.log(jsonCurrent.person_ldap_server)
                        const activeServers = jsonCurrent?.person_ldap_server.filter((item) => item.status !== "deleted");
                        // console.log(activeServers)
                        setLdapServers(activeServers);
                    }
                    setLoadingValue(false);
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === "development") console.log("error", errorr);
                });
        }
    }
    const handleAddNew = (event) => {
        handleNavigate(event, navigate, "/addEditLdapServer");
    };
    const handleButton = (event, buttonType, row) => {
        if (buttonType === "edit") {
            handleEdit(event, row);
        }
        if (buttonType === "assign") {
            handleAssign(event, row);
        }
        if (buttonType === "groups") {
            handleGroups(event, row);
        }
        if (buttonType === "connection") {
            handleTestConnection(event, row);
        }
        if (buttonType === "delete") {
            handleOpenDeleteAlertDialog(event, row);
        }
    };
    const handleEdit = (event, row) => {
        dispatch(setLdapServer(row));
        event.stopPropagation();
        const queryParams = new URLSearchParams({
            id: row.ldap_server_id,
            sid: row.ldap_server_search_id
        }).toString();
        handleNavigate(event, navigate, `/addEditLdapServer?id=${queryParams}`);
    };
    const handleAssign = (event, row) => {
        dispatch(setLdapServer(row));
        event.stopPropagation();
        handleNavigate(event, navigate, `/ldapUserTypes?id=${row.ldap_server_id}`);
    };
    const handleGroups = (event, row) => {
        dispatch(setLdapServer(row));
        event.stopPropagation();
        handleNavigate(event, navigate, `/ldapGroups?id=${row.ldap_server_id}`);
    };
    const handleTestConnection = (event, row) => {
        console.log(row);
        setOpenDialog(true);
        const apiKey = organziation?.api_key_list?.[0]?.key;
        LdapService.TestConnection({...row, apiKey}).then((json) => {
            if (json.success === true && json.count > 0) {
                setTestConnectionResult(json.person_ldap_server_test_connection);
            }
        });
    };
    const handleOpenDeleteAlertDialog = (event, row) => {
        setItemToDelete(row);
    };
    const handleDelete = () => {
        const apiKey = organziation?.api_key_list?.[0]?.key;
        LdapService.DeleteLdapServers({
            ldap_server_id: itemToDelete.ldap_server_id,
            apiKey
        }).then((json) => {
            if (json.success === true && json.count > 0) {
                getServers();
                setLoadingValue(true);
                handleCloseDeleteAlertDialog();
            }
        });
    };
    const handleCloseDeleteAlertDialog = () => {
        setItemToDelete(null);
    };
    function handleCloseDialog() {
        setTestConnectionResult([]);
        setOpenDialog(false);
    }
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`,
                backgroundColor: theme.palette.custom.backgroundColor
            }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: {xs: "block", sm: "center"},
                    gap: "1rem",
                    mb: "1rem"
                }}>
                <Typography sx={{fontSize: "45px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("LdapServers")}
                </Typography>
                <StyledButton variant="contained" onClick={handleAddNew}>
                    {t("AddNewLdapServer")}
                </StyledButton>
            </Box>
            <Divider sx={{mb: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <TemplateTable
                headCells={[
                    {id: "target_host", label: "TargetHost"},
                    {id: "target_port", label: "TargetPort"},
                    {id: "tls"},
                    {id: "bind_user_name", label: "Username"},
                    {
                        id: "created_timestamp",
                        label: "Created",
                        attributeType: "dateTime"
                    },
                    {
                        id: "updated_timestamp",
                        label: "Updated",
                        attributeType: "dateTime"
                    },
                    {
                        id: "latest_sync_timestamp",
                        label: "Last Sync",
                        attributeType: "dateTime"
                    },
                    {
                        id: "action",
                        buttons: ["edit", "connection", "assign", "groups", "delete"]
                    }
                ]}
                handleButton={handleButton}
                itemsList={ldapServers}
                itemId="ldap_server_id"
                loading={loadingValue}
                searchAttributes={["target_host", "target_port", "bind_user_name"]}
                allowExport
                exportTitle={t("LdapServers")}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle sx={{color: theme.palette.custom.text}}>{t("Connection Test Result")}</DialogTitle>
                <Box m="1rem" sx={{minWidth: {md: "35rem"}}}>
                    {testConnectionResult?.length ? (
                        testConnectionResult.map((item) => (
                            <Box mt="1rem" key={item.test_title}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "1rem"
                                    }}>
                                    <StyledTypography>{item.test_title}:</StyledTypography>
                                    <StyledTypography sx={{fontWeight: "bold"}} color={item.success ? "green" : "red"}>
                                        {t(item.success ? "OK" : "Error")}
                                    </StyledTypography>
                                </Box>
                                {!item.success && <StyledTypography color="red">{item.test_result[0] || item.exception_error_msg}</StyledTypography>}
                            </Box>
                        ))
                    ) : (
                        <Loading />
                    )}
                </Box>
                <DialogActions>
                    <Button
                        sx={{
                            "color": theme.palette.custom.buttonTextColor,
                            "backgroundColor": theme.palette.custom.borderColor,
                            "&:hover": {
                                backgroundColor: theme.palette.custom.buttonHoverColor,
                                color: theme.palette.custom.buttonHoverTextColor,
                                boxShadow: "none"
                            }
                        }}
                        onClick={handleCloseDialog}>
                        {t("OK")}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog
                open={Boolean(itemToDelete)}
                onClose={handleCloseDeleteAlertDialog}
                onDoFunction={() => {
                    handleDelete();
                }}
                dialogTitle={t("Are You sure You want to delete This Server? This can not be undone!")}
                cancelLabel={t("Cancel")}
                buttonLabel={t("Delete")}
            />
        </Box>
    );
}

export default LdapServers;
