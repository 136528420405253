import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import DevicesService from "../api/devices";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import DeviceRowDataTableRow from "./deviceRowDataTableRow";
import {
  getUrlVars,
  GetDateTimeStringCustom,
  getComparator,
  stableSort,
} from "../utils/systemInfo";
import StyledTypography from "../common/StyledTypography";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const headCells = [
  {
    id: "payload.dst",
    numeric: false,
    disablePadding: true,
    label: "data_dst",
    sort: true,
  },
  {
    id: "payload.battery_healthy_b",
    numeric: false,
    disablePadding: true,
    label: "battery_healthy_b",
    sort: true,
  },
  {
    id: "payload.battery_level",
    numeric: false,
    disablePadding: true,
    label: "battery_level",
    sort: true,
  },
  {
    id: "payload.battery_low_b",
    numeric: false,
    disablePadding: true,
    label: "battery_low_b",
    sort: true,
  },
  {
    id: "payload.battery_voltage",
    numeric: true,
    disablePadding: true,
    label: "battery_voltage",
    sort: true,
  },
  {
    id: "payload.device_healthy_b",
    numeric: true,
    disablePadding: true,
    label: "device_healthy_b",
    sort: true,
  },
  {
    id: "payload.external_temperature",
    numeric: true,
    disablePadding: true,
    label: "external_temperature",
    sort: true,
  },
  {
    id: "payload.internal_temperature",
    numeric: true,
    disablePadding: true,
    label: "internal_temperature",
    sort: true,
  },
  {
    id: "payload.lat",
    numeric: true,
    disablePadding: true,
    label: "lat",
    sort: true,
  },
  {
    id: "payload.lon",
    numeric: true,
    disablePadding: true,
    label: "lon",
    sort: true,
  },
  {
    id: "payload.level_reading_at_present",
    numeric: false,
    disablePadding: true,
    label: "level_reading_at_present",
    sort: true,
  },
  {
    id: "payload.level_reading_at_present_1t",
    numeric: false,
    disablePadding: true,
    label: "level_reading_at_present_1t",
    sort: true,
  },
  {
    id: "payload.level_reading_at_present_2t",
    numeric: false,
    disablePadding: true,
    label: "level_reading_at_present_2t",
    sort: true,
  },
  {
    id: "payload.level_reading_at_present_3t",
    numeric: false,
    disablePadding: true,
    label: "level_reading_at_present_3t",
    sort: true,
  },
  {
    id: "payload.level_reading_at_present_status_i",
    numeric: false,
    disablePadding: true,
    label: "level_reading_at_present_status_i",
    sort: true,
  },
  {
    id: "payload.level_reading_at_present_status_i_1t",
    numeric: false,
    disablePadding: true,
    label: "level_reading_at_present_status_i_1t",
    sort: true,
  },
  {
    id: "payload.level_reading_at_present_status_i_2t",
    numeric: true,
    disablePadding: true,
    label: "level_reading_at_present_status_i_2t",
    sort: true,
  },
  {
    id: "payload.level_reading_at_present_status_i_3t",
    numeric: true,
    disablePadding: true,
    label: "level_reading_at_present_status_i_3t",
    sort: true,
  },
  {
    id: "payload.link_quality",
    numeric: true,
    disablePadding: true,
    label: "link_quality",
    sort: true,
  },
  {
    id: "payload.liquid_detect",
    numeric: true,
    disablePadding: true,
    label: "liquid_detect",
    sort: true,
  },
  {
    id: "payload.liquid_detect_b",
    numeric: true,
    disablePadding: true,
    label: "liquid_detect_b",
    sort: true,
  },
  {
    id: "payload.measured_val",
    numeric: true,
    disablePadding: true,
    label: "measured_val",
    sort: true,
  },
  {
    id: "payload.measurement_interval",
    numeric: false,
    disablePadding: true,
    label: "measurement_interval",
    sort: true,
  },
  {
    id: "payload.message_id",
    numeric: false,
    disablePadding: true,
    label: "msg_id",
    sort: true,
  },
  {
    id: "payload.movement_detect_b",
    numeric: false,
    disablePadding: true,
    label: "movement_detect_b",
    sort: true,
  },
  {
    id: "payload.msg_id",
    numeric: false,
    disablePadding: true,
    label: "msg_id",
    sort: true,
  },
  {
    id: "payload.peak_flow_since_last_tx",
    numeric: false,
    disablePadding: true,
    label: "peak_flow_since_last_tx",
    sort: true,
  },
  {
    id: "payload.sensor_error_b",
    numeric: false,
    disablePadding: true,
    label: "sensor_error_b",
    sort: true,
  },
  {
    id: "payload.sensor_error_type",
    numeric: false,
    disablePadding: true,
    label: "sensor_error_type",
    sort: true,
  },
  {
    id: "payload.status",
    numeric: false,
    disablePadding: true,
    label: "status",
    sort: true,
  },
  {
    id: "payload.temperature",
    numeric: false,
    disablePadding: true,
    label: "temperature",
    sort: true,
  },
  {
    id: "payload.total_time_to_date",
    numeric: false,
    disablePadding: true,
    label: "total_time_to_date",
    sort: true,
  },
  {
    id: "payload.total_volume_to_date",
    numeric: false,
    disablePadding: true,
    label: "total_volume_to_date",
    sort: true,
  },
  {
    id: "payload.volume_since_last_tx",
    numeric: false,
    disablePadding: true,
    label: "volume_since_last_tx",
    sort: true,
  },
  {
    id: "payload.zone_id",
    numeric: false,
    disablePadding: true,
    label: "zone_id",
    sort: true,
  },
  {
    id: "payload.cno_ok_b",
    numeric: false,
    disablePadding: true,
    label: "cno_ok_b",
    sort: true,
  },
  {
    id: "payload.gps_lock_b",
    numeric: false,
    disablePadding: true,
    label: "gps_lock_b",
    sort: true,
  },
  {
    id: "payload.gps_used_b",
    numeric: false,
    disablePadding: true,
    label: "gps_used_b",
    sort: true,
  },
  {
    id: "payload.hdop",
    numeric: false,
    disablePadding: true,
    label: "hdop",
    sort: true,
  },
  {
    id: "payload.beacon_visible_b",
    numeric: false,
    disablePadding: true,
    label: "beacon_visible_b",
    sort: true,
  },
  {
    id: "source",
    numeric: false,
    disablePadding: true,
    label: "source",
    sort: true,
  },
];
export default function DeviceRowDataTab() {
  const theme = useTheme();
  const { t } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState([]);
  const [rowDataArray, setRowDataArray] = useState(undefined);
  const [loadingValue, setLoadingValue] = useState(true);
  const DeviceId = String(getUrlVars()["device"]);

  useEffect(() => {
    if (organziation) {
      setRowDataArray(null);
      const apiKey = organziation?.api_key_list?.[0]?.key;
      let DevicesClass = { deviceType: "", source: "iot" };
      DevicesService.getDevices({
        DeviceID: DeviceId ? Number(DeviceId) : "",
        DeviceType: DevicesClass.deviceType,
        Source: DevicesClass.source,
        Live: true,
        History: true,
        apiKey,
        HistoryFromDST: GetDateTimeStringCustom(2),
        historyToDST: "",
      })
        .then((json) => {
          if (json && json.device_profile && json.device_profile.length > 0) {
            // setOpsumInputData({
            //     json, machineTypeFilter: "",
            // })
            const deviceObject = json.device_profile.find(
              (x) => x.device_uid.toString() === DeviceId
            );
            setRowDataArray(deviceObject.iot_history);
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
  }, [organziation, DeviceId]);

  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    if (
      newSearchText &&
      newSearchText.length > 0 &&
      rowDataArray &&
      rowDataArray.length > 0
    ) {
      setRowDataArray(
        rowDataArray.filter((item) =>
          item.payload.dst.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
    }
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (rowDataArray && rowDataArray.length > 0) {
        return stableSort(rowDataArray, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, rowDataArray]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  return (
    <Box
      sx={{
        p: "12px",
        mt: "8px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        maxWidth: "90vw",
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          {/* <HomePagesMenu pageName={'Maintenance'} /> */}
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            variant="h2"
            gutterBottom
          >
            {/* {capitalize(t('Devices'))} */}
            {t("Raw Data")}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <TextField
        value={searchText}
        onChange={handleSearch}
        margin="normal"
        fullWidth
        id="search"
        label={t("Search")}
        name="search"
        autoFocus
        sx={{
          width: { xs: "100%", sm: "20%" },
          // borderColor: theme.palette.custom.borderColor,
          //borderRadius: '11px',
          backgroundColor: theme.palette.custom.light,
        }}
      />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //size={'medium'}
          >
            <EnhancedTableHead
              hideCheck={true}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {rowDataArray && rowDataArray.length > 0 && visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <DeviceRowDataTableRow
                      key={row.name}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={rowDataArray ? rowDataArray.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
