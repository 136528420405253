import React, { Fragment } from "react";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import StyledTypography from "../common/StyledTypography";
import ViewButton from "../common/viewButton";
import EditButton from "../common/editButton";


export default function MachineGroupsTableRow({
  row,
  handleClick,
  isItemSelected,
  handleView,
  handleEdit,
}) {
  return (
    <Fragment key={row.name}>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell align="left"/>
        <TableCell onClick={(event) => handleClick(event, row)} align="left">
          <StyledTypography variant="subtitle2">
            {row.name}
          </StyledTypography>{" "}
        </TableCell>
        <TableCell align="left" onClick={(event) => handleClick(event, row)}>
          <StyledTypography variant="subtitle2">
            {row.description}
          </StyledTypography>
        </TableCell>
        <TableCell align="left" onClick={(event) => handleClick(event, row)}>
          <StyledTypography variant="subtitle2">
            {row.physical_address}
          </StyledTypography>
        </TableCell>
        <TableCell align="left" onClick={(event) => handleClick(event, row)}>
          <StyledTypography variant="subtitle2">
            {row.status}
          </StyledTypography>
        </TableCell>
        <TableCell align="left" onClick={(event) => handleClick(event, row)}>
          <StyledTypography variant="subtitle2">
            {row.updated_timestamp}
          </StyledTypography>
        </TableCell>
        <TableCell align="center">
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              gap:"1rem"
            }}
          >
            <ViewButton onClick={(event) => handleView(event, row)} />
            <EditButton onClick={(event) => handleEdit(event, row)} />
          </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
MachineGroupsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  isItemSelected: PropTypes.bool,
  handleView: PropTypes.func,
  handleEdit: PropTypes.func,
};
