import React from "react";
import { Box, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import moment from "moment";
import EditButton from "./editButton";
import StyledTypography from "./StyledTypography";
import { setStateHistory } from "../store/stateHistorySlice";
// import { GetLatestDate, GetMachineTypeIcon } from "../utils/systemInfo";
export default function StateHistoryComponent({
  row,
  triggerToggleDrawer,
  usersList,
  locationsList,
}) {
  //   const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const handleButton = (event, buttonType, row) => {
  const handleButton = () => {
    dispatch(setStateHistory(row));
    triggerToggleDrawer(true);
  };
  function getUserName(id) {
    const objectuser = usersList.find((x) => x.profile_uid.toString() === id);
    return objectuser ? `${objectuser?.firstname} ${objectuser?.lastname}` : "";
  }
  return (
    <Box sx={{ display: "flex", flexFlow: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
        }}
      >
        <Box display="flex" alignItems="center">
          <StyledTypography variant={"h6"}>
            {t(row.state_type)}{" "}
          </StyledTypography>{" "}
          {row.device_state_history_tags &&
            row.device_state_history_tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag.tag_name}
                sx={{
                  ml: "4px",
                  backgroundColor: tag.tag_color,
                  color: "white",
                }}
                size="small"
              />
            ))}
        </Box>
        <EditButton key={"edit"} small onClick={handleButton} />
      </Box>
      {/* <Box display="flex" alignItems="center">
        <StyledTypography variant={"body2"}>
          {row.device_profile?.name ?? null}
        </StyledTypography>
        <StyledTypography sx={{ ml: "10px" }} variant={"body2"}>
          {row.device_profile?.vehicle_type ? (
            <div
              style={{ width: "40px" }}
              dangerouslySetInnerHTML={{
                __html: GetMachineTypeIcon(
                  row.device_profile?.vehicle_type ?? "Unknown"
                ).replace(
                  "theme.palette.custom.text",
                  theme.palette.custom.text
                ),
              }}
            />
          ) : null}
          {row.device_profile?.vehicle_type ?? null}
        </StyledTypography>
        <StyledTypography sx={{ ml: "10px" }} variant={"body2"}>
          {row.device_profile?.oem ? (
            <img
              width="40px"
              src={`${window.env.REACT_APP_PUBLIC_URL}/images/oem/${String(
                row.device_profile?.oem ?? "nerospec"
              ).replaceAll(" ", "")}_${
                localStorage.getItem("color_theme") === "dark"
                  ? "Dark"
                  : "Light"
              }.png`}
              alt="logo"
            />
          ) : null}
          {row.device_profile?.oem ?? null}
        </StyledTypography>
        <StyledTypography sx={{ ml: "10px" }} variant={"body2"}>
          {GetLatestDate([
            row.device_profile?.dst_last_opsum,
            row.device_profile?.latest_data_dst,
            row.device_profile?.dst_last_seen,
          ])}
        </StyledTypography>
      </Box> */}
      <Box display="flex" alignItems="center">
        <StyledTypography variant={"body2"}>
          {row.assigned_to_person_id
            ? getUserName(row.assigned_to_person_id)
            : null}
        </StyledTypography>
        <StyledTypography sx={{ ml: "10px" }} variant={"body2"}>
          {row.state_end && row.state_end > row.state_start
            ? `${Math.floor(
                moment(row.state_end).diff(moment(row.state_start), "minutes") /
                  60
              )}:${
                moment(row.state_end).diff(moment(row.state_start), "minutes") %
                60
              }`
            : row.state_start}
        </StyledTypography>
        <StyledTypography sx={{ ml: "10px" }} variant={"body2"}>
          {row.state_location_id
            ? locationsList.find(
                (x) =>
                  x.operation_location_named_lookup_id === row.state_location_id
              )?.name
            : null}
        </StyledTypography>
      </Box>
      <StyledTypography variant={"body2"}>
        {t(row.state_description)}
      </StyledTypography>
    </Box>
  );
}
StateHistoryComponent.propTypes = {
  row: PropTypes.object,
  triggerToggleDrawer: PropTypes.func,
  usersList: PropTypes.array,
  locationsList: PropTypes.array,
};
