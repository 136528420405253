import React from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CustomTextField from "./CustomTextField";
import PropTypes from "prop-types";

function SearchInput({ searchText, handleSearch, sx }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <CustomTextField
      value={searchText}
      onChange={handleSearch}
      margin="normal"
      fullWidth
      label={t("Search")}
      name="search"
      variant="filled"
      sx={{
        ...sx,
        width: sx?.width ? sx.width : { xs: "100%", sm: "20%" },
      }}
      InputLabelProps={{
        style: { color: theme.palette.custom.text },
      }}
    />
  );
}
SearchInput.propTypes = {
  searchText: PropTypes.string,
  sx: PropTypes.object,
  handleSearch: PropTypes.func,
};

export default SearchInput;
