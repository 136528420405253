import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../common/breadcrumbs";
import DevicesService from "../api/devices";
import CommonDateTimePicker from "../common/commonDateTimePicker";
import {
  GetDateTimeStringToday,
  getUrlVars,
  LatestDataColoring,
} from "../utils/systemInfo";
import Loading from "../common/loading";
import DevicesMenu from "../common/devicesMenu";
import { setDeviceData } from "../store/deviceSlice";
import StyledTypography from "../common/StyledTypography";
import TableGreenTypography from "../common/TableGreenTypography";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Machines", href: "machines" },
  { id: 2, name: "BM10", href: "" },
  { id: 3, name: "History", href: "" },
];

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: "160px",
  height: "32px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.borderColor,
    m: 0,
  })
);
function DeviceHistory() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const device = useSelector(({ device }) => device);
  const [loadingValue, setLoadingValue] = useState(true);
  const [grafanaLink, setGrafanaLink] = useState(undefined);
  const [devicesList, setDevicesList] = useState(undefined);
  const timezoneMins = useSelector(({ timezone }) => timezone);
  const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"]);

  useEffect(() => {
    handleGetDeviceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleBreadCrumbsArray = (breadcrumbs, device) => {
    if (DeviceTitleDataName === "Device") {
      breadcrumbs[1] = {
        id: 1,
        name: "Devices",
        href: "devices",
      };
    }
    if (breadcrumbs.length >= 3) {
      switch (DeviceTitleDataName) {
        case "Device":
          breadcrumbs[2] = {
            id: 2,
            name: device.DeviceName,
            href: `/device?device=${device.DeviceID}&DeviceTitleDataName=${device.DeviceTitleDataName}&Dashboard=${device.GrafanaDashboardUID}&MachineName=${device.DeviceName}&device_uid=${device.DeviceID}`,
            noTranslation: true,
          };
          break;
        case "Machine":
        default:
          breadcrumbs[2] = {
            id: 2,
            name: device.DeviceName,
            href: `/device?device=${device.DeviceID}&DeviceTitleDataName=${device.DeviceTitleDataName}`,
            noTranslation: true,
          };
          break;
      }
      breadcrumbs[3] = {
        id: 3,
        name: "History",
        href: window.location.href,
      };
      return breadcrumbs;
    }
    breadcrumbs.push({
      id: 1,
      name: device.DeviceName,
      href: `/device?device=${device.DeviceID}`,
      noTranslation: true,
    });
    return breadcrumbs;
  };
  const handleOpenInGrafana = () => {
    if (grafanaLink) {
      window.open(grafanaLink, "_blank");
    }
  };
  const handleUpdateGrafanaLink = (newUrl) => {
    setGrafanaLink(newUrl);
  };
  function handleGetDeviceData() {
    const DeviceId = String(getUrlVars()["device_uid"]);
    setDevicesList(undefined);
    dispatch(setDeviceData(null));
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      //const apiKey = organziation?.api_key_list?.[0]?.key;
      let DevicesClass;
      switch (DeviceTitleDataName.toLowerCase()) {
        case "machine":
        default:
          DevicesClass = {
            deviceType: [
              "nerohub",
              "nanohub",
              "cyberhub",
              "stratahub",
              "nerotrax",
              "train",
              "autonomous hub",
            ],
            source: "",
          };
          DevicesService.getDevices({
            DeviceID: DeviceId ? Number(DeviceId) : "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            apiKey,
            HistoryFromDST: "",
            HistoryToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                // setOpsumInputData({
                //     json, machineTypeFilter: "",
                // })
                const deviceObject = json.device_profile.find(
                  (x) => x.device_uid.toString() === DeviceId
                );
                dispatch(
                  setDeviceData({
                    json: deviceObject,
                    machineTypeFilter: "",
                    machineType: DeviceTitleDataName,
                  })
                );
                setDevicesList(json.device_profile);
              } else {
                navigate("/");
              }
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
          break;

        case "device":
          DevicesClass = { deviceType: [], source: "iot" };
          DevicesService.getDevices({
            DeviceID: DeviceId ? Number(DeviceId) : "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            Live: false,
            History: false,
            apiKey,
            HistoryFromDST: "",
            HistoryToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                // setOpsumInputData({
                //     json, machineTypeFilter: "",
                // })
                const deviceObject = json.device_profile.find(
                  (x) => x.device_uid.toString() === DeviceId
                );
                dispatch(
                  setDeviceData({
                    json: deviceObject,
                    machineTypeFilter: "",
                    machineType: DeviceTitleDataName,
                  })
                );
                setDevicesList(json.device_profile);
              } else {
                navigate("/");
              }
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
          break;
      }
    }
  }
  return (
    <Box
      sx={{
        p: "8px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: `100%`,
        height: "100%",
      }}
    >
      <Box sx={{ width: "100%", height: { xs: "fit-content", sm: `88px` } }}>
        {!loadingValue && device ? (
          <Breadcrumbs
            breadcrumbs={handleBreadCrumbsArray(breadcrumbs, device)}
          />
        ) : (
          <Box sx={{ height: "10vh", pt: "3%" }}>
            <Loading />
          </Box>
        )}
        {!loadingValue && device ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexFlow: { xs: "column", sm: "row" },
              alignItems: "center",
              height: { xs: "fit-content", sm: "64px" },
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: {
                  xs: "100%",
                  sm: DeviceTitleDataName !== "Device" ? "20%" : "30%",
                },
                marginRight: { xs: 0, sm: "5%" },
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{ width: "50px" }}
                dangerouslySetInnerHTML={{
                  __html: device.DeviceTypeIcon.replace(
                    "theme.palette.custom.text",
                    theme.palette.custom.text
                  ),
                }}
              />
              {devicesList && devicesList.length > 0 ? (
                <DevicesMenu
                  handleChangeDevice={handleGetDeviceData}
                  pageType="history"
                  devices={devicesList}
                  isSmall={true}
                />
              ) : (
                <NumberTypography variant="h6">
                  {device.DeviceName}
                </NumberTypography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                width: {
                  xs: "100%",
                  sm: DeviceTitleDataName !== "Device" ? "25%" : "30%",
                },
                marginRight: { xs: 0, sm: "5%" },
                p: { xs: 0, sm: "12px" },
                textAlign: "center",
              }}
            >
              <StyledTypography variant="h6">
                {t("LastSeenWithDots")} &nbsp;
              </StyledTypography>
              <Typography
                sx={{
                  color: LatestDataColoring(device.DeviceLastSeen, true),
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                }}
                variant="h6"
              >
                {moment
                  .utc(device.DeviceLastSeen)
                  .add(timezoneMins, "minutes")
                  .format("YYYY-MM-DD HH:mm")}
              </Typography>
            </Box>
            {DeviceTitleDataName !== "Device" && (
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  width: { xs: "100%", sm: "30%" },
                  p: { xs: 0, sm: "12px" },
                  textAlign: "center",
                }}
              >
                <StyledTypography variant="h6">
                  {t("LastLocation")} &nbsp;
                </StyledTypography>

                {device.NamedLocationOriginal === "Unknown" ? (
                  <TableGreenTypography
                    sx={{
                      color: "red",
                    }}
                    variant="h6"
                  >
                    {device.NamedLocationOriginal}
                  </TableGreenTypography>
                ) : (
                  <StyledTypography variant="h6">
                    {device.NamedLocationOriginal}
                  </StyledTypography>
                )}
              </Box>
            )}
            <StyledButton
              id="demo-zoom-button"
              variant="contained"
              disableElevation
              onClick={() => handleOpenInGrafana()}
              sx={{ margin: "0px 8px" }}
            >
              {t("OpenInGrafana")}
            </StyledButton>
          </Box>
        ) : null}
      </Box>

      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          height: { xs: "100%", sm: `calc(100% - 94px)` },
        }}
      >
        <CommonDateTimePicker
          handleUpdateGrafanaLink={handleUpdateGrafanaLink}
        />
      </Box>
    </Box>
  );
}

export default DeviceHistory;
