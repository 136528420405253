import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import LdapService from "../api/Ldap";
import {getUrlVars} from "../utils/systemInfo";
import TemplateAddEdit from "../common/Layout/templatePages/templateAddEdit";
import {setLdapServer, initialState} from "../store/ldapServerSlice";
import TemplateTable from "../common/Layout/templatePages/templateTable";
import {Tabs, Tab, Box, Button, Select, MenuItem} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

const breadcrumbs = [
    {id: 0, name: "Home", href: "./"},
    {id: 1, name: "LdapServers", href: "ldapServers"},
    {id: 2, name: "Add/Edit Ldap Server", href: "addEditLdapServer"}
];
function AddEditLdapServer() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ldapServer = useSelector(({ldapServer}) => ldapServer);
    const organziation = useSelector(({organziation}) => organziation);
    const ldapServerId = String(getUrlVars()["id"]);
    const [ldapUsers, setLdapUsers] = useState([]);
    const [ldapSync, setLdapSync] = useState([]);
    const [ldapSearch, setLdapSearch] = useState([]);
    const [ldapUsersLoading, setLdapUsersLoading] = useState(true);
    const [ldapSyncLoading, setLdapSyncLoading] = useState(true);
    const [ldapSearchLoading, setLdapSearchLoading] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const {t} = useTranslation();
    const theme = useTheme();
    const [logLevelFilter, setLogLevelFilter] = useState(-1);
    const logLevelOptions = [
        {value: -1, label: "All"},
        {value: 0, label: "Info"},
        {value: 1, label: "Warning"},
        {value: 2, label: "Error"},
        {value: 3, label: "Exception"}
    ];

    useEffect(() => {
        if (!ldapServer?.ldap_server_id && ldapServerId && ldapServerId !== "undefined") {
            const apiKey = organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
            LdapService.GetLdapServers({
                serverId: ldapServerId,
                OrganizationId: organziation.uid,
                apiKey
            })
                .then((jsonCurrent) => {
                    if (jsonCurrent?.person_ldap_server?.length) {
                        dispatch(setLdapServer(jsonCurrent?.person_ldap_server[0]));
                    } else {
                        navigate("/ldapServers");
                    }
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === "development") console.log("error", errorr);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ldapServer]);
    useEffect(() => {
        if (ldapServer?.ldap_server_id) {
            const apiKey = organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
            LdapService.GetLdapUsers({
                operation_id: organziation.uid,
                apiKey
            })
                .then((jsonCurrent) => {
                    if (jsonCurrent?.person_ldap_user?.length) {
                        setLdapUsers(jsonCurrent?.person_ldap_user);
                    }
                    setLdapUsersLoading(false);
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === "development") console.log("error", errorr);
                });
            LdapService.GetLdapSearch({serverID: ldapServer?.ldap_server_id, apiKey}).then((json) => {
                if (json.success && json.count > 0) {
                    setLdapSearch(json?.person_ldap_server_search);
                }
                setLdapSearchLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ldapServer]);
    useEffect(() => {
        if (ldapServer?.ldap_server_id) {
            const apiKey = organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
            LdapService.GetLdapServerSyncLog({
                ldap_server_id: ldapServer?.ldap_server_id,
                operation_id: organziation.uid,
                apiKey
            })
                .then((jsonCurrent) => {
                    if (jsonCurrent?.person_ldap_sync_log?.length) {
                        setLdapSync(jsonCurrent?.person_ldap_sync_log);
                    }
                    setLdapSyncLoading(false);
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === "development") console.log("error", errorr);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ldapServer]);

    useEffect(() => {
        return () => {
            dispatch(setLdapServer(initialState));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSave(item) {
        LdapService.PostLdapServers(item).then((json) => {
            // console.log(json)
            if (json.success === true && json.count > 0) {
                navigate("/ldapServers");
            }
        });
    }
    function handleAddEditFilter() {
        navigate(`/ldapCustomFilters?id=${ldapServer?.ldap_server_id}`);
    }

    function handleButton(event, actionType, row) {
        event.stopPropagation();

        if (actionType === "edit") {
            const queryParams = new URLSearchParams({
                id: row.ldap_server_id,
                sid: row.ldap_server_search_id
            }).toString();
            navigate(`/ldapCustomFilters?${queryParams}`);
        } else if (actionType === "delete") {
            if (window.confirm("Are you sure you want to delete this filter?")) {
                LdapService.RemoveLdapSearch({ldap_server_search_id: row.ldap_server_search_id})
                    .then((response) => {
                        if (response.success) {
                            setLdapSearch((prevSearch) => prevSearch.filter((item) => item.ldap_server_search_id !== row.ldap_server_search_id));
                        }
                    })
                    .catch((error) => console.error("Failed to delete:", error));
            }
        }
    }
    return (
        <TemplateAddEdit
            breadcrumbs={breadcrumbs}
            title="Add/Edit Ldap Server"
            gridCol={2}
            item={ldapServer}
            dataToShow={[
                {key: "bind_user_name", label: "Bind Username", isRequired: true},
                {
                    key: "bind_password",
                    label: "Bind Password",
                    type: "password",
                    isRequired: true
                },
                {key: "target_host", label: "Host", isRequired: true},
                {key: "target_port", label: "Port", type: "number", isRequired: true},
                {key: "status"},
                {key: "realm", label: "Realm", isRequired: true}
            ]}
            onSaveClick={(item) =>
                handleSave({
                    ...item,
                    operation_id: item.operation_id || organziation.uid,
                    apiKey: organziation?.api_key_list?.[0]?.key
                })
            }
            additionalData={
                ldapServer?.ldap_server_id && (
                    <Box>
                        <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="Ldap Server Tabs">
                            <Tab label="Server Users" />
                            <Tab label="Custom Search" />
                            <Tab label="Server Sync Log" />
                        </Tabs>
                        {tabIndex === 0 && (
                            <TemplateTable
                                itemsList={ldapUsers}
                                headCells={[
                                    {id: "name"},
                                    {id: "user_principal_name", label: "Email"},
                                    // { id: "mail", label: "Email" },
                                    // { id: "display_name", label: "Display Name" },
                                    // { id: "given_name", label: "Given Name" },
                                    {
                                        id: "created_timestamp",
                                        label: "Created",
                                        attributeType: "dateTime"
                                    },
                                    {
                                        id: "updated_timestamp",
                                        label: "Updated",
                                        attributeType: "dateTime"
                                    }
                                    // {
                                    //   id: "action",
                                    //   buttons: ["edit", "connection"],
                                    // },
                                ]}
                                // handleButton={handleButton}
                                itemId="ldap_user_id"
                                loading={ldapUsersLoading}
                                searchAttributes={["name", "user_principal_name"]}
                                title="Server Users"
                                // allowExport
                                // exportTitle={t("LdapServerUsers")}
                            />
                        )}
                        {tabIndex === 1 && (
                            <TemplateTable
                                itemsList={ldapSearch}
                                headCells={[
                                    {id: "ldap_server_id", label: "Server ID"},
                                    {id: "ldap_server_search_id", label: "Search ID"},
                                    {id: "search_type", label: "Search Type"},
                                    {id: "custom_filter", label: "Custom Filter"},
                                    {
                                        id: "created_timestamp",
                                        label: "Created",
                                        attributeType: "dateTime"
                                    },
                                    {
                                        id: "updated_timestamp",
                                        label: "Updated",
                                        attributeType: "dateTime"
                                    },
                                    {
                                        id: "action",
                                        buttons: ["edit", "delete"]
                                    }
                                ]}
                                handleButton={handleButton}
                                itemId="ldapSearchId"
                                loading={ldapSearchLoading}
                                searchAttributes={["ldap_server_id", "search_type"]}
                                title="Custom Search"
                                showButton={true}
                                titleButton={
                                    <Button
                                        variant="contained"
                                        sx={{
                                            "backgroundColor": theme.palette.custom.borderColor,
                                            "color": "white",
                                            "&:hover": {
                                                backgroundColor: theme.palette.custom.buttonHoverColor,
                                                color: theme.palette.custom.buttonHoverTextColor,
                                                boxShadow: "none"
                                            },
                                            "display": "flex",
                                            "float": "right"
                                        }}
                                        onClick={() => handleAddEditFilter()}>
                                        {t("Add Custom Filter")}
                                    </Button>
                                }
                                // allowExport
                                // exportTitle={t("LdapServerUsers")}
                            />
                        )}
                        {tabIndex === 2 && (
                                <TemplateTable
                                    itemsList={ldapSync.filter((log) => logLevelFilter === -1 || log.log_level === logLevelFilter)}
                                    headCells={[
                                        {id: "log_level", label: "Log Level"},
                                        {id: "txt", label: "Description"},
                                        // { id: "mail", label: "Email" },
                                        // { id: "display_name", label: "Display Name" },
                                        // { id: "given_name", label: "Given Name" },
                                        {
                                            id: "engine_dst",
                                            label: "Engine Timestamp",
                                            attributeType: "dateTime"
                                        },
                                        {
                                            id: "server_dst",
                                            label: "Server Timestamp",
                                            attributeType: "dateTime"
                                        }
                                        // {
                                        //   id: "action",
                                        //   buttons: ["edit", "connection"],
                                        // },
                                    ]}
                                    // handleButton={handleButton}
                                    itemId="ldap_server_id"
                                    loading={ldapSyncLoading}
                                    searchAttributes={["txt"]}
                                    title="Server Sync Log"
                                    showButton
                                    titleButton={(
                                        <Select
                                        value={logLevelFilter}
                                        onChange={(e) => setLogLevelFilter(Number(e.target.value))}
                                        sx={{
                                            "width": "200px",
                                            "backgroundColor": theme.palette.custom.inputBackgroundColor,
                                            "color": theme.palette.custom.text,
                                            "border": `1px solid ${theme.palette.custom.borderColor}`,
                                            "borderRadius": "8px",
                                            "& .MuiOutlinedInput-notchedOutline": {border: "none"}
                                        }}>
                                        {logLevelOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {t(option.label)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    )}
                                    // allowExport
                                    // exportTitle={t("LdapServerUsers")}
                                />
                        )}
                    </Box>
                )
            }
        />
    );
}

export default AddEditLdapServer;
