import React from "react";
import { createBrowserRouter, useLocation, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoginPage from "../pages/loginPage";
import IndexPage from "../pages/indexPage";
import Safety from "../pages/safety";
import Monitoring from "../pages/monitoring";
import Maintenance from "../pages/maintenance";
import Productivity from "../pages/productivity";
import { setUser } from "../store/userSlice";
import { setOrganization } from "../store/organziationSlice";
import { setPermissions } from "../store/permissionsSlice";
import About from "../pages/about";
import Profile from "../pages/profile";
import Organization from "../pages/organization";
import Organizations from "../pages/organizationsPage";
import Layout from "../common/Layout/layout";
import Machines from "../pages/machines";
import Machine from "../pages/machine";
import MachineCompare from "../pages/machineCompare";
import Users from "../pages/users";
import CreateUser from "../pages/userCreate";
import AssignUsers from "../pages/assignUsers";
import AssignDevices from "../pages/assignDevices";
import DevToUsers from "../pages/devToUsers";
import AddEditMachine from "../pages/addEditMachine";
import AddEditDashboard from "../pages/addEditDashboard";
import Dashboard from "../pages/dashboard"; // import personService from '../api/person';
import Dashboards from "../pages/dashboards";
import DeviceHistory from "../pages/deviceHistory";
import DeviceSignals from "../pages/deviceSignals";
import Documentation from "../pages/documentation";
import Changelog from "../pages/changelog";
import HelpdeskDashboard from "../pages/indexHelpdesk";
import ContactUs from "../pages/contactUs";
import Tickets from "../pages/tickets";
import MyTickets from "../pages/myTickets";
import Templates from "../pages/templates";
import TicketTemplateRequirements from "../pages/ticketTemplateRequirements";
import NewTicket from "../pages/newTicket";
import GrafanaBackend from "../pages/grafanaBackend";
import NewTicketTemplate from "../pages/newTicketTemplate";
import NotificationsPage from "../pages/notificationsPage";
import usePrevious from "../common/cutomHooks/usePrevious";
import AddOrganizationLink from "../pages/addOrganizationLink";
import AddMachineStatusPage from "../pages/addMachineStatusPage";
import AddMachineDocsPage from "../pages/addMachineDocsPage";
import MachineStatus from "../pages/machineStatus";
import DeviceSignalEditPage from "../pages/deviceSignalEditPage";
import MachineStatusCommentsPage from "../pages/machineStatusCommentPage";
import MyProfile from "../pages/myProfile";
import AddShift from "../pages/addShift";
import MachineTrend from "../pages/machineTrend";
import MachineDocumentation from "../pages/machineDocumentation";
import ServerHubConfigPage from "../pages/serverHubConfigPage";
import PropTypes from "prop-types";
import DeviceConfig from "../pages/viewDeviceConfig";
import AddHubServerConfig from "../pages/addHubServerConfig";
import OrganizationCofigPage from "../pages/organizationCofigPage";
import MachinesGroups from "../pages/machineGroups";
import AddDeviceGroup from "../pages/addDeviceGroup";
import UserSigninLogger from "../pages/userSigninLoggerPage";
import ViewDeviceGroup from "../pages/viewDeviceGroup";

import PublicPage from "../pages/publicPage";
import PublicLayout from "../pages/publicLayout";
import UserAuditTrails from "../pages/UserAuditTrails";
import NamedLocations from "../pages/NamedLocations";
import AddEditNamedLocation from "../pages/addEditNamedLocation";
import SignalUpload from "../pages/signalUpload";
import LoadMapPins from "../pages/map";
import LdapServers from "../pages/LdapServers";
import AddEditLdapServer from "../pages/AddEditLdapServer";
import LdapGroups from "../pages/ldapGroups";
import ViewLdapGroup from "../pages/viewLdapGroup";
import LdapUsers from "../pages/ldapUsers";
import ViewLdapUser from "../pages/viewLdapUser";
import DeviceStateHistory from "../pages/deviceStateHistoryPage";
import Tags from "../pages/tagsPage";
import AddEditTag from "../pages/addEditTag";
import AddEditLdapCustomFilter from "../pages/addEditLdapCustomFilter"
import AssignUserType from "../pages/assignLdapUserType";

function RequireAuth({ children }) {
  const token = localStorage.getItem("auth_token");
  const user = localStorage.getItem("user");
  const prevUser = usePrevious(user);
  const defaultOrganization = localStorage.getItem("defaultOrganization");
  let location = useLocation();
  const dispatch = useDispatch();

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    if (!window.env.REACT_APP_PUBLIC_PAGE) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  } else if (user && user !== prevUser) {
    const userObj = JSON.parse(user);
    dispatch(setUser(userObj));
    if (defaultOrganization) {
      const orgObj = JSON.parse(defaultOrganization);
      dispatch(setOrganization(orgObj));
    }
    const permissions = localStorage.getItem("permissions");
    if (permissions) {
      const permissionsObj = JSON.parse(permissions);
      dispatch(setPermissions(permissionsObj));
    }
  }

  return children;
}
RequireAuth.propTypes = {
  children: PropTypes.node,
};
function GetRouter(authToken) {
  const router = createBrowserRouter([
    window.env.REACT_APP_PUBLIC_PAGE && !authToken
      ? {
          path: "",
          element: (
            <PublicLayout>
              <PublicPage />
            </PublicLayout>
          ),
        }
      : {
          element: <Layout />,
          children: [
            {
              path: "",
              element: (
                <RequireAuth>
                  <IndexPage />
                </RequireAuth>
              ),
            },
          ],
        },
    window.env.REACT_APP_PUBLIC_PAGE && !authToken
      ? {
          path: "about",
          element: (
            <PublicLayout>
              <About />
            </PublicLayout>
          ),
        }
      : {
          element: <Layout />,
          children: [
            {
              path: "about",
              element: (
                <RequireAuth>
                  <About />
                </RequireAuth>
              ),
            },
          ],
        },
    {
      element: <Layout />,
      children: [
        {
          path: "safety",
          element: (
            <RequireAuth>
              <Safety />
            </RequireAuth>
          ),
        },
        {
          path: "monitoring",
          element: (
            <RequireAuth>
              <Monitoring />
            </RequireAuth>
          ),
        },
        {
          path: "maintenance",
          element: (
            <RequireAuth>
              <Maintenance />
            </RequireAuth>
          ),
        },
        {
          path: "productivity",
          element: (
            <RequireAuth>
              <Productivity />
            </RequireAuth>
          ),
        },
        {
          path: "devices",
          element: (
            <RequireAuth>
              <Machines />
            </RequireAuth>
          ),
        },
        {
          path: "myDevices",
          element: (
            <RequireAuth>
              <Machines />
            </RequireAuth>
          ),
        },
        {
          path: "machines",
          element: (
            <RequireAuth>
              <Machines />
            </RequireAuth>
          ),
        },
        {
          path: "myMachines",
          element: (
            <RequireAuth>
              <Machines />
            </RequireAuth>
          ),
        },
        {
          path: "deviceGroups",
          element: (
            <RequireAuth>
              <MachinesGroups />
            </RequireAuth>
          ),
        },
        {
          path: "myDeviceGroups",
          element: (
            <RequireAuth>
              <MachinesGroups />
            </RequireAuth>
          ),
        },
        {
          path: "machineGroups",
          element: (
            <RequireAuth>
              <MachinesGroups />
            </RequireAuth>
          ),
        },
        {
          path: "myMachineGroups",
          element: (
            <RequireAuth>
              <MachinesGroups />
            </RequireAuth>
          ),
        },
        {
          path: "deviceGroups/addEditDeviceGroup",
          element: (
            <RequireAuth>
              <AddDeviceGroup />
            </RequireAuth>
          ),
        },
        {
          path: "myDeviceGroups/addEditDeviceGroup",
          element: (
            <RequireAuth>
              <AddDeviceGroup />
            </RequireAuth>
          ),
        },
        {
          path: "machineGroups/addEditDeviceGroup",
          element: (
            <RequireAuth>
              <AddDeviceGroup />
            </RequireAuth>
          ),
        },
        {
          path: "myMachineGroups/addEditDeviceGroup",
          element: (
            <RequireAuth>
              <AddDeviceGroup />
            </RequireAuth>
          ),
        },
        {
          path: "deviceGroups/viewDeviceGroup",
          element: (
            <RequireAuth>
              <ViewDeviceGroup />
            </RequireAuth>
          ),
        },
        {
          path: "myDeviceGroups/viewDeviceGroup",
          element: (
            <RequireAuth>
              <ViewDeviceGroup />
            </RequireAuth>
          ),
        },
        {
          path: "machineGroups/viewDeviceGroup",
          element: (
            <RequireAuth>
              <ViewDeviceGroup />
            </RequireAuth>
          ),
        },
        {
          path: "myMachineGroups/viewDeviceGroup",
          element: (
            <RequireAuth>
              <ViewDeviceGroup />
            </RequireAuth>
          ),
        },
        {
          path: "device",
          element: (
            <RequireAuth>
              <Machine />
            </RequireAuth>
          ),
        },
        {
          path: "deviceCompare",
          element: (
            <RequireAuth>
              <MachineCompare />
            </RequireAuth>
          ),
        },
        {
          path: "profile",
          element: (
            <RequireAuth>
              <Profile />
            </RequireAuth>
          ),
        },
        {
          path: "organization",
          element: (
            <RequireAuth>
              <Organization />
            </RequireAuth>
          ),
        },
        {
          path: "organizations",
          element: (
            <RequireAuth>
              <Organizations />
            </RequireAuth>
          ),
        },
        {
          path: "organizationCreate",
          element: (
            <RequireAuth>
              <Organization new />
            </RequireAuth>
          ),
        },
        {
          path: "deviceProfile",
          element: (
            <RequireAuth>
              <AddEditMachine />
            </RequireAuth>
          ),
        },
        {
          path: "dashboard",
          element: (
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          ),
        },
        {
          path: "grafanaBackendDashboardAddEdit",
          element: (
            <RequireAuth>
              <AddEditDashboard />
            </RequireAuth>
          ),
        },
        {
          path: "addEditOrganizationLink",
          element: (
            <RequireAuth>
              <AddOrganizationLink />
            </RequireAuth>
          ),
        },
        {
          path: "users",
          element: (
            <RequireAuth>
              <Users />
            </RequireAuth>
          ),
        },
        {
          path: "userCreate",
          element: (
            <RequireAuth>
              <CreateUser />
            </RequireAuth>
          ),
        },
        {
          path: "assignUsers",
          element: (
            <RequireAuth>
              <AssignUsers />
            </RequireAuth>
          ),
        },
        {
          path: "assignDevices",
          element: (
            <RequireAuth>
              <AssignDevices />
            </RequireAuth>
          ),
        },
        {
          path: "devToUsers",
          element: (
            <RequireAuth>
              <DevToUsers />
            </RequireAuth>
          ),
        },
        {
          path: "dashboards",
          element: (
            <RequireAuth>
              <Dashboards />
            </RequireAuth>
          ),
        },
        {
          path: "deviceHistory",
          element: (
            <RequireAuth>
              <DeviceHistory />
            </RequireAuth>
          ),
        },
        {
          path: "deviceSignals",
          element: (
            <RequireAuth>
              <DeviceSignals />
            </RequireAuth>
          ),
        },
        {
          path: "documentation",
          element: (
            <RequireAuth>
              <Documentation />
            </RequireAuth>
          ),
        },
        {
          path: "contactus",
          element: (
            <RequireAuth>
              <ContactUs />
            </RequireAuth>
          ),
        },
        {
          path: "changelog",
          element: (
            <RequireAuth>
              <Changelog />
            </RequireAuth>
          ),
        },
        {
          path: "indexHelpdesk",
          element: (
            <RequireAuth>
              <HelpdeskDashboard />
            </RequireAuth>
          ),
        },
        {
          path: "tickets",
          element: (
            <RequireAuth>
              <Tickets />
            </RequireAuth>
          ),
        },
        {
          path: "myTickets",
          element: (
            <RequireAuth>
              <MyTickets />
            </RequireAuth>
          ),
        },
        {
          path: "templates",
          element: (
            <RequireAuth>
              <Templates />
            </RequireAuth>
          ),
        },
        {
          path: "ticketTemplateRequirements",
          element: (
            <RequireAuth>
              <TicketTemplateRequirements />
            </RequireAuth>
          ),
        },
        {
          path: "newTicketTemplate",
          element: (
            <RequireAuth>
              <NewTicketTemplate />
            </RequireAuth>
          ),
        },
        {
          path: "newTicket",
          element: (
            <RequireAuth>
              <NewTicket />
            </RequireAuth>
          ),
        },
        {
          path: "grafanaBackend",
          element: (
            <RequireAuth>
              <GrafanaBackend />
            </RequireAuth>
          ),
        },
        {
          path: "notifications",
          element: (
            <RequireAuth>
              <NotificationsPage />
            </RequireAuth>
          ),
        },
        {
          path: "addMachineStatus",
          element: (
            <RequireAuth>
              <AddMachineStatusPage />
            </RequireAuth>
          ),
        },
        {
          path: "addMachineDocs",
          element: (
            <RequireAuth>
              <AddMachineDocsPage />
            </RequireAuth>
          ),
        },
        {
          path: "machineStatus",
          element: (
            <RequireAuth>
              <MachineStatus />
            </RequireAuth>
          ),
        },
        {
          path: "editDeviceSignal",
          element: (
            <RequireAuth>
              <DeviceSignalEditPage />
            </RequireAuth>
          ),
        },
        {
          path: "machineComments",
          element: (
            <RequireAuth>
              <MachineStatusCommentsPage />
            </RequireAuth>
          ),
        },
        {
          path: "myProfile",
          element: (
            <RequireAuth>
              <MyProfile />
            </RequireAuth>
          ),
        },
        {
          path: "addShift",
          element: (
            <RequireAuth>
              <AddShift />
            </RequireAuth>
          ),
        },
        {
          path: "machineTrend",
          element: (
            <RequireAuth>
              <MachineTrend />
            </RequireAuth>
          ),
        },
        {
          path: "machineDocumentation",
          element: (
            <RequireAuth>
              <MachineDocumentation />
            </RequireAuth>
          ),
        },
        {
          path: "hubServerInfoApi",
          element: (
            <RequireAuth>
              <ServerHubConfigPage />
            </RequireAuth>
          ),
        },
        {
          path: "userAuditTrails",
          element: (
            <RequireAuth>
              <UserAuditTrails />
            </RequireAuth>
          ),
        },
        {
          path: "namedLocationConfig",
          element: (
            <RequireAuth>
              <NamedLocations />
            </RequireAuth>
          ),
        },
        {
          path: "addEditHubServerConfig",
          element: (
            <RequireAuth>
              <AddHubServerConfig />
            </RequireAuth>
          ),
        },
        {
          path: "deviceConfig",
          element: (
            <RequireAuth>
              <DeviceConfig />
            </RequireAuth>
          ),
        },
        {
          path: "organizationConfig",
          element: (
            <RequireAuth>
              <OrganizationCofigPage />
            </RequireAuth>
          ),
        },
        {
          path: "userSigninLogger",
          element: (
            <RequireAuth>
              <UserSigninLogger />
            </RequireAuth>
          ),
        },
        {
          path: "addEditNamedLocation",
          element: (
            <RequireAuth>
              <AddEditNamedLocation />
            </RequireAuth>
          ),
        },
        {
          path: "ldapServers",
          element: (
            <RequireAuth>
              <LdapServers />
            </RequireAuth>
          ),
        },
        {
          path: "addEditLdapServer",
          element: (
            <RequireAuth>
              <AddEditLdapServer />
            </RequireAuth>
          ),
        },
        {
          path: "ldapGroups",
          element: (
            <RequireAuth>
              <LdapGroups />
            </RequireAuth>
          ),
        },
        {
          path: "viewLdapGroup",
          element: (
            <RequireAuth>
              <ViewLdapGroup />
            </RequireAuth>
          ),
        },
        {
          path: "ldapUsers",
          element: (
            <RequireAuth>
              <LdapUsers />
            </RequireAuth>
          ),
        },
        {
          path: "viewLdapUser",
          element: (
            <RequireAuth>
              <ViewLdapUser />
            </RequireAuth>
          ),
        },
        {
          path: "signalUpload",
          element: (
            <RequireAuth>
              <SignalUpload />
            </RequireAuth>
          ),
        },
        {
          path: "map",
          element: (
            <RequireAuth>
              <LoadMapPins />
            </RequireAuth>
          ),
        },
        {
          path: "deviceStateHistory",
          element: (
            <RequireAuth>
              <DeviceStateHistory />
            </RequireAuth>
          ),
        },
        {
          path: "tags",
          element: (
            <RequireAuth>
              <Tags />
            </RequireAuth>
          ),
        },
        {
          path: "addEditTag",
          element: (
            <RequireAuth>
              <AddEditTag />
            </RequireAuth>
          ),
        },
        {
          path: "ldapCustomFilters",
          element: (
            <RequireAuth>
              <AddEditLdapCustomFilter />
            </RequireAuth>
          ),
        },
        {
          path: "ldapUserTypes",
          element: (
            <RequireAuth>
              <AssignUserType />
            </RequireAuth>
          ),
        },
        // {
        //   path: "login",
        //   element: <LoginPage />,
        // },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
  ]);
  return router;
}
export default GetRouter;
