/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.text,
  },
}));

function LocationsMenu({ locationName, onChooseLocation, locationsList }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [name, setName] = useState(locationName ? locationName : "");
  const [anchorPage, setAnchorPage] = useState(null);

  useEffect(() => {
    if (locationsList && locationsList.length > 0) {
      const nameValue =
        locationsList.find(
          (x) => x.operation_location_named_lookup_id === locationName
        )?.name ?? "";
      setName(nameValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationName]);
  const handleOpenMenu = (event) => {
    setAnchorPage(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorPage(null);
  };
  const handleChooseName = (event, item) => {
    setName(item.name);
    onChooseLocation(item);
    setAnchorPage(null);
  };
  return (
    <>
      <Tooltip title="Open settings">
        <Button
          variant="outlined"
          onClick={handleOpenMenu}
          sx={{
            width: "100%",
            height: "56px",
            backgroundColor: "transparent",
            border: 1,
            borderColor: theme.palette.custom.borderColor,
            borderRadius: "11px",
            color: theme.palette.custom.text,
            justifyContent: "space-between",
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
          }}
          endIcon={
            <KeyboardArrowDownIcon
              sx={{
                font: "inherit",
                color: theme.palette.custom.text,
                "&:hover": {
                  backgroundColor: theme.palette.custom.buttonHoverColor,
                  color: theme.palette.custom.buttonHoverTextColor,
                  boxShadow: "none",
                },
              }}
            />
          }
        >
          {name !== "" ? t(name) : t("Location")}
        </Button>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorPage}
        open={Boolean(anchorPage)}
        onClose={handleCloseMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {locationsList &&
          locationsList.map((item, index) => (
            <MenuItem key={index} onClick={(e) => handleChooseName(e, item)}>
              <Typography textAlign="center">{t(item.name)}</Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
}
LocationsMenu.propTypes = {
  locationName: PropTypes.string,
  onChooseLocation: PropTypes.func,
  locationsList: PropTypes.array,
};

export default LocationsMenu;
