import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

function FrequentStatisticsComponent({ title, textValue }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        textAlign: "center",
        width: { xs: "100%", sm: "20%" },
      }}
    >
      <Typography
        sx={{
          fontSize: "20px",
          mb: 2,
          color: theme.palette.custom.text,
        }}
        variant="h3"
        gutterBottom
      >
        {title}:
      </Typography>
      <Typography
        sx={{
          fontSize: "20px",
          mb: 0,
          color: theme.palette.custom.text,
        }}
        variant="h3"
        gutterBottom
      >
        {textValue}
      </Typography>
    </Box>
  );
}
FrequentStatisticsComponent.propTypes = {
  title: PropTypes.string,
  textValue: PropTypes.string,
};

export default FrequentStatisticsComponent;
