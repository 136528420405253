import Utils from "../utils/utils";

class OpsumService extends Utils.EventEmitter {
  getDeviceOpsumQuery(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          timeframe: {
            from: inputData.historyFromDST,
            to: inputData.historyToDST,
          },
          device: [
            {
              uid: inputData.deviceID,
              device_type: inputData.deviceType,
            },
          ],
          channel: ["event"],
          method: ["list"],
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDeviceOpsumTimers(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          timeframe: {
            from: inputData.historyFromDST,
            to: inputData.historyToDST,
          },
          device: [
            {
              uid: inputData.deviceID,
            },
          ],
          channel: ["timer"],
          sensor: [
            { uid: 0 },
            { uid: 2 },
            { uid: 1 },
            { uid: 3 },
            { uid: 4 },
            { uid: 5 },
          ],
          method: ["sum"],
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new OpsumService();

export default instance;
