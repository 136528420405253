import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LdapService from "../api/Ldap";
import { getUrlVars } from "../utils/systemInfo";
import TemplateAddEdit from "../common/Layout/templatePages/templateAddEdit";
import { setLdapGroup, initialState } from "../store/ldapGroupSlice";
import TemplateTable from "../common/Layout/templatePages/templateTable";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "LdapGroups", href: "ldapGroups" },
  { id: 2, name: "View Ldap Group", href: "viewLdapGroup" },
];
function ViewLdapGroup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ldapGroup = useSelector(({ ldapGroup }) => ldapGroup);
  const organziation = useSelector(({ organziation }) => organziation);
  const ldapGroupId = String(getUrlVars()["id"]);
  const [ldapUsers, setLdapUsers] = useState([]);

  useEffect(() => {
    if (
      !ldapGroup?.ldap_group_id &&
      ldapGroupId &&
      ldapGroupId !== "undefined"
    ) {
      const apiKey =
        organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
      LdapService.GetLdapGroups({
        groupId: ldapGroupId,
        OrganizationId: organziation.uid,
        apiKey,
      })
        .then((jsonCurrent) => {
          if (jsonCurrent?.person_ldap_group?.length) {
            dispatch(setLdapGroup(jsonCurrent?.person_ldap_group[0]));
          } else {
            navigate("/ldapGroups");
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("error", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldapGroup]);
  useEffect(() => {
    if (ldapGroup?.ldap_group_id) {
      setLdapUsers(ldapGroup.person_ldap_user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldapGroup]);

  useEffect(() => {
    return () => {
      dispatch(setLdapGroup(initialState));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TemplateAddEdit
      breadcrumbs={breadcrumbs}
      title="Ldap Group"
      gridCol={2}
      item={ldapGroup}
      readOnly
      dataToShow={[{ key: "name", isRequired: true }]}
      onSaveClick={() => {}}
      additionalData={
        ldapGroup?.ldap_group_id && (
          <TemplateTable
            subPage
            itemsList={ldapUsers}
            headCells={[
              { id: "name" },
              { id: "user_principal_name", label: "Email" },
              // { id: "mail", label: "Email" },
              // { id: "display_name", label: "Display Name" },
              // { id: "given_name", label: "Given Name" },
              {
                id: "created_timestamp",
                label: "Created",
                attributeType: "dateTime",
              },
              {
                id: "updated_timestamp",
                label: "Updated",
                attributeType: "dateTime",
              },
              // {
              //   id: "action",
              //   buttons: ["edit", "connection"],
              // },
            ]}
            // handleButton={handleButton}
            itemId="ldap_user_id"
            searchAttributes={["name", "user_principal_name"]}
            title="Group Users"
            // allowExport
            // exportTitle={t("LdapGroupUsers")}
          />
        )
      }
    />
  );
}

export default ViewLdapGroup;
