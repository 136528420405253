import React, {useState, useEffect, useMemo} from "react";
import {Box, Button, Typography, List, ListItem, ListItemText, IconButton} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";
import {styled, useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import {useDispatch, useSelector} from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import Breadcrumbs from "../common/breadcrumbs";
import SearchInput from "../common/searchInput";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV";
import StyledTypography from "../common/StyledTypography";
import SignalsService from "../api/signals";
import {getComparator, stableSort} from "../utils/systemInfo";
import {setSignalDescriptorsData} from "../store/signalDescriptorsSlice";
import SignalUploadTableRow from "./signalUploadTableRow";
import Save from "@mui/icons-material/Save";

const breadcrumbs = [
    {id: 0, name: "Home", href: "./"},
    {id: 1, name: "HubServerConfig", href: "hubServerInfoApi"}
];

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(({theme}) => ({
    "color": theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
        color: theme.palette.custom.text
    }
}));

const StyledButton = styled((props) => <Button {...props} />)(({theme}) => ({
    "minWidth": "fit-content",
    "width": "150px",
    "height": "32px",
    "margin": "0px",
    "color": theme.palette.custom.buttonTextColor,
    "backgroundColor": theme.palette.custom.buttonColor,
    "borderColor": theme.palette.custom.borderColor,
    "borderWidth": "1px",
    "borderStyle": "solid",
    "&:hover": {
        backgroundColor: theme.palette.custom.buttonHoverColor,
        color: theme.palette.custom.buttonHoverTextColor,
        boxShadow: "none"
    }
}));

const headCells = [
    {
        id: "machineType",
        numeric: false,
        disablePadding: true,
        label: "Machine Type",
        sort: true
    },
    {
        id: "sortIDX",
        numeric: false,
        disablePadding: false,
        label: "Sort IDX",
        sort: true
    },
    {
        id: "signalDescriptorID",
        numeric: false,
        disablePadding: false,
        label: "Signal IDX",
        sort: true
    },
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Name",
        sort: true
    },
    {
        id: "eUnit",
        numeric: false,
        disablePadding: false,
        label: "Eunit",
        sort: false
    },
    {
        id: "color",
        numeric: false,
        disablePadding: false,
        label: "color",
        sort: false
    },
    {
        id: "min",
        numeric: false,
        disablePadding: false,
        label: "Min",
        sort: false
    },
    {
        id: "max",
        numeric: false,
        disablePadding: false,
        label: "Max",
        sort: false
    },
    {
        id: "lowThresh",
        numeric: false,
        disablePadding: false,
        label: "Low Threshold",
        sort: false
    },
    {
        id: "highThresh",
        numeric: false,
        disablePadding: false,
        label: "High Threshold",
        sort: false
    },
    {
        id: "desc",
        numeric: false,
        disablePadding: false,
        label: "Description",
        sort: false
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        sort: false
    }
];

const SignalUpload = () => {
    const [file, setFile] = useState(null);
    const theme = useTheme();
    const {t} = useTranslation();
    const organziation = useSelector(({organziation}) => organziation);
    const signalDescriptors = useSelector(({signalDescriptors}) => signalDescriptors);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("DeviceName");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(Number(localStorage.getItem("rowsPerPage")) ?? 10);
    const [signalDescriptorsInputData, setSignalDescriptorsInputData] = useState([]);
    const dispatch = useDispatch();
    const [loadingValue, setLoadingValue] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [apiKey, setApiKey] = useState("");

    useEffect(() => {
        if (organziation) {
            setSignalDescriptorsInputData(null);
            setApiKey(organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey"));
            SignalsService.GetDeviceSignalDescriptors({
                signal_descriptor_id: "",
                machine_type: "",
                apiKey
            })
            .then(async (json) => {
                console.log(json); // Debugging
                if (json && json.device_signal_descriptor && json.device_signal_descriptor.length > 0) {
                    const formattedData = {
                        json: json.device_signal_descriptor,
                        newSearchText: ""
                    };
                    console.log(formattedData)
                    setSignalDescriptorsInputData(formattedData);
                    await dispatch(setSignalDescriptorsData(formattedData)); // Await state update
                    setLoadingValue(false);
                }
            })
            .catch((error) => {
                console.error("API Fetch Error:", error);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organziation, apiKey]);
    

    const handleFileChange = (event) => {
        const newFile = event.target.files[0];
        setFile(newFile);
    };
    const handleFileSubmit = () => {
        if (!file) {
            console.error("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        SignalsService.UploadSignalDescriptor(formData, apiKey)
            .then((json) => {
                console.log("Upload Success:", json);
            })
            .catch((error) => {
                console.error("Upload failed:", error);
            });
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const newFile = event.dataTransfer.files[0];
        setFile(newFile);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const removeFile = () => {
        setFile(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem("rowsPerPage", event.target.value);
        setPage(0);
    };

    function handleSearch(ev) {
        setPage(0);
        setLoadingValue(true);
        const newSearchText = ev.target.value;
        setSearchText(newSearchText);
        let inputObj = signalDescriptorsInputData;
        inputObj.newSearchText = newSearchText;
        dispatch(setSignalDescriptorsData(inputObj));
        setLoadingValue(false);
    }

    const visibleRows = useMemo(
        () => {
            if (signalDescriptors && signalDescriptors.length > 0) {
                return stableSort(signalDescriptors, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            } else {
                return [];
            }
        },
        [order, orderBy, page, rowsPerPage, signalDescriptors]
        //[page, rowsPerPage, safety],
    );

    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{
                minHeight: "90vh",
                height: "fit-content",
                overflow: "auto"
            }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: {xs: "block", sm: "center"},
                    height: {xs: "fit-content", sm: "64px"}
                }}>
                <Box
                    sx={{
                        display: {xs: "block", sm: "flex"},
                        width: "100%",
                        alignItems: "center"
                    }}>
                    <Box
                        sx={{
                            display: {xs: "block", sm: "flex"},
                            width: "100%",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}>
                        <Box sx={{width: "100%"}}>
                            {
                                //<HomePagesMenu pageName={'Maintenance'} />
                            }
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                            <Typography
                                sx={{
                                    fontSize: "45px",
                                    mb: 0,
                                    color: theme.palette.custom.text
                                }}
                                variant="h2"
                                gutterBottom>
                                {
                                    // {capitalize(t('Devices'))}
                                }
                                {t("SignalUpload")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Box
                sx={{
                    p: 3,
                    border: "2px dashed",
                    borderColor: "primary.main",
                    borderRadius: 2,
                    textAlign: "center",
                    backgroundColor: "background.paper"
                }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}>
                <Typography variant="h6" gutterBottom>
                    Drag and drop a file here, or click to upload
                </Typography>
                <StyledButton variant="contained" component="label" startIcon={<CloudUploadIcon />}>
                    Select File
                    <input type="file" hidden onChange={handleFileChange} />
                </StyledButton>
                <br></br>
                <StyledButton onClick={handleFileSubmit} variant="contained" component="label" startIcon={<Save />} style={{marginTop: "5px"}}>
                    Submit
                </StyledButton>

                {file && (
                    <List sx={{mt: 2}}>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={removeFile}>
                                    <DeleteIcon />
                                </IconButton>
                            }>
                            <ListItemText primary={file.name} />
                        </ListItem>
                    </List>
                )}
            </Box>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <SearchInput
                searchText={searchText}
                handleSearch={handleSearch}
                autoFocus
                sx={{
                    width: {xs: "100%", sm: "20%"},
                    borderColor: theme.palette.custom.borderColor,
                    borderRadius: "11px",
                    backgroundColor: theme.palette.custom.light
                }}
            />
            {loadingValue ? (
                <Box sx={{height: "30vh", pt: "10%"}}>
                    <Loading />
                </Box>
            ) : (
                <TableContainer>
                    <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                        <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} hideCheck/>

                        <TableBody>
                            {signalDescriptors && signalDescriptors.length > 0 && visibleRows.length ? (
                                visibleRows.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return <SignalUploadTableRow key={index} row={row} labelId={labelId} />;
                                })
                            ) : (
                                <StyledTypography>{t("No entries found")}</StyledTypography>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <StyledTablePagination
                SelectProps={{
                    inputProps: {
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    "backgroundColor": theme.palette.secondary.dark,
                                    "color": theme.palette.custom.text,
                                    "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: theme.palette.custom.borderColor
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "blue"
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                        backgroundColor: "blue"
                                    }
                                }
                            }
                        }
                    }
                }}
                rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                component="div"
                count={signalDescriptors ? signalDescriptors.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <StyledButton
                variant="contained"
                sx={{
                    "backgroundColor": theme.palette.custom.buttonColor,
                    "color": theme.palette.custom.buttonTextColor,
                    "&:hover": {
                        backgroundColor: theme.palette.custom.buttonHoverColor,
                        color: theme.palette.custom.buttonHoverTextColor,
                        boxShadow: "none"
                    },
                    "float": "right",
                    "width": "min-content",
                    "whiteSpace": "nowrap",
                    "padding": "1rem"
                }}
                onClick={() => exportTableToCSV(headCells, visibleRows, "Organizations List")}>
                {t("Download List (*.csv)")}
            </StyledButton>
        </Box>
    );
};

export default SignalUpload;
