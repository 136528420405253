/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setSignalDescriptorsData = createAsyncThunk(
  "signalDescriptors/setSignalDescriptorsData",
  async (inputData) => {
    let signalDescriptors = [];
    if (inputData === null) {
      return null;
    }
    signalDescriptors = PopulateSignalDescriptors(inputData?.json);
    if (inputData?.newSearchText?.length && signalDescriptors && signalDescriptors.length) {
      const searchText = inputData.newSearchText.toLowerCase();
      return signalDescriptors.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );
    }
    return signalDescriptors;
  }
);
function PopulateSignalDescriptors(json) {
  let signalDescriptors = [];
  const signalDescriptorsArray = json;
  console.log(signalDescriptorsArray)
  for (let index in signalDescriptorsArray) {
    let signalDescriptorsDataObj = {
      signalDescriptorID: "",
      machineType: "",
      sortIDX: "",
      name: "",
      eUnit: "",
      color: "",
      min: "",
      max: "",
      lowThresh: "",
      highThresh: "",
      description: "",
      status: "",
      createdBy: "",
      updatedBy: "",
      updatedTimestamp: "",
      createdTimestamp: "",
    };
    signalDescriptorsDataObj.signalDescriptorID = signalDescriptorsArray[index].signal_descriptor_id;
    signalDescriptorsDataObj.machineType = signalDescriptorsArray[index].machine_type;
    signalDescriptorsDataObj.sortIDX = signalDescriptorsArray[index].signal_idx;
    signalDescriptorsDataObj.name = signalDescriptorsArray[index].name;
    signalDescriptorsDataObj.eUnit = signalDescriptorsArray[index].eunit;
    signalDescriptorsDataObj.color = signalDescriptorsArray[index].color;
    signalDescriptorsDataObj.min = signalDescriptorsArray[index].min;
    signalDescriptorsDataObj.max = signalDescriptorsArray[index].max;
    signalDescriptorsDataObj.lowThresh = signalDescriptorsArray[index].low_thresh;
    signalDescriptorsDataObj.highThresh = signalDescriptorsArray[index].high_thresh;
    signalDescriptorsDataObj.description = signalDescriptorsArray[index].description;
    signalDescriptorsDataObj.status = signalDescriptorsArray[index].status;
    signalDescriptorsDataObj.createdBy = signalDescriptorsArray[index].created_by_person_id;
    signalDescriptorsDataObj.updatedBy = signalDescriptorsArray[index].updated_by_person_id;
    signalDescriptorsDataObj.createdTimestamp = signalDescriptorsArray[index].created_timestamp;
    signalDescriptorsDataObj.updatedTimestamp = signalDescriptorsArray[index].updated_timestamp;
    signalDescriptors.push(signalDescriptorsDataObj);
  }
  return signalDescriptors;
}

const initialState = null;

const signalDescriptorsSlice = createSlice({
  name: "signalDescriptors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSignalDescriptorsData.fulfilled, (state, action) => action.payload);
  },
});

export const selectSignalDescriptors = ({ signalDescriptors }) => signalDescriptors;

export default signalDescriptorsSlice.reducer;
