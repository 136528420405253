/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setLdapGroup = createAsyncThunk(
  "ldapGroup/setLdapGroup",
  async (ldapGroup) => {
    return ldapGroup;
  }
);

export const initialState = {
  ldap_group_id: "",
  operation_id: "",
  name: "",
  sam_account_name: "",
  distinguished_name: "",
  cn: "",
  object_sid: "",
  object_guid: ""
};

const ldapGroupSlice = createSlice({
  name: "ldapGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setLdapGroup.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectLdapGroup = ({ ldapGroup }) => ldapGroup;

export default ldapGroupSlice.reducer;
