import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Button,
  Table,
  TextField,
  TableBody,
  TablePagination,
  TableContainer,
  Tab,
  Tabs,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import Loading from "../common/loading";
import Breadcrumbs from "../common/breadcrumbs";
import PersonAudit from "../api/personAudit";
import EnhancedTableHead from "../common/EnhancedTableHead";
import UserSigninLoggerTableRow from "./userSigninLoggerTableRow";
import UserSigninLoggerCountTab from "./userSigninLoggerCountTab";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV";
import StyledTypography from "../common/StyledTypography";
import FrequentStatisticsComponent from "../common/frequentStatisticsComponent";

import {
  GetDateTimeStringToday,
  GetDateTimeStringYesterday,
  GetDateTimeString7DaysAgo,
  GetDateTimeString14DaysAgo,
  GetDateTimeString30DaysAgo,
  GetDateTimeString90DaysAgo,
  getComparator,
  stableSort,
} from "../utils/systemInfo";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Developer Portal", href: "" },
  { id: 2, name: "User Signin Logger", href: "userSigninLogger" },
];
const timespanList = [
  {
    id: 0,
    secondLabel: "Day on Day",
    Label: "Last 24 hours",
    timespan: "24 hours",
  },
  {
    id: 1,
    secondLabel: "Week on Week",
    Label: "Last 7 days",
    timespan: "168 hours",
  },
  {
    id: 2,
    secondLabel: "Fortnight on Fortnight",
    Label: "Last 14 days",
    timespan: "336 hours",
  },
  {
    id: 3,
    secondLabel: "Month on Month",
    Label: "Last 30 days",
    timespan: "720 hours",
  },
  {
    id: 4,
    secondLabel: "Quarter on Quarter",
    Label: "Last 90 hours",
    timespan: "2160 hours",
  },
];
const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.borderColor,
    m: 0,
  })
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.light,
    maxHeight: "300px",
  },
}));
const StyleSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
}));
const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const StyleDivider = styled((props) => <Divider {...props} />)(({ theme }) => ({
  display: { xs: "none", sm: "flex" },
  height: "70px",
  mx: "2%",
  backgroundColor: theme.palette.custom.borderColor,
}));
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    height: "36px",
    minHeight: "36px",
    borderRadius: "3px",
    padding: "8px 16px",
    color: theme.palette.custom.text,
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonHoverColor,
    },
  })
);
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "device_id",
    numeric: false,
    disablePadding: true,
    label: "DeviceId",
    sort: true,
  },
  {
    id: "device_model",
    numeric: false,
    disablePadding: false,
    label: "DeviceModel",
    sort: true,
  },
  {
    id: "device_platform",
    numeric: false,
    disablePadding: false,
    label: "DevicePlatform",
    sort: true,
  },
  {
    id: `app_version`,
    numeric: false,
    disablePadding: false,
    label: "AppVersion",
    sort: true,
  },
  {
    id: `lat`,
    numeric: false,
    disablePadding: false,
    label: "Lat",
    sort: true,
  },
  {
    id: `lon`,
    numeric: false,
    disablePadding: false,
    label: "Lon",
    sort: true,
  },
  {
    id: `ip_address`,
    numeric: false,
    disablePadding: false,
    label: "IpAddress",
    sort: true,
  },
  {
    id: `screen_resolution`,
    numeric: false,
    disablePadding: false,
    label: "ScreenResolution",
    sort: true,
  },
  {
    id: `grahics_card`,
    numeric: false,
    disablePadding: false,
    label: "GrahicsCard",
    sort: true,
  },
  {
    id: `mac_address`,
    numeric: false,
    disablePadding: false,
    label: "MacAddress",
    sort: true,
  },
  {
    id: `memory`,
    numeric: false,
    disablePadding: false,
    label: "Memory",
    sort: true,
  },
  {
    id: `system_language`,
    numeric: false,
    disablePadding: false,
    label: "SystemLanguage",
    sort: true,
  },
  {
    id: `login_time`,
    numeric: false,
    disablePadding: false,
    label: "LoginTime",
    sort: true,
  },
];

let SigninLoggerBackendList = [];
let SigninLoggerCountBackendList = [];
function UserSigninLoggerPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [loadingValue, setLoadingValue] = useState(true);
  const [loadingCountValue, setLoadingCountValue] = useState(true);
  const [signinLoggerList, setSigninLoggerList] = useState(undefined);
  const [signinLoggerCountList, setSigninLoggerCountList] = useState(undefined);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("login_time");
  const [page, setPage] = useState(0);
  const [timeSpanMenuLabel, setTimespanMenuLabel] = useState("Week on Week");
  const [dateRangeString, setDateRangeString] = useState("Last 7 days");
  const [timespanString, setTimespanString] = useState("168 hours");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mostCommonDeviceModel, setMostCommonDeviceModel] = useState(null);
  const [mostCommonDevicePlatform, setMostCommonDevicePlatform] =
    useState(null);
  const [mostCommonScreenResolution, setMostCommonScreenResolution] =
    useState(null);
  const [mostUsedSystemLanguage, setMostUsedSystemLanguage] = useState(null);
  const [mostFrequentUser, setMostFrequentUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [dateTimeStringFrom, setDateTimeStringFrom] = useState(
    GetDateTimeString7DaysAgo()
  );
  const [dateTimeStringTo, setDateTimeStringTo] = useState(
    GetDateTimeStringToday()
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");
  const [searchCountText, setSearchCountText] = useState("");

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    handleGetLoggerList(GetDateTimeString7DaysAgo(), GetDateTimeStringToday());
  }, []);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleBreadCrumbsArray = (breadcrumbs) => {
    if (breadcrumbs.length === 3) {
      return breadcrumbs;
    }
    // breadcrumbs.push({ id: 1, name: device.DeviceName, href: `/device?device=${device.DeviceID}` })
    return breadcrumbs;
  };
  function handleGetLoggerList(from, to) {
    setLoadingValue(true);
    setLoadingCountValue(true);
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      setSigninLoggerList(null);
      SigninLoggerBackendList = [];
      PersonAudit.GetUserSignInLogger({
        signin_logger_uid: "",
        person_profile_uid: "",
        apiKey,
        dst_from: from,
        dst_to: to,
      })
        .then((json) => {
          if (
            json &&
            json.person_auth_signin_logger &&
            json.person_auth_signin_logger.length > 0
          ) {
            let arrayDeviceModel = [];
            let arrayUsers = [];
            let arraymostCommonDevicePlatform = [];
            let arrayScreenResolutionDistribution = [];
            let arrayMostUsedSystemLanguage = [];
            let device_screen_resolution = "";

            // setTemplatesList(json.ticket_template_header.filter((item) => item.status === "active"))
            for (let i = 0; i < json.person_auth_signin_logger.length; i++) {
              arrayDeviceModel.push(
                json.person_auth_signin_logger[i].device.model
              );
              arrayUsers.push(
                json.person_auth_signin_logger[i].person_profile.firstname +
                  " " +
                  json.person_auth_signin_logger[i].person_profile.lastname +
                  " " +
                  "(" +
                  json.person_auth_signin_logger[i].person_profile_uid +
                  ")"
              );
              arraymostCommonDevicePlatform.push(
                json.person_auth_signin_logger[i].device.platform
              );
              arrayMostUsedSystemLanguage.push(
                json.person_auth_signin_logger[i].device.system_language
              );

              device_screen_resolution =
                json.person_auth_signin_logger[i].device.screen_resolution;
              if (String(device_screen_resolution).length > 2) {
                arrayScreenResolutionDistribution.push(
                  device_screen_resolution
                );
              }
              let userExistIndex = SigninLoggerCountBackendList.findIndex(
                (x) =>
                  x.person_profile_uid ===
                  json.person_auth_signin_logger[i]?.person_profile_uid
              );
              if (userExistIndex > -1) {
                SigninLoggerCountBackendList[userExistIndex].count++;
                if (
                  json.person_auth_signin_logger[i].dst >
                  SigninLoggerCountBackendList[userExistIndex].latest_dst
                ) {
                  SigninLoggerCountBackendList[userExistIndex].latest_dst =
                    json.person_auth_signin_logger[i].dst;
                }
              } else {
                let userLogininCountObj = {
                  name:
                    json.person_auth_signin_logger[i]?.person_profile
                      .firstname +
                    " " +
                    json.person_auth_signin_logger[i]?.person_profile.lastname +
                    " " +
                    "(" +
                    json.person_auth_signin_logger[i]?.person_profile_uid +
                    ")",
                  person_profile_uid:
                    json.person_auth_signin_logger[i].person_profile_uid,
                  count: 1,
                  latest_dst: json.person_auth_signin_logger[i].dst,
                };
                SigninLoggerCountBackendList.push(userLogininCountObj);
              }
              let userLogininObj = {
                name:
                  json.person_auth_signin_logger[i]?.person_profile.firstname +
                  " " +
                  json.person_auth_signin_logger[i]?.person_profile.lastname +
                  " " +
                  "(" +
                  json.person_auth_signin_logger[i]?.person_profile_uid +
                  ")",
                device_id: json.person_auth_signin_logger[i]?.device?.id,
                device_model: json.person_auth_signin_logger[i]?.device?.model,
                device_platform:
                  json.person_auth_signin_logger[i]?.device?.platform,
                app_version: json.person_auth_signin_logger[i]?.app?.full_name,
                lat: json.person_auth_signin_logger[i]?.ip_lookup?.latitude,
                lon: json.person_auth_signin_logger[i]?.ip_lookup?.longitude,
                ip_address:
                  json.person_auth_signin_logger[i]?.ip_lookup?.ip_address,
                screen_resolution:
                  json.person_auth_signin_logger[i]?.device?.screen_resolution,
                grahics_card:
                  json.person_auth_signin_logger[i]?.device?.display_hardware,
                mac_address: json.person_auth_signin_logger[i]?.device?.mac,
                memory: json.person_auth_signin_logger[i]?.device?.memory,
                system_language:
                  json.person_auth_signin_logger[i]?.device?.system_language,
                login_time: json.person_auth_signin_logger[i]?.dst,
              };
              SigninLoggerBackendList.push(userLogininObj);
            }
            let mostCommonDeviceModelCount = 0;
            for (let i = 0; i < arrayDeviceModel.length; i++) {
              let count = arrayDeviceModel.filter(
                (x) => x === arrayDeviceModel[i]
              ).length;
              if (count > mostCommonDeviceModelCount) {
                mostCommonDeviceModelCount = count;
                setMostCommonDeviceModel(arrayDeviceModel[i]);
              }
            }
            let mostFrequentUserCount = 0;
            for (let i = 0; i < arrayUsers.length; i++) {
              let count = arrayUsers.filter((x) => x === arrayUsers[i]).length;
              if (count > mostFrequentUserCount) {
                mostFrequentUserCount = count;
                setMostFrequentUser(arrayUsers[i]);
              }
            }
            let mostCommonScreenResolutionCount = 0;
            for (let i = 0; i < arrayScreenResolutionDistribution.length; i++) {
              let count = arrayScreenResolutionDistribution.filter(
                (x) => x === arrayScreenResolutionDistribution[i]
              ).length;
              if (count > mostCommonScreenResolutionCount) {
                mostCommonScreenResolutionCount = count;
                setMostCommonScreenResolution(
                  arrayScreenResolutionDistribution[i]
                );
              }
            }
            let mostCommonDevicePlatformCount = 0;
            for (let i = 0; i < arraymostCommonDevicePlatform.length; i++) {
              let count = arraymostCommonDevicePlatform.filter(
                (x) => x === arraymostCommonDevicePlatform[i]
              ).length;
              if (count > mostCommonDevicePlatformCount) {
                mostCommonDevicePlatformCount = count;
                setMostCommonDevicePlatform(arraymostCommonDevicePlatform[i]);
              }
            }
            let mostUsedSystemLanguageCount = 0;
            for (let i = 0; i < arrayMostUsedSystemLanguage.length; i++) {
              let count = arrayMostUsedSystemLanguage.filter(
                (x) => x === arrayMostUsedSystemLanguage[i]
              ).length;
              if (count > mostUsedSystemLanguageCount) {
                mostUsedSystemLanguageCount = count;
                setMostUsedSystemLanguage(arrayMostUsedSystemLanguage[i]);
              }
            }
            setSigninLoggerList(SigninLoggerBackendList);
            setSigninLoggerCountList(SigninLoggerCountBackendList);
          }

          setLoadingValue(false);
          setLoadingCountValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    } else {
      setSigninLoggerList(null);
      setLoadingValue(false);
    }
  }
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    if (
      newSearchText &&
      newSearchText.length > 0 &&
      SigninLoggerBackendList &&
      SigninLoggerBackendList.length > 0
    ) {
      setSigninLoggerList(
        SigninLoggerBackendList.filter((item) =>
          item.name.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
    }
    setLoadingValue(false);
  }
  function handleSearchCount(ev) {
    setPage(0);
    setLoadingCountValue(true);
    const newSearchText = ev.target.value;
    setSearchCountText(newSearchText);
    if (
      newSearchText &&
      newSearchText.length > 0 &&
      SigninLoggerCountBackendList &&
      SigninLoggerCountBackendList.length > 0
    ) {
      setSigninLoggerCountList(
        SigninLoggerCountBackendList.filter((item) =>
          item.name.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
    }
    setLoadingCountValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (signinLoggerList && signinLoggerList.length > 0) {
        return stableSort(
          signinLoggerList,
          getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, signinLoggerList]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleChooseTimespan = (item) => {
    setLoadingValue(true);
    setTimespanMenuLabel(item.label);
    setTimespanString(item.timespan);
    setDateRangeString(item.secondLabel);
    let DataFrom;
    const DataTo = GetDateTimeStringToday();
    switch (item.id) {
      case 0:
        DataFrom = GetDateTimeStringYesterday();
        break;
      case 1:
      default:
        DataFrom = GetDateTimeString7DaysAgo();
        break;
      case 2:
        DataFrom = GetDateTimeString14DaysAgo();
        break;
      case 3:
        DataFrom = GetDateTimeString30DaysAgo();
        break;
      case 4:
        DataFrom = GetDateTimeString90DaysAgo();
        break;
    }
    setDateTimeStringFrom(DataFrom);
    setDateTimeStringTo(DataTo);
    handleGetLoggerList(DataFrom, DataTo);
    setAnchorElUser(false);
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {" "}
            {/* <HomePagesMenu pageName={'Maintenance'} /> */}
            <Breadcrumbs breadcrumbs={handleBreadCrumbsArray(breadcrumbs)} />
            <Typography
              sx={{
                fontSize: "45px",
                mb: 0,
                color: theme.palette.custom.text,
              }}
              variant="h2"
              gutterBottom
            >
              {/* {capitalize(t('Devices'))} */}
              {t("User Signin Logger")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        component="main"
        sx={{
          p: "12px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "16px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          height: { xs: "fit-content", sm: "48px" },
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            {t("DataSummary").toLocaleUpperCase()} &nbsp;|&nbsp;
          </Typography>
          <NumberTypography variant="subtitle2">
            {t(dateRangeString)}
            {` (${moment(dateTimeStringFrom, "YYYY/MM/DD HH:mm:ss").format(
              "YYYY/MM/DD"
            )} - ${moment(dateTimeStringTo, "YYYY/MM/DD HH:mm:ss").format(
              "YYYY/MM/DD"
            )})`}
          </NumberTypography>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            &nbsp;|&nbsp;{t("Timespan")}:&nbsp;{timespanString}
          </Typography>
        </Box>
        <>
          <Tooltip title="Open settings">
            <Button
              variant="text"
              onClick={handleOpenUserMenu}
              endIcon={
                <KeyboardArrowDownIcon
                  sx={{ color: theme.palette.custom.text }}
                />
              }
            >
              <StyleSpan>{timeSpanMenuLabel}</StyleSpan>
            </Button>
          </Tooltip>
          <StyledMenu
            id="menu-appbar"
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {timespanList &&
              timespanList.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => handleChooseTimespan(item)}
                >
                  <Typography textAlign="center">
                    {item.label ? item.label : ""}
                  </Typography>
                </MenuItem>
              ))}
          </StyledMenu>
        </>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: { backgroundColor: theme.palette.custom.borderColor },
        }}
        value={tabValue}
        onChange={handleTabChange}
        textColor="secondary"
      >
        <StyledTab iconPosition="start" label={t("UserLogin")} key={0} />
        <StyledTab iconPosition="start" label={t("UserLoginCount")} key={1} />
      </Tabs>
      <Box
        component="main"
        sx={{
          p: "12px",
          justifyContent: "space-between",
          textAlign: "center",
          borderRadius: "16px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          minHeight: { xs: "fit-content", sm: "48px" },
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            my: 2,
            color: theme.palette.custom.text,
          }}
          variant="h3"
          gutterBottom
        >
          {/* {capitalize(t('Devices'))} */}
          {t("MostFrequentStatistics")}:
        </Typography>
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            justifyContent: "space-between",
          }}
        >
          <FrequentStatisticsComponent
            title={t("DeviceModelType")}
            textValue={mostCommonDeviceModel}
          />
          <StyleDivider orientation="vertical" />
          <FrequentStatisticsComponent
            title={t("DevicePlatform")}
            textValue={mostCommonDevicePlatform}
          />
          <StyleDivider orientation="vertical" />
          <FrequentStatisticsComponent
            title={t("ScreenResolution")}
            textValue={mostCommonScreenResolution}
          />
          <StyleDivider orientation="vertical" />
          <FrequentStatisticsComponent
            title={t("SystemLanguage")}
            textValue={mostUsedSystemLanguage}
          />
          <StyleDivider orientation="vertical" />
          <FrequentStatisticsComponent
            title={t("User")}
            textValue={mostFrequentUser}
          />
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      {tabValue === 0 && (
        <>
          <TextField
            value={searchText}
            onChange={handleSearch}
            margin="normal"
            fullWidth
            id="search"
            label={t("Search")}
            name="search"
            autoFocus
            sx={{
              width: { xs: "100%", sm: "20%" },
              // borderColor: theme.palette.custom.borderColor,
              //borderRadius: '11px',
              backgroundColor: theme.palette.custom.light,
            }}
          />

          {loadingValue ? (
            <Box sx={{ height: "30vh", pt: "10%" }}>
              <Loading />
            </Box>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  hideCheck={true}
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {signinLoggerList &&
                  signinLoggerList.length > 0 &&
                  visibleRows.length ? (
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <UserSigninLoggerTableRow
                          key={index}
                          row={row}
                          handleClick={handleClick}
                          isItemSelected={isItemSelected}
                          labelId={labelId}
                        />
                      );
                    })
                  ) : (
                    <StyledTypography>{t("No entries found")}</StyledTypography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <StyledTablePagination
            SelectProps={{
              inputProps: {
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.secondary.dark,
                      color: theme.palette.custom.text,
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: theme.palette.custom.borderColor,
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "blue",
                      },
                      "& .MuiMenuItem-root.Mui-selected:hover": {
                        backgroundColor: "blue",
                      },
                    },
                  },
                },
              },
            }}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            component="div"
            count={signinLoggerList ? signinLoggerList.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
      {tabValue === 1 && (
        <UserSigninLoggerCountTab
          loadingValue={loadingCountValue}
          signinLoggerCountList={signinLoggerCountList}
          handleSearch={handleSearchCount}
          searchText={searchCountText}
        />
      )}
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.buttonColor,
          color: theme.palette.custom.buttonTextColor,
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          exportTableToCSV(headCells, visibleRows, "Templates List")
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}

export default UserSigninLoggerPage;
