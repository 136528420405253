import * as React from "react";
import { Box, Divider, Typography, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { showMessage } from "../store/messageSlice";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CustomTextField from "../common/CustomTextField";
import LocationsMenu from "../common/locationsMenu";
import UsersMenu from "../common/usersMenu";
import { setStateHistory } from "../store/stateHistorySlice";
import { getUrlVars } from "../utils/systemInfo";
import DevicesService from "../api/devices";

const initialState = {
  device_state_history_id: "",
  serial: "",
  state_type: "",
  state_result: "",
  state_description: "",
  state_start: "",
  state_end: "",
  state_location_id: "",
  assigned_to_person_id: "",
  status: "active",
};
const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.borderColor,
    width: "10%",
    "&.MuiLoadingButton-loading": {
      backgroundColor: theme.palette.custom.light,
    },
  })
);
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  height: "36px",
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  margin: "0px 8px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
  },
  "& .MuiButtonBase-root": {
    height: "30px",
    color: theme.palette.custom.buttonTextColor,
    // backgroundColor: theme.palette.custom.buttonColor,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.custom.borderColor,
      borderRadius: "4px",
    },
    height: "36px",
    color: theme.palette.custom.buttonTextColor,
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.custom.text,
  },
}));
function AddEditDeviceStateHistory({
  triggerToggleDrawer,
  refreshStateHistory,
  usersList,
  locationsList,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [type, setType] = useState("");
  const [result, setResult] = useState("");
  const [description, setDescription] = useState("");
  const [locationId, setlocationId] = useState("");
  const [userId, setUserId] = useState("");
  const [fromValue, setFromValue] = useState(dayjs());
  const [toValue, setToValue] = useState(dayjs());
  const [savingLoading, setSavingLoading] = useState(false);

  const dispatch = useDispatch();
  const timezoneMins = useSelector(({ timezone }) => timezone);
  const stateHistory = useSelector(({ stateHistory }) => stateHistory);
  const deviceSerial = String(getUrlVars()["serial"]);
  const organziation = useSelector(({ organziation }) => organziation);

  useEffect(() => {
    console.log("deviceStateHistory", stateHistory);
    setType(stateHistory.state_type);
    setResult(stateHistory.state_result);
    setDescription(stateHistory.state_description);
    setlocationId(stateHistory.state_location_id);
    setUserId(stateHistory.assigned_to_person_id);
    setFromValue(
      stateHistory.state_start
        ? dayjs(stateHistory.state_start).add(timezoneMins, "minutes")
        : ""
    );
    setToValue(
      stateHistory.state_end
        ? dayjs(stateHistory.state_end).add(timezoneMins, "minutes")
        : ""
    );
    // if (stateHistory?.device_state_history_id !== "") {
    //   setType(stateHistory.state_type);
    //   setResult(stateHistory.state_result);
    //   setDescription(stateHistory.state_description);
    //   setFromValue(
    //     dayjs(stateHistory.state_start).add(timezoneMins, "minutes")
    //   );
    //   setToValue(dayjs(stateHistory.state_end).add(timezoneMins, "minutes"));
    // }
    //  else {
    //   dispatch(setStateHistory(initialState));
    // }

    // return () => {
    //   dispatch(setTag(initialState));
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateHistory]);
  function canBeSubmitted() {
    return type;
  }
  function handleSave() {
    setSavingLoading(true);
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");

    DevicesService.SetDeviceStateHistory({
      device_state_history_id:
        stateHistory?.device_state_history_id !== ""
          ? stateHistory?.device_state_history_id
          : "",
      serial: stateHistory?.serial !== "" ? stateHistory?.serial : deviceSerial,
      state_type: type,
      state_start: fromValue,
      state_location_id: locationId,
      state_description: description,
      state_result: result,
      state_end: toValue,
      assigned_to_person_id: userId,
      // device_state_tag_link_action_list:
      //   inputData.device_state_tag_link_action_list,
      apiKey,
    }).then((json) => {
      if (json.success === true && json.count > 0) {
        setSavingLoading(false);
        dispatch(
          showMessage({
            message: t("saved Successfully"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "success", //success error info warning null
          })
        );
        dispatch(setStateHistory(initialState));
        triggerToggleDrawer(false);
        refreshStateHistory();
      }
    });
  }
  function changeLocationValue(item) {
    console.log("state_location_id", item.operation_location_named_lookup_id);
    setlocationId(item.operation_location_named_lookup_id);
  }
  function changeUserValue(item) {
    console.log("assigned_to_person_id", item.profile_uid);
    setUserId(item.profile_uid);
  }
  return (
    <>
      <DrawerHeader>
        <Typography
          sx={{
            fontSize: "35px",
            mb: 0,
            ml: 1,
            color: theme.palette.custom.text,
          }}
          variant="h2"
          gutterBottom
        >
          {t("NewStateHistory")}
        </Typography>
        <CloseIcon onClick={() => triggerToggleDrawer(false)} />
      </DrawerHeader>
      <Divider />
      <Box
        sx={{
          display: { xs: "block", sm: "grid" },
          gridTemplateColumns: `repeat(${2}, minmax(0, 1fr))`,
          width: "100%",
          gap: "1rem",
          color: "#FFFFFF",
          justifyContent: "stretch",
        }}
      >
        <CustomTextField
          error={!type}
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
          id="txtType"
          label={t("Type")}
          variant="filled"
        />

        <CustomTextField
          error={!description}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          multiline
          id="txtDescription"
          label={t("Description")}
          variant="filled"
        />
        <StyledDateTimePicker
          //  label={t("Date")}
          slotProps={{
            textField: { size: "small" },
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: theme.palette.custom.buttonColor,
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "4px",
                },
              },
            },
            layout: {
              sx: {
                ".MuiDateCalendar-root": {
                  color: theme.palette.custom.borderColor,
                  borderWidth: 1,
                  borderColor: theme.palette.custom.borderColor,
                  border: "2px solid",
                  backgroundColor: theme.palette.custom.backgroundColor,
                },
                ".MuiPickersDay-root": {
                  color: theme.palette.custom.text,
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "4px",
                },
                ".Mui-selected": {
                  borderColor: theme.palette.custom.borderColor,
                  border: "2px solid",
                },
                ".MuiPickersCalendarHeader-root": {
                  color: theme.palette.custom.text,
                },
                ".MuiPickersCalendarHeader-switchViewButton": {
                  color: theme.palette.custom.text,
                },
                ".MuiDayCalendar-weekDayLabel": {
                  color: theme.palette.custom.text,
                },
              },
            },
          }}
          value={fromValue}
          onChange={(newValue) => setFromValue(newValue)}
          TextFieldComponent={(params) => (
            <TextField
              sx={{
                height: "20px",
                backgroundColor: theme.palette.custom.text,
                borderColor: theme.palette.custom.borderColor,
                color: theme.palette.custom.text,
              }}
              fullWidth
              defaultValue={{ undefined }}
              {...params}
            />
          )}
        />
        <StyledDateTimePicker
          //  label={t("Date")}
          slotProps={{
            textField: { size: "small" },
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: theme.palette.custom.buttonColor,
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "4px",
                },
              },
            },
            layout: {
              sx: {
                ".MuiDateCalendar-root": {
                  color: theme.palette.custom.borderColor,
                  borderWidth: 1,
                  borderColor: theme.palette.custom.borderColor,
                  border: "2px solid",
                  backgroundColor: theme.palette.custom.backgroundColor,
                },
                ".MuiPickersDay-root": {
                  color: theme.palette.custom.text,
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "4px",
                },
                ".Mui-selected": {
                  borderColor: theme.palette.custom.borderColor,
                  border: "2px solid",
                },
                ".MuiPickersCalendarHeader-root": {
                  color: theme.palette.custom.text,
                },
                ".MuiPickersCalendarHeader-switchViewButton": {
                  color: theme.palette.custom.text,
                },
                ".MuiDayCalendar-weekDayLabel": {
                  color: theme.palette.custom.text,
                },
              },
            },
          }}
          value={toValue}
          onChange={(newValue) => setToValue(newValue)}
          TextFieldComponent={(params) => (
            <TextField
              sx={{
                height: "20px",
                backgroundColor: theme.palette.custom.text,
                borderColor: theme.palette.custom.borderColor,
                color: theme.palette.custom.text,
              }}
              fullWidth
              defaultValue={{ undefined }}
              {...params}
            />
          )}
        />
        <CustomTextField
          error={!result}
          value={result}
          onChange={(e) => {
            setResult(e.target.value);
          }}
          id="txtResult"
          label={t("Result")}
          variant="filled"
        />
        <LocationsMenu
          locationName={locationId}
          onChooseLocation={changeLocationValue}
          locationsList={locationsList}
        />
        <UsersMenu
          userId={userId}
          onChooseUser={changeUserValue}
          usersList={usersList}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
        }}
      >
        <StyledLoadingButton
          loading={savingLoading}
          loadingIndicator={<CircularProgress color="primary" size={16} />}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          disabled={!canBeSubmitted()}
          onClick={() => handleSave()}
        >
          {t("Save")}
        </StyledLoadingButton>
      </Box>
    </>
  );
}
AddEditDeviceStateHistory.propTypes = {
  triggerToggleDrawer: PropTypes.func,
  refreshStateHistory: PropTypes.func,
  usersList: PropTypes.array,
  locationsList: PropTypes.array,
};

export default AddEditDeviceStateHistory;
