import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  GetDateTimeString14DaysAgo,
  GetDateTimeString30DaysAgo,
  GetDateTimeString7DaysAgo,
  GetDateTimeString90DaysAgo,
  GetDateTimeStringToday,
  GetDateTimeStringYesterday,
} from "../utils/systemInfo";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UserAuditTrailsService from "../api/userAuditTrails";
import { useSelector } from "react-redux";
import TemplateTable from "../common/Layout/templatePages/templateTable";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.light,
    maxHeight: "300px",
  },
}));
const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.borderColor,
    m: 0,
  })
);
const StyleSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
}));
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "3px",
    padding: "8px 16px",
    color: theme.palette.custom.text,
    height: "36px",
    minHeight: "36px",
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonColor,
      color: theme.palette.custom.buttonHoverTextColor,
      opacity: "70%",
    },
  })
);
const timespanList = [
  {
    id: 0,
    label: "Day on Day",
    secondLabel: "Last 24 hours",
    timespan: "24 hours",
  },
  {
    id: 1,
    label: "Week on Week",
    secondLabel: "Last 7 days",
    timespan: "168 hours",
  },
  {
    id: 2,
    label: "Fortnight on Fortnight",
    secondLabel: "Last 14 days",
    timespan: "336 hours",
  },
  {
    id: 3,
    label: "Month on Month",
    secondLabel: "Last 30 days",
    timespan: "720 hours",
  },
  {
    id: 4,
    label: "Quarter on Quarter",
    secondLabel: "Last 90 hours",
    timespan: "2160 hours",
  },
];
function UserAuditTrails() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [dateRangeString, setDateRangeString] = useState("Last 7 days");
  const [dateTimeStringFrom, setDateTimeStringFrom] = useState(
    GetDateTimeString7DaysAgo()
  );
  const [dateTimeStringTo, setDateTimeStringTo] = useState(
    GetDateTimeStringToday()
  );
  const organziation = useSelector(({ organziation }) => organziation);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [loadingValue, setLoadingValue] = useState(true);
  const [timeSpanMenuLabel, setTimespanMenuLabel] = useState("Last 7 days");
  const [timespanString, setTimespanString] = useState("168 hours");
  const [auditInfoArray, setAuditInfoArray] = useState([]);
  const [orgCountArray, setOrgCountArray] = useState([]);
  const [locationCountArray, setLocationCountArray] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      const DataFrom = GetDateTimeString7DaysAgo();
      const DataTo = GetDateTimeStringToday();

      UserAuditTrailsService.GetUserAuditTrails({
        // personProfileId: user?.profileId,
        historyFromDST: DataFrom,
        historyToDST: DataTo,
        apiKey,
      })
        .then((jsonCurrent) => {
          if (jsonCurrent?.person_audit_trail?.length) {
            createArrays(jsonCurrent?.person_audit_trail);
          } else {
            setLoadingValue(false);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function createArrays(jsonArray) {
    createAuditInfoArray(jsonArray);
    createOrgCountArray(jsonArray);
    createLocationCountArray(jsonArray);
    setLoadingValue(false);
  }
  function createAuditInfoArray(jsonArray) {
    const AuditInfoArray = jsonArray.map((item) => ({
      id: item.audit_trail_id,
      userFullName:
        item.person_profile?.firstname + " " + item.person_profile?.lastname,
      userProfileId: item.person_profile_id,
      organization: item.action_text?.split("::")[0].trim(),
      action: item.action_text?.split("::")[1].split("(")[0].trim(),
      location: item.action_text?.match(/\(([^)]+)\)/)[1],
      time: item.dst,
    }));
    setAuditInfoArray(AuditInfoArray);
  }
  function createOrgCountArray(jsonArray) {
    const OrgCountArray = [];
    jsonArray.forEach((item) => {
      const orgName = item.action_text.split("::")[0].trim();
      const existingOrg = OrgCountArray.find(
        (org) => org.organization === orgName
      );

      if (existingOrg) {
        existingOrg.auditCount += 1;
        if (new Date(item.dst) > new Date(existingOrg.lastAuditDate)) {
          existingOrg.lastAuditDate = item.dst;
        }
      } else {
        OrgCountArray.push({
          organization: orgName,
          lastAuditDate: item.dst,
          auditCount: 1,
        });
      }
    });
    setOrgCountArray(OrgCountArray);
  }
  function createLocationCountArray(jsonArray) {
    const LocationCountArray = [];
    jsonArray.forEach((item) => {
      const locationMatch = item.action_text.match(/\(([^)]+)\)/);
      const location = locationMatch ? locationMatch[1] : null;

      if (location) {
        const existingLocation = LocationCountArray.find(
          (loc) => loc.location === location
        );

        if (existingLocation) {
          existingLocation.auditCount += 1;
          if (new Date(item.dst) > new Date(existingLocation.lastAuditDate)) {
            existingLocation.lastAuditDate = item.dst;
          }
        } else {
          LocationCountArray.push({
            location: location,
            lastAuditDate: item.dst,
            auditCount: 1,
          });
        }
      }
    });
    setLocationCountArray(LocationCountArray);
  }

  const handleChooseTimespan = (item) => {
    setLoadingValue(true);
    setTimespanMenuLabel(item.secondLabel);
    setTimespanString(item.timespan);
    setDateRangeString(item.secondLabel);
    let DataFrom;
    const DataTo = GetDateTimeStringToday();
    switch (item.id) {
      case 0:
        DataFrom = GetDateTimeStringYesterday();
        break;
      case 1:
      default:
        DataFrom = GetDateTimeString7DaysAgo();
        break;
      case 2:
        DataFrom = GetDateTimeString14DaysAgo();
        break;
      case 3:
        DataFrom = GetDateTimeString30DaysAgo();
        break;
      case 4:
        DataFrom = GetDateTimeString90DaysAgo();
        break;
    }
    setDateTimeStringFrom(DataFrom);
    setDateTimeStringTo(DataTo);
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      UserAuditTrailsService.GetUserAuditTrails({
        // personProfileId: user?.profileId,
        historyFromDST: DataFrom,
        historyToDST: DataTo,
        apiKey,
      })
        .then((jsonCurrent) => {
          if (jsonCurrent?.person_audit_trail?.length) {
            createArrays(jsonCurrent?.person_audit_trail);
          } else {
            setLoadingValue(false);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    setAnchorElUser(false);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        backgroundColor: theme.palette.custom.backgroundColor,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: { xs: "block", sm: "center" },
          gap: "1rem",
        }}
      >
        <Typography
          sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.text }}
          variant="h2"
          gutterBottom
        >
          {t("UserAuditTrails")}
        </Typography>
      </Box>
      <Divider
        sx={{ mb: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        component="main"
        sx={{
          p: "12px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "16px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          height: { xs: "fit-content", sm: "48px" },
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            {t("AuditTrailsDataSummary").toLocaleUpperCase()} &nbsp;|&nbsp;
          </Typography>
          <NumberTypography variant="subtitle2">
            {t(dateRangeString)}
            {` (${moment(dateTimeStringFrom, "YYYY/MM/DD HH:mm:ss").format(
              "YYYY/MM/DD"
            )} - ${moment(dateTimeStringTo, "YYYY/MM/DD HH:mm:ss").format(
              "YYYY/MM/DD"
            )})`}
          </NumberTypography>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            &nbsp;|&nbsp;{t("Timespan")}:&nbsp;{timespanString}
          </Typography>
        </Box>
        <>
          <Tooltip title={t("ChooseInterval")}>
            <Button
              variant="text"
              onClick={handleOpenUserMenu}
              endIcon={
                <KeyboardArrowDownIcon
                  sx={{ color: theme.palette.custom.text }}
                />
              }
            >
              <StyleSpan>{timeSpanMenuLabel}</StyleSpan>
            </Button>
          </Tooltip>
          <StyledMenu
            id="menu-appbar"
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {timespanList &&
              timespanList.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => handleChooseTimespan(item)}
                >
                  <Typography textAlign="center">
                    {item.secondLabel ? item.secondLabel : ""}
                  </Typography>
                </MenuItem>
              ))}
          </StyledMenu>
        </>
      </Box>
      <Tabs
        sx={{ mt: "2rem" }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: { backgroundColor: theme.palette.custom.borderColor },
        }}
        value={tabValue}
        onChange={handleTabChange}
        textColor="secondary"
      >
        <StyledTab label={t("AuditTrailInfo")} />
        <StyledTab label={t("OrganizationAuditTrailCount")} />
        <StyledTab label={t("LocationAuditTrailCount")} />
      </Tabs>
      {tabValue === 0 && (
        <TemplateTable
          headCells={[
            {
              id: "userFullName",
              label: "UserFullName",
            },
            {
              id: "userProfileId",
              label: "UserProfileID",
            },
            {
              id: "organization",
              label: "Organization",
            },
            {
              id: `action`,
              label: "Action",
            },
            {
              id: `location`,
              label: "Location",
            },
          ]}
          itemsList={auditInfoArray}
          itemId="id"
          loading={loadingValue}
          searchAttributes={[
            "userFullName",
            "userProfileId",
            "organization",
            "action",
            "location",
          ]}
          allowExport
          exportTitle={t("AuditTrailInfo")}
        />
      )}
      {tabValue === 1 && (
        <TemplateTable
          headCells={[
            {
              id: "organization",
              label: "Organization",
            },
            {
              id: "lastAuditDate",
              label: "LastAuditDate",
              attributeType: "dateTime",
            },
            {
              id: "auditCount",
              label: "AuditCount",
            },
          ]}
          itemsList={orgCountArray}
          itemId="organization"
          loading={loadingValue}
          searchAttributes={["organization"]}
          allowExport
          exportTitle={t("OrganizationAuditTrailCount")}
        />
      )}
      {tabValue === 2 && (
        <TemplateTable
          headCells={[
            {
              id: "location",
              label: "Location",
            },
            {
              id: "lastAuditDate",
              label: "LastAuditDate",
              attributeType: "dateTime",
            },
            {
              id: "auditCount",
              label: "AuditCount",
            },
          ]}
          itemsList={locationCountArray}
          itemId="location"
          loading={loadingValue}
          searchAttributes={["location"]}
          allowExport
          exportTitle={t("LocationAuditTrailCount")}
        />
      )}
    </Box>
  );
}

export default UserAuditTrails;
