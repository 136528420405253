import { combineReducers } from "@reduxjs/toolkit";
import user from "./userSlice";
import organziation from "./organziationSlice";
import permissions from "./permissionsSlice";
import opsum from "./opsumSlice";
import monitoring from "./monitoringSlice";
import safety from "./safetySlice";
import maintenance from "./maintenanceSlice";
import productivity from "./productivitySlice";
import machines from "./machinesSlice";
import device from "./deviceSlice";
import deviceCompare from "./deviceCompareSlice";
import machineSafety from "./machineSafetySlice";
import users from "./usersSlice";
import assignDevice from "./assignDevicesSlice";
import exceptions from "./exceptionsSlice";
import shifts from "./shiftsSlice";
import organizations from "./organizationsSlice";
import organziations from "./organziationsSlice";
import allUsers from "./allUserSlice";
import dashboard from "./dashboardSlice";
import dashboards from "./dashboardsSlice";
import assignUsers from "./assignUserSlice";
import devToUser from "./devToUserSlice";
import notifications from "./notificationsSlice";
import theme from "./themeSlice";
import templates from "./ticketTemplatesSlice";
import inputOptions from "./inputOptionsSlice";
import dashboardsBackend from "./dashboardsBackendSlice";
import dashboardsOrganizationLinking from "./dashboardsOrganizationLinkingSlice";
import dashboardsOrganizationLinkings from "./dashboardsOrganizationLinkingsSlice";
import machineStatus from "./machineStatusSlice";
import machineDocumentation from "./machineDocumentationSlice";
import machineComments from "./machineStatusCommentSlice";
import message from "./messageSlice";
import timezone from "./timezoneSlice";
import hubServerConfig from "./hubServerConfigSlice";
import addHubServerConfig from "./addHubServerConfigSlice";
import subOrganizations from "./subOrganizationsSlice";
import machineGroups from "./machineGroupsSlice";
import deviceGroup from "./deviceGroupSlice";
import linkedDevices from "./linkedDevicesSlice";
import linkedDevicesView from "./linkedDevicesViewSlice";
import template from "./templateSlice";
import namedLocation from "./namedLocationSlice";
import ldapServer from "./ldapServerSlice";
import ldapGroup from "./ldapGroupSlice";
import ldapUser from "./ldapUserSlice";
import signalDescriptors from "./signalDescriptorsSlice";
import tag from "./tagSlice";
import stateHistory from "./stateHistorySlice";
import userTypes from "./userTypesSlice";
import assignUserTypes from "./assignUserTypeSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    user,
    organziation,
    permissions,
    opsum,
    safety,
    monitoring,
    maintenance,
    productivity,
    machines,
    device,
    deviceCompare,
    machineSafety,
    users,
    exceptions,
    shifts,
    organizations,
    organziations,
    allUsers,
    assignDevice,
    assignUsers,
    devToUser,
    dashboard,
    dashboards,
    theme,
    notifications,
    templates,
    inputOptions,
    dashboardsBackend,
    dashboardsOrganizationLinking,
    dashboardsOrganizationLinkings,
    machineStatus,
    machineDocumentation,
    machineComments,
    message,
    timezone,
    hubServerConfig,
    addHubServerConfig,
    subOrganizations,
    machineGroups,
    deviceGroup,
    linkedDevices,
    linkedDevicesView,
    template,
    namedLocation,
    ldapServer,
    ldapGroup,
    ldapUser,
    signalDescriptors,
    tag,
    stateHistory,
    userTypes,
    assignUserTypes,
    ...asyncReducers,
  });

  /*
  Reset the redux store when user logged out
   */
  // if (action.type === 'user/userLoggedOut') {
  //   // state = undefined;
  // }

  return combinedReducer(state, action);
};

export default createReducer;
