import Utils from "../utils/utils";

class ThemesService extends Utils.EventEmitter {
  SetThemeProfileLink(
    theme_profile_link_id = "",
    operation_profile_id = "",
    theme_profile_id = "",
    status = "active"
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            theme_profile_link_id: theme_profile_link_id,
            operation_profile_id: operation_profile_id,
            theme_profile_id: theme_profile_id,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/theme/profile/link/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  getThemeProfileLink(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          theme_profile_link_id: "",
          operation_profile_id: inputData.uid,
          theme_profile_id: "",
          status: "active",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/theme/profile/link/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetThemeProfile(theme_id = "", name = "", description = "", status = "") {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            theme_id: theme_id,
            name: name,
            description: description,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/theme/profile/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  getThemeProfile(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          theme_id: inputData.theme_profile_id,
          name: "",
          description: "",
          status: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/config/theme/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetThemeProfileAttributes(
    theme_attribute_id = "",
    theme_profile_id = "",
    name = "",
    description = "",
    value = "",
    data_type = "",
    attribute_type = "",
    status = ""
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            theme_attribute_id: theme_attribute_id,
            theme_profile_id: theme_profile_id,
            name: name,
            description: description,
            value: value,
            data_type: data_type,
            attribute_type: attribute_type,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/theme/profile/attributes/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetThemeProfileAttributes(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          theme_attribute_id: inputData.theme_attribute_id,
          theme_profile_id: inputData.theme_profile_id,
          name: inputData.name,
          description: "",
          value: "",
          data_type: "",
          attribute_type: "",
          status: inputData.status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/config/theme/profile/attributes/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new ThemesService();

export default instance;
