import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import LdapService from "../api/Ldap";
import TemplateAddEdit from "../common/Layout/templatePages/templateAddEdit";
import {setLdapServer, initialState} from "../store/ldapServerSlice";

const breadcrumbs = [
    {id: 0, name: "Home", href: "./"},
    {id: 1, name: "LdapServers", href: "ldapServers"},
    {id: 2, name: "Add/Edit Ldap Custom Filter", href: "addEditLdapServer"}
];
function AddEditLdapCustomFilter() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ldapServer = useSelector(({ldapServer}) => ldapServer);
    const organziation = useSelector(({organziation}) => organziation);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const ldapServerId = params.get("id");
    const ldapSearchId = params.get("sid");
    const [ldapSearch, setLdapSearch] = useState([]);
    const [testConnectionResult, setTestConnectionResult] = useState([]);

    useEffect(() => {
        if (!ldapServer?.ldap_server_id && ldapServerId && ldapServerId !== "undefined") {
            const apiKey = organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
            LdapService.GetLdapServers({
                serverId: ldapServerId,
                OrganizationId: organziation.uid,
                apiKey
            })
                .then((jsonCurrent) => {
                    if (jsonCurrent?.person_ldap_server?.length) {
                        console.log(jsonCurrent);
                        dispatch(setLdapServer(jsonCurrent?.person_ldap_server[0]));
                        LdapService.TestConnection({ldap_server_id: ldapServerId}).then((json) => {
                            console.log(json);
                            if (json.success === true && json.count > 0) {
                                setTestConnectionResult(
                                    json.person_ldap_server_test_connection.filter((item) => {
                                        if (item.test_title === "Organizational Units") {
                                            return item;
                                        }
                                    })
                                );
                                console.log(testConnectionResult);
                            }
                        });
                    } else {
                        navigate("/ldapServers");
                    }
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === "development") console.log("error", errorr);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ldapServer]);
    useEffect(() => {
        if (ldapServer?.ldap_server_id) {
            const apiKey = organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
            LdapService.GetLdapSearch({ldap_server_id: ldapServer?.ldap_server_id, ldap_server_search_id: ldapSearchId, apiKey}).then((json) => {
                // console.log(json)
                if (json.success && json.count > 0) {
                    // console.log(json)
                    setLdapSearch(json?.person_ldap_server_search[0]);
                } else {
                    setLdapSearch({
                        ldap_server_id: ldapServer?.ldap_server_id || ldapServerId
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ldapServer]);

    useEffect(() => {
        return () => {
            dispatch(setLdapServer(initialState));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSave(item) {
        LdapService.PostLdapSearch(item).then((json) => {
            // console.log(json)
            // console.log(item)
            if (json.success === true && json.count > 0) {
                const queryParams = new URLSearchParams({
                    id: ldapServerId
                }).toString();
                navigate(`/addEditLdapServer?${queryParams}`);
            }
        });
    }

    return (
        <TemplateAddEdit
            breadcrumbs={breadcrumbs}
            title="Add/Edit Ldap Custom Filter"
            gridCol={2}
            item={ldapSearch}
            dataToShow={[
                {key: "ldap_server_search_id", label: "Server Search ID", isRequired: false},
                {
                    key: "ldap_server_id",
                    label: "Server ID",
                    isRequired: true
                },
                {key: "search_type", label: "Search Type", isRequired: true},
                {key: "custom_filter", label: "Custom Filter", isRequired: false},
                {
                    key: "base_dn",
                    label: "Base DN",
                    type: "select",
                    isRequired: true,
                    options: testConnectionResult[0]?.test_result || []
                }
            ]}
            onSaveClick={(item) =>
                handleSave({
                    ...item,
                    apiKey: organziation?.api_key_list?.[0]?.key
                })
            }
        />
    );
}

export default AddEditLdapCustomFilter;
