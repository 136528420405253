import Utils from "../utils/utils";

class PersonAudit extends Utils.EventEmitter {
  GetUserSignInLogger(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          signin_logger_uid: inputData.signin_logger_uid,
          person_profile_uid: inputData.person_profile_uid,
          dst_from: inputData.dst_from,
          dst_to: inputData.dst_to,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/person/profile/login/logger/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new PersonAudit();

export default instance;
