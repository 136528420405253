import Utils from "../utils/utils";

class LdapService extends Utils.EventEmitter {
  GetLdapServers(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          ldap_server_id: inputData.serverId || "",
          operation_id: inputData.OrganizationId || "",
          target_host: "",
          target_port: "",
          tls: "",
          allow_unsafe_password_bind: "",
          bind_user_name: "",
          bind_password: "",
          timeout: "",
          realm: "",
          status: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/server/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  PostLdapServers(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            ldap_server_id: inputData.ldap_server_id || "",
            operation_id: inputData.operation_id || "",
            target_host: inputData.target_host || "",
            target_port: inputData.target_port || "",
            tls: inputData.tls || "false",
            allow_unsafe_password_bind: "true",
            bind_user_name: inputData.bind_user_name || "",
            bind_password: inputData.bind_password || "",
            timeout: inputData.timeout || "5500",
            realm: inputData.realm || "Nerospec.internal",
            status: inputData.status || "config",
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/server/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  TestConnection(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          ldap_server_id: inputData.ldap_server_id || "",
          anonymous_test: "true",
          credentials_test: "true",
          naming_context_test: "true",
          organizational_units_test: "true",
          users_test: "true",
          groups_test: "true",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/person/ldap/server/test/connection`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetLdapUsers(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          ldap_user_id: inputData.ldap_user_id || "",
          operation_id: inputData.operation_id || "",
          name: "",
          user_principal_name: "",
          display_name: "",
          mail: "",
          sam_account_name: "",
          distinguished_name: "",
          canonical_name: "",
          cn: "",
          description: "",
          object_sid: "",
          object_guid: "",
          given_name: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/user/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetLdapGroups(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          ldap_group_id: inputData.ldap_group_id || "",
          operation_id: inputData.operation_id || "",
          ldap_server_id: inputData.ldap_server_id,
          name: "",
          sam_account_name: "",
          distinguished_name: "",
          cn: "",
          object_sid: "",
          object_guid: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/group/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  DeleteLdapServers(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        delete: [
          {
            ldap_server_id: inputData.ldap_server_id || "",
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/person/ldap/server/delete`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetLdapSearch(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
            ldap_server_search_id: inputData.ldap_server_search_id || "",
            ldap_server_id: inputData.ldap_server_id || "",
            search_type: inputData.search_type,
            base_dn: inputData.base_dn,
            custom_filter: inputData.custom_filter,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/server/search/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  PostLdapSearch(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            ldap_server_search_id: inputData.ldap_server_search_id || "",
            ldap_server_id: inputData.ldap_server_id || "",
            search_type: inputData.search_type,
            base_dn: inputData.base_dn,
            custom_filter: inputData.custom_filter,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/server/search/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  RemoveLdapSearch(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        delete: [
          {
            ldap_server_search_id: inputData.ldap_server_search_id || "",
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/server/search/delete`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetLdapUserTypesLink(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
            person_ldap_group_id: inputData.person_ldap_group_id || "",
            app_user_type_uid: inputData.app_user_type_uid || "",
            operation_id: inputData.operation_id,
            ldap_server_id: inputData.ldap_server_id || "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/group/user/type/link/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  PostLdapUserTypesLink(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            person_ldap_group_id: inputData.person_ldap_group_id || "",
            app_user_type_uid: inputData.app_user_type_uid || "",
            operation_id: inputData.operation_id,
            ldap_server_id: inputData.ldap_server_id,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/group/user/type/link/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  RemoveLdapUserTypesLink(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        delete: [
          {
            ldap_group_user_type_link_id: inputData.ldap_group_user_type_link_id || "",
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/group/user/type/link/delete`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetLdapServerSyncLog(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
            ldap_server_id: inputData.person_ldap_group_id || "",
            operation_id: inputData.operation_id || "",
            log_level: inputData.log_level,
            engine_dst: inputData.engine_dst || "",
            server_dst: inputData.server_dst || "",
            dst: inputData.dst || "",
            pagination: inputData.pagination || "",
        }
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/ldap/sync/log/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}

const instance = new LdapService();

export default instance;
