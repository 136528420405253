/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setLdapUser = createAsyncThunk(
  "ldapUser/setLdapUser",
  async (ldapUser) => {
    return ldapUser;
  }
);

export const initialState = {
  ldap_user_id: "",
  user_principal_name: "",
  created_timestamp: "",
  updated_timestamp: "",
  person_ldap_group: [],

  operation_id: "104",
  distinguished_name: "",

  name: "",
  display_name: "",
  mail: "",
  sam_account_name: "",
  canonical_name: "",
  cn: "",
  description: "",
  object_sid: "",
  object_guid: "",
  given_name: "",
};

const LdapUserSlice = createSlice({
  name: "ldapUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setLdapUser.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectLdapUser = ({ ldapUser }) => ldapUser;

export default LdapUserSlice.reducer;
