import React, {useState, useMemo, useEffect} from "react";
import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {styled, useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import PropTypes from "prop-types";
import TemplateTableRow from "./templateTableRow";
import Loading from "../../loading";
import EnhancedTableHead from "../../EnhancedTableHead";
import SearchInput from "../../searchInput";
import StyledTypography from "../../StyledTypography";
import {getComparator, stableSort} from "../../../utils/systemInfo";
import exportTableToCSV from "../../customFunctions/exportTableToCSV";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(({theme}) => ({
    "color": theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
        color: theme.palette.custom.text
    }
}));
const getNestedValue = (obj, path) => {
    return path?.split(".").reduce((acc, part) => acc && acc[part], obj);
};
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function TemplateTable(props) {
    const {headCells: pHeadCells, itemsList: ItemsBackendList, orderBy: pOrderBy, loading: pLoading, title, itemId, searchAttributes, allowExport, exportTitle, handleButton, subPage, titleButton, showButton = false} = props;
    const headCells = pHeadCells.map((hc) => ({
        ...hc,
        label: hc.label || capitalizeFirstLetter(hc.id),
        numeric: hc.numeric || false,
        disablePadding: hc.disablePadding || false,
        sort: hc.sort || true
    }));
    const theme = useTheme();
    const {t} = useTranslation();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(pOrderBy || headCells[0].id);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(Number(localStorage.getItem("rowsPerPage")) ?? 10);
    const [searchText, setSearchText] = useState("");
    const [selected, setSelected] = useState([]);
    const [loadingValue, setLoadingValue] = useState(false);
    const [itemsList, setItemsList] = useState(ItemsBackendList);
    useEffect(() => {
        setItemsList(ItemsBackendList);
    }, [ItemsBackendList]);

    function handleSearch(ev) {
        setPage(0);
        setLoadingValue(true);
        const newSearchText = ev.target.value;
        setSearchText(newSearchText);
        if (newSearchText && newSearchText.length > 0 && ItemsBackendList && ItemsBackendList.length > 0) {
            setItemsList(ItemsBackendList.filter((item) => searchAttributes.some((attribute) => getNestedValue(item, attribute)?.toString().toLowerCase().includes(newSearchText.toLowerCase()))));
        }
        setLoadingValue(false);
    }
    const visibleRows = useMemo(() => {
        if (itemsList && itemsList.length > 0) {
            return stableSort(itemsList, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        } else {
            return [];
        }
    }, [order, orderBy, page, rowsPerPage, itemsList]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem("rowsPerPage", event.target.value);
        setPage(0);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    return (
        <Box
            sx={{
                p: "12px",
                mt: "6px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{
                minHeight: !subPage && "90vh",
                height: "fit-content"
            }}>
            {title && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: {xs: "block", sm: "center"},
                            height: "64px"
                        }}>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                            {/* <HomePagesMenu pageName={'Maintenance'} /> */}
                            <Typography
                                sx={{
                                    fontSize: "35px",
                                    mb: 0,
                                    color: theme.palette.custom.text
                                }}
                                variant="h4"
                                gutterBottom>
                                {/* {capitalize(t('Devices'))} */}
                                {t(title)}
                            </Typography>
                            {showButton && titleButton}
                        </Box>
                    </Box>
                    <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
                </>
            )}
            <SearchInput searchText={searchText} handleSearch={handleSearch} />
            {loadingValue || pLoading ? (
                <Box sx={{height: "30vh", pt: "10%"}}>
                    <Loading />
                </Box>
            ) : (
                <TableContainer>
                    <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                        <EnhancedTableHead hideCheck headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

                        <TableBody>
                            {itemsList && itemsList.length > 0 && visibleRows.length ? (
                                visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(getNestedValue(row, itemId));
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return <TemplateTableRow key={index} headCells={headCells} row={row} itemId={itemId} handleClick={handleClick} handleButton={handleButton} isItemSelected={isItemSelected} labelId={labelId} />;
                                })
                            ) : (
                                <StyledTypography>{t("No entries found")}</StyledTypography>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <StyledTablePagination
                SelectProps={{
                    inputProps: {
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    "backgroundColor": theme.palette.secondary.dark,
                                    "color": theme.palette.custom.text,
                                    "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: theme.palette.custom.borderColor
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "blue"
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                        backgroundColor: "blue"
                                    }
                                }
                            }
                        }
                    }
                }}
                rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                component="div"
                count={itemsList ? itemsList.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {allowExport && (
                <Box sx={{width: "100%", display: "flex", justifyContent: "end"}}>
                    <Button
                        variant="contained"
                        sx={{
                            "backgroundColor": theme.palette.custom.borderColor,
                            "color": "white",
                            "&:hover": {
                                backgroundColor: theme.palette.custom.buttonHoverColor,
                                color: theme.palette.custom.buttonHoverTextColor,
                                boxShadow: "none"
                            },
                            "width": "min-content",
                            "whiteSpace": "nowrap",
                            "padding": "1rem"
                        }}
                        onClick={() => exportTableToCSV(headCells, visibleRows, exportTitle)}>
                        {t("Download List (*.csv)")}
                    </Button>
                </Box>
            )}
        </Box>
    );
}
TemplateTable.propTypes = {
    headCells: PropTypes.array.isRequired,
    itemsList: PropTypes.array.isRequired,
    itemId: PropTypes.string.isRequired,
    orderBy: PropTypes.string,
    loading: PropTypes.bool,
    title: PropTypes.string,
    exportTitle: PropTypes.string,
    searchAttributes: PropTypes.array,
    allowExport: PropTypes.bool,
    handleButton: PropTypes.func,
    subPage: PropTypes.bool,
    titleButton: PropTypes.element,
    showButton: PropTypes.bool
};
export default TemplateTable;
