import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme, styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Breadcrumbs from "../common/breadcrumbs";
import { useNavigate } from "react-router-dom";
import { setDeviceGroup } from "../store/deviceGroupSlice";
import DevicesService from "../api/devices";
import CustomTextField from "../common/CustomTextField";
import { showMessage } from "../store/messageSlice";
import PropTypes from "prop-types";
import { setLinkedDevices } from "../store/linkedDevicesSlice";
import { setMachinesData } from "../store/machinesSlice";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import {
  getComparator,
  GetDateTimeStringToday,
  stableSort,
} from "../utils/systemInfo";
import StyledTypography from "../common/StyledTypography";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV";
import DeviceGroupLinkTableRow from "./deviceGroupLinkTableRow";
import SearchInput from "../common/searchInput";
import AlertDialog from "../common/AlertDialog";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "3px",
    padding: "8px 16px",
    color: theme.palette.custom.text,
    height: "36px",
    minHeight: "36px",
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonColor,
      color: theme.palette.custom.buttonHoverTextColor,
      opacity: "70%",
    },
  })
);
const headCells = [
  {
    id: "DeviceName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "DeviceSerial",
    numeric: false,
    disablePadding: false,
    label: "Serial",
    sort: true,
  },
  {
    id: "DeviceType",
    numeric: false,
    disablePadding: false,
    label: "Machine Type",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "DeviceLastSeen",
    numeric: false,
    disablePadding: false,
    label: "LastSeen",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
function LinkDevices() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const organziation = useSelector(({ organziation }) => organziation);
  const deviceGroup = useSelector(({ deviceGroup }) => deviceGroup);
  const machines = useSelector(({ machines }) => machines);
  const linkedDevices = useSelector(({ linkedDevices }) => linkedDevices);
  const [opsumLinkedDevices, setOpsumLinkedDevices] = useState([]);
  const [loadingValue, setLoadingValue] = useState(true);
  const [loadingValueLinked, setLoadingValueLinked] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const parentPageName =
    window.location.href.split("/")[window.location.href.split("/").length - 2];

  const visibleRows = React.useMemo(() => {
    if (machines && machines.length > 0) {
      return stableSort(machines, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    } else {
      return [];
    }
  }, [order, orderBy, page, rowsPerPage, machines]);
  const visibleRowsLinked = React.useMemo(() => {
    if (linkedDevices && linkedDevices.length > 0) {
      return stableSort(linkedDevices, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    } else {
      return [];
    }
  }, [order, orderBy, page, rowsPerPage, linkedDevices]);

  useEffect(() => {
    if (deviceGroup?.person_device_group_id) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      DevicesService.getDevices({
        DeviceID: "",
        DeviceType: "",
        Source: "",
        apiKey,
        historyFromDST: "",
        historyToDST: GetDateTimeStringToday(),
      })
        .then((json) => {
          if (json && json.device_profile && json.device_profile.length > 0) {
            dispatch(
              setMachinesData({
                json: json.device_profile,
                machineTypeFilter: "",
                machineType: parentPageName?.toLowerCase().includes("device")
                  ? "device"
                  : "machine",
              })
            );
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("error", errorr);
        });
      DevicesService.getDeviceGroupLinkedDevices({
        deviceGroupId: deviceGroup?.person_device_group_id,
        apiKey,
      })
        .then((json) => {
          if (
            json &&
            json.person_device_group_link &&
            json.person_device_group_link.length > 0
          ) {
            setOpsumLinkedDevices(json.person_device_group_link);
          }
          setLoadingValueLinked(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("error", errorr);
        });
    } else {
      navigate(`/${parentPageName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceGroup]);
  useEffect(() => {
    if (opsumLinkedDevices?.length && machines?.length) {
      const linkedDevicesData = machines
        .filter((m) =>
          opsumLinkedDevices.some(
            (old) => String(old.device_id) === String(m.DeviceID)
          )
        )
        .map((m) => {
          const old = opsumLinkedDevices.find(
            (old) => String(old.device_id) === String(m.DeviceID)
          );
          return {
            ...m,
            dst: old.dst,
            person_device_group_id: old.person_device_group_id,
            person_device_group_link_id: old.person_device_group_link_id,
          };
        });
      dispatch(setLinkedDevices(linkedDevicesData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsumLinkedDevices, machines]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function handleSearch(ev) {
    setPage(0);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function handleLink(row) {
    setSaveLoading(true);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    DevicesService.LinkDeviceToDeviceGroup({
      deviceGroupId: deviceGroup?.person_device_group_id,
      DeviceID: row.DeviceID,
      status: "link",
      apiKey,
    })
      .then((json) => {
        if (
          json &&
          json.person_device_group_link &&
          json.person_device_group_link.length
        ) {
          dispatch(
            showMessage({
              message: t("Device Assigned Successfully"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "success", //success error info warning null
            })
          );
          addToLinkedDevices({ ...row, ...json.person_device_group_link[0] });
        } else {
          dispatch(
            showMessage({
              message: t("error occurred"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "error", //success error info warning null
            })
          );
        }
        setSaveLoading(false);
        setOpenDialog(false);
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === "development")
          console.log("error", errorr);
        setSaveLoading(false);
        setOpenDialog(false);
      });
  }
  function handleUnlink(row) {
    setSaveLoading(true); //linkId
    const apiKey = organziation?.api_key_list?.[0]?.key;
    DevicesService.LinkDeviceToDeviceGroup({
      linkId: row?.person_device_group_link_id,
      deviceGroupId: deviceGroup?.person_device_group_id,
      DeviceID: row.DeviceID,
      status: "unlink",
      apiKey,
    })
      .then((json) => {
        if (
          json &&
          json.person_device_group_link &&
          json.person_device_group_link.length
        ) {
          dispatch(
            showMessage({
              message: t("Device unlinked Successfully"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "success", //success error info warning null
            })
          );
          removeLinkedDevices(row.DeviceID);
        } else {
          dispatch(
            showMessage({
              message: t("error occurred"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "error", //success error info warning null
            })
          );
        }
        setSaveLoading(false);
        setOpenDialog(false);
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === "development")
          console.log("error", errorr);
        setSaveLoading(false);
        setOpenDialog(false);
      });
  }
  const isLinked = (device) => {
    return linkedDevices.find(
      (ld) => String(ld.DeviceID) === String(device.DeviceID)
    );
  };
  function handleClose() {
    setOpenDialog(false);
  }
  function addToLinkedDevices(device) {
    dispatch(setLinkedDevices([...linkedDevices, device]));
  }
  function removeLinkedDevices(DeviceID) {
    dispatch(
      setLinkedDevices(
        linkedDevices.filter((ld) => String(ld.DeviceID) !== String(DeviceID))
      )
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={value}
          TabIndicatorProps={{
            style: { backgroundColor: theme.palette.custom.borderColor },
          }}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label={t("All Devices")} {...a11yProps(0)} />
          <StyledTab label={t("Linked Devices")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: "1rem",
            alignItems: { md: "center" },
          }}
        >
          <SearchInput searchText={searchText} handleSearch={handleSearch} />
        </Box>
        {loadingValue ? (
          <Box sx={{ height: "30vh", pt: "10%" }}>
            <Loading />
          </Box>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                hideCheck
              />

              <TableBody>
                {machines && machines.length > 0 && visibleRows.length ? (
                  visibleRows
                    .filter((item) =>
                      Object.values(item).some((value) =>
                        String(value).toLowerCase().includes(searchText)
                      )
                    )
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <DeviceGroupLinkTableRow
                          key={row.name}
                          row={row}
                          onClick={() =>
                            setOpenDialog({
                              ...row,
                              person_device_group_link_id: linkedDevices?.find(
                                (ld) =>
                                  String(ld.DeviceID) === String(row.DeviceID)
                              )?.person_device_group_link_id,
                            })
                          }
                          labelId={labelId}
                          showNamedLocationOriginal={visibleRows.find(
                            (vr) =>
                              vr.NamedLocationOriginal &&
                              vr.NamedLocationOriginal !== "Unknown"
                          )}
                          isDevice={parentPageName
                            ?.toLowerCase()
                            .includes("device")}
                          isLinked={isLinked(row)}
                        />
                      );
                    })
                ) : (
                  <StyledTypography>{t("No entries found")}</StyledTypography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <StyledTablePagination
          SelectProps={{
            inputProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.custom.text,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.custom.borderColor,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "blue",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "blue",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={machines ? machines.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.borderColor,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
            float: "right",
            width: "min-content",
            whiteSpace: "nowrap",
            padding: "1rem",
          }}
          onClick={() =>
            exportTableToCSV(headCells, visibleRows, "devices List")
          }
        >
          {t("Download List (*.csv)")}
        </Button>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SearchInput searchText={searchText} handleSearch={handleSearch} />
        {loadingValueLinked ? (
          <Box sx={{ height: "30vh", pt: "10%" }}>
            <Loading />
          </Box>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                hideCheck
              />

              <TableBody>
                {linkedDevices &&
                linkedDevices.length > 0 &&
                visibleRowsLinked.length ? (
                  visibleRowsLinked
                    .filter((item) =>
                      Object.values(item).some((value) =>
                        String(value).toLowerCase().includes(searchText)
                      )
                    )
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <DeviceGroupLinkTableRow
                          key={row.name}
                          row={row}
                          onClick={() => setOpenDialog(row)}
                          labelId={labelId}
                          showNamedLocationOriginal={visibleRowsLinked.find(
                            (vr) =>
                              vr.NamedLocationOriginal &&
                              vr.NamedLocationOriginal !== "Unknown"
                          )}
                          isDevice={parentPageName
                            ?.toLowerCase()
                            .includes("device")}
                          isLinked
                        />
                      );
                    })
                ) : (
                  <StyledTypography>{t("No entries found")}</StyledTypography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <StyledTablePagination
          SelectProps={{
            inputProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.custom.text,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.custom.borderColor,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "blue",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "blue",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={linkedDevices ? linkedDevices.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.buttonColor,
            color: theme.palette.custom.buttonTextColor,
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
            float: "right",
            width: "min-content",
            whiteSpace: "nowrap",
            padding: "1rem",
          }}
          onClick={() =>
            exportTableToCSV(
              headCells,
              visibleRowsLinked,
              "Linked devices List"
            )
          }
        >
          {t("Download List (*.csv)")}
        </Button>
      </CustomTabPanel>
      <AlertDialog
        open={Boolean(openDialog)}
        onClose={handleClose}
        onDoFunction={() =>
          isLinked(openDialog)
            ? handleUnlink(openDialog)
            : handleLink(openDialog)
        }
        saveLoading={saveLoading}
        dialogTitle={
          isLinked(openDialog)
            ? t("Are you sure you want to unlink this machine/device?")
            : t("Are you sure you want to link this machine/device?")
        }
        cancelLabel={t("Cancel")}
        buttonLabel={isLinked(openDialog) ? t("Unlink") : t("Link")}
      />
    </Box>
  );
}
const initDeviceGroup = {
  person_device_group_id: "",
  name: "",
  description: "",
  status: "active",
};
function AddDeviceGroup() {
  const parentPageName =
    window.location.href.split("/")[window.location.href.split("/").length - 2];
  const breadcrumbs = [
    { id: 0, name: "Home", href: "./" },
    { id: 1, name: "Device Group", href: `/${parentPageName}` },
    { id: 2, name: "Add/Edit Device Group", href: "addEditDeviceGroup" },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceGroup = useSelector(({ deviceGroup }) => deviceGroup);
  const { t } = useTranslation();
  const theme = useTheme();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const organziation = useSelector(({ organziation }) => organziation);
  useEffect(() => {
    setName(deviceGroup?.name);
    setDescription(deviceGroup?.description);
    setStatus(deviceGroup?.status);
    setPhysicalAddress(deviceGroup?.physical_address);
    return () => {
      dispatch(setDeviceGroup(initDeviceGroup));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceGroup]);
  async function onSaveClick() {
    const deviceGroupToSave = {
      person_device_group_id: deviceGroup?.person_device_group_id,
      person_id: deviceGroup?.person_id,
      operation_uid: deviceGroup?.operation_uid,
      name,
      description,
      status,
      physical_address: physicalAddress,
      apiKey: organziation?.api_key_list?.[0].key,
    };
    handleSave(deviceGroupToSave);
  }
  function handleSave(inputData) {
    DevicesService.SetDeviceGroup(inputData).then(async (json) => {
      if (json.success === true && json.count > 0) {
        dispatch(
          showMessage({
            message: t("Saved Successfully"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "success", //success error info warning null
          })
        );
        navigate(`/${parentPageName}`);
      } else {
        dispatch(
          showMessage({
            message: t("error occurred"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
      }
    });
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{ height: "100%" }}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.borderColor,
          }}
          gutterBottom
        >
          {t("Add/Edit Device group")}:
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          width: "100%",
          flexDirection: "row",
          color: "#FFFFFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "stretch",
            width: "100%",
            gap: "3rem",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              width: "100%",
            }}
          >
            <CustomTextField
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              id="txtName"
              label={t("Name")}
              variant="filled"
              margin="normal"
            />
            <CustomTextField
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              id="txtStatus"
              label={t("Status")}
              variant="filled"
              margin="normal"
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              width: "100%",
            }}
          >
            <CustomTextField
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              id="Description"
              label={t("Description")}
              variant="filled"
              margin="normal"
            />
            <CustomTextField
              value={physicalAddress}
              onChange={(e) => {
                setPhysicalAddress(e.target.value);
              }}
              id="physicalAddress"
              label={t("PhysicalAddress")}
              variant="filled"
              margin="normal"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.borderColor,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
          }}
          onClick={onSaveClick}
        >
          {t("Save")}
        </Button>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <LinkDevices />
    </Box>
  );
}

export default AddDeviceGroup;
