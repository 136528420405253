import Utils from "../utils/utils";

class MaintenanceService extends Utils.EventEmitter {
  getDeviceMaintenanceTypes(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          maintenance_type_id: inputData.maintenanceTypeId,
          name: "",
          status: "",
          updated_timestamp_from: "",
          updated_timestamp_to: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/type/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //  .post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/type/get`, headers, body)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  getDeviceMaintenances(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          maintenance_schedule_id: "",
          device_profile_id: inputData.deviceProfileId,
          maintenance_type_id: "",
          name: inputData.name,
          date_from_start: "",
          date_from_end: "",
          date_to_start: "",
          date_to_end: "",
          status: inputData.status,
          updated_timestamp_from: "",
          updated_timestamp_to: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new MaintenanceService();

export default instance;
