import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DevicesService from "../api/devices";
import { getUrlVars } from "../utils/systemInfo";
import TemplateAddEdit from "../common/Layout/templatePages/templateAddEdit";
import { setTag } from "../store/tagSlice";

const initialState = {
  device_state_tag_id: "",
  name: "",
  description: "",
  color: "",
  status: "active",
};

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Tags", href: "tags" },
  { id: 2, name: "Add/Edit Tag", href: "addEditTag" },
];
function AddEditTag() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tag = useSelector(({ tag }) => tag);
  const organziation = useSelector(({ organziation }) => organziation);
  const tagId = String(getUrlVars()["id"]);

  useEffect(() => {
    if (!getUrlVars()["id"]) {
      dispatch(setTag(initialState));
    }
    if (tag.device_state_tag_id === "" && getUrlVars()["id"]) {
      const defaultOrganization = localStorage.getItem("defaultOrganization");
      const orgObj = JSON.parse(defaultOrganization);
      const apiKey =
        organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
      const orgUid = organziation?.uid ?? orgObj?.uid;
      DevicesService.GetOrgnizationTags({
        device_state_tag_id: tagId,
        operation_id: orgUid,
        dst: {
          f: "",
          t: "",
        },
        apiKey,
      })
        .then((jsonCurrent) => {
          if (jsonCurrent?.device_state_tag?.length) {
            const tagObject = jsonCurrent?.device_state_tag[0];
            dispatch(
              setTag({
                device_state_tag_id: tagObject.device_state_tag_id,
                name: tagObject.tag_name,
                description: tagObject.tag_description,
                color: tagObject.tag_color,
                operation_id: tagObject.operation_id,
                status: tagObject.status,
              })
            );
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }

    // return () => {
    //   dispatch(setTag(initialState));
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  function handleSave({
    device_state_tag_id,
    name,
    description,
    color,
    status,
    api_key_current,
  }) {
    DevicesService.setOrgnizationTag({
      device_state_tag_id,
      name,
      description,
      color,
      operation_id: organziation.uid,
      status,
      api_key_current,
    }).then((json) => {
      if (json.success === true && json.count > 0) {
        navigate("/tags");
      }
    });
  }
  return (
    <TemplateAddEdit
      breadcrumbs={breadcrumbs}
      title="Add/Edit Tag"
      gridCol={2}
      item={tag}
      dataToShow={[
        { key: "name", /*label: "Name",*/ type: "text", isRequired: true },
        { key: "description", type: "text" },
        { key: "color", type: "color" },
      ]}
      onSaveClick={(item) =>
        handleSave({
          ...item,
          api_key_current: organziation?.api_key_list?.[0]?.key,
        })
      }
    />
  );
}

export default AddEditTag;
