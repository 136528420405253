import Utils from "../utils/utils";

class NamedLocationsService extends Utils.EventEmitter {
  GetNamedLocations(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_location_named_lookup_id: inputData.id || "",
          name: inputData.name || "",
          lookup: inputData.lookup || "",
          x: {
            f: "",
            t: "",
          },
          y: {
            f: "",
            t: "",
          },
          z: {
            f: "",
            t: "",
          },
          status: inputData.status || "",
          updated_timestamp: {
            f: "",
            t: "",
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/location/config/operation/named/lookup/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  LocationNamedLookupPost(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            operation_location_named_lookup_id:
              inputData.operation_location_named_lookup_id,
            operation_profile_uid: inputData.operation_profile_uid,
            lookup: inputData.lookup,
            name: inputData.name,
            x: inputData.x,
            y: inputData.y,
            z: inputData.z,
            description: inputData.description,
            type: inputData.type,
            type_color: inputData.type_color,
            status: inputData.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/location/config/operation/named/lookup/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}

const instance = new NamedLocationsService();

export default instance;
