import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {Box, Divider, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import LdapService from "../api/Ldap";
import {useDispatch, useSelector} from "react-redux";
import TemplateTable from "../common/Layout/templatePages/templateTable";
import handleNavigate from "../common/customFunctions/handleNavigate";
import {setLdapGroup} from "../store/ldapGroupSlice";
import {useNavigate, useLocation} from "react-router-dom";

function LdapGroups() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const organziation = useSelector(({organziation}) => organziation);
    const [loadingValue, setLoadingValue] = useState(true);
    const [ldapGroups, setLdapGroups] = useState([]);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const ldapServerId = params.get("id");

    useEffect(() => {
        if (organziation) {
            const apiKey = organziation?.api_key_list?.[0]?.key;
            const orgUid = ldapServerId === null ? "" : organziation.uid
            LdapService.GetLdapGroups({
                ldap_server_id: ldapServerId || "",
                operation_id: orgUid || "",
                apiKey
            })
                .then((jsonCurrent) => {
                    console.log(jsonCurrent);
                    if (jsonCurrent?.person_ldap_group?.length) {
                        setLdapGroups(jsonCurrent?.person_ldap_group);
                    }
                    setLoadingValue(false);
                })
                .catch((errorr) => {
                    if (process.env.NODE_ENV === "development") console.log("error", errorr);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButton = (event, buttonType, row) => {
        if (buttonType === "view") {
            handleView(event, row);
        }
    };
    const handleView = (event, row) => {
        dispatch(setLdapGroup(row));
        event.stopPropagation();
        handleNavigate(event, navigate, `/viewLdapGroup?id=${row.ldap_group_id}`);
    };
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`,
                backgroundColor: theme.palette.custom.backgroundColor
            }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: {xs: "block", sm: "center"},
                    gap: "1rem",
                    mb: "1rem"
                }}>
                <Typography sx={{fontSize: "45px", mb: 0, color: theme.palette.custom.text}} variant="h2" gutterBottom>
                    {t("LdapGroups")}
                </Typography>
            </Box>
            <Divider sx={{mb: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <TemplateTable
                headCells={[
                    {id: "name"},
                    {
                        id: "created_timestamp",
                        label: "Created",
                        attributeType: "dateTime"
                    },
                    {
                        id: "updated_timestamp",
                        label: "Updated",
                        attributeType: "dateTime"
                    },
                    {
                        id: "action",
                        buttons: ["view"]
                    }
                ]}
                handleButton={handleButton}
                itemsList={ldapGroups}
                itemId="ldap_group_id"
                loading={loadingValue}
                searchAttributes={["name", "sam_account_name"]}
                allowExport
                exportTitle={t("LdapGroups")}
            />
        </Box>
    );
}

export default LdapGroups;
