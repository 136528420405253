import React, { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import moment from "moment";
import StyledTypography from "../common/StyledTypography";
import PropTypes from "prop-types";

export default function UserSigninLoggerCountTableRow({
  row,
  handleClick,
  isItemSelected,
}) {
  const timezoneMins = useSelector(({ timezone }) => timezone);

  return (
    <Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, row.ticket_number)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.ticket_number}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell align="left">
          <StyledTypography variant="subtitle2">{row.name}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {moment
              .utc(row.latest_dst)
              .add(timezoneMins, "minutes")
              .format("YYYY-MM-DD HH:mm")}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">{row.count}</StyledTypography>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
UserSigninLoggerCountTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  isItemSelected: PropTypes.bool,
};
