/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetOperationPicturesFromJSON } from "../utils/systemInfo";

export const setOrganizations = createAsyncThunk(
  "organizations/setOrganizations",
  async (inputData) => {
    let organziationsArray = [];
    if (inputData === null) {
      return null;
    }
    organziationsArray = PopulateOrganization(inputData.jsonCurrent);
    if (
      inputData.newSearchText &&
      inputData.newSearchText.length > 0 &&
      organziationsArray &&
      organziationsArray.length > 0
    ) {
      const searchText = inputData.newSearchText.toLowerCase();
      return organziationsArray.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );
    }
    return organziationsArray;
  }
);
function PopulateOrganization(jsonThisWeek) {
  let OpsumArray = [];
  const orgArray = jsonThisWeek.filter(
    (x) => x.app_profile_info.full_name === window.env.REACT_APP_API_APP_NAME
  );
  for (let i in orgArray) {
    const Organization_App_Info = orgArray[i].app_profile_info;
    const Organization_Info = orgArray[i].operation_profile_info;
    let opsumDataObj = {
      id: "",
      name: "",
      emailAddress: "",
      mobileNumber: "",
      website: "",
      iconPublicURL: null,
      logoPublicURL: null,
      backgroundPublicURL: null,
      apiKey: null,
      taxNumber: null,
      vatNumber: null,
    };
    if (Organization_App_Info === undefined || Organization_Info === undefined)
      continue;
    if (Organization_Info.status === "active") {
      const Organization_ApiKeyList = Organization_Info.api_key_list;
      if (Organization_ApiKeyList === undefined) continue;
      const Organization_APIKeyObject = Organization_ApiKeyList.find((item) => {
        return (
          item.status === "active" &&
          new Date(1899, 11, 30 + item.expiry_date) > new Date()
        );
      });
      if (Organization_APIKeyObject !== undefined) {
        opsumDataObj.id = Organization_Info.uid;
        opsumDataObj.name = Organization_Info.full_name;
        opsumDataObj.emailAddress = Organization_Info.email_address;
        opsumDataObj.website = Organization_Info.website_address;
        opsumDataObj.mobileNumber = Organization_Info.tel_primary;
        opsumDataObj.taxNumber = Organization_Info.tax_number;
        opsumDataObj.vatNumber = Organization_Info.vat_number;
        opsumDataObj.apiKey = Organization_APIKeyObject.key;
        const Organization_Image_Array = Organization_Info.operation_image_list;
        const Organization_Images = GetOperationPicturesFromJSON(
          Organization_Image_Array
        );
        opsumDataObj.iconPublicURL = Organization_Images.small_logo;
        opsumDataObj.logoPublicURL = Organization_Images.full_logo;
        opsumDataObj.backgroundPublicURL =
          Organization_Images.web_dashboard_background;
      }
    }

    OpsumArray.push(opsumDataObj);
  }
  return OpsumArray;
}

const initialState = null;

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setOrganizations.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectOrganizations = ({ organizations }) => organizations;

export default organizationsSlice.reducer;
