import React from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.custom.backgroundColor2,
        color: theme.palette.custom.text,
        fontWeight: "bold",
        '& .Mui-active': {
            color: theme.palette.common.borderColor,
            '& .MuiTableSortLabel-icon': {
                color: theme.palette.custom.borderColor,
            }
        },

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

export default function StyledTableCell({ children }) {
    return (
        <StyledCell >
            {children}
        </StyledCell>
    );
}
StyledTableCell.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
      ])
};