import {Box, Button, Checkbox, Divider, FormControlLabel, Typography, Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import PropTypes from "prop-types";
import Breadcrumbs from "../../breadcrumbs";
import CustomTextField from "../../CustomTextField";
import {useTranslation} from "react-i18next";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function TemplateAddEdit({breadcrumbs, title, gridCol, dataToShow, item: parentItem, onSaveClick, additionalData, readOnly}) {
    const {t} = useTranslation();
    const theme = useTheme();
    const [item, setItem] = useState({});

    useEffect(() => {
        if (parentItem) {
            setItem(parentItem);
        }
    }, [parentItem]);

    const canBeSubmitted = () => {
        for (let field of dataToShow) {
            if (field.isRequired) {
                const value = item[field.key];
                if (!value) {
                    return false;
                }
                switch (field.type) {
                    case "text":
                    default:
                        if (typeof value !== "string") return false;
                        break;
                    case "boolean":
                        if (typeof value !== "boolean") return false;
                        break;
                    case "date":
                        if (isNaN(Date.parse(value))) return false;
                        break;
                    case "email": {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) return false;
                        break;
                    }
                    case "url":
                        try {
                            new URL(value);
                        } catch (_) {
                            return false;
                        }
                        break;
                    case "select":
                        if (typeof value !== "string") return false;
                        break;
                }
            }
        }
        return true;
    };

    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{height: "100%"}}>
            {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            <Box sx={{display: {xs: "block", sm: "flex"}}}>
                <Typography sx={{fontSize: "45px", mb: 0, color: theme.palette.custom.borderColor}} gutterBottom>
                    {t(title)}:
                </Typography>
            </Box>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Box
                sx={{
                    display: {xs: "block", sm: "grid"},
                    gridTemplateColumns: `repeat(${gridCol || 2}, minmax(0, 1fr))`,
                    width: "100%",
                    gap: "1rem",
                    color: "#FFFFFF",
                    justifyContent: "stretch"
                }}>
                {dataToShow.map((field) => {
                    if (field.type === "boolean") {
                        return (
                            <FormControlLabel
                                key={field.key}
                                control={
                                    <Checkbox
                                        disabled={readOnly}
                                        checked={item[field.key] === "true"}
                                        onChange={(e) => {
                                            setItem((prevItem) => ({
                                                ...prevItem,
                                                [field.key]: e.target.checked.toString()
                                            }));
                                        }}
                                    />
                                }
                                label={t(field.label || capitalizeFirstLetter(field.key))}
                            />
                        );
                    } else if (field.type === "select" && field.options) {
                        return (
                            <FormControl
                                key={field.key}
                                required={field.isRequired}
                                disabled={readOnly}
                                fullWidth
                                variant="filled"
                                sx={{
                                    border: "1px solid",
                                    borderColor: theme.palette.custom.borderColor,
                                    borderRadius: "11px",
                                    backgroundColor: theme.palette.custom.backgroundColor
                                }}>
                                <InputLabel style={{color: "white", mixBlendMode: "difference"}}>{t(field.label || capitalizeFirstLetter(field.key))}</InputLabel>
                                <Select
                                    disableUnderline={true}
                                    error={field.isRequired && !item[field.key]}
                                    value={item[field.key] || ""}
                                    onChange={(e) => {
                                        setItem((prevItem) => ({
                                            ...prevItem,
                                            [field.key]: e.target.value
                                        }));
                                    }}
                                    label={t(field.label || capitalizeFirstLetter(field.key))}
                                    sx={{
                                        "borderRadius": "11px",
                                        "backgroundColor": theme.palette.custom.backgroundColor,
                                        "&:before": {border: "none"},
                                        "&.Mui-disabled:before": {borderStyle: "none"}
                                    }}>
                                    {field.options.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Typography textAlign="left">{capitalizeFirstLetter(option)}</Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    } else {
                        return (
                            <CustomTextField
                                key={field.key}
                                disabled={readOnly}
                                error={field.isRequired && !item[field.key]}
                                value={item[field.key] || ""}
                                onChange={(e) => {
                                    setItem((prevItem) => ({
                                        ...prevItem,
                                        [field.key]: e.target.value
                                    }));
                                }}
                                id={field.key}
                                label={t(field.label || capitalizeFirstLetter(field.key))}
                                type={field.type || "text"}
                                variant="filled"
                                margin="normal"
                            />
                        );
                    }
                })}
            </Box>
            <Box
                sx={{
                    display: {xs: "block", sm: "flex"},
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    alignItems: "center"
                }}>
                {!readOnly && (
                    <Button
                        variant="contained"
                        sx={{
                            "backgroundColor": theme.palette.custom.borderColor,
                            "color": "white",
                            "&:hover": {
                                backgroundColor: theme.palette.custom.buttonHoverColor,
                                color: theme.palette.custom.buttonHoverTextColor,
                                boxShadow: "none"
                            }
                        }}
                        disabled={!canBeSubmitted()}
                        onClick={() => onSaveClick(item)}>
                        {t("Save")}
                    </Button>
                )}
            </Box>
            {additionalData && (
                <>
                    <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
                    {additionalData}
                </>
            )}
        </Box>
    );
}

TemplateAddEdit.propTypes = {
    breadcrumbs: PropTypes.array,
    title: PropTypes.string.isRequired,
    gridCol: PropTypes.number,
    dataToShow: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired,
    onSaveClick: PropTypes.func,
    additionalData: PropTypes.element,
    readOnly: PropTypes.bool
};

export default TemplateAddEdit;
