import * as React from "react";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import Divider from "@mui/material/Divider";
import {styled, useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {useState, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import LdapService from "../api/Ldap";
import {setLdapGroup} from "../store/ldapGroupSlice";
import {setUserTypesData} from "../store/userTypesSlice";
import UserTypeToGroupTableRow from "./assignLdapUserTypeTableRow";
import LdapGroupTableRow from "./ldapGroupTableRow";
import {stableSort, getComparator} from "../utils/systemInfo";
import ProfileService from "../api/profile";
import SearchInput from "../common/searchInput.js";
import StyledTypography from "../common/StyledTypography.js";
import Breadcrumbs from "../common/breadcrumbs";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(({theme}) => ({
    "color": theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
        color: theme.palette.custom.text
    }
}));

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{p: 3}}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};
function a11yProps(index) {
    return {
        "id": `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({theme}) => ({
    "textTransform": "none",
    "fontWeight": theme.typography.fontWeightRegular,
    "fontSize": theme.typography.pxToRem(15),
    "backgroundColor": theme.palette.custom.backgroundColor,
    "borderRadius": "3px",
    "padding": "8px 16px",
    "color": theme.palette.custom.text,
    "height": "36px",
    "minHeight": "36px",
    "&.Mui-selected": {
        color: theme.palette.custom.text
    },
    "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
        color: theme.palette.primary
    },
    "&:hover": {
        backgroundColor: theme.palette.custom.buttonColor,
        color: theme.palette.custom.buttonHoverTextColor,
        opacity: "70%"
    }
}));

const groupHead = [
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Group Name",
        sort: true
    },
    {
        id: "ldap_server_id",
        numeric: false,
        disablePadding: true,
        label: "Server ID",
        sort: true
    },
    {
        id: "ldap_group_id",
        numeric: false,
        disablePadding: false,
        label: "Group ID",
        sort: true
    },
    {
        id: "operation_id",
        numeric: false,
        disablePadding: false,
        label: "Organization ID",
        sort: true
    },
    {
        id: "view",
        numeric: false,
        disablePadding: false,
        label: "View",
        sort: false
    }
];

const userTypeHead = [
    {
        id: "userTypeID",
        numeric: false,
        disablePadding: false,
        label: "User Type ID",
        sort: true
    },
    {
        id: "userType",
        numeric: false,
        disablePadding: true,
        label: "User Type",
        sort: true
    },
    {
        id: "desc",
        numeric: false,
        disablePadding: false,
        label: "Description",
        sort: true
    },
    {
        id: "uStatus",
        numeric: false,
        disablePadding: false,
        label: "User Type Status",
        sort: true
    },
    {
        id: "aStatus",
        numeric: false,
        disablePadding: false,
        label: "Assignment Status",
        sort: true
    },
    {
        id: "view",
        numeric: false,
        disablePadding: false,
        label: "View",
        sort: false
    }
];
const breadcrumbs = [
    {id: 0, name: "Home", href: "./"},
    {id: 1, name: "Assign User Types"}
];

export default function AssignUserType() {
    const groups = useSelector(({ldapGroup}) => ldapGroup);
    const dispatch = useDispatch();
    const userTypes = useSelector(({userTypes}) => userTypes);
    const [selectedGroup, setSelectedGroup] = useState(undefined);
    const organziation = useSelector(({organziation}) => organziation);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const ldapServerId = params.get("id");
    const [value, setValue] = useState(0);
    const [userTypesInputData, setUserTypesInputData] = useState(undefined);
    const [orderUserType, setOrderUserTypes] = useState("asc");
    const [orderByUserTypes, setOrderByUserTypes] = useState("firstname");
    const [pageUserTypes, setPageUserTypes] = useState(0);
    const [rowsPerPageUserTypes, setRowsPerPageUserTypes] = useState(Number(localStorage.getItem("rowsPerPage")) ?? 10);
    const [assignUserTypes, setAssignUserTypes] = useState([]);
    const [searchTextUserTypes, setSearchTextUserTypes] = useState("");
    const [loadingValueUserTypes, setLoadingValueUserTypes] = useState(true);
    const [groupsInputData, setGroupsInputData] = useState(undefined);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("DeviceName");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(Number(localStorage.getItem("rowsPerPage")) ?? 10);
    const [searchText, setSearchText] = useState("");
    const [loadingValue, setLoadingValue] = useState(true);
    const [disabledTab, setDisabledTab] = useState(true);
    const visibleRows = useMemo(() => {
        if (groups && groups.length > 0) {
            return stableSort(groups, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        } else {
            return [];
        }
    }, [order, orderBy, page, rowsPerPage, groups]);
    const visibleRowsUser = useMemo(() => {
        if (userTypes && userTypes.length > 0) {
            return stableSort(userTypes, getComparator(orderUserType, orderByUserTypes)).slice(pageUserTypes * rowsPerPageUserTypes, pageUserTypes * rowsPerPageUserTypes + rowsPerPageUserTypes);
        } else {
            return [];
        }
    }, [orderUserType, orderByUserTypes, pageUserTypes, rowsPerPageUserTypes, userTypes]);

    function handleSearch(ev) {
        setPage(0);
        setLoadingValue(true);
        const newSearchText = ev.target.value;
        setSearchText(newSearchText);
        let groupsInputObj = groupsInputData;
        groupsInputObj.newSearchText = newSearchText;
        dispatch(setLdapGroup(groupsInputObj));
        setLoadingValue(false);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem("rowsPerPage", event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function handleSearchUserTypes(ev) {
        setLoadingValueUserTypes(true);
        const newSearchText = ev.target.value;
        setSearchTextUserTypes(newSearchText);
        let userTypesInputObj = userTypesInputData;
        userTypesInputObj.newSearchText = newSearchText;
        dispatch(setUserTypesData(userTypesInputObj));
        setLoadingValueUserTypes(false);
    }
    const handleChangeRowsPerPageUserTypes = (event) => {
        setRowsPerPageUserTypes(parseInt(event.target.value, 10));
        localStorage.setItem("rowsPerPageUserTypes", event.target.value);
        setPageUserTypes(0);
    };
    const handleChangePageUserTypes = (event, newPage) => {
        setPageUserTypes(newPage);
    };
    const handleRequestSortUserTypes = (event, property) => {
        const isAsc = orderByUserTypes === property && orderUserType === "asc";
        setOrderUserTypes(isAsc ? "desc" : "asc");
        setOrderByUserTypes(property);
    };

    useEffect(() => {
        const apiKey = localStorage.getItem("apiKey");
        setLoadingValue(true);
        console.log(ldapServerId);
        LdapService.GetLdapGroups({
            ldap_server_id: ldapServerId,
            OrganizationId: organziation.uid,
            apiKey: apiKey
        })
            .then((json) => {
                if (json) {
                    console.log(json);
                    setGroupsInputData(json.person_ldap_group);
                    dispatch(setLdapGroup(json.person_ldap_group));
                }
                setLoadingValue(false);
            })
            .catch((error) => {
                if (process.env.NODE_ENV === "development") console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if (selectedGroup === undefined) return;
        console.log("Fetching user types for group:", selectedGroup);
        setLoadingValueUserTypes(true);
        const apiKey = localStorage.getItem("apiKey");
        LdapService.GetLdapUserTypesLink({person_ldap_group_id: "Group1"}).then((json) => {
            console.log(json);
            setAssignUserTypes(json.person_ldap_group_user_type_link);
        });
        ProfileService.GetUserType({apiKey})
            .then((json) => {
                if (json && json.app_user_type.length > 0) {
                    setUserTypesInputData({json});
                    dispatch(setUserTypesData({json}));
                }
                setLoadingValueUserTypes(false);
            })
            .catch((error) => {
                if (process.env.NODE_ENV === "development") console.log(error);
            });
    }, [dispatch, selectedGroup]);
    const onSelectGroup = (group) => {
        localStorage.setItem("assignToGroup", JSON.stringify(group));
        setSelectedGroup(group);
        setDisabledTab(false);
        setValue(1);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeData = (row, isAssign) => {
        if (!selectedGroup) return;
        if (isAssign) {
            const assignMeta = JSON.parse(localStorage.getItem("assignToGroup"));
            setAssignUserTypes((prev) => [
                ...prev,
                {
                    person_ldap_group_id: selectedGroup.ldap_group_id,
                    app_user_type_uid: row.user_type_uid,
                    operation_id: assignMeta?.operation_id,
                    ldap_server_id: selectedGroup.ldap_server_id
                }
            ]);
        } else {
            setAssignUserTypes((prev) => prev.filter((link) => link.app_user_type_uid !== row.user_type_uid));
        }
    };

    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`
            }}
            style={{
                height: "100%",
                overflow: "auto"
            }}>
            <Box sx={{display: {xs: "block", sm: "flex"}}}>
                <Box sx={{width: "100%"}}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <Typography
                        sx={{
                            fontSize: "45px",
                            mb: 0,
                            color: theme.palette.custom.text
                        }}
                        variant="h2"
                        gutterBottom>
                        {t("Assign")} {t("User Type")} {t("To")} {t("Group")}
                    </Typography>
                </Box>
            </Box>
            <Divider sx={{my: "10px", backgroundColor: theme.palette.custom.borderColor}} />
            <Box>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    value={value}
                    TabIndicatorProps={{
                        style: {backgroundColor: theme.palette.custom.borderColor}
                    }}
                    onChange={handleChange}
                    aria-label="basic tabs example">
                    <StyledTab label={t("Groups")} {...a11yProps(0)} />
                    {value === 1 && <StyledTab label={t("User Types")} {...a11yProps(1)} disabled={disabledTab} />}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <SearchInput searchText={searchText} handleSearch={handleSearch} />
                <Typography sx={{flexGrow: "1", textAlign: "center"}}>{t("First, select a group you want to assign a user type to")}</Typography>
                {loadingValue ? (
                    <Box sx={{height: "30vh", pt: "10%"}}>
                        <Loading />
                    </Box>
                ) : (
                    <TableContainer>
                        <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                            <EnhancedTableHead headCells={groupHead} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} hideCheck />

                            <TableBody>
                                {groups && groups.length > 0 && visibleRows.length ? (
                                    visibleRows.map((row, index) => {
                                        return <LdapGroupTableRow key={index} row={row} onSelectGroup={() => onSelectGroup(row)} />;
                                    })
                                ) : (
                                    <StyledTypography>{t("No entries found")}</StyledTypography>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <StyledTablePagination
                    SelectProps={{
                        inputProps: {
                            MenuProps: {
                                PaperProps: {
                                    sx: {
                                        "backgroundColor": theme.palette.secondary.dark,
                                        "color": theme.palette.custom.text,
                                        "& .MuiMenuItem-root.Mui-selected": {
                                            backgroundColor: theme.palette.custom.borderColor
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "blue"
                                        },
                                        "& .MuiMenuItem-root.Mui-selected:hover": {
                                            backgroundColor: "blue"
                                        }
                                    }
                                }
                            }
                        }
                    }}
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                    component="div"
                    count={groups ? groups.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {xs: "column-reverse", md: "row"},
                        gap: "1rem",
                        alignItems: {md: "center"}
                    }}>
                    <SearchInput searchText={searchTextUserTypes} handleSearch={handleSearchUserTypes} />
                </Box>
                {loadingValueUserTypes ? (
                    <Box sx={{height: "30vh", pt: "10%"}}>
                        <Loading />
                    </Box>
                ) : (
                    <TableContainer>
                        <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                            <EnhancedTableHead headCells={userTypeHead} order={orderUserType} orderBy={orderByUserTypes} onRequestSort={handleRequestSortUserTypes} hideCheck />

                            <TableBody>
                                {userTypes && userTypes.length > 0 && visibleRowsUser.length ? (
                                    visibleRowsUser.map((row, index) => {
                                        return <UserTypeToGroupTableRow key={index} iKey={index} row={row} labelId={`enhanced-table-checkbox-${index}`} selectedGroup={selectedGroup} assignedLinks={assignUserTypes} handleChangeData={handleChangeData}></UserTypeToGroupTableRow>;
                                    })
                                ) : (
                                    <StyledTypography>{t("No entries found")}</StyledTypography>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <StyledTablePagination
                    SelectProps={{
                        inputProps: {
                            MenuProps: {
                                PaperProps: {
                                    sx: {
                                        "backgroundColor": theme.palette.secondary.dark,
                                        "color": theme.palette.custom.text,
                                        "& .MuiMenuItem-root.Mui-selected": {
                                            backgroundColor: theme.palette.custom.borderColor
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "blue"
                                        },
                                        "& .MuiMenuItem-root.Mui-selected:hover": {
                                            backgroundColor: "blue"
                                        }
                                    }
                                }
                            }
                        }
                    }}
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                    component="div"
                    count={userTypes ? userTypes.length : 0}
                    rowsPerPage={rowsPerPageUserTypes}
                    page={pageUserTypes}
                    onPageChange={handleChangePageUserTypes}
                    onRowsPerPageChange={handleChangeRowsPerPageUserTypes}
                />
            </CustomTabPanel>
        </Box>
    );
}
