import React from "react";
import PersonAdd from "@mui/icons-material/PersonAdd";
import PersonRemove from "@mui/icons-material/PersonRemove";
import {Box, Typography, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

function AssignButton({type, title, text, onClick}) {
    const theme = useTheme();
    const {t} = useTranslation();

    const Icon = type === "add" ? PersonAdd : PersonRemove;

    return (
        <Tooltip title={t(title || (type === "add" ? "Assign" : "Unassign"))}>
            <Box
                onClick={onClick}
                sx={{
                    "backgroundColor": theme.palette.custom.buttonColor,
                    "borderRadius": "4px",
                    "border": `1px solid ${theme.palette.custom.borderColor}`,
                    "width": "fit-content",
                    "height": "36px",
                    "padding": "5px 10px",
                    "display": "flex",
                    "alignItems": "center",
                    "cursor": "pointer",
                    "&:hover": {
                        backgroundColor: theme.palette.custom.buttonHoverColor,
                        color: theme.palette.custom.buttonHoverTextColor,
                        boxShadow: "none"
                    }
                }}>
                <Icon
                    sx={{
                        "color": theme.palette.custom.buttonTextColor,
                        "fontSize": 24,
                        "&:hover": {
                            color: theme.palette.custom.buttonHoverTextColor,
                            boxShadow: "none"
                        }
                    }}
                />
                {text && (
                    <Typography
                        sx={{
                            whiteSpace: "nowrap",
                            ml: 1,
                            color: theme.palette.custom.buttonTextColor
                        }}>
                        {t(text)}
                    </Typography>
                )}
            </Box>
        </Tooltip>
    );
}

AssignButton.propTypes = {
    type: PropTypes.oneOf(["add", "remove"]),
    title: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func
};

export default AssignButton;
