/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setStateHistory = createAsyncThunk(
  "stateHistory/setStateHistory",
  async (stateHistory) => {
    return stateHistory;
  }
);

const initialState = {
  device_state_history_id: "",
  serial: "",
  state_type: "",
  state_description: "",
  state_start: "",
  state_end: "",
  state_location_id: "",
  assigned_to_person_id: "",
  status: "active",
};

const stateHistorySlice = createSlice({
  name: "stateHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setStateHistory.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectStateHistory = ({ stateHistory }) => stateHistory;

export default stateHistorySlice.reducer;
