import React, { useState, useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import OrganizationService from "../api/organization.js";
import ProfileService from "../api/profile";
import { setAssignUsersData } from "../store/assignUserSlice.js";
import UserAssignTableRow from "./userAssignTableRow.js";
import { stableSort, getComparator } from "../utils/systemInfo";
import SearchInput from "../common/searchInput.js";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV.js";
import StyledTypography from "../common/StyledTypography.js";
import Breadcrumbs from "../common/breadcrumbs";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Developer Users" },
  { id: 2, name: "Assign Users", href: "assignUsers" },
];

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);

const userHead = [
  {
    id: "firstname",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    sort: true,
  },
  {
    id: "lastname",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    sort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email Address",
    sort: true,
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Mobile Number",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "userType",
    numeric: false,
    disablePadding: false,
    label: "User Type",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];

export default function AssignUsers() {
  const dispatch = useDispatch();
  // const organziation = useSelector(({ organziation }) => organziation);
  const users = useSelector(({ assignUsers }) => assignUsers);
  const [userInputData, setUserInputData] = useState(undefined);
  const [orderUser, setOrderUser] = useState("asc");
  const [orderByUser, setOrderByUser] = useState("firstname");
  const [pageUser, setPageUser] = useState(0);
  const [rowsPerPageUser, setRowsPerPageUser] = useState(
    Number(localStorage.getItem("rowsPerPage")) || 5
  );
  const [searchTextUser, setSearchTextUser] = useState("");
  const [loadingValueUser, setLoadingValueUser] = useState(true);
  const [orgUid, setOrgUid] = useState(undefined);
  const visibleRowsUser = useMemo(
    () => {
      if (users && users.length > 0) {
        return stableSort(users, getComparator(orderUser, orderByUser)).slice(
          pageUser * rowsPerPageUser,
          pageUser * rowsPerPageUser + rowsPerPageUser
        );
      } else {
        return [];
      }
    },
    [orderUser, orderByUser, pageUser, rowsPerPageUser, users]
    //[page, rowsPerPage, safety],
  );
  function getCSVUser(u) {
    let email = "";
    let mobile = "";
    if (u?.contact?.length) {
      for (let i in u.contact) {
        if (
          u.contact[i].contact_type === "email_address" &&
          u.contact[i].primary_contact === true
        ) {
          email = u.contact[i].value;
        } else if (
          u.contact[i].contact_type === "tel_mobile" &&
          u.contact[i].primary_contact === true
        ) {
          mobile = u.contact[i].value;
        }
      }
    }
    const userType = JSON.parse(
      localStorage.getItem("userTypes")
    )?.app_user_type?.find(
      (a) => a.user_type_uid?.toString() === u.user_type_uid?.toString()
    )?.name;
    return { ...u, email, mobile, userType };
  }

  function handleSearchUser(ev) {
    setLoadingValueUser(true);
    const newSearchText = ev.target.value;
    setSearchTextUser(newSearchText);
    let userInputObj = userInputData;
    userInputObj.newSearchText = newSearchText;
    dispatch(setAssignUsersData(userInputObj));
    setLoadingValueUser(false);
  }

  const handleChangeRowsPerPageUser = (event) => {
    setRowsPerPageUser(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPageUser", event.target.value);
    setPageUser(0);
  };
  const handleChangePageUser = (event, newPage) => {
    setPageUser(newPage);
  };
  const handleRequestSortUser = (event, property) => {
    const isAsc = orderByUser === property && orderUser === "asc";
    setOrderUser(isAsc ? "desc" : "asc");
    setOrderByUser(property);
  };

  const handleChangeUserData = (row, isLink, userType) => {
    let newUsersList = [];
    userInputData.json.forEach((e) => {
      if (e.profile_id === row.profile_id) {
        let userNewObj = {
          firstname: row.firstname,
          lastname: row.lastname,
          fullname: row.firstname + " " + row.lastname,
          uid: row.uid,
          profile_id: row.profile_id,
          user_type_uid: userType,
          contact: row.contact,
          status: isLink ? "link" : "unlink",
        };
        newUsersList.push(userNewObj);
      } else {
        newUsersList.push(e);
      }
    });
    dispatch(
      setAssignUsersData({ json: newUsersList, newSearchText: searchTextUser })
    );
    setUserInputData({ json: newUsersList });
  };
  useEffect(() => {
    const defaultOrganization = JSON.parse(
      localStorage.getItem("defaultOrganization")
    );
    setOrgUid(defaultOrganization?.uid);
    setUserInputData(null);
    const apiKey = defaultOrganization?.api_key_list?.[0]?.key;
    const orgID = defaultOrganization.uid;
    OrganizationService.GetOperationPersonLink({
      orgID: orgID,
      apiKey: apiKey,
    })
      .then((json) => {
        if (
          json &&
          json.operation_person_profile_link &&
          json.operation_person_profile_link.length > 0
        ) {
          const usersList = PopulateUsers(json);
          setUserInputData({ json: usersList });
          dispatch(setAssignUsersData({ json: usersList }));
        }
        setLoadingValueUser(false);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") console.log(error);
      });
    OrganizationService.GetApiKeys(
      "",
      orgUid,
      "active",
      "MTIxMnwxfDN8MjE5OS0wMS0wMSAwMDowMDowMA"
    ).then((json) => {
      localStorage.setItem(
        "assignToOrg",
        JSON.stringify({
          fullName: json.app[0].operation_full_name,
          uid: json.app[0].operation_uid,
          apiKey: json.app[0].key,
        })
      );
    });
    ProfileService.GetUserType({
      user_type_uid: "",
      apiKey,
    }).then((jsonTypes) => {
      localStorage.setItem("userTypes", JSON.stringify(jsonTypes));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function PopulateUsers(json) {
    let user = [];

    const UsersArray = json.operation_person_profile_link;
    for (let index in UsersArray) {
      let userDataObj = {
        firstname: "",
        lastname: "",
        fullname: "",
        uid: "",
        profile_id: "",
        user_type_uid: "",
        contact: "",
        status: "",
      };
      if (
        UsersArray[index].person_profile !== null &&
        UsersArray[index].person_profile.status === "active"
      ) {
        userDataObj.fullname =
          UsersArray[index].person_profile.firstname +
          " " +
          UsersArray[index].person_profile.lastname;
        userDataObj.firstname = UsersArray[index].person_profile.firstname;
        userDataObj.lastname = UsersArray[index].person_profile.lastname;
        userDataObj.uid = UsersArray[index].person_profile_uid;
        userDataObj.profile_id = UsersArray[index].person_profile.profile_id;
        userDataObj.user_type_uid = UsersArray[index].user_type_uid;
        if (
          UsersArray[index].person_profile.contact !== undefined &&
          UsersArray[index].person_profile.contact.length !== 0
        ) {
          userDataObj.contact = UsersArray[index].person_profile.contact;
        } else {
          userDataObj.contact = [];
        }
        userDataObj.status = UsersArray[index].status;
        user.push(userDataObj);
      }
    }
    return user;
  }
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Typography
              sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.text }}
              variant="h2"
              gutterBottom
            >
              {t("Assign")}
              {t("Users")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <SearchInput
        searchText={searchTextUser}
        handleSearch={handleSearchUser}
      />
      {loadingValueUser ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={userHead}
              order={orderUser}
              orderBy={orderByUser}
              onRequestSort={handleRequestSortUser}
              hideCheck
            />

            <TableBody>
              {users && users.length > 0 && visibleRowsUser.length ? (
                visibleRowsUser.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <UserAssignTableRow
                      handleChangeUserData={handleChangeUserData}
                      labelId={labelId}
                      ikey={index}
                      key={index}
                      row={row}
                      orgUid={orgUid}
                    ></UserAssignTableRow>
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.custom.backgroundColor,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={users ? users.length : 0}
        rowsPerPage={rowsPerPageUser}
        page={pageUser}
        onPageChange={handleChangePageUser}
        onRowsPerPageChange={handleChangeRowsPerPageUser}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.buttonColor,
          color: theme.palette.custom.buttonTextColor,
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          exportTableToCSV(
            userHead,
            visibleRowsUser.map(getCSVUser),
            "Users List"
          )
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}
