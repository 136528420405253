import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import ScheduledTimeService from "../api/scheduledTime";
import { getUrlVars } from "../utils/systemInfo";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CustomTextField from "../common/CustomTextField";
import Breadcrumbs from "../common/breadcrumbs";
import AlertDialog from "../common/AlertDialog";
import { setShiftsTabOpened } from "../store/shiftsSlice";
import handleNavigate from "../common/customFunctions/handleNavigate";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Organization", href: "organization" },
];

const CustomTimePicker = styled(DesktopTimePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    border: 1,
    borderColor: `${theme.palette.custom.borderColor} !important`,
    borderRadius: "11px",
    "&:hover": {
      border: 1,
      borderColor: `${theme.palette.custom.borderColor} !important`,
      borderRadius: "11px",
    },
  },

  marginBottom: "16px",
  marginTop: "16px",
  paddingTop: "10px",
  paddingBottom: "0px",
  backgroundColor: theme.palette.custom.backgroundColor,
  color: theme.palette.custom.textDarkColor,
  border: 1,
  borderColor: theme.palette.custom.borderColor,
  borderRadius: "11px",
  "&:hover": {
    border: 1,
    borderColor: theme.palette.custom.borderColor,
    borderRadius: "11px",
  },
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    paddingBottom: "6.5px",
  },
  "& .MuiFormLabel-root.MuiInputLabel-root": {
    transform: "translate(14px, 7px) scale(0.75) !important",
  },
}));

export default function AddShift() {
  const organziation = useSelector(({ organziation }) => organziation);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [shiftName, setShiftName] = useState("");
  const [desc, setDesc] = useState("");
  const [startTime, setStartTime] = useState(dayjs("00-00", ["HH", "mm"]));
  const [endTime, setEndTime] = useState(dayjs("00-00", ["HH", "mm"]));
  const orgUid = getUrlVars()["orgUid"];
  const configUid = getUrlVars()["configUid"];
  const action = getUrlVars()["action"];
  const [apiKey, setApiKey] = useState(undefined);
  const displayDelete = action === "edit" ? "block" : "none";
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [saveDeleteLoading, setSaveDeleteLoading] = useState(false);

  useEffect(() => {
    setApiKey(organziation?.api_key_list?.[0]?.key);
    if (action === "edit") {
      let scheduledTimeInfo = {
        shift_time_config_uid: configUid,
        operation_profile_uid: orgUid,
        name: "",
        status: "active",
        apiKey: apiKey,
      };
      ScheduledTimeService.GetOrganizationShiftTimes(scheduledTimeInfo).then(
        (json) => {
          if (json.success) {
            const shiftJson = json.shift_time_config[0];
            setShiftName(shiftJson.name);
            setDesc(shiftJson.description);
            setStartTime(dayjs(shiftJson.start_time, "h:mm A"));
            setEndTime(dayjs(shiftJson.end_time, "h:mm A"));
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSave(shiftName, startTime, endTime, desc, orgUid, apiKey) {
    if (shiftName !== "" && shiftName !== undefined) {
      setSaveLoading(true);
      let scheduledTimeInfo = {
        shift_time_config_uid: action === "edit" ? configUid : "",
        operation_uid: orgUid,
        name: shiftName,
        status: "active",
        start_time: dayjs(startTime).format("HH:mm"),
        end_time: dayjs(endTime).format("HH:mm"),
        description: desc,
        apiKey: apiKey,
      };
      ScheduledTimeService.SetOrganizationShiftTimes(scheduledTimeInfo)
        .then((json) => {
          if (json.success) {
            navigate("/organization");
            dispatch(setShiftsTabOpened(true));
            setSaveLoading(false);
            setOpenDialog(false);
          }
        })
        .catch((errorr) => {
          setSaveLoading(false);
          setOpenDialog(false);
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
  }

  function handleDelete(shiftName, startTime, endTime, desc, orgUid, apiKey) {
    setSaveDeleteLoading(true);
    let scheduledTimeInfo = {
      shift_time_config_uid: configUid,
      operation_uid: orgUid,
      name: "",
      status: "removed",
      start_time: "",
      end_time: "",
      description: "",
      apiKey: apiKey,
    };
    ScheduledTimeService.SetOrganizationShiftTimes(scheduledTimeInfo)
      .then((json) => {
        if (json.success) {
          setSaveDeleteLoading(false);
          setOpenDeleteDialog(false);
          navigate("/organization");
          dispatch(setShiftsTabOpened(true));
        }
      })
      .catch(() => {
        setSaveDeleteLoading(false);
        setOpenDeleteDialog(false);
      });
  }

  const theme = useTheme();
  const { t } = useTranslation();
  const handleReturn = (event) => {
    handleNavigate(event, navigate, `/organization`);
    // navigate();
    dispatch(setShiftsTabOpened(true));
  };
  function handleClose() {
    setOpenDialog(false);
  }
  function handleDeleteClose() {
    setOpenDeleteDialog(false);
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {
              //<HomePagesMenu pageName={'Maintenance'} />
            }
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "block", sm: "center" },
                height: "64px",
              }}
            >
              <IconButton
                size="small"
                sx={{
                  backgroundColor: theme.palette.custom.buttonColor,
                  color: theme.palette.custom.buttonTextColor,
                  mr: 4,
                  width: "40px",
                  height: "40px",
                }}
                onClick={handleReturn}
              >
                <ArrowBackOutlinedIcon fontSize="inherit" />
              </IconButton>
              <Typography
                sx={{
                  fontSize: "45px",
                  mb: 0,
                  color: theme.palette.custom.borderColor,
                }}
                variant="h2"
                gutterBottom
              >
                {t("Create Shift")}:
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            width: "49%",
          }}
        >
          <CustomTextField
            autoComplete="off"
            id="txtName"
            label={t("Shift Name")}
            variant="filled"
            value={shiftName}
            onChange={(e) => setShiftName(e.target.value)}
            sx={{
              width: { xs: "100%", sm: "100%%" },
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
          <CustomTimePicker
            label={t("Time Start")}
            views={["hours", "minutes"]}
            // format="hh:mm"
            ampm={false}
            value={startTime}
            onChange={(newValue) => setStartTime(newValue)}
            sx={{
              borderRadius: "11px",
              border: `1px solid ${theme.palette.custom.borderColor}`,
              ":hover": {
                border: `1px solid ${theme.palette.custom.borderColor}`,
              },
            }}
          />
          <CustomTextField
            id="txtStatus"
            label={t("Status")}
            variant="filled"
            disabled
            defaultValue={"active"}
            sx={{
              width: { xs: "100%", sm: "100%%" },
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            width: "49%",
          }}
        >
          <CustomTextField
            id="txtDesc"
            label={t("Shift Description")}
            variant="filled"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            sx={{
              width: { xs: "100%", sm: "100%%" },
              borderColor: theme.palette.custom.borderColor,
              borderRadius: "11px",
              backgroundColor: theme.palette.custom.light,
            }}
          />
          <CustomTimePicker
            label={t("Time End")}
            views={["hours", "minutes"]}
            // format="hh:mm"
            ampm={false}
            value={endTime}
            onChange={(newValue) => {
              setEndTime(newValue);
            }}
            sx={{
              borderRadius: "11px",
              border: `1px solid ${theme.palette.custom.borderColor}`,
              ":hover": {
                border: `1px solid ${theme.palette.custom.borderColor}`,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: displayDelete === "block" ? "space-between" : "end",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.buttonColor,
            color: theme.palette.custom.buttonTextColor,
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
            display: displayDelete,
          }}
          onClick={() => {
            setOpenDeleteDialog(true);
          }}
        >
          {t("Delete")}
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.buttonColor,
            color: theme.palette.custom.buttonTextColor,
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
          }}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          {t("Save")}
        </Button>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={() =>
          handleSave(shiftName, startTime, endTime, desc, orgUid, apiKey)
        }
        saveLoading={saveLoading}
        dialogTitle={t("Are you sure you want to add shift?")}
        cancelLabel={t("Cancel")}
        buttonLabel={t("Save")}
      />
      <AlertDialog
        open={openDeleteDialog}
        onClose={handleDeleteClose}
        onDoFunction={() =>
          handleDelete(shiftName, startTime, endTime, desc, orgUid, apiKey)
        }
        saveLoading={saveDeleteLoading}
        dialogTitle={t("Are you sure you want to delete shift?")}
        cancelLabel={t("Cancel")}
        buttonLabel={t("Delete")}
      />
    </Box>
  );
}
