/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setNamedLocation = createAsyncThunk(
  "namedLocation/setNamedLocation",
  async (namedLocation) => {
    return namedLocation;
  }
);

const initialState = {
  operation_location_named_lookup_id: "",
  operation_profile_uid: 0,
  lookup: "",
  name: "",
  x: 0,
  y: 0,
  z: 0,
  description: "",
  status: "",
  created_by_person_id: "",
  updated_by_person_id: "",
  created_timestamp: "",
  updated_timestamp: ""
};

const namedLocationSlice = createSlice({
  name: "namedLocation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setNamedLocation.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectNamedLocation = ({ namedLocation }) => namedLocation;

export default namedLocationSlice.reducer;
