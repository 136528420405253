import Utils from "../utils/utils";

class ContactService extends Utils.EventEmitter {
  SendContactRequest(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            contact_request_id: inputData.request_id,
            session_id: "",
            app_id: "",
            operation_uid: "",
            source: inputData.src,
            request_type: inputData.type,
            subject: inputData.subject,
            description: inputData.description,
            firstname: inputData.firstname,
            lastname: inputData.lastname,
            tel_mobile: inputData.tel,
            email_address: inputData.email,
            person_profile_id: "",
            status: inputData.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/comms/contact/request/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new ContactService();

export default instance;
