/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setTag = createAsyncThunk("tag/setTag", async (tag) => {
  return tag;
});

const initialState = {
  device_state_tag_id: "",
  name: "",
  description: "",
  color: "",
  status: "active",
};

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTag.fulfilled, (state, action) => action.payload);
  },
});

export const selectTag = ({ tag }) => tag;

export default tagSlice.reducer;
