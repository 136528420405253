/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetGrafanaDashboardUID,
  GetMachineTypeIcon,
  GetLatestDate,
} from "../utils/systemInfo";

export const setMachinesData = createAsyncThunk(
  "machines/setMachinesData",
  async (inputData) => {
    let RecordOPSUM = [];
    if (inputData === null) {
      return null;
    }
    RecordOPSUM = PopulateDataHomeOPSUM(
      inputData.json,
      inputData.machineTypeFilter,
      inputData.machineType
    );
    if (
      inputData.newSearchText &&
      inputData.newSearchText.length > 0 &&
      RecordOPSUM &&
      RecordOPSUM.length > 0
    ) {
      const searchText = inputData.newSearchText.toLowerCase();
      return RecordOPSUM.filter(
        (item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          ) || item.DeviceSerialHex.toLowerCase().includes(searchText)
      );
    }
    return RecordOPSUM;
  }
);
function PopulateDataHomeOPSUM(
  jsonThisWeek,
  machineTypeFilter = "",
  machineTypeValue
) {
  let OpsumArray = [];
  const DevicesArray = jsonThisWeek;

  for (let index in DevicesArray) {
    let opsumDataObj = {
      MachineType: "",
      DeviceName: "",
      DeviceID: "",
      DeviceStatus: "",
      DeviceLatestOPSUM: "",
      DeviceLatestData: "",
      DeviceLatestRaw: "",
      DeviceLastSeen: "",
      NamedLocationOriginal: "",
      GrafanaDashboardUID: "",
      DeviceType: "",
      DeviceTypeIcon: "",
      DeviceSerial: "",
      DeviceSerialHex: "",
      DeviceOEMIcon: "",
      DeviceOEM: "",
      DevicePID: "",
      DeviceTitleDataName: "",
    };
    opsumDataObj.DeviceName = DevicesArray[index].name;
    opsumDataObj.DeviceID = DevicesArray[index].device_uid;
    opsumDataObj.DeviceSerial = DevicesArray[index].serial;
    opsumDataObj.DeviceSerialHex = Number(DevicesArray[index].serial)
      .toString(16)
      .toLocaleUpperCase();
    opsumDataObj.DeviceOEM = String(DevicesArray[index].oem);
    opsumDataObj.DevicePID = DevicesArray[index].device_profile_id;
    if (opsumDataObj.DeviceOEM.length === 0)
      opsumDataObj.DeviceOEM = "nerospec";
    opsumDataObj.DeviceOEMIcon = `${
      window.env.REACT_APP_PUBLIC_URL
    }/images/oem/${opsumDataObj.DeviceOEM.replaceAll(" ", "")}_${
      localStorage.getItem("color_theme") === "dark" ? "Dark" : "Light"
    }.png`;

    machineTypeValue = String(
      DevicesArray[index].vehicle_type !== "" &&
        DevicesArray[index].vehicle_type !== undefined
        ? "Machine"
        : "Device"
    );
    // DeviceType = String(DevicesArray[index].profile.vehicle_type);
    // if (DeviceType == "") DeviceType = "Unknown";

    switch (machineTypeValue.toLowerCase()) {
      case "machine":
      default: {
        opsumDataObj.DeviceTitleDataName = "Machine";
        let DeviceType = String(DevicesArray[index].vehicle_type);
        opsumDataObj.DeviceType = String(DevicesArray[index].vehicle_type);
        if (DeviceType === "") opsumDataObj.DeviceType = "Unknown";
        const DeviceTypeIcon = GetMachineTypeIcon(DeviceType);
        opsumDataObj.DeviceTypeIcon = DeviceTypeIcon;
        let MachineType = String(DevicesArray[index].machine_type);
        opsumDataObj.MachineType = MachineType;
        opsumDataObj.GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);

        if (DeviceType !== machineTypeFilter && machineTypeFilter !== "")
          continue;

        let LastLocation = DevicesArray[index]?.location?.named?.lookup?.name;
        if (LastLocation === undefined || LastLocation === "") {
          LastLocation = `Unknown`;
        }
        opsumDataObj.DeviceLastSeen = GetLatestDate([
          DevicesArray[index]?.dst_last_opsum,
          DevicesArray[index]?.latest_data_dst,
          DevicesArray[index]?.dst_last_seen,
        ]);

        opsumDataObj.NamedLocationOriginal = LastLocation;
        break;
      }
      case "device": {
        opsumDataObj.DeviceLastSeen = GetLatestDate([
          DevicesArray[index]?.dst,
          DevicesArray[index]?.latest_data_dst,
          DevicesArray[index]?.dst_last_seen,
        ]);

        opsumDataObj.DeviceTitleDataName = "Device";
        let machineType = String(DevicesArray[index].device_type);
        opsumDataObj.DeviceType = String(DevicesArray[index].device_type);
        if (machineType === "") opsumDataObj.DeviceType = "Unknown";

        opsumDataObj.GrafanaDashboardUID = GetGrafanaDashboardUID(machineType);
        const machineTypeIcon = GetMachineTypeIcon(machineType);
        opsumDataObj.DeviceTypeIcon = machineTypeIcon;
        break;
      }
    }
    OpsumArray.push(opsumDataObj);
  }
  return OpsumArray;
}

const initialState = null;

const machinesSlice = createSlice({
  name: "machines",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setMachinesData.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectMachines = ({ machines }) => machines;

export default machinesSlice.reducer;
