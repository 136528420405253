/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const setAssignUserTypesData = createAsyncThunk("assignUserTypes/setAssignUserTypes", async (inputData) => {
    let assignUserTypes = [];
    if (inputData === null) {
        return null;
    }
    // if (inputData.json&& inputData.json.operation_person_profile_link && inputData.json.operation_person_profile_link.length > 0) {
    // assignUsers = PopulateUsers(inputData.json);
    // }else{
    //     assignUsers = inputData.json
    // }
    assignUserTypes = inputData.json.person_ldap_group_user_type_link
    if (inputData.newSearchText && inputData.newSearchText.length > 0 && assignUserTypes && assignUserTypes.length > 0) {
        const searchText = inputData.newSearchText.toLowerCase();
        return assignUserTypes.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return assignUserTypes;
});

const initialState = null;

const assignUserTypesSlice = createSlice({
    name: "assignUserTypes",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setAssignUserTypesData.fulfilled, (state, action) => action.payload);
    }
});

export const selectUserTypes= ({userTypes}) => userTypes;

export default assignUserTypesSlice.reducer;
