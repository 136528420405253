import React, {useState} from "react";
import {Box, Button} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import StyledTypography from "../common/StyledTypography";
import AssignButton from "../common/assignButton";
import AlertDialog from "../common/AlertDialog";
import {showMessage} from "../store/messageSlice";
import {useDispatch} from "react-redux";
import LdapService from "../api/Ldap";

export default function AssignUserTypeTableRow({
    row,
    iKey,
    labelId,
    //   selectedGroup,
    assignedLinks,
    handleChangeData
}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const isAssigned = assignedLinks?.some((link) => link.app_user_type_uid === row.user_type_uid);

    const matchingLink = assignedLinks?.find((link) => link.app_user_type_uid === row.user_type_uid);

    function handleAssign() {
        setSaveLoading(true);
        const assignMeta = JSON.parse(localStorage.getItem("assignToGroup"));
        const apiKey = localStorage.getItem("apiKey");

        if (!isAssigned) {
            const payload = {
                person_ldap_group_id: "Group1",
                app_user_type_uid: row.user_type_uid,
                operation_id: assignMeta?.operation_id,
                ldap_server_id: "20250320120511365_KASDcbAAxpoBOiUCB"
            };

            LdapService.PostLdapUserTypesLink(payload)
                .then((json) => {
                    if (json?.success) {
                        console.log(json);
                        handleChangeData?.(row, true);
                        dispatch(
                            showMessage({
                                message: t("User type assigned successfully"),
                                autoHideDuration: 8000,
                                anchorOrigin: {vertical: "top", horizontal: "center"},
                                variant: "success"
                            })
                        );
                    }
                    setSaveLoading(false);
                    setOpenDialog(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                    setOpenDialog(false);
                    dispatch(
                        showMessage({
                            message: t("Error occurred while assigning user type"),
                            autoHideDuration: 8000,
                            anchorOrigin: {vertical: "top", horizontal: "center"},
                            variant: "error"
                        })
                    );
                });
        } else if (matchingLink) {
            const payload = {
                ldap_group_user_type_link_id: matchingLink.ldap_group_user_type_link_id
            };

            LdapService.RemoveLdapUserTypesLink(payload, apiKey)
                .then((json) => {
                    if (json?.success) {
                        console.log(json);
                        handleChangeData?.(row, false);
                        dispatch(
                            showMessage({
                                message: t("User type unassigned successfully"),
                                autoHideDuration: 8000,
                                anchorOrigin: {vertical: "top", horizontal: "center"},
                                variant: "success"
                            })
                        );
                    }
                    setSaveLoading(false);
                    setOpenDialog(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                    setOpenDialog(false);
                    dispatch(
                        showMessage({
                            message: t("Error occurred while unassigning user type"),
                            autoHideDuration: 8000,
                            anchorOrigin: {vertical: "top", horizontal: "center"},
                            variant: "error"
                        })
                    );
                });
        }
    }

    function handleClose() {
        setOpenDialog(false);
    }

    return (
        <>
            <TableRow hover tabIndex={-1} key={iKey} sx={{cursor: "pointer"}}>
                <TableCell component="th" id={labelId} scope="row" sx={{padding: "16px"}}>
                    <Box sx={{textAlign: "left", display: "flex", alignItems: "center"}}>
                        <StyledTypography variant="subtitle2">{row.user_type_uid}</StyledTypography>
                    </Box>
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" sx={{padding: "16px"}}>
                    <Box sx={{textAlign: "left", display: "flex", alignItems: "center"}}>
                        <StyledTypography variant="subtitle2">{row.name}</StyledTypography>
                    </Box>
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" sx={{padding: "16px"}}>
                    <Box sx={{textAlign: "left", display: "flex", alignItems: "center"}}>
                        <StyledTypography variant="subtitle2">{row.description}</StyledTypography>
                    </Box>
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" sx={{padding: "16px"}}>
                    <Box sx={{textAlign: "left", display: "flex", alignItems: "center"}}>
                        <StyledTypography variant="subtitle2">{row.status}</StyledTypography>
                    </Box>
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" sx={{padding: "16px"}}>
                    <Box sx={{textAlign: "left", display: "flex", alignItems: "center"}}>
                        <StyledTypography variant="subtitle2">{row.isAssigned ? "Assigned" : "Unassigned"}</StyledTypography>
                    </Box>
                </TableCell>
                <TableCell align="left">
                    <Button onClick={() => setOpenDialog(true)}>
                        <AssignButton type={isAssigned ? "remove" : "add"} title={isAssigned ? t("Unassign") : t("Assign")} />
                    </Button>
                </TableCell>
            </TableRow>
            <AlertDialog open={openDialog} onClose={handleClose} onDoFunction={handleAssign} saveLoading={saveLoading} dialogTitle={isAssigned ? t("Are you sure you want to unassign this user type?") : t("Are you sure you want to assign this user type?")} cancelLabel={t("Cancel")} buttonLabel={isAssigned ? t("Unassign") : t("Assign")} />
        </>
    );
}

AssignUserTypeTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    iKey: PropTypes.number,
    labelId: PropTypes.string,
    selectedGroup: PropTypes.object.isRequired,
    assignedLinks: PropTypes.array.isRequired,
    handleChangeData: PropTypes.func
};
