import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import InputBase from "@mui/material/InputBase";

import ContactService from "../api/contact";
import CustomTextField from "../common/CustomTextField";

const typesList = [
  { id: 0, value: "General Enquiry" },
  { id: 1, value: "Bug Report" },
  { id: 2, value: "Feature Request" },
  { id: 3, value: "Feedback" },
  { id: 3, value: "Other" },
];

const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.borderColor,
    width: "10%",
    "&.MuiLoadingButton-loading": {
      backgroundColor: theme.palette.custom.light,
    },
  })
);
const StylePageSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
  fontSize: "40px",
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: theme.palette.custom.text,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.custom.backgroundColor,
    border: `1px solid ${theme.palette.custom.borderColor}`,
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
function ContactUs() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [emailAddress, setEmailAddress] = useState(undefined);
  const [contactNumber, setContactNumber] = useState(undefined);
  const [title, setTitle] = useState(undefined);
  const [issueType, setIssueType] = useState("General Enquiry");
  const [supportIssueDescription, setSupportIssueDescription] =
    useState(undefined);
  const [savingLoading, setSavingLoading] = useState(false);

  const handleSubmit = (event) => {
    setSavingLoading(true);
    event.preventDefault();
    const apiKey = organziation?.api_key_list?.[0]?.key;
    if (organziation) {
      ContactService.SendContactRequest({
        firstname: firstName,
        lastname: lastName,
        email: emailAddress,
        description: supportIssueDescription,
        type: issueType,
        tel: contactNumber,
        subject: title,
        request_id: "",
        status: "active",
        src: window.location.href,
        apiKey,
      })
        // .then((json) => {
        // })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("SendContactRequestError", errorr);
        });
    }
    setSavingLoading(false);
    navigate(`/`);
  };
  const handleChooseIssueType = (event) => {
    setIssueType(event.target.value);
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <StylePageSpan>{t("ContactUs")}</StylePageSpan>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          m: 2,
          pb: 1,
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <CustomTextField
          value={firstName}
          margin="normal"
          fullWidth
          id="firstName"
          label={t("Firstname")}
          name="firstName"
          autoFocus
          variant="filled"
          sx={{
            width: "100%",
          }}
          InputLabelProps={{
            style: { color: theme.palette.custom.text },
          }}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />
        <CustomTextField
          value={lastName}
          margin="normal"
          fullWidth
          id="lastName"
          label={t("Lastname")}
          name="lastName"
          autoFocus
          variant="filled"
          sx={{
            width: "100%",
          }}
          InputLabelProps={{
            style: { color: theme.palette.custom.text },
          }}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
        />
        <CustomTextField
          value={emailAddress}
          margin="normal"
          fullWidth
          id="emailAddress"
          label={t("Email Address")}
          name="emailAddress"
          autoFocus
          variant="filled"
          sx={{
            width: "100%",
          }}
          InputLabelProps={{
            style: { color: theme.palette.custom.text },
          }}
          onChange={(event) => {
            setEmailAddress(event.target.value);
          }}
        />

        <CustomTextField
          value={contactNumber}
          margin="normal"
          fullWidth
          id="contactNumber"
          label={t("contactNumber")}
          name="contactNumber"
          autoFocus
          variant="filled"
          sx={{
            width: "100%",
          }}
          InputLabelProps={{
            style: { color: theme.palette.custom.text },
          }}
          onChange={(event) => {
            setContactNumber(event.target.value);
          }}
        />

        <CustomTextField
          value={title}
          margin="normal"
          fullWidth
          id="title"
          label={t("Title")}
          name="title"
          autoFocus
          variant="filled"
          sx={{
            width: "100%",
          }}
          InputLabelProps={{
            style: { color: theme.palette.custom.text },
          }}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
        <FormControl
          sx={{
            m: 0,
            minWidth: 120,
            width: "100%",
            borderColor: theme.palette.custom.borderColor,
          }}
          variant="standard"
        >
          <InputLabel
            sx={{ color: theme.palette.custom.text }}
            id="demo-simple-select-helper-label"
          >
            {t("SupportIssueType")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={issueType}
            onChange={handleChooseIssueType}
            input={<BootstrapInput />}
          >
            {typesList &&
              typesList.map((item) => (
                <MenuItem key={0} value={item.value}>
                  <Typography textAlign="left">
                    {item ? t(item.value) : ""}
                  </Typography>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <CustomTextField
          value={supportIssueDescription}
          margin="normal"
          fullWidth
          id="supportIssueDescription"
          label={t("supportIssueDescription")}
          name="supportIssueDescription"
          autoFocus
          multiline
          rows={4}
          variant="filled"
          sx={{
            width: "100%",
          }}
          InputLabelProps={{
            style: { color: theme.palette.custom.text },
          }}
          onChange={(event) => {
            setSupportIssueDescription(event.target.value);
          }}
        />
      </Box>
      <Box sx={{ width: "100%", alignItems: "end", textAlign: "end" }}>
        <StyledLoadingButton
          loading={savingLoading}
          loadingIndicator={<CircularProgress color="primary" size={16} />}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          onClick={handleSubmit}
        >
          {t("Send")}
        </StyledLoadingButton>
      </Box>
    </Box>
  );
}

export default ContactUs;
