import * as React from "react";
import {
  Box,
  IconButton,
  capitalize,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  GetDateTimeStringToday,
  GetDateTimeStringYesterday,
  GetDateTimeString7DaysAgo,
  GetDateTimeString14DaysAgo,
  GetDateTimeString30DaysAgo,
  GetDateTimeString90DaysAgo,
  GetDateTimeString2DaysAgo,
  GetDateTimeString60DaysAgo,
  GetDateTimeString180DaysAgo,
  GetDateTimeString28DaysAgo,
  GetMachineTypeIcon,
} from "../utils/systemInfo";
import moment from "moment";
import HomeColumn from "../common/homeColumn";
import DonutChart from "../common/donutChart";
import { setOpsumData } from "../store/opsumSlice";
import OpsumService from "../api/opsum";
import DevicesService from "../api/devices";
import MaintenanceService from "../api/maintenance";
import Loading from "../common/loading";

// const gridContainer = {
//     display: "grid",
//     flexFlow: "row",
//     gridTemplateRows: "repeat(4, 1fr)"
// };
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.light,
    maxHeight: "300px",
  },
}));
const StyleSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
}));
const StyleMachineSpan = styled((props) => <span {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    fontSize: "2rem",
  })
);
const timespanList = [
  {
    id: 0,
    label: "Day on Day",
    secondLabel: "Last 24 hours",
    timespan: "24 hours",
  },
  {
    id: 1,
    label: "Week on Week",
    secondLabel: "Last 7 days",
    timespan: "168 hours",
  },
  {
    id: 2,
    label: "Fortnight on Fortnight",
    secondLabel: "Last 14 days",
    timespan: "336 hours",
  },
  {
    id: 3,
    label: "Month on Month",
    secondLabel: "Last 30 days",
    timespan: "720 hours",
  },
  {
    id: 4,
    label: "Quarter on Quarter",
    secondLabel: "Last 90 hours",
    timespan: "2160 hours",
  },
];
const StyledTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
  })
);
const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.borderColor,
    m: 0,
  })
);
const StyledColumnBox = styled((props) => <Box {...props} />)(() => ({
  textAlign: "center",
  display: "flex",
  flexFlow: "column",
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2B2F38",
    color: theme.palette.custom.text,
    maxWidth: 220,
    border: `1px solid ${theme.palette.custom.borderColor}`,
  },
}));
function Home() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organziation = useSelector(({ organziation }) => organziation);
  const opsum = useSelector(({ opsum }) => opsum);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorMachine, setAnchorMachine] = useState(null);
  const [machineDeviceName, setMachineDeviceName] = useState("Fleet");
  const [opsumInputData, setOpsumInputData] = useState(undefined);
  const [machineDeviceArray, setMachineDeviceArray] = useState(["Fleet"]);
  const [dateTimeStringFrom, setDateTimeStringFrom] = useState(
    GetDateTimeString7DaysAgo()
  );
  const [dateTimeStringTo, setDateTimeStringTo] = useState(
    GetDateTimeStringToday()
  );
  const [dateRangeString, setDateRangeString] = useState("Last 7 days");
  const [timespanString, setTimespanString] = useState("168 hours");
  const [maintUIDValue, setMaintUID] = useState(undefined);
  const [maintCountValue, setMaintCount] = useState(undefined);
  const [timeSpanMenuLabel, setTimespanMenuLabel] = useState("Week on Week");
  const [maintenanceTypes, setMaintenanceTypes] = useState(undefined);
  const [maintenanceSchedule, setMaintenanceSchedule] = useState(undefined);
  const [loadingValue, setLoadingValue] = useState(true);
  const [productivityExpand, setProductivityExpand] = useState(false);
  const [productivityExpandIcon, setProductivityExpandIcon] = useState(false);
  const [colCount, setColCount] = useState(1);
  const [sidebarOpen, setSidebarOpen] = useState(
    localStorage.getItem("sidebarOpen") === "true"
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (organziation) {
      setOpsumInputData(null);
      const apiKey = organziation?.api_key_list?.[0]?.key;
      DevicesService.getDevices({
        deviceID: "",
        deviceType: [
          "nerohub",
          "nanohub",
          "cyberhub",
          "stratahub",
          "nerotrax",
          "train",
          "autonomous hub",
        ],
        apiKey,
        historyFromDST: GetDateTimeString7DaysAgo(),
        historyToDST: GetDateTimeStringToday(),
      })
        .then((json) => {
          if (json && json.device_profile && json.device_profile.length > 0) {
            let machineTypesArray = ["Fleet"];
            for (let index = 0; index < json.device_profile.length; index++) {
              if (
                machineTypesArray.indexOf(
                  json.device_profile[index].vehicle_type
                ) === -1
              ) {
                machineTypesArray.push(json.device_profile[index].vehicle_type);
              }
            }

            setMachineDeviceArray(machineTypesArray);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
      OpsumService.getDeviceOpsumQuery({
        deviceID: "",
        deviceType: [
          "nerohub",
          "nanohub",
          "cyberhub",
          "stratahub",
          "nerotrax",
          "train",
          "autonomous hub",
        ],
        apiKey,
        historyFromDST: GetDateTimeString7DaysAgo(),
        historyToDST: GetDateTimeStringToday(),
      })
        .then((jsonCurrent) => {
          OpsumService.getDeviceOpsumQuery({
            deviceID: "",
            deviceType: [
              "nerohub",
              "nanohub",
              "cyberhub",
              "stratahub",
              "nerotrax",
              "train",
              "autonomous hub",
            ],
            apiKey,
            historyFromDST: GetDateTimeString14DaysAgo(),
            historyToDST: GetDateTimeString7DaysAgo(),
          })
            .then((jsonPrevious) => {
              setOpsumInputData({
                jsonCurrent,
                jsonPrevious,
                machineTypeFilter: "",
              });
              dispatch(
                setOpsumData({
                  jsonCurrent,
                  jsonPrevious,
                  machineTypeFilter: "",
                })
              );
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
      let count = 0;
      let maintType = [];
      MaintenanceService.getDeviceMaintenanceTypes({
        maintenanceTypeId: "",
        apiKey,
      })
        .then((jsonTypes) => {
          setMaintenanceTypes(jsonTypes);
          MaintenanceService.getDeviceMaintenances({
            deviceProfileId: "",
            name: "",
            status: "active",
            apiKey,
          })
            .then((jsonSchedules) => {
              setMaintenanceSchedule(jsonSchedules);
              for (let i in jsonTypes.device_maintenance_type) {
                for (let j in jsonSchedules.device_maintenance_schedule) {
                  if (
                    jsonTypes.device_maintenance_type[i].name ===
                      jsonSchedules.device_maintenance_schedule[j]
                        .maintenance_type_id &&
                    jsonSchedules.device_maintenance_schedule[j].status ===
                      "active"
                  ) {
                    if (
                      new Date(
                        jsonSchedules.device_maintenance_schedule[j].date_from
                      ) <= new Date(GetDateTimeString7DaysAgo()) &&
                      new Date(
                        jsonSchedules.device_maintenance_schedule[j].date_to
                      ) >= new Date(GetDateTimeStringToday())
                    ) {
                      maintType.push(
                        jsonSchedules.device_maintenance_schedule[j]
                          .maintenance_type_id
                      );
                      count++;
                    }
                  }
                }
              }
              if (count > 0) {
                let maintUID = [];
                let maintCount = [];
                maintType.forEach((e) => {
                  if (maintUID.includes(e) !== true) {
                    maintUID.push(e);
                    maintCount[maintUID.indexOf(e)] = 1;
                  } else {
                    maintCount[maintUID.indexOf(e)] =
                      maintCount[maintUID.indexOf(e)] + 1;
                  }
                });
                setMaintUID(maintUID);
                setMaintCount(maintCount);
              }
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const handleStorageChange = () => {
      setSidebarOpen(localStorage.getItem("sidebarOpen") === "true");
    };
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener(
      "customEventLocalStorageChange",
      handleStorageChange
    );
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener(
        "customEventLocalStorageChange",
        handleStorageChange
      );
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    function calculateColCount() {
      const sidebarWidth = setSidebarOpen ? 250 : 70;
      const availableWidth = windowWidth - sidebarWidth;
      const colWidth = 280;
      const maxColCount =
        organziation?.uid === 19 ||
        organziation?.uid === 92 ||
        organziation?.uid === 68
          ? 3
          : 4;
      const calculatedColCount = Math.floor(availableWidth / colWidth);
      setColCount(Math.min(calculatedColCount, maxColCount));
    }
    calculateColCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarOpen, windowWidth]);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenMachineMenu = (event) => {
    setAnchorMachine(event.currentTarget);
  };
  const handleCloseMachineMenu = () => {
    setAnchorMachine(null);
  };
  const handleChooseTimespan = (item) => {
    setLoadingValue(true);
    setTimespanMenuLabel(item.label);
    setTimespanString(item.timespan);
    setDateRangeString(item.secondLabel);
    let DataFrom;
    let DataFromBefore;
    const DataTo = GetDateTimeStringToday();
    switch (item.id) {
      case 0:
        DataFrom = GetDateTimeStringYesterday();
        DataFromBefore = GetDateTimeString2DaysAgo();
        break;
      case 1:
      default:
        DataFrom = GetDateTimeString7DaysAgo();
        DataFromBefore = GetDateTimeString28DaysAgo();
        break;
      case 2:
        DataFrom = GetDateTimeString14DaysAgo();
        DataFromBefore = GetDateTimeStringYesterday();
        break;
      case 3:
        DataFrom = GetDateTimeString30DaysAgo();
        DataFromBefore = GetDateTimeString60DaysAgo();
        break;
      case 4:
        DataFrom = GetDateTimeString90DaysAgo();
        DataFromBefore = GetDateTimeString180DaysAgo();
        break;
    }
    setDateTimeStringFrom(DataFrom);
    setDateTimeStringTo(DataTo);
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      OpsumService.getDeviceOpsumQuery({
        deviceID: "",
        deviceType: [
          "nerohub",
          "nanohub",
          "cyberhub",
          "stratahub",
          "nerotrax",
          "train",
          "autonomous hub",
        ],
        apiKey,
        historyFromDST: DataFrom,
        historyToDST: DataTo,
      })
        .then((jsonCurrent) => {
          OpsumService.getDeviceOpsumQuery({
            deviceID: "",
            deviceType: [
              "nerohub",
              "nanohub",
              "cyberhub",
              "stratahub",
              "nerotrax",
              "train",
              "autonomous hub",
            ],
            apiKey,
            historyFromDST: DataFromBefore,
            historyToDST: DataFrom,
          })
            .then((jsonPrevious) => {
              setOpsumInputData({
                jsonCurrent,
                jsonPrevious,
                machineTypeFilter:
                  machineDeviceName === "Fleet" ? "" : machineDeviceName,
              });
              dispatch(
                setOpsumData({
                  jsonCurrent,
                  jsonPrevious,
                  machineTypeFilter:
                    machineDeviceName === "Fleet" ? "" : machineDeviceName,
                })
              );
              setLoadingValue(false);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
      if (maintenanceTypes && maintenanceSchedule) {
        let count = 0;
        let maintType = [];
        for (let i in maintenanceTypes) {
          for (let j in maintenanceSchedule.device_maintenance_schedule) {
            if (
              maintenanceTypes[i].name ===
                maintenanceSchedule.device_maintenance_schedule[j]
                  .maintenance_type_id &&
              maintenanceSchedule.device_maintenance_schedule[j].status ===
                "active"
            ) {
              if (
                new Date(
                  maintenanceSchedule.device_maintenance_schedule[j].date_from
                ) <= new Date(DataFrom) &&
                new Date(
                  maintenanceSchedule.device_maintenance_schedule[j].date_to
                ) >= new Date(DataTo)
              ) {
                maintType.push(
                  maintenanceSchedule.device_maintenance_schedule[j]
                    .maintenance_type_id
                );
                count++;
              }
            }
          }
        }
        if (count > 0) {
          let maintUID = [];
          let maintCount = [];
          maintType.forEach((e) => {
            if (maintUID.includes(e) !== true) {
              maintUID.push(e);
              maintCount[maintUID.indexOf(e)] = 1;
            } else {
              maintCount[maintUID.indexOf(e)] =
                maintCount[maintUID.indexOf(e)] + 1;
            }
          });
          setMaintUID(maintUID);
          setMaintCount(maintCount);
        }
      }
    }
    setAnchorElUser(false);
  };
  const handleChooseMachine = (item) => {
    setLoadingValue(true);
    let opsumInputObj = opsumInputData;
    opsumInputObj.machineTypeFilter = item;
    dispatch(setOpsumData(opsumInputObj));
    setMachineDeviceName(item);
    setAnchorMachine(false);
    setLoadingValue(false);
  };
  const handleArrowIcon = (newValue, OldValue, reverse, fontSize) => {
    if (newValue.toFixed(2) > OldValue.toFixed(2)) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue.toFixed(2) < OldValue.toFixed(2)) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };
  const toggleProductivityExpand = () => {
    setProductivityExpand(!productivityExpand);
  };
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        backgroundColor: theme.palette.custom.backgroundColor,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          gap: "1rem",
        }}
      >
        <Box
          sx={{ display: { xs: "block", sm: "flex" }, alignItems: "normal" }}
        >
          <Typography
            sx={{
              fontSize: { xs: "2rem", sm: "2.5rem" },
              fontWeight: "bolder",
              color: theme.palette.custom.borderColor,
            }}
            variant="h2"
            gutterBottom
          >
            {organziation?.full_name?.toLowerCase() === "ghh"
              ? "inSITE"
              : capitalize(t("SIMAC"))}
          </Typography>
          <Typography
            sx={{
              ml: { xs: 0, sm: 1 },
              mt: { xs: 0, sm: 1 },
              fontSize: { xs: "1rem", sm: "1.5rem" },
              color: theme.palette.custom.text,
            }}
            variant="h4"
            gutterBottom
          >
            {t("HomeSubHeader")}
          </Typography>
        </Box>
        <>
          <Tooltip title="Open settings">
            <Button
              variant="text"
              onClick={handleOpenMachineMenu}
              endIcon={
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: { xs: "30px", sm: "45px" },
                    color: theme.palette.custom.text,
                  }}
                />
              }
            >
              <StyleMachineSpan>{machineDeviceName}</StyleMachineSpan>
            </Button>
          </Tooltip>
          <StyledMenu
            id="menu-appbar"
            anchorEl={anchorMachine}
            open={Boolean(anchorMachine)}
            onClose={handleCloseMachineMenu}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {machineDeviceArray && opsumInputData ? (
              machineDeviceArray.map((item) => (
                <MenuItem key={item} onClick={() => handleChooseMachine(item)}>
                  <ListItemIcon>
                    {item !== "Fleet" && (
                      <div
                        style={{ width: "30px" }}
                        dangerouslySetInnerHTML={{
                          __html: GetMachineTypeIcon(item).replace(
                            "theme.palette.custom.text",
                            theme.palette.custom.text
                          ),
                        }}
                      />
                    )}
                  </ListItemIcon>

                  <Typography textAlign="center">{item ? item : ""}</Typography>
                </MenuItem>
              ))
            ) : (
              <Loading />
            )}
          </StyledMenu>
        </>
      </Box>
      <Divider
        sx={{ mb: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <Box
        component="main"
        sx={{
          p: "12px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "16px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          height: { xs: "fit-content", sm: "48px" },
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            {t("DataSummary").toLocaleUpperCase()} &nbsp;|&nbsp;
          </Typography>
          <NumberTypography variant="subtitle2">
            {t(dateRangeString)}
            {` (${moment(dateTimeStringFrom, "YYYY/MM/DD HH:mm:ss").format(
              "YYYY/MM/DD"
            )} - ${moment(dateTimeStringTo, "YYYY/MM/DD HH:mm:ss").format(
              "YYYY/MM/DD"
            )})`}
          </NumberTypography>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            &nbsp;|&nbsp;{t("Timespan")}:&nbsp;{timespanString}
          </Typography>
        </Box>
        <>
          <Tooltip title="Open settings">
            <Button
              variant="text"
              onClick={handleOpenUserMenu}
              endIcon={
                <KeyboardArrowDownIcon
                  sx={{ color: theme.palette.custom.text }}
                />
              }
            >
              <StyleSpan>{timeSpanMenuLabel}</StyleSpan>
            </Button>
          </Tooltip>
          <StyledMenu
            id="menu-appbar"
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {timespanList &&
              timespanList.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => handleChooseTimespan(item)}
                >
                  <Typography textAlign="center">
                    {item.label ? item.label : ""}
                  </Typography>
                </MenuItem>
              ))}
          </StyledMenu>
        </>
      </Box>
      <Box
        sx={{
          p: "12px",
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: `repeat(${colCount}, minmax(0, 1fr))`,
          height: "87%",
        }}
      >
        {organziation?.uid === 19 || organziation?.uid === 92 ? (
          <></>
        ) : (
          <HomeColumn
            icon={
              <VerifiedUserOutlinedIcon
                sx={{
                  width: "85px",
                  height: "85px",
                  color: theme.palette.custom.borderColor,
                }}
              />
            }
            title={t("Safety")}
            redirectLink={"safety"}
            content={
              !loadingValue || opsum ? (
                <>
                  {" "}
                  <Typography
                    sx={{ color: theme.palette.custom.borderColor }}
                    variant="h5"
                    gutterBottom
                  >
                    {t("CwsEvents")}
                  </Typography>
                  <Box sx={{ width: "80%", ml: "9%", mb: "8px" }}>
                    {/* {
                                opsum ?
                                    <LinearProgress sx={{ height: 30, borderRadius: 5, backgroundColor: 'green', borderColor: 'red' }} variant="buffer" value={(opsum.TotalRuntime / (opsum.TotalRuntime + opsum.TotalSBICrawlMinutes + opsum.TotalSBIStopMinutes))}
                                        valueBuffer={((opsum.TotalRuntime + opsum.TotalSBICrawlMinutes) / opsum.TotalRuntime + opsum.TotalSBICrawlMinutes + opsum.TotalSBIStopMinutes)} />
                                    : null
                            } */}
                    {opsum && (
                      <Box
                        sx={{ width: "100%", height: "2rem", display: "flex" }}
                      >
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <strong>
                                <span
                                  style={{ color: theme.palette.primary.main }}
                                >
                                  {t("Engine Runtime")}
                                </span>
                                <br />
                                <span>{`${t("Time(Hours)")}: ${Math.round(
                                  opsum.TotalRuntime / 60
                                )} `}</span>
                              </strong>
                            </React.Fragment>
                          }
                        >
                          <Box
                            sx={{
                              backgroundColor: "green",
                              height: "100%",
                              borderRadius: "0.2rem",
                              width: `${
                                (opsum.TotalRuntime /
                                  (opsum.TotalRuntime +
                                    opsum.TotalCWSCrawlMinutes +
                                    opsum.TotalCWSBrakeMinutes)) *
                                100
                              }%`,
                            }}
                          />
                        </HtmlTooltip>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <strong>
                                <span
                                  style={{ color: theme.palette.primary.main }}
                                >
                                  {t("Crawl time")}
                                </span>
                                <br />
                                <span>{`${t("Time(Hours)")}: ${Math.round(
                                  opsum.TotalCWSCrawlMinutes / 60
                                )} `}</span>
                              </strong>
                            </React.Fragment>
                          }
                        >
                          <Box
                            sx={{
                              backgroundColor: "yellow",
                              height: "100%",
                              borderRadius: "0.2rem",
                              width: `${
                                (opsum.TotalCWSCrawlMinutes /
                                  (opsum.TotalRuntime +
                                    opsum.TotalCWSCrawlMinutes +
                                    opsum.TotalCWSBrakeMinutes)) *
                                100
                              }%`,
                            }}
                          />
                        </HtmlTooltip>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <strong>
                                <span
                                  style={{ color: theme.palette.primary.main }}
                                >
                                  {t("Stop time")}
                                </span>
                                <br />
                                <span>{`${t("Time(Hours)")}: ${Math.round(
                                  opsum.TotalCWSBrakeMinutes / 60
                                )} `}</span>
                              </strong>
                            </React.Fragment>
                          }
                        >
                          <Box
                            sx={{
                              backgroundColor: "red",
                              height: "100%",
                              borderRadius: "0.2rem",
                              width: `${
                                (opsum.TotalCWSBrakeMinutes /
                                  (opsum.TotalRuntime +
                                    opsum.TotalCWSCrawlMinutes +
                                    opsum.TotalCWSBrakeMinutes)) *
                                100
                              }%`,
                            }}
                          />
                        </HtmlTooltip>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <StyledColumnBox>
                      <StyledTypography variant="subtitle2">
                        {t("CWS CRAWL EVENTS")} :&nbsp;{" "}
                        <NumberTypography variant="subtitle2">
                          {opsum.TotalCWSCrawlCounter
                            ? Number(opsum.TotalCWSCrawlCounter).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </NumberTypography>{" "}
                        {t("incidents")}
                      </StyledTypography>

                      <StyledTypography variant="subtitle2" gutterBottom>
                        {t("Time")} :&nbsp;{" "}
                        <NumberTypography variant="subtitle2">
                          {opsum.TotalCWSCrawlMinutes
                            ? Number(opsum.TotalCWSCrawlMinutes / 60).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </NumberTypography>{" "}
                        {t("hours")}
                      </StyledTypography>
                    </StyledColumnBox>
                    {opsum &&
                      handleArrowIcon(
                        opsum.TotalCWSCrawlCounter,
                        opsum.TotalCWSCrawlCounterLastWeek,
                        false,
                        30
                      )}
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <StyledColumnBox>
                      <StyledTypography variant="subtitle2">
                        {t("CWS BRAKE EVENTS")} :&nbsp;{" "}
                        <NumberTypography variant="subtitle2">
                          {opsum.TotalCWSBrakeCounter
                            ? Number(opsum.TotalCWSBrakeCounter).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </NumberTypography>{" "}
                        {t("incidents")}
                      </StyledTypography>

                      <StyledTypography variant="subtitle2" gutterBottom>
                        {t("Time")} :&nbsp;{" "}
                        <NumberTypography variant="subtitle2">
                          {opsum.TotalCWSBrakeMinutes
                            ? Number(opsum.TotalCWSBrakeMinutes / 60).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </NumberTypography>{" "}
                        {t("hours")}
                      </StyledTypography>
                    </StyledColumnBox>
                    {opsum &&
                      handleArrowIcon(
                        opsum.TotalCWSBrakeCounter,
                        opsum.TotalCWSBrakeCounterLastWeek,
                        false,
                        30
                      )}
                  </Box>
                  <Divider
                    sx={{
                      my: "10px",
                      backgroundColor: theme.palette.custom.borderColor,
                    }}
                  />
                  <Typography
                    sx={{ color: theme.palette.custom.borderColor }}
                    variant="h5"
                    gutterBottom
                  >
                    {t("CbiEvents")}
                  </Typography>
                  <Box sx={{ width: "80%", ml: "9%", mb: "8px" }}>
                    {/* {
                                opsum ?
                                    <LinearProgress sx={{ height: 30, borderRadius: 5, backgroundColor: 'green', borderColor: 'red' }} variant="buffer" value={(opsum.TotalRuntime / (opsum.TotalRuntime + opsum.TotalCWSCrawlMinutes + opsum.TotalCWSBrakeMinutes))}
                                        valueBuffer={((opsum.TotalRuntime + opsum.TotalCWSCrawlMinutes) / opsum.TotalRuntime + opsum.TotalCWSCrawlMinutes + opsum.TotalCWSBrakeMinutes)} />
                                    : null
                            } */}
                    {opsum && (
                      <Box
                        sx={{ width: "100%", height: "2rem", display: "flex" }}
                      >
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <strong>
                                <span
                                  style={{ color: theme.palette.primary.main }}
                                >
                                  {t("Engine Runtime")}
                                </span>
                                <br />
                                <span>{`${t("Time(Hours)")}: ${Math.round(
                                  opsum.TotalRuntime / 60
                                )} `}</span>
                              </strong>
                            </React.Fragment>
                          }
                        >
                          <Box
                            sx={{
                              backgroundColor: "green",
                              height: "100%",
                              borderRadius: "0.2rem",
                              width: `${
                                (opsum.TotalRuntime /
                                  (opsum.TotalRuntime +
                                    opsum.TotalSBICrawlMinutes +
                                    opsum.TotalSBIStopMinutes)) *
                                100
                              }%`,
                            }}
                          />
                        </HtmlTooltip>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <strong>
                                <span
                                  style={{ color: theme.palette.primary.main }}
                                >
                                  {t("Crawl time")}
                                </span>
                                <br />
                                <span>{`${t("Time(Hours)")}: ${Math.round(
                                  opsum.TotalSBICrawlMinutes / 60
                                )} `}</span>
                              </strong>
                            </React.Fragment>
                          }
                        >
                          <Box
                            sx={{
                              backgroundColor: "yellow",
                              height: "100%",
                              borderRadius: "0.2rem",
                              width: `${
                                (opsum.TotalSBICrawlMinutes /
                                  (opsum.TotalRuntime +
                                    opsum.TotalSBICrawlMinutes +
                                    opsum.TotalSBIStopMinutes)) *
                                100
                              }%`,
                            }}
                          />
                        </HtmlTooltip>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <strong>
                                <span
                                  style={{ color: theme.palette.primary.main }}
                                >
                                  {t("Stop time")}
                                </span>
                                <br />
                                <span>{`${t("Time(Hours)")}: ${Math.round(
                                  opsum.TotalSBIStopMinutes / 60
                                )} `}</span>
                              </strong>
                            </React.Fragment>
                          }
                        >
                          <Box
                            sx={{
                              backgroundColor: "red",
                              height: "100%",
                              borderRadius: "0.2rem",
                              width: `${
                                (opsum.TotalSBIStopMinutes /
                                  (opsum.TotalRuntime +
                                    opsum.TotalSBICrawlMinutes +
                                    opsum.TotalSBIStopMinutes)) *
                                100
                              }%`,
                            }}
                          />
                        </HtmlTooltip>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <StyledColumnBox>
                      <StyledTypography variant="subtitle2">
                        {t("SBI CRAWL EVENTS")} :&nbsp;{" "}
                        <NumberTypography variant="subtitle2">
                          {opsum.TotalSBICrawlCounter
                            ? Number(opsum.TotalSBICrawlCounter).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </NumberTypography>{" "}
                        {t("incidents")}
                      </StyledTypography>

                      <StyledTypography variant="subtitle2" gutterBottom>
                        {t("Time")} :&nbsp;{" "}
                        <Typography
                          sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          {opsum.TotalSBICrawlMinutes
                            ? Number(opsum.TotalSBICrawlMinutes / 60).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </Typography>{" "}
                        {t("hours")}
                      </StyledTypography>
                    </StyledColumnBox>
                    {opsum &&
                      handleArrowIcon(
                        opsum.TotalSBICrawlCounter,
                        opsum.TotalSBICrawlCounterLastWeek,
                        false,
                        30
                      )}
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <StyledColumnBox>
                      <StyledTypography variant="subtitle2">
                        {t("SBI STOP EVENTS")} :&nbsp;{" "}
                        <Typography
                          sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          {opsum.TotalSBIStopCounter
                            ? Number(opsum.TotalSBIStopCounter).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </Typography>{" "}
                        {t("incidents")}
                      </StyledTypography>
                      <StyledTypography variant="subtitle2" gutterBottom>
                        {t("Time")} :&nbsp;{" "}
                        <Typography
                          sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          {opsum.TotalSBIStopMinutes
                            ? Number(opsum.TotalSBIStopMinutes / 60).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </Typography>{" "}
                        {t("hours")}
                      </StyledTypography>
                    </StyledColumnBox>
                    {opsum &&
                      handleArrowIcon(
                        opsum.TotalSBIStopCounter,
                        opsum.TotalSBIStopCounterLastWeek,
                        false,
                        30
                      )}
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    height: { xs: "100px", sm: "400px" },
                    pt: { xs: "50px", sm: "200px" },
                  }}
                >
                  <Loading />
                </Box>
              )
            }
          />
        )}
        <HomeColumn
          icon={
            <AnalyticsOutlinedIcon
              sx={{
                width: "85px",
                height: "85px",
                color: theme.palette.custom.borderColor,
              }}
            />
          }
          title={t("Productivity")}
          redirectLink={"productivity"}
          onMouseEnter={() => {
            setProductivityExpandIcon(true);
          }}
          onMouseLeave={() => {
            setProductivityExpandIcon(false);
          }}
          content={
            !loadingValue || opsum ? (
              <Box sx={{ position: "relative" }}>
                {productivityExpandIcon && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "-0.75rem",
                      right: "-0.75rem",
                    }}
                    onClick={toggleProductivityExpand}
                  >
                    {!productivityExpand ? <AddIcon /> : <CloseIcon />}
                  </IconButton>
                )}
                <DonutChart
                  width={200}
                  height={200}
                  listArray={[
                    {
                      label: "Work Time",
                      amount: opsum.TotalWorkingTime
                        ? Number(opsum.TotalWorkingTime / 60).toFixed(2)
                        : 0,
                      index: 1,
                      color: "#198754",
                      current: opsum.TotalWorkingTime,
                      previous: opsum.TotalWorkingTimeLastWeek,
                    },
                    {
                      label: "Idle Time",
                      amount: opsum.TotalIdleTime
                        ? Number(opsum.TotalIdleTime / 60).toFixed(2)
                        : 0,
                      index: 2,
                      color: "#ffa500",
                      current: opsum.TotalIdleTime,
                      previous: opsum.TotalIdleTimeLastWeek,
                    },
                    {
                      label: "Offline Time",
                      amount: opsum.TotalOfflineTime
                        ? Number(opsum.TotalOfflineTime / 60).toFixed(2)
                        : 0,
                      index: 3,
                      color: "#6c757d",
                      current: opsum.TotalOfflineTime,
                      previous: opsum.TotalOfflineTimeLastWeek,
                    },
                  ]}
                />
                <Divider
                  sx={{
                    my: "10px",
                    backgroundColor: theme.palette.custom.borderColor,
                  }}
                />
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledTypography variant="subtitle2">
                    {t("WorkingTime")} :&nbsp;{" "}
                    <Typography
                      sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      {opsum.TotalWorkingTime
                        ? Number(opsum.TotalWorkingTime / 60).toFixed(2)
                        : (0).toFixed(2)}
                      &nbsp;
                    </Typography>{" "}
                    {t("hours")}
                  </StyledTypography>
                  {handleArrowIcon(
                    opsum.TotalWorkingTime,
                    opsum.TotalWorkingTimeLastWeek,
                    false
                  )}
                </Box>
                <Divider
                  sx={{
                    my: "10px",
                    backgroundColor: theme.palette.custom.borderColor,
                  }}
                />
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledTypography variant="subtitle2">
                    {t("Idle Time")} :&nbsp;{" "}
                    <NumberTypography variant="subtitle2">
                      {opsum.TotalIdleTime
                        ? Number(opsum.TotalIdleTime / 60).toFixed(2)
                        : (0).toFixed(2)}
                      &nbsp;
                    </NumberTypography>{" "}
                    {t("hours")}
                  </StyledTypography>
                  {handleArrowIcon(
                    opsum.TotalIdleTime,
                    opsum.TotalIdleTimeLastWeek,
                    true
                  )}
                </Box>
                <Divider
                  sx={{
                    my: "10px",
                    backgroundColor: theme.palette.custom.borderColor,
                  }}
                />
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledTypography variant="subtitle2">
                    {t("Offline Time")} :&nbsp;{" "}
                    <NumberTypography variant="subtitle2">
                      {opsum.TotalOfflineTime
                        ? Number(opsum.TotalOfflineTime / 60).toFixed(2)
                        : (0).toFixed(2)}
                      &nbsp;
                    </NumberTypography>{" "}
                    {t("hours")}
                  </StyledTypography>
                  {handleArrowIcon(
                    opsum.TotalOfflineTime,
                    opsum.TotalOfflineTimeLastWeek,
                    true
                  )}
                </Box>
                {productivityExpand && (
                  <>
                    <Divider
                      sx={{
                        my: "10px",
                        backgroundColor: theme.palette.custom.borderColor,
                      }}
                    />
                    <Box
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        display: "flex",
                        flexFlow: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <StyledTypography variant="subtitle2">
                        {t("Unscheduled Time")} :&nbsp;{" "}
                        <Typography
                          sx={{ color: theme.palette.custom.borderColor, m: 0 }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          {opsum.TotalUnscheduledTime
                            ? Number(opsum.TotalUnscheduledTime / 60).toFixed(2)
                            : (0).toFixed(2)}
                          &nbsp;
                        </Typography>{" "}
                        {t("hours")}
                      </StyledTypography>
                      {handleArrowIcon(
                        opsum.TotalUnscheduledTime,
                        opsum.TotalUnscheduledTimeLastWeek,
                        false
                      )}
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  height: { xs: "100px", sm: "400px" },
                  pt: { xs: "50px", sm: "200px" },
                }}
              >
                <Loading />
              </Box>
            )
          }
        />
        <HomeColumn
          icon={
            <SettingsOutlinedIcon
              sx={{
                width: "85px",
                height: "85px",
                color: theme.palette.custom.borderColor,
              }}
            />
          }
          title={t("Maintenance")}
          redirectLink={"maintenance"}
          content={
            loadingValue ? (
              <Box
                sx={{
                  height: { xs: "100px", sm: "400px" },
                  pt: { xs: "50px", sm: "200px" },
                }}
              >
                <Loading />
              </Box>
            ) : maintUIDValue &&
              maintCountValue &&
              maintCountValue.length > 0 ? (
              maintUIDValue.map((e) => (
                <>
                  <NumberTypography variant="subtitle2">
                    {t(e)}
                  </NumberTypography>
                  <Typography
                    sx={{ color: theme.palette.custom.text }}
                    variant="h2"
                    gutterBottom
                  >
                    {maintCountValue[maintUIDValue.indexOf(e)]}{" "}
                    <RemoveCircleOutlineRoundedIcon
                      sx={{
                        minHeight: 48,
                        color: "grey",
                        fontSize: 40,
                      }}
                    />
                  </Typography>
                </>
              ))
            ) : (
              <Typography
                sx={{ color: theme.palette.custom.text }}
                variant="subtitle2"
                gutterBottom
              >
                {t("No Scheduled Maintenance")}
              </Typography>
            )
          }
        />
        {organziation?.uid === 68 ? (
          <></>
        ) : (
          <HomeColumn
            icon={
              <EqualizerOutlinedIcon
                sx={{
                  width: "85px",
                  height: "85px",
                  color: theme.palette.custom.borderColor,
                }}
              />
            }
            title={t("Monitoring")}
            redirectLink={"monitoring"}
            content={
              !loadingValue || opsum ? (
                <>
                  {" "}
                  <DonutChart
                    width={200}
                    height={200}
                    listArray={[
                      {
                        label: "Active Machines",
                        amount: opsum.ActiveDevicesCount
                          ? Number(opsum.ActiveDevicesCount)
                          : 0,
                        index: 1,
                        color: "#198754",

                        current: opsum.ActiveDevicesCount
                          ? Number(opsum.ActiveDevicesCount)
                          : 0,
                        previous: opsum.ActiveDevicesCountLastWeek
                          ? Number(opsum.ActiveDevicesCountLastWeek)
                          : 0,
                        // percentage: opsum.ActiveDevicesCount / (opsum.ActiveDevicesCount + opsum.InactiveDevicesCount)
                      },
                      {
                        label: "Inactive Machines",
                        amount: opsum.InactiveDevicesCount
                          ? Number(opsum.InactiveDevicesCount)
                          : 0,
                        index: 2,
                        color: "#6c757d",

                        current: opsum.InactiveDevicesCount
                          ? Number(opsum.InactiveDevicesCount)
                          : 0,
                        previous: opsum.InactiveDevicesCountLastWeek
                          ? Number(opsum.InactiveDevicesCountLastWeek)
                          : 0,
                        // percentage: opsum.InactiveDevicesCount / (opsum.ActiveDevicesCount + opsum.InactiveDevicesCount)
                      },
                    ]}
                  />
                  <Divider
                    sx={{
                      my: "10px",
                      backgroundColor: theme.palette.custom.borderColor,
                    }}
                  />
                  <Typography
                    sx={{ color: theme.palette.custom.text }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    {t("Active Machines")}
                  </Typography>
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{ color: theme.palette.custom.borderColor }}
                      variant="h2"
                    >
                      {`${opsum.ActiveDevicesCount} / ${
                        opsum.ActiveDevicesCount + opsum.InactiveDevicesCount
                      }`}
                    </Typography>
                    {handleArrowIcon(
                      opsum.ActiveDevicesCount,
                      opsum.ActiveDevicesCountLastWeek,
                      false,
                      60
                    )}
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    height: { xs: "100px", sm: "400px" },
                    pt: { xs: "50px", sm: "200px" },
                  }}
                >
                  <Loading />
                </Box>
              )
            }
          />
        )}
      </Box>
    </Box>
  );
}

export default Home;
