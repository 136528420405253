import axios from "axios";
import i18next from "i18next";

import Utils from "./utils";
import {
  GetUserIP,
  GetComputerName,
  GetOperatingSystem,
  GetScreenResolution,
  GetSystemLanguage,
  GetSystemMemory,
  GetVideoCardName,
  getBrowser,
} from "./systemInfo";

const languages = ["af", "en", "de", "kl", "ru", "fr", "it", "mn", "es"];

/* eslint-disable camelcase */

class AuthService extends Utils.EventEmitter {
  //   init() {
  //     this.setInterceptors();
  //     this.handleAuthentication();
  //   }

  setInterceptors() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise(() => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  }

  handleAuthentication() {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  }

  signInWithEmailAndPassword(email, password) {
    if (
      email.length === 10 &&
      email.charAt(0) === "0" &&
      email.includes("@") === false
    ) {
      // replace only the first zero if the first char in string is a '0' and has length 10 - most likely a mobile number
      email = email.replace("0", "27");
    }
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        auth: {
          contact_uid: "",
          person_profile_uid: "",
          contact_type: "",
          value: email,
          primary_contact: "",
          status: "active",
          response: false,
        },
      });
      const headers = {
        "Content-Type": "application/json",
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/person/auth/validate/contact`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((response) => {
          let apiKey;
          let userType;
          if (
            response &&
            response.success === true &&
            response.person_auth_validate_contact.length > 0
          ) {
            const json = response;
            let AppNameMatch = false;
            for (let i in json.person_auth_validate_contact) {
              const Organization_App_Info =
                json.person_auth_validate_contact[i].app_profile_info;
              const Organization_Info =
                json.person_auth_validate_contact[i].operation_profile_info;
              if (
                Organization_App_Info === undefined ||
                Organization_Info === undefined
              )
                continue;
              if (
                Organization_App_Info.full_name.toLowerCase() ===
                window.env.REACT_APP_API_APP_NAME.toLowerCase()
              ) {
                const Organization_ApiKeyList = Organization_Info.api_key_list;
                if (Organization_ApiKeyList === undefined) continue;
                const Organization_APIKeyObject = Organization_ApiKeyList.find(
                  (item) => {
                    return (
                      item.status === "active" &&
                      new Date(1899, 11, 30 + item.expiry_date) > new Date()
                    );
                  }
                );

                if (Organization_APIKeyObject !== undefined) {
                  // Organization needs to have apiKey
                  window.env.api_key = Organization_APIKeyObject.key;
                  AppNameMatch = true;
                  break;
                }
              }
            }
            const operationProfileInfo =
              response.person_auth_validate_contact[0].operation_profile_info;
            const operationPersonProfileInfo =
              response.person_auth_validate_contact[0]
                .operation_person_profile_link_info;
            apiKey = operationProfileInfo?.api_key_list?.[0]?.key;
            localStorage.setItem("apiKey", apiKey);
            userType = operationPersonProfileInfo.user_type_uid;
            if (AppNameMatch) {
              const User_VideoCard = GetVideoCardName();
              const User_ComputerName = GetComputerName();
              const User_ScreenResolution = GetScreenResolution();
              const User_BrowserObject = getBrowser();
              const User_Browser =
                User_BrowserObject.name + " " + User_BrowserObject.version;
              const User_OperatingSystem = GetOperatingSystem();
              const User_SystemLanguage = GetSystemLanguage();
              //const User_IPAddress = GetPublicIPAddress();
              const User_SystemMemory = GetSystemMemory() + " GB";
              var User_IPAddress = GetUserIP();
              var User_Location_Latitude = 0.0;
              var User_Location_Longitude = 0.0;
              const bodyObj = JSON.stringify({
                auth: {
                  contact: email,
                  password: password,
                  lat: User_Location_Latitude,
                  lon: User_Location_Longitude,
                  ip_address: User_IPAddress,
                  device_model: "web",
                  device_id: User_IPAddress,
                  device_platform:
                    User_OperatingSystem + " (" + User_Browser + ")",
                  app_version: window.env.REACT_APP_API_APP_VERSION,
                  device_screen_resolution: User_ScreenResolution,
                  device_memory: User_SystemMemory,
                  device_display_hardware: User_VideoCard,
                  device_mac: "",
                  device_system_language: User_SystemLanguage,
                  device_name: User_ComputerName,
                  operation_id: window.env.REACT_APP_LDAP_OPERATION_ID,
                  ldap: window.env.REACT_APP_LDAP === "true",
                },
              });
              const headerObj = {
                "Content-Type": "application/json",
                apiKey: apiKey,
              };
              fetch(
                `${window.env.REACT_APP_API_IP}/ns-core/person/auth/signin`,
                {
                  method: "post",
                  headers: headerObj,
                  body: bodyObj,
                }
              )
                .then((res) => res.json())
                .then((res) => {
                  if (res && res && res.success) {
                    const contactInfo = res.person_auth_signin[0];
                    const profileId =
                      contactInfo.person_profile_info.profile_id;
                    const profileUid =
                      contactInfo.person_profile_info.profile_uid;
                    languages.forEach((element) => {
                      const method = "POST";
                      const body = JSON.stringify({
                        get: {
                          language_id: "",
                          name: "",
                          code: element,
                          status: "active",
                          compact: true,
                          variable_name: true,
                        },
                      });
                      const headers = {
                        "Content-Type": "application/json",
                        apiSession: res.session_id,
                        apiKey,
                      };
                      fetch(
                        `${window.env.REACT_APP_API_IP}/ns-core/config/language/dictionary/translations/get`,
                        {
                          method,
                          headers,
                          body,
                        }
                      )
                        .then((response) => response.json())
                        .then((json) => {
                          i18next.addResourceBundle(
                            element,
                            "translation",
                            json
                          );
                        })
                        .catch((error) => {
                          if (process.env.NODE_ENV === "development")
                            console.log("translationError", error);
                        });
                    });
                    localStorage.setItem("auth_token", res.session_id);
                    window.dispatchEvent(new Event("authToken"));
                    let User_Email = "";
                    let User_MobileNumber = "";
                    if (
                      contactInfo.person_profile_info.contact_type ===
                      "email_address"
                    )
                      User_Email = contactInfo.person_profile_info.value;
                    if (
                      contactInfo.person_profile_info.contact_type ===
                      "tel_mobile"
                    )
                      User_MobileNumber = contactInfo.person_profile_info.value;
                    resolve({
                      profileId: profileId,
                      profileUid: profileUid,
                      firstName: contactInfo.person_profile_info.firstname,
                      lastName: contactInfo.person_profile_info.lastname,
                      userType,
                      apiKey,
                      email: User_Email,
                      mobileNumber: User_MobileNumber,
                      organizations: json.person_auth_validate_contact,
                    });
                  } else {
                    reject(res && res.msg ? res.msg : null);
                  }
                })
                .catch((err) => {
                  if (process.env.NODE_ENV === "development")
                    console.log("err", err);
                  reject(
                    err.response !== undefined
                      ? err.response?.error_description
                      : null
                  );
                });
            }
          } else {
            reject(response?.msg);
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err", err);
          reject(
            err.response !== undefined ? err.response?.error_description : null
          );
        });
    });
  }

  setSession(access_token) {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  }

  logout() {
    this.setSession(null);
    this.emit("onLogout", "Logged out");
  }

  getAccessToken() {
    return window.localStorage.getItem("jwt_access_token");
  }
}

const instance = new AuthService();

export default instance;
