import Utils from "../utils/utils";

class ProjectService extends Utils.EventEmitter {
  GetProjectSummary(project_profile_id, project_profile_name, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          project_profile_name: project_profile_name,
          project_profile_id: project_profile_id,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/project/summary/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  GetProjectProfile(operation_id, project_id, app_id, name, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_id: operation_id,
          project_profile_id: project_id,
          app_id: app_id,
          name: name,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/project/profile/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  GetProjectGroup(task_group_id, project_id, app_id, name, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          project_profile_id: project_id,
          task_group_id: task_group_id,
          app_id: app_id,
          name: name,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/project/task/group/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  GetProjectTask(
    app_id,
    project_profile_id,
    task_group_id,
    task_id,
    name,
    status,
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          app_id: app_id,
          project_profile_id: project_profile_id,
          task_group_id: task_group_id,
          task_id: task_id,
          name: name,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/project/task/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  GetTaskParam(param_id, task_id, name, input_id, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          task_id: task_id,
          task_param_id: param_id,
          input_requirement_uid: input_id,
          name: name,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/project/task/param/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  GetParamAttr(attr_id, param_id, name, input_id, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          param_attribute_id: attr_id,
          task_param_id: param_id,
          input_requirement_uid: input_id,
          name: name,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/project/task/param/attribute/get`,
        {
          method: "POST",
          headers,
          body,
        }
      )
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  GetParamVal(value_id, param_id, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          param_value_id: value_id,
          task_param_id: param_id,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/project/task/param/value/get`,
        {
          method: "POST",
          headers,
          body,
        }
      )
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  GetGroupComment(group_id, dst_from, dst_to, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          comment_task_group_id: "",
          task_group_id: group_id,
          subject: "",
          status: status,
          updated_timestamp: {
            f: dst_from,
            t: dst_to,
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/project/comment/task/group/get`,
        {
          method: "POST",
          headers,
          body,
        }
      )
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  SetProjectProfile(
    operation_id,
    project_id,
    app_id,
    name,
    description,
    status,
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const url = `${window.env.REACT_APP_API_IP}/ns-core/project/profile/post`;
      const body = JSON.stringify({
        post: [
          {
            operation_id: operation_id,
            project_profile_id: project_id,
            app_id: app_id,
            name: name,
            description: description,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(url, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((err) =>
          reject(err.response ? err.response.data.error_description : null)
        );
    });
  }

  SetProjectGroup(
    task_group_id,
    project_id,
    app_id,
    name,
    description,
    status,
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            app_id: app_id,
            project_profile_id: project_id,
            task_group_id: task_group_id,
            name: name,
            description: description,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/project/task/group/post`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  SetProjectTask(
    app_id,
    project_profile_id,
    task_group_id,
    task_id,
    name,
    description,
    status,
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            app_id: app_id,
            project_profile_id: project_profile_id,
            task_group_id: task_group_id,
            task_id: task_id,
            name: name,
            description: description,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/project/task/post`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  SetTaskParam(
    param_id,
    task_id,
    name,
    description,
    input_id,
    created_timestamp,
    status,
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            task_param_id: param_id,
            task_id: task_id,
            name: name,
            description: description,
            input_requirement_uid: input_id,
            created_timestamp: created_timestamp,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/project/task/param/post`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  SetParamAttr(attr_id, param_id, name, description, input_id, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            param_attribute_id: attr_id,
            task_param_id: param_id,
            name: name,
            description: description,
            input_requirement_uid: input_id,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/project/task/param/attribute/post`,
        {
          method: "POST",
          headers,
          body,
        }
      )
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  SetParamVal(value_id, param_id, value, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            param_value_id: value_id,
            task_param_id: param_id,
            value: value,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/project/task/param/value/post`,
        {
          method: "POST",
          headers,
          body,
        }
      )
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }

  SetGroupComment(group_id, comment, status, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            comment_task_group_id: "",
            task_group_id: group_id,
            subject: "",
            comment: comment,
            status: status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/project/comment/task/group/post`,
        {
          method: "POST",
          headers,
          body,
        }
      )
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Received non-JSON response");
          }
        })
        .then((json) => resolve(json))
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response ? err.response.data.error_description : err.message
          );
        });
    });
  }
}

const instance = new ProjectService();
export default instance;
