import React from "react";
import {TableRow, TableCell, Button} from "@mui/material";
import StyledTypography from "../common/StyledTypography";
import PropTypes from "prop-types";
import AssignButton from "../common/assignButton";

export default function LdapGroupTableRow({row, isSelected, onSelectGroup}) {
    return (
        <TableRow hover selected={isSelected} sx={{cursor: "pointer"}}>
            <TableCell>
                <StyledTypography>{row.name}</StyledTypography>
            </TableCell>
            <TableCell>
                <StyledTypography>{row.ldap_server_id}</StyledTypography>
            </TableCell>
            <TableCell>
                <StyledTypography>{row.ldap_group_id}</StyledTypography>
            </TableCell>
            <TableCell>
                <StyledTypography>{row.operation_id}</StyledTypography>
            </TableCell>
            <TableCell>
                <Button className="viewUser" hidden onClick={onSelectGroup}>
                    <AssignButton type="add" title="Assign" text="Assign"></AssignButton>
                </Button>
            </TableCell>
        </TableRow>
    );
}

LdapGroupTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    onSelectGroup: PropTypes.func.isRequired,
    isSelected: PropTypes.bool
};
