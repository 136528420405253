import React from "react";
import { Typography, Grid, Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import Hexagons from "../common/hexagon";
import PropTypes from "prop-types";

const themeColor = "#F47721";
function PublicPage() {
  const theme = useTheme();
  const StyledTypography = (props) => (
    <Typography
      variant="h5"
      align="center"
      color={theme?.palette?.custom?.borderColor || themeColor}
      sx={{ marginTop: "5rem" }}
    >
      {props.text}
    </Typography>
  );
  StyledTypography.propTypes = {
    text: PropTypes.string,
  };
  return (
    <Box>
      <img
        style={{ width: "100%" }}
        src={`${window.env.REACT_APP_PUBLIC_URL}/public_background.jpg`}
        alt="logo"
      />
      <Box
        sx={{
          margin: "2rem",
          display: "flex",
          alignItems: "stretch",
          gap: "1rem",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <img
            style={{ width: "150px" }}
            src={`${window.env.REACT_APP_PUBLIC_URL}/logo_nerospec_core.png`}
            alt="logo"
          />
          <Typography variant="h4">
            Short Interval Monitoring And Control
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            SIMAC is a state-of-the-art software package that allows operators
            and managers alike to access in-depth hover views and insights via
            dashboards that are expertly tailored to a specific mine.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            href="about"
          >
            LEARN MORE
          </Button>
        </Box>
        <Box sx={{ width: "50%" }}>
          <img
            // style={{  width: "150px" }}
            src={`${window.env.REACT_APP_PUBLIC_URL}/MachineType_Side_LHD.svg`}
            alt="logo"
          />
        </Box>
      </Box>

      <Box
        sx={{
          margin: "2rem",
          padding: "2rem",
          border: `${
            theme?.palette?.custom?.borderColor || themeColor
          } solid 1px`,
          borderRadius: "1rem",
        }}
      >
        <Typography variant="h4" align="center" color="primary">
          <b>USE CASES</b>
        </Typography>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={3}>
            <Hexagons style={{ width: "150px", height: "250px" }}>
              <VerifiedUserOutlinedIcon
                sx={{
                  width: "85px",
                  height: "85px",
                  color: theme?.palette?.custom?.borderColor || themeColor,
                }}
              />
            </Hexagons>
            <StyledTypography text="SAFETY" />
          </Grid>
          <Grid item xs={3}>
            <Hexagons style={{ width: "150px", height: "250px" }}>
              <AnalyticsOutlinedIcon
                sx={{
                  width: "85px",
                  height: "85px",
                  color: theme?.palette?.custom?.borderColor || themeColor,
                }}
              />
            </Hexagons>
            <StyledTypography text="PRODUCTIVITY" />
          </Grid>
          <Grid item xs={3}>
            <Hexagons style={{ width: "150px", height: "250px" }}>
              <SettingsOutlinedIcon
                sx={{
                  width: "85px",
                  height: "85px",
                  color: theme?.palette?.custom?.borderColor || themeColor,
                }}
              />
            </Hexagons>
            <StyledTypography text="MAINTENANCE" />
          </Grid>
          <Grid item xs={3}>
            <Hexagons style={{ width: "150px", height: "250px" }}>
              <EqualizerOutlinedIcon
                sx={{
                  width: "85px",
                  height: "85px",
                  color: theme?.palette?.custom?.borderColor || themeColor,
                }}
              />
            </Hexagons>
            <StyledTypography text="MONITORING" />
          </Grid>
        </Grid>
      </Box>
      <img
        style={{ width: "100%" }}
        src={`${window.env.REACT_APP_PUBLIC_URL}/simac_devices.png`}
        alt="logo"
      />
      <Box
        sx={{
          margin: "2rem",
          display: "flex",
          alignItems: "stretch",
          gap: "1rem",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <img
            // style={{  width: "150px" }}
            src={`${window.env.REACT_APP_PUBLIC_URL}/simac_laptop.svg`}
            alt="logo"
          />
        </Box>
        <Box sx={{ width: "50%" }}>
          <Typography variant="h4">SIMAC</Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            SIMAC provides critical operational insights that can be constantly
            optimized to increase productivity, unlocking the measurable
            benefits of Short Interval Control. With Short Interval Control
            integrated into the SIMAC AI, managers and operators are able to
            view machine statistics in real-time.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            href="about"
          >
            LEARN MORE
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "2rem",
          display: "flex",
          alignItems: "stretch",
          gap: "1rem",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Typography variant="h4">DASHBOARDS</Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Managers and overseers can control what viewing privileges other
            staff members have regarding the dashboard, and they can customize
            their own dashboard to maximize the impact of the data and
            statistics they are surveying.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            The customization of this pivotal information will empower mines as
            no other tech has before; with the SIMAC AI, the software will
            deliver proactive insights, allowing mines to mitigate machine
            maintenance and preventing scenarios that can stagger or halt
            production.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            href="about"
          >
            LEARN MORE
          </Button>
        </Box>
        <Box sx={{ width: "50%" }}>
          <img
            style={{ width: "100%" }}
            src={`${window.env.REACT_APP_PUBLIC_URL}/SIMAC-mockup.png`}
            alt="logo"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default PublicPage;
