import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ProfilePictureSelect from "../common/ProfileSelect/button";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import ProfileService from "../api/profile";
import OrganizationService from "../api/organization";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
// import OrganizationsMenu from "../common/Layout/organizationsMenu";
import OrganizationsInput from "../common/Layout/organizationsInput";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../common/CustomTextField";
import SelectFields from "../common/selectFields";
import { showMessage } from "../store/messageSlice";
import { validateEmail } from "../utils/systemInfo";
import FakeUser from "../FakeUser.png";

export default function CreateUser() {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState();
  const [userTypes, setUserTypes] = useState([]);
  const [name, setName] = useState("");
  const [lName, setLName] = useState("");
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [profileSaved, setProfileSaved] = useState(false);
  const [passwordSaved, setPasswordSaved] = useState(false);
  const [organizationSaved, setOrganizationSaved] = useState(false);
  const [pictureSaved, setPictureSaved] = useState(false);
  const [mobileSaved, setMobileSaved] = useState(false);
  const [emailSaved, setEmailSaved] = useState(false);
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const apiKey = organziation?.api_key_list?.[0]?.key;
  const [emailError, setEmailError] = useState(false);
  function handleCancel() {
    navigate("/users");
  }

  useEffect(() => {
    ProfileService.GetUserType({
      user_type_uid: "",
      name: "",
      status: "active",
      apiKey: apiKey,
    }).then((json) => {
      if (json.app_user_type !== undefined && json.app_user_type.length > 0) {
        let userTypesJson = json.app_user_type;
        let userTypesArr = [];
        for (let i in userTypesJson) {
          userTypesArr.push({
            value: userTypesJson[i].user_type_uid,
            label: userTypesJson[i].name,
          });
          setUserTypes(userTypesArr);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    setSaveLoading(true);
    ProfileService.SetUserProfile("", name, lName, "active", userType)
      .then((jsonPf) => {
        if (jsonPf?.success && jsonPf?.count >= 1) {
          setProfileSaved(true);
          const ppID = jsonPf.person_profile[0].profile_id;
          const ppUID = jsonPf.person_profile[0].profile_uid;
          ProfileService.SetUserPassword("", ppID, pass)
            .then((jsonPass) => {
              if (jsonPass?.count >= 1) setPasswordSaved(true);
            })
            .catch(() => {
              showErrorMessage();
              setSaveLoading(false);
            });
          if (document.getElementById("file").files.length > 0) {
            ProfileService.SetUserProfilePicture(ppID, "pfpForm", apiKey).then(
              (json) => {
                if (json?.count >= 1) setPictureSaved(true);
              }
            );
          } else {
            setPictureSaved(true);
          }

          ProfileService.SetUserContact({
            contact_uid: "",
            person_profile_uid: ppUID,
            contact_type: "tel_mobile",
            value: mobile,
          })
            .then((jsonMobile) => {
              if (jsonMobile?.count >= 1) {
                setMobileSaved(true);
              } else {
                showErrorMessage();
                setSaveLoading(false);
              }
            })
            .catch(() => {
              showErrorMessage();
              setSaveLoading(false);
            });
          ProfileService.SetUserContact({
            contact_uid: "",
            person_profile_uid: ppUID,
            contact_type: "email_address",
            value: email,
          })
            .then((jsonEmail) => {
              if (jsonEmail?.count >= 1) {
                setEmailSaved(true);
              } else {
                showErrorMessage();
                setSaveLoading(false);
              }
            })
            .catch(() => {
              showErrorMessage();
              setSaveLoading(false);
            });
          if (userType !== 0 && organziation) {
            OrganizationService.SetOperationPersonLink(
              {
                operation_id: organziation.uid,
                person_id: ppID,
                user_type_uid: userType,
                auto_assign_ticket: "0",
                status: "link",
              },
              apiKey
            ).then((json) => {
              if (json) {
                setOrganizationSaved(true);
              }
            });
          }
        }
      })
      .catch(() => {
        showErrorMessage();
        setSaveLoading(false);
      });
  };
  function showErrorMessage() {
    dispatch(
      showMessage({
        message: t("error occurred"), //text or html
        autoHideDuration: 10000, //ms
        anchorOrigin: {
          vertical: "top", //top bottom
          horizontal: "center", //left center right
        },
        variant: "error", //success error info warning null
      })
    );
  }
  const theme = useTheme();
  const { t } = useTranslation();
  const handleUserTypeChange = (value) => {
    setUserType(value);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  // function handleClick() {
  //   return (
  //     <OrganizationsMenu
  //       sx={{
  //         width: { xs: "100%", sm: "100%%" },
  //         borderColor: theme.palette.custom.borderColor,
  //         borderRadius: "11px",
  //         backgroundColor: theme.palette.custom.light,
  //       }}
  //     />
  //   );
  // }
  useEffect(() => {
    if (
      profileSaved &&
      passwordSaved &&
      organizationSaved &&
      pictureSaved &&
      emailSaved &&
      mobileSaved
    ) {
      dispatch(
        showMessage({
          message: t("successfully saved"), //text or html
          autoHideDuration: 30000, //ms
          anchorOrigin: {
            vertical: "top", //top bottom
            horizontal: "center", //left center right
          },
          variant: "success", //success error info warning null
        })
      );
      setSaveLoading(false);

      setProfileSaved(false);
      setPasswordSaved(false);
      setOrganizationSaved(false);
      setPictureSaved(false);
      setMobileSaved(false);
      setEmailSaved(false);
      navigate("/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileSaved,
    passwordSaved,
    organizationSaved,
    pictureSaved,
    emailSaved,
    mobileSaved,
  ]);
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        height: "100%",
      }}
    >
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.text,
          }}
          variant="h2"
          gutterBottom
        >
          {t("Createuser")}
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        id="profileBox"
        sx={{
          display: { xs: "block", sm: "flex" },
          width: "100%",
          flexDirection: "row",
          color: "#FFFFFF",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
            }}
          >
            <ProfilePictureSelect
              frmID={"pfpForm"}
              layID={"layPfpSelect"}
              divID={"pfpDiv"}
              fileID={"file"}
              header={"Change Profile Picture"}
              label={"Profile Picture"}
              url={FakeUser}
            ></ProfilePictureSelect>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              //gap: "1rem",
              width: "100%",
              marginLeft: ".5rem",
              marginRight: ".5rem",
            }}
          >
            {/* <CustomTextField
              id="txtUser"
              label={t("Username")}
              variant="filled"
              value={user}
              onChange={(e) => {
                setUser(e.target.value);
              }}
              defaultValue="Username"
              sx={{
                width: { xs: "100%", sm: "100%%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            /> */}
            <Box sx={{ height: "80px" }}>
              <CustomTextField
                id="txtFirstName"
                label={t("Firstname")}
                variant="filled"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                inputProps={{ maxLength: 32 }}
                // defaultValue="Firstname"
                sx={{
                  width: { xs: "100%", sm: "100%" },
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "11px",
                  backgroundColor: theme.palette.custom.light,
                }}
              />
            </Box>
            <Box sx={{ height: "80px" }}>
              <CustomTextField
                id="txtLastName"
                label={t("Lastname")}
                variant="filled"
                // defaultValue="Lastname"
                value={lName}
                inputProps={{ maxLength: 32 }}
                onChange={(e) => {
                  setLName(e.target.value);
                }}
                sx={{
                  width: { xs: "100%", sm: "100%%" },
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "11px",
                  backgroundColor: theme.palette.custom.light,
                  marginTop: { xs: "8px", sm: "0px" },
                }}
              />
            </Box>
            <Box sx={{ height: "80px" }}>
              <CustomTextField
                id="txtMobile"
                label={t("Mobile Number")}
                variant="filled"
                // defaultValue="Mobile Number"
                value={mobile}
                type="tel"
                inputProps={{ maxLength: 32 }}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+</InputAdornment>
                  ),
                }}
                sx={{
                  width: { xs: "100%", sm: "100%%" },
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "11px",
                  backgroundColor: theme.palette.custom.light,
                  marginTop: { xs: "8px", sm: "0px" },
                }}
              />
            </Box>
            <OrganizationsInput>
              {/* <CustomTextField
                id="txtOrg"
                label={t("Organization")}
                variant="filled"
                defaultValue="Organization"
                onClick={handleClick}
                sx={{
                  width: { xs: "100%", sm: "100%%" },
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "11px",
                  backgroundColor: theme.palette.custom.light,
                  marginTop: { xs: "8px", sm: "0px" },
                }}
              /> */}
            </OrganizationsInput>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              // gap: "1rem",
              width: "100%",
              marginLeft: ".5rem",
              marginRight: ".5rem",
              alignSelf: "flex-start",
            }}
          >
            <Box sx={{ height: "80px" }}>
              <CustomTextField
                id="txtEmail"
                label={t("Email Address")}
                variant="filled"
                value={email}
                type="email"
                error={email !== "" && emailError}
                helperText={emailError ? t("Email not Valid") : null}
                InputProps={{
                  maxLength: 64,
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <IconButton
                  //       aria-label="toggle password visibility"
                  //       edge="end"
                  //     >
                  //       <EmailIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // ),
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onBlur={() => {
                  const errorEmail = validateEmail(email);
                  if (errorEmail) {
                    // set value displayed in the textbox
                    setEmailError(false);
                  } else {
                    setEmailError(true);
                  }
                }}
                // defaultValue="Email Address"
                sx={{
                  width: { xs: "100%", sm: "100%%" },
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "11px",
                  backgroundColor: theme.palette.custom.light,
                }}
              />
            </Box>
            <Box sx={{ height: "80px" }}>
              <CustomTextField
                id="txtPass"
                label={t("Password")}
                variant="filled"
                type={showPassword ? "text" : "password"}
                // defaultValue="Password"
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: { xs: "100%", sm: "100%%" },
                  borderColor: theme.palette.custom.borderColor,
                  borderRadius: "11px",
                  backgroundColor: theme.palette.custom.light,
                  marginTop: { xs: "8px", sm: "0px" },
                }}
              />
            </Box>
            <Box sx={{ height: "80px" }}>
              <SelectFields
                title={t("User Type")}
                value={userType}
                fields={userTypes}
                onChange={(value) => {
                  handleUserTypeChange(value);
                }}
                sx={{ marginTop: { xs: "8px", sm: "0px" } }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "red",
            color: "white",
            "&:hover": { backgroundColor: "red" },
          }}
          onClick={handleCancel}
        >
          {t("Cancel")}
        </Button>
        <LoadingButton
          loading={saveLoading}
          loadingIndicator={<CircularProgress color="primary" size={16} />}
          type="submit"
          variant="contained"
          sx={{
            mt: 1,
            mb: 1,
            backgroundColor: theme.palette.custom.borderColor,
            color: "white",
            "&:hover": { backgroundColor: theme.palette.custom.borderColor },
          }}
          onClick={() => {
            handleSave(name, lName, pass, email, mobile, userType);
          }}
        >
          {t("CreateUser")}
        </LoadingButton>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
    </Box>
  );
}
