import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Home from "./home";
import HomeGrafanaPage from "./homeGrafanaPage";
import HomeSimacPage from "./homeSimacPage";
import HomeJojoPage from "./homeJojoPage";
import OrganizationService from "../api/organization";
import { UpdateURLQueryStringWithoutReload } from "../utils/systemInfo";

function IndexPage() {
  const [choosePage, setChoosePage] = useState(null);
  const organziation = useSelector(({ organziation }) => organziation);
  useEffect(() => {
    if (organziation) {
      getOrganizationAttributes({
        uid: organziation.uid,
        apiKey: organziation?.api_key_list?.[0]?.key,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);
  function getOrganizationAttributes(orgInfo) {
    OrganizationService.GetOperationProfileAttributes(orgInfo).then(
      (response) => {
        if (response.success && response.count > 0) {
          const homePageType = response?.profile_attribute?.find(
            (a) => a.channel === "homePage_default"
          )?.value;
          switch (homePageType) {
            case "tsebo":
              UpdateURLQueryStringWithoutReload(
                "Dashboard",
                "TseboHomeDashboard"
              );
              UpdateURLQueryStringWithoutReload(
                "DeviceTitleDataName",
                "Device"
              );
              setChoosePage(2);
              break;
            case "nerospec":
              setChoosePage(3);
              break;
            case "jojo":
            case "maximator":
            case "motion hub":
            case "nerospec iot":
            case "checkers":
            case "behrtech":
            case "premisehq saas inc.":
            case "soteria risk solutions":
            case "connected sensors":
            case "ctrack":
            case "deucon":
              setChoosePage(4);
              break;
            case "default":
            default:
              setChoosePage(1);
              break;
          }
        } else {
          setChoosePage(1);
        }
      }
    );
  }
  return (
    <>
      {choosePage === 1 && <Home />}
      {choosePage === 2 && <HomeGrafanaPage />}
      {choosePage === 3 && <HomeSimacPage />}
      {choosePage === 4 && <HomeJojoPage />}
    </>
  );
}

export default IndexPage;
