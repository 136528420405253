import Utils from "../utils/utils";

class OperationService extends Utils.EventEmitter {
  GetOperationProfileSummary(operation_profile_uid) {
    return new Promise((resolve, reject) => {
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/profile/summary/${operation_profile_uid}`
      )
        .then((response) => response.json())
        //axios
        // .post(`${window.env.REACT_APP_API_IP}/ns-core/device/opsum/query`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new OperationService();

export default instance;
