import React, { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import StyledTypography from "../../StyledTypography";
import EditButton from "../../editButton";
import ViewButton from "../../viewButton";
import GroupsButton from "../../groupsButton";
import { Box } from "@mui/material";
import TestConnectionButton from "../../testConnectionButton";
import DeleteButton from "../../deleteButton";
import AssignButton from "../../assignButton";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";


export default function TemplateTableRow({ row, headCells, handleClick, isItemSelected, itemId, handleButton }) {
    const timezoneMins = useSelector(({ timezone }) => timezone);
    const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
};
const handleMenuClose = () => {
    setAnchorEl(null);
};

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    function getValue(headCell) {
        switch (headCell.attributeType) {
            case "fullname":
                return (headCell.attribute || headCell.id)?.split(" ").map(hc => getNestedValue(row, hc)).join(" ")
            // case "boolean":{
            //     const value =getNestedValue(row, (headCell.attribute || headCell.id))
            //     return 
            // }
            case "dateTime":
                return moment.utc(getNestedValue(row, (headCell.attribute || headCell.id)))
                    .add(timezoneMins, "minutes")
                    .format("YYYY-MM-DD HH:mm")
            default:
                return getNestedValue(row, (headCell.attribute || headCell.id))
        }
    }

    const getButtonLabel = (type) => {
        switch (type) {
            case "edit": return "Edit";
            case "assign": return "Assign User Types";
            case "groups": return "View Groups";
            case "connection": return "Test Connection";
            case "delete": return "Delete";
            case "view": default: return "View";
        }
    };
    
    const renderButton = (type) => {
        const commonProps = {
            key: type,
            onClick: (event) => {
                event.stopPropagation();
                handleButton(event, type, row);
            }
        };
    
        switch (type) {
            case "edit": return <EditButton {...commonProps} />;
            case "assign": return <AssignButton title="Assign User Types" type="add" {...commonProps} />;
            case "groups": return <GroupsButton title="View Groups" type="add" {...commonProps} />;
            case "connection": return <TestConnectionButton {...commonProps} />;
            case "delete": return <DeleteButton {...commonProps} />;
            case "view":
            default: return <ViewButton {...commonProps} />;
        }
    };
    
    return (
        <Fragment>
            <TableRow
                hover
                onClick={(event) => handleClick(event, getNestedValue(row, itemId))}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={getNestedValue(row, itemId)}
                selected={isItemSelected}
                sx={{ cursor: "pointer" }}
            >
                {headCells?.map(headCell => (
                    <TableCell key={headCell.id} align="left">
                    {headCell?.id === "action" ? (
                        <>
                            {headCell?.buttons?.length <= 3 ? (
                                <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                                    {headCell.buttons.map((buttonType) => renderButton(buttonType))}
                                </Box>
                            ) : (
                                <>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <IconButton onClick={handleMenuOpen} size="small">
                                            <SettingsIcon />
                                        </IconButton>
                                    </Box>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleMenuClose}
                                        onClick={(e) => e.stopPropagation()}
                                        PaperProps={{
                                            elevation: 3,
                                            sx: { mt: 1.5 }
                                        }}
                                    >
                                        {headCell.buttons.map((buttonType) => (
                                            <MenuItem
                                                key={buttonType}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleButton(event, buttonType, row);
                                                    handleMenuClose();
                                                }}
                                            >
                                                <ListItemIcon sx={{marginRight: "5px"}}>{renderButton(buttonType, true)}</ListItemIcon>
                                                <ListItemText>{getButtonLabel(buttonType)}</ListItemText>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </>
                    ) : (
                        <StyledTypography variant="subtitle2">{getValue(headCell)}</StyledTypography>
                    )}
                </TableCell>
                
                ))}
            </TableRow>
        </Fragment>
    );
}
TemplateTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  headCells: PropTypes.array.isRequired,
  itemId: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  handleButton: PropTypes.func,
  isItemSelected: PropTypes.bool,
};
