import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NamedLocationsService from "../api/namedLocations";
import { useDispatch, useSelector } from "react-redux";
import TemplateTable from "../common/Layout/templatePages/templateTable";
import handleNavigate from "../common/customFunctions/handleNavigate";
import { setNamedLocation } from "../store/namedLocationSlice";
import { useNavigate } from "react-router-dom";

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  height: "36px",
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
  float: "right",
  width: "min-content",
  whiteSpace: "nowrap",
  padding: "1rem",
}));
function NamedLocations() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const [loadingValue, setLoadingValue] = useState(true);
  const [namedLocations, setNamedLocations] = useState([]);

  useEffect(() => {
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;

      NamedLocationsService.GetNamedLocations({
        // id: id,
        apiKey,
      })
        .then((jsonCurrent) => {
          if (jsonCurrent?.config_operation_location_named_lookup?.length) {
            setNamedLocations(
              jsonCurrent?.config_operation_location_named_lookup
            );
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAddNewNamedLocation = (event) => {
    handleNavigate(event, navigate, "/addEditNamedLocation");
  };
  const handleButton = (event, buttonType, row) => {
    if (buttonType === "edit") {
      handleEditNamedLocation(event, row);
    }
  };
  const handleEditNamedLocation = (event, row) => {
    dispatch(setNamedLocation(row));
    event.stopPropagation();
    handleNavigate(
      event,
      navigate,
      `/addEditNamedLocation?id=${row.operation_location_named_lookup_id}`
    );
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        backgroundColor: theme.palette.custom.backgroundColor,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          gap: "1rem",
        }}
      >
        <Typography
          sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.text }}
          variant="h2"
          gutterBottom
        >
          {t("NamedLocations")}
        </Typography>
        <StyledButton variant="contained" onClick={handleAddNewNamedLocation}>
          {t("AddNewNamedLocation")}
        </StyledButton>
      </Box>
      <Divider
        sx={{ mb: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <TemplateTable
        headCells={[
          { id: "name" },
          { id: "description" },
          { id: "lookup" },
          { id: "status" },
          { id: "x" },
          { id: "y" },
          { id: "z" },
          {
            id: "created_timestamp",
            label: "Created",
            attributeType: "dateTime",
          },
          {
            id: "updated_timestamp",
            label: "Updated",
            attributeType: "dateTime",
          },
          {
            id: "action",
            buttons: ["edit"],
          },
        ]}
        handleButton={handleButton}
        itemsList={namedLocations}
        itemId="name"
        loading={loadingValue}
        searchAttributes={["name", "description", "lookup"]}
        allowExport
        exportTitle={t("NamedLocations")}
      />
    </Box>
  );
}

export default NamedLocations;
