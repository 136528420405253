import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

function ViewButton({ onClick, marginRight }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Tooltip title={t("view details")}>
      <Box
        onClick={onClick}
        sx={{
          marginRight: marginRight ?? 0,
          backgroundColor: theme.palette.custom.buttonColor,
          borderRadius: "4px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          // display: "flex",
          // justifyContent: "center",
          width: "46px",
          height: "36px",
          padding: "5px 10px",
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
        }}
      >
        <VisibilityIcon
          sx={{
            color: theme.palette.custom.buttonTextColor,
            fontSize: 24,
            "&:hover": {
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
          }}
        />
      </Box>
    </Tooltip>
  );
}
ViewButton.propTypes = {
  onClick: PropTypes.func,
  marginRight: PropTypes.string,
};

export default ViewButton;
