import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Select, MenuItem, FormControl } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Breadcrumbs from "../common/breadcrumbs";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import OrganizationService from "../api/organization";
import { setMachinesData } from "../store/machinesSlice";
import SearchInput from "../common/searchInput.js";
import DevicesService from "../api/devices";
import MachinesTableRow from "./machinesTableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate, useOutletContext } from "react-router-dom";
import handleNavigate from "../common/customFunctions/handleNavigate.js";
import StyledTypography from "../common/StyledTypography.js";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  GetDateTimeStringToday,
  GetDateTimeString7DaysAgo,
  GetDateTimeString14DaysAgo,
  getComparator,
  stableSort,
} from "../utils/systemInfo";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import Popover from "@mui/material/Popover";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import ColorPicker from "../common/colorPicker";
import PinSVG from "../common/svg/PinSVG.js";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.borderColor,
    width: "10%",
    "&.MuiLoadingButton-loading": {
      backgroundColor: theme.palette.custom.light,
    },
  })
);

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);

const CustomSelect = styled(Select)(({ theme }) => ({
  background: theme.palette.custom.inputBackgroundColor,
  height: "36px",
  width: "100%",
  minWidth: "100px",
  marginLeft: "10px",
  color: theme.palette.custom.text,
  outlineColor: theme.palette.custom.borderColor,
  "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.custom.borderColor,
    color: theme.palette.custom.borderColor,
  },
  "&.Mui-focused": {
    color: theme.palette.custom.text,
  },
  "&:focused": {
    color: theme.palette.custom.borderColor,
  },
  "& .MuiList-root": {
    backgroundColor: theme.palette.custom.inputBackgroundColor,
  },
}));

const headCells = [
  {
    id: "DeviceName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sort: true,
  },
  {
    id: "DeviceSerial",
    numeric: false,
    disablePadding: false,
    label: "Serial",
    sort: true,
  },
  {
    id: "DeviceType",
    numeric: false,
    disablePadding: true,
    label: "MachineType",
    sort: true,
  },
  {
    id: "DeviceOEM",
    numeric: false,
    disablePadding: true,
    label: "oem",
    sort: true,
  },
  {
    id: "NamedLocationOriginal",
    numeric: false,
    disablePadding: false,
    label: "LastLocation",
    sort: true,
  },
  {
    id: "DeviceLastSeen",
    numeric: false,
    disablePadding: false,
    label: "LastSeen",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Site Layout", href: "map" },
];
const pinPaths = {
  generic: [
    "M742.76,1544.49c-99.79-168.24-98.14-334.43,5.57-500.81-25.98-24.74-52.78-50.31-79.59-75.67-18.56-17.53-37.11-34.84-55.46-52.58-35.05-34.02-37.32-84.33-5.15-118.76,32.16-34.64,84.12-35.88,119.79-2.06,46.39,43.92,92.16,88.45,139.17,133.61,75.46-49.28,158.14-74.64,248.45-74.23,90.31.41,171.75,27.42,245.56,76.7,44.95-44.54,89.28-88.45,133.61-132.37,24.33-24.33,52.99-34.64,86.6-24.54,61.24,18.14,80,92.78,34.84,138.97-44.54,45.57-89.89,90.72-136.08,136.9,49.48,74.64,76.7,157.52,76.7,248.24s-26.8,172.99-77.32,249.07c45.15,44.95,89.89,89.48,134.64,134.22,21.03,21.03,30.31,46.39,24.33,76.08-6.39,31.96-25.57,53.61-56.29,63.5-31.55,10.1-60.2,3.09-83.71-20-42.89-42.06-84.95-84.74-127.42-127.21-5.57-5.77-9.48-8.25-18.14-3.09-163.5,98.14-326.18,95.87-487.82-4.95-1.24-.82-2.47-1.24-5.36-2.89-16.49,17.32-33.4,34.84-50.1,52.58-27.22,28.45-53.81,57.52-81.44,85.56-23.71,23.92-53.19,30.51-84.95,20-31.34-10.31-50.1-32.99-55.87-65.57-5.15-29.9,5.57-54.43,26.39-75.67,38.76-40,77.32-80.2,115.87-120.41,4.74-5.36,9.28-10.31,13.2-14.64h0ZM1110.38,1574.8c152.16,3.3,276.08-116.49,279.99-270.92,3.92-153.4-115.67-279.79-267.83-283.09-156.28-3.51-282.88,114.02-286.38,265.77-3.71,161.44,113.61,284.74,274.22,288.24h0Z",
  ],
  home: [
    "M1929.08,1767.1V1489.23c0-19.42,19.82-39.24,39.64-39.24h158.56c19.82,0,39.64,19.82,39.64,39.64V1767.1a39.64,39.64,0,0,0,39.64,39.64h317.11a39.64,39.64,0,0,0,39.64-39.64V1212.15a39.64,39.64,0,0,0-11.57-28.06l-107.35-107.27V815.76a39.64,39.64,0,0,0-39.64-39.64h-79.28a39.63,39.63,0,0,0-39.63,39.64v102.5L2076.06,708.41a39.63,39.63,0,0,0-56-.07l-.07.07-475.68,475.68a39.6,39.6,0,0,0-11.57,28.06V1767.1a39.64,39.64,0,0,0,39.64,39.64h317.11A39.64,39.64,0,0,0,1929.08,1767.1Z",
  ],
  "wi-fi": [
    "M1557.39,1320.57c-30.33,30.33-30.33,79.69,0,110.32,30.33,30.33,79.7,30.33,110.32,0,209.94-209.94,551.6-209.94,761.54,0a77.55,77.55,0,0,0,110,0c30.33-30.33,30.33-79.7,0-110.32C2268.68,1049.67,1828,1049.67,1557.39,1320.57Z",
    "M1767.92,1531.1c-30.33,30.33-30.33,79.69,0,110.32,30.33,30.33,79.7,30.33,110.32,0,93.67-94,246.51-94,340.18,0a77.8,77.8,0,1,0,110-110C2174.12,1376.47,1922.55,1376.47,1767.92,1531.1Z",
    "M2753.07,1106.47c-388.64-388.35-1020.83-388.35-1409.48,0-30.33,30.33-30.33,79.69,0,110.32,30.33,30.33,79.7,30.33,110.32,0,327.69-328,861.15-328,1189.14,0a77.55,77.55,0,0,0,110,0C2783.4,1186.46,2783.4,1137.1,2753.07,1106.47Z",
    "M2048.39,1700.11c-57.34,0-103.78,46.44-103.78,103.78s46.44,103.78,103.78,103.78,103.78-46.44,103.78-103.78S2105.73,1700.11,2048.39,1700.11Z",
  ],
  television: [
    "M2640.8,827.41H1455.13a29.4,29.4,0,0,0-29.4,29.4V1675a29.39,29.39,0,0,0,29.4,29.37H1901v70.51a26.79,26.79,0,0,0,.31,3h-64a27,27,0,0,0,0,53.91h436.05a27,27,0,0,0,0-53.91h-64c.08-1,.31-2,.31-3v-70.51h431.15a29.4,29.4,0,0,0,29.41-29.37V856.81A29.47,29.47,0,0,0,2640.8,827.41Zm-53.89,719.37a29.39,29.39,0,0,1-29.39,29.4H1538.42a29.4,29.4,0,0,1-29.4-29.4V926.37a29.41,29.41,0,0,1,29.4-29.39h1019.1a29.39,29.39,0,0,1,29.39,29.39v620.41Z",
  ],
  "radio frequency": [
    "M1667.05,1318.87a379.23,379.23,0,0,1,111.69-269.82,54.49,54.49,0,0,0-77-77.09c-92.6,92.66-143.6,215.86-143.6,346.91s51,254.25,143.6,346.92a54.5,54.5,0,1,0,77-77.1A379.22,379.22,0,0,1,1667.05,1318.87Z",
    "M1460,1318.87c0-157.26,61.2-305.1,172.32-416.3a54.49,54.49,0,0,0-77-77.09c-131.7,131.79-204.23,307-204.23,493.39s72.53,361.6,204.23,493.4a54.5,54.5,0,1,0,77-77.1C1521.24,1624,1460,1476.13,1460,1318.87Z",
    "M2048.39,1035.41c-156.2,0-283.28,127.16-283.28,283.46s127.08,283.47,283.28,283.47,283.28-127.17,283.28-283.47S2204.59,1035.41,2048.39,1035.41Zm0,457.9c-96.12,0-174.32-78.25-174.32-174.44s78.2-174.44,174.32-174.44,174.33,78.26,174.33,174.44S2144.52,1493.31,2048.39,1493.31Z",
    "M2541.46,825.48a54.49,54.49,0,0,0-77,77.09c111.12,111.2,172.32,259,172.32,416.3s-61.2,305.11-172.32,416.3a54.5,54.5,0,0,0,77,77.1c131.7-131.8,204.24-307,204.24-493.4S2673.16,957.27,2541.46,825.48Z",
    "M2395.08,972a54.49,54.49,0,0,0-77,77.09,381.76,381.76,0,0,1,0,539.64,54.5,54.5,0,0,0,77,77.1c92.6-92.67,143.6-215.87,143.6-346.92S2487.68,1064.62,2395.08,972Z",
  ],
  bluetooth: [
    "M2447.35,1584.17l-328.76-274,328.76-274a64.74,64.74,0,0,0,0-99.47L2058.9,613.05c-42.16-35.14-106.19-5.16-106.19,49.73V1153.9L1739.53,940.71a64.74,64.74,0,0,0-91.56,91.56l277.92,277.93L1648,1588.13a64.74,64.74,0,0,0,91.56,91.56l213.18-213.19v491.12c0,54.89,64,84.87,106.19,49.73l388.45-323.7A64.74,64.74,0,0,0,2447.35,1584.17ZM2082.2,801l222.58,185.48L2082.2,1172Zm0,1018.38v-371l222.58,185.48Z",
  ],
};

function HoverCard({ hoveredPin, machines, theme }) {
  if (!hoveredPin) return null;
  return (
    <Box
      sx={{
        position: "fixed",
        top: 20,
        right: 20,
        width: 300,
        backgroundColor: theme.palette.custom.backgroundColor,
        border: `1px solid ${theme.palette.custom.borderColor}`,
        borderRadius: 2,
        p: 2,
        zIndex: 1300,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          color: theme.palette.custom.text,
        }}
      >
        {hoveredPin.name} ({hoveredPin.type})
      </Typography>
      <Divider sx={{ my: 1 }} />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: theme.palette.custom.text,
                }}
              >
                Machine
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: theme.palette.custom.text,
                }}
              >
                Last Seen
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {machines &&
              machines
                .filter((m) => m?.NamedLocationOriginal === hoveredPin.name)
                .map((machine, i) => (
                  <TableRow key={i}>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        color: theme.palette.custom.text,
                      }}
                    >
                      {machine.DeviceName}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        color: theme.palette.custom.text,
                      }}
                    >
                      {machine.DeviceLastSeen}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

HoverCard.propTypes = {
  hoveredPin: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  machines: PropTypes.arrayOf(PropTypes.object).isRequired,
  theme: PropTypes.object.isRequired,
};

const LoadMapPins = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [mapPins, setMapPins] = useState([]);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [mapDimensions, setMapDimensions] = useState({ width: 0, height: 0 });
  const layMapRef = useRef(null);
  const imgRef = useRef(null);
  const organziation = useSelector(({ organziation }) => organziation);
  const machines = useSelector(({ machines }) => machines);
  const [organizationName, setOrganizationName] = useState("");
  const [opsumInputData, setOpsumInputData] = useState(undefined);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("DeviceLastSeen");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 5
  );
  const [searchText, setSearchText] = useState("");
  const [loadingValue, setLoadingValue] = useState(true);
  const DeviceTitleDataName = "Machine";
  const dispatch = useDispatch();
  const [namedLocation, setNamedLocation] = useState("");
  const [selectedPin, setSelectedPin] = useState(null);
  const { open } = useOutletContext();
  const [hoveredPin, setHoveredPin] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const pinTypes = [...new Set(mapPins?.map((pin) => pin.type))];
  const [genGradient, setGenGradient] = useState({
    start: "#4d4d4d",
    stop: "#b3b3b3",
  });
  const [homeGradient, setHomeGradient] = useState({
    start: "#8E3503",
    stop: "#f47721",
  });
  const [wifiGradient, setWifiGradient] = useState({
    start: "#003366",
    stop: "#3399ff",
  });
  const [tvGradient, setTvGradient] = useState({
    start: "#660066",
    stop: "#cc33ff",
  });
  const [rfGradient, setRfGradient] = useState({
    start: "#ad1010",
    stop: "#ff4444",
  });
  const [btGradient, setBtGradient] = useState({
    start: "#00008b",
    stop: "#00bfff",
  });
  const [savingLoading, setSavingLoading] = useState(false);
  const handleSubmit = () => {
    setSavingLoading(true);

    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
    const orgUID = organziation?.uid;

    if (!apiKey || !orgUID) {
      console.error("Missing API key or Org UID.");
      setSavingLoading(false);
      return;
    }

    const typeColorMap = {
      home: `${homeGradient.start}-${homeGradient.stop}`,
      "wi-fi": `${wifiGradient.start}-${wifiGradient.stop}`,
      television: `${tvGradient.start}-${tvGradient.stop}`,
      "radio frequency": `${rfGradient.start}-${rfGradient.stop}`,
      bluetooth: `${btGradient.start}-${btGradient.stop}`,
      generic: `${genGradient.start}-${genGradient.stop}`,
    };

    const updateRequests = mapPins.map((pin) => {
      const type = pin.type?.toLowerCase() || "generic";
      const typeColor = typeColorMap[type] || typeColorMap["generic"];

      const locationInfo = {
        locationID: pin.operation_location_named_lookup_id,
        x: pin.x,
        y: pin.y,
        orgUID: orgUID,
        type_color: typeColor,
        type: pin.type || "generic",
        apiKey: apiKey,
      };

      return OrganizationService.SetOperationNamedLocation(locationInfo);
    });

    Promise.all(updateRequests)
      .then((responses) => {
        console.log("All locations updated successfully:", responses);
        setSavingLoading(false);
      })
      .catch((error) => {
        console.error("Error updating locations:", error);
        setSavingLoading(false);
      });
  };

  const handleFilterChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleMouseEnter = (pin) => {
    setHoveredPin(pin);
  };

  const handleMouseLeave = () => {
    setHoveredPin(null);
  };

  const updateMapDimensions = useCallback(() => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      const container = layMapRef.current;
      const scaleX = container.clientWidth / naturalWidth;
      const scaleY = container.clientHeight / naturalHeight;
      const scale = Math.min(scaleX, scaleY);

      setMapDimensions({
        width: naturalWidth * scale,
        height: naturalHeight * scale,
      });
    }
  }, []);

  const getGradientColors = (type, typeColor) => {
    if (typeColor) {
      const colors = typeColor.split("-");

      if (colors.length === 1) {
        return { start: colors[0], stop: colors[0] }; // Solid color
      }

      return { start: colors[0], stop: colors[colors.length - 1] }; // First & last colors
    }

    const defaultGradients = {
      home: homeGradient,
      "wi-fi": wifiGradient,
      television: tvGradient,
      "radio frequency": rfGradient,
      bluetooth: btGradient,
      generic: genGradient,
    };

    return defaultGradients[type?.toLowerCase()] || genGradient;
  };

  useEffect(() => {
    updateMapDimensions();
  }, [open, updateMapDimensions]);

  useEffect(() => {
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
    const orgUID = organziation?.uid;

    setOrganizationName(organziation?.full_name);

    let DevicesClass = {
      deviceType: [
        "nerohub",
        "nanohub",
        "cyberhub",
        "stratahub",
        "nerotrax",
        "train",
        "autonomous hub",
      ],
      source: "",
    };

    if (apiKey) {
      OrganizationService.GetOperationNamedLocation({ orgUID, apiKey }).then(
        (json) => {
          console.log(json);
          const fetchedPins = json.config_operation_location_named_lookup;
          setMapPins(fetchedPins);
          fetchedPins.forEach((pin) => {
            if (pin.type_color) {
              const { start, stop } = getGradientColors(
                pin.type,
                pin.type_color
              );

              switch (pin.type?.toLowerCase()) {
                case "home":
                  setHomeGradient({ start, stop });
                  break;
                case "wi-fi":
                  setWifiGradient({ start, stop });
                  break;
                case "television":
                  setTvGradient({ start, stop });
                  break;
                case "radio frequency":
                  setRfGradient({ start, stop });
                  break;
                case "bluetooth":
                  setBtGradient({ start, stop });
                  break;
                default:
                  setGenGradient({ start, stop });
                  break;
              }
            }
          });

          setOpsumInputData({
            json: [],
            machineTypeFilter: "",
            machineType: DeviceTitleDataName || "machine",
          });

          dispatch(
            setMachinesData({
              json: [],
              machineTypeFilter: "",
              machineType: DeviceTitleDataName || "machine",
            })
          );

          DevicesService.getDevices({
            deviceID: "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            apiKey,
            historyFromDST: "",
            historyToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                setOpsumInputData({
                  json: json.device_profile,
                  machineTypeFilter: "",
                  machineType: DeviceTitleDataName || "machine",
                });
                dispatch(
                  setMachinesData({
                    json: json.device_profile,
                    machineTypeFilter: "",
                    machineType: DeviceTitleDataName || "machine",
                  })
                );
              }
              setLoadingValue(false);
            })
            .catch((error) => {
              if (error?.name === "AbortError") {
                console.log("Fetch aborted");
              } else if (process.env.NODE_ENV === "development") {
                console.log("getUserTypePermissionserrorr", error);
              }
            });
        }
      );
    }

    const handleResize = () => {
      updateMapDimensions();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMapDimensions, organziation, dispatch]);

  const handleOpen = (event, pin) => {
    event.stopPropagation();
    setSelectedPin({
      anchor: event.currentTarget,
      pin: pin,
    });
    setNamedLocation(pin.name);
  };

  const handleClose = () => {
    setSelectedPin(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };

  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let opsumInputObj = opsumInputData;
    opsumInputObj.machineTypeFilter = "";
    opsumInputObj.newSearchText = newSearchText;
    dispatch(setMachinesData(opsumInputObj));
    setLoadingValue(false);
  }
  const handleArrowIcon = (newValue, OldValue, reverse, fontSize) => {
    if (newValue > OldValue) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue < OldValue) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };

  function handleView(event, row) {
    switch (row.DeviceTitleDataName) {
      case "Device":
        handleNavigate(
          event,
          navigate,
          `/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}`
        );
        break;
      case "Machine":
      default:
        handleNavigate(
          event,
          navigate,
          `/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}`
        );
        break;
    }
  }

  const visibleRows = useMemo(() => {
    if (machines && machines.length > 0) {
      return stableSort(
        machines.filter((m) => m?.NamedLocationOriginal === namedLocation),
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    } else {
      return [];
    }
  }, [order, orderBy, page, rowsPerPage, machines, namedLocation]);

  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        minHeight: "90vh",
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <Typography
                sx={{
                  fontSize: "45px",
                  mb: 0,
                  color: theme.palette.custom.text,
                }}
                variant="h2"
                gutterBottom
              >
                {t("Site Layout (Map)")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <FormControl sx={{ minWidth: 200, mb: 2, mr: "12px" }}>
          <CustomSelect
            value={selectedType}
            onChange={handleFilterChange}
            displayEmpty
          >
            <MenuItem value="">All</MenuItem>
            {pinTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      {mapPins.length === 0 && (
        <Typography
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "24px",
            color: theme.palette.custom.text,
            textAlign: "center",
          }}
        >
          {`${organizationName} doesn't have a Site Layout (Map).`}
        </Typography>
      )}
      <div
        ref={layMapRef}
        style={{
          position: "relative",
          width: "100%",
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          ref={imgRef}
          src={`/images/maps/${organizationName
            .toLowerCase()
            .replace(/ /g, "_")}_map.png`}
          alt="Site Map"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          }}
          onLoad={updateMapDimensions}
        />

        {mapPins
          .filter((pin) => !selectedType || pin.type === selectedType)
          .map((pin, index) => {
            if (!layMapRef.current || !imgRef.current) return null;

            const container = layMapRef.current;
            const containerWidth = container.clientWidth;
            const containerHeight = container.clientHeight;
            const imgWidth = imgRef.current.clientWidth;
            const imgHeight = imgRef.current.clientHeight;

            if (
              !containerWidth ||
              !containerHeight ||
              !imgWidth ||
              !imgHeight
            ) {
              console.warn("Skipping pin placement, missing dimensions");
              return null;
            }

            const imgLeft = (containerWidth - imgWidth) / 2;
            const imgTop = (containerHeight - imgHeight) / 2;
            const xPos = imgLeft + pin.x * imgWidth - 20;
            const yPos = imgTop + pin.y * imgHeight - 71;

            const type = pin.type?.toLowerCase() || "generic";
            const gradient =
              type === "home"
                ? homeGradient
                : type === "wi-fi"
                ? wifiGradient
                : type === "television"
                ? tvGradient
                : type === "radio frequency"
                ? rfGradient
                : type === "bluetooth"
                ? btGradient
                : genGradient;
            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  left: `${xPos}px`,
                  top: `${yPos}px`,
                  width: "31px",
                  height: "auto",
                  cursor: "pointer",
                  pointerEvents: "auto",
                  transform: "none",
                }}
                title={`${pin.name} (${pin.type})`}
                name={pin.name}
                onMouseEnter={() => handleMouseEnter(pin)}
                onMouseLeave={handleMouseLeave}
                onClick={(event) => handleOpen(event, pin)}
              >
                <PinSVG
                  gradientStart={gradient.start}
                  gradientStop={gradient.stop}
                  pinPaths={pinPaths[type] || pinPaths.generic}
                  type={type}
                />
              </div>
            );
          })}

        <HoverCard hoveredPin={hoveredPin} machines={machines} theme={theme} />
        <Popover
          id="color-picker-popover"
          open={Boolean(selectedPin)}
          anchorEl={selectedPin?.anchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {loadingValue ? (
            <Box sx={{ height: "30vh", pt: "10%" }}>
              <Loading />
            </Box>
          ) : (
            <Box
              sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`,
              }}
            >
              <Typography
                sx={{
                  fontSize: "32px",
                  mb: 0,
                  m: "5px",
                  color: theme.palette.custom.text,
                }}
                variant="h2"
                gutterBottom
              >
                {`${selectedPin?.pin?.name} ${selectedPin?.pin?.identifier} (${selectedPin?.pin?.type})`}
              </Typography>
              <Divider
                sx={{
                  my: "10px",
                  backgroundColor: theme.palette.custom.borderColor,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <SearchInput
                  searchText={searchText}
                  handleSearch={handleSearch}
                />
              </Box>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    headCells={headCells.filter(
                      (hc) =>
                        hc.id !== "NamedLocationOriginal" ||
                        visibleRows.find(
                          (vr) =>
                            vr.NamedLocationOriginal &&
                            vr.NamedLocationOriginal !== "Unknown"
                        )
                    )}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {machines && machines.length > 0 && visibleRows.length ? (
                      visibleRows.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <MachinesTableRow
                            key={index}
                            row={row}
                            handleArrowIcon={handleArrowIcon}
                            dateFromBefore={GetDateTimeString14DaysAgo()}
                            dateFrom={GetDateTimeString7DaysAgo()}
                            dateto={GetDateTimeStringToday()}
                            labelId={labelId}
                            handleView={handleView}
                            showNamedLocationOriginal={true}
                          />
                        );
                      })
                    ) : (
                      <StyledTypography>
                        {t("No entries found")}
                      </StyledTypography>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <StyledTablePagination
            SelectProps={{
              inputProps: {
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.secondary.dark,
                      color: theme.palette.custom.text,
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: theme.palette.custom.borderColor,
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "blue",
                      },
                      "& .MuiMenuItem-root.Mui-selected:hover": {
                        backgroundColor: "blue",
                      },
                    },
                  },
                },
              },
            }}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            component="div"
            count={
              machines?.filter(
                (m) => m?.NamedLocationOriginal === namedLocation
              )?.length ?? 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Popover>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          gap: "3rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <ColorPicker
            initialColor={genGradient.start}
            label="Generic Gradient Start"
            onColorChange={(color) =>
              setGenGradient((prev) => ({ ...prev, start: color }))
            }
          />
          <ColorPicker
            initialColor={genGradient.stop}
            label="Generic Gradient Stop"
            onColorChange={(color) =>
              setGenGradient((prev) => ({ ...prev, stop: color }))
            }
          />
          <ColorPicker
            initialColor={homeGradient.start}
            label="Home Gradient Start"
            onColorChange={(color) =>
              setHomeGradient((prev) => ({ ...prev, start: color }))
            }
          />
          <ColorPicker
            initialColor={homeGradient.stop}
            label="Home Gradient Stop"
            onColorChange={(color) =>
              setHomeGradient((prev) => ({ ...prev, stop: color }))
            }
          />
          <ColorPicker
            initialColor={wifiGradient.start}
            label="WiFi Gradient Start"
            onColorChange={(color) =>
              setWifiGradient((prev) => ({ ...prev, start: color }))
            }
          />
          <ColorPicker
            initialColor={wifiGradient.stop}
            label="WiFi Gradient Stop"
            onColorChange={(color) =>
              setWifiGradient((prev) => ({ ...prev, stop: color }))
            }
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <ColorPicker
            initialColor={tvGradient.start}
            label="Television Gradient Start"
            onColorChange={(color) =>
              setTvGradient((prev) => ({ ...prev, start: color }))
            }
          />
          <ColorPicker
            initialColor={tvGradient.stop}
            label="Television Gradient Stop"
            onColorChange={(color) =>
              setTvGradient((prev) => ({ ...prev, stop: color }))
            }
          />
          <ColorPicker
            initialColor={rfGradient.start}
            label="Radio Gradient Start"
            onColorChange={(color) =>
              setRfGradient((prev) => ({ ...prev, start: color }))
            }
          />
          <ColorPicker
            initialColor={rfGradient.stop}
            label="Radio Gradient Stop"
            onColorChange={(color) =>
              setRfGradient((prev) => ({ ...prev, stop: color }))
            }
          />
          <ColorPicker
            initialColor={btGradient.start}
            label="Bluetooth Gradient Start"
            onColorChange={(color) =>
              setBtGradient((prev) => ({ ...prev, start: color }))
            }
          />
          <ColorPicker
            initialColor={btGradient.stop}
            label="Bluetooth Gradient Stop"
            onColorChange={(color) =>
              setBtGradient((prev) => ({ ...prev, stop: color }))
            }
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          gap: "3rem",
        }}
      >
        <StyledLoadingButton
          loading={savingLoading}
          loadingIndicator={<CircularProgress color="primary" size={16} />}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          onClick={handleSubmit}
        >
          {t("Save")}
        </StyledLoadingButton>
      </Box>
    </Box>
  );
};

export default LoadMapPins;
