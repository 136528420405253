import Utils from "../utils/utils";
import { JavaScriptDateToDelphiDate } from "../utils/systemInfo";

class DevicesService extends Utils.EventEmitter {
  getDevices(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_profile_id: "",
          device_uid: inputData.DeviceID,
          name: "",
          serial: "",
          device_type: inputData.DeviceType,
          device_type_id: "",
          source: inputData.Source,
          device_oem_operation_profile_uid: "",
          machine_type: "",
          data_summary: inputData.Live ? inputData.Live : false,
          history: {
            output: inputData.History ? inputData.History : false,
            device_dst_from: inputData.HistoryFromDST,
            device_dst_to: inputData.HistoryToDST,
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/profile/get`, {
        method: "post",
        headers,
        body,
        signal: inputData.signal,
      })
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDevicesCount(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_profile_id: "",
          device_uid: inputData.DeviceID,
          name: "",
          serial: "",
          device_type: inputData.DeviceType,
          device_type_id: "",
          source: inputData.Source,
          device_oem_operation_profile_uid: "",
          machine_type: "",
          data_summary: inputData.Live ? inputData.Live : false,
          history: {
            output: inputData.History ? inputData.History : false,
            device_dst_from: inputData.HistoryFromDST,
            device_dst_to: inputData.HistoryToDST,
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          let Organization_Total_Machines = 0;
          if (json.success === true && json.count > 0)
            Organization_Total_Machines = json.count;
          resolve(Organization_Total_Machines);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetDeviceProfile(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            device_profile_id: inputData.device_profile_id,
            device_uid: inputData.device_uid,
            serial: inputData.serial,
            name: inputData.name,
            oem: inputData.oem,
            device_type: inputData.device_type,
            nerohub: inputData.nerohub,
            device_type_id: inputData.device_type_id,
            device_oem_operation_profile_uid:
              inputData.device_oem_operation_profile_uid,
            machine_type: inputData.machine_type,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/profile/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDeviceHistoryLatest(inputData) {
    if (inputData.channels.length === 0) {
      inputData.channels.push("battery_low", "value", "is_visible", "din0"); // Default Channels for nerospec devices
      inputData.channels.push(
        "total_out",
        "total_in",
        "temperature",
        "rssi",
        "period_out",
        "period_in",
        "battery_level"
      ); // Channels for nngen2_people_counter1
    }
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device: inputData.devices,
          channel: inputData.channels,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/iot/history/latest/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetDeviceMuteConfig(inputData) {
    function GetDeviceMuteConfig() {
      let MuteValue = -1;
      let dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + inputData.MuteDays);
      if (inputData.mute_device)
        MuteValue = JavaScriptDateToDelphiDate(dateObj);

      return {
        section_tag: "alerts_basic",
        last_change_dst: JavaScriptDateToDelphiDate(new Date()),
        values: [
          {
            key: "muted_dst",
            typ: 2,
            val: MuteValue,
          },
          {
            key: "tz",
            typ: 1,
            val: -new Date().getTimezoneOffset(),
          },
        ],
      };
    }
    let device_config = [];
    device_config.push(GetDeviceMuteConfig());
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: {
          devices: [
            {
              address: inputData.device_profile_serial,
              config: device_config,
            },
          ],
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/person/device/config/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDeviceConfig(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          devices: [
            {
              device_id: inputData.device_profile_id,
            },
          ],
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/device/config/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetPersonDeviceConfig(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          devices: [
            {
              device_id: inputData.deviceID,
            },
          ],
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/person/device/config/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${window.env.REACT_APP_API_IP}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDeviceStateHistory(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          serial: inputData.serial,
          state: inputData.state,
          person_id: inputData.person_id,
          dst: {
            f: inputData.HistoryFromDST,
            t: inputData.HistoryToDST,
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/state/history/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetDeviceStateHistory(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            device_state_history_id: inputData.device_state_history_id,
            serial: inputData.serial,
            state_type: inputData.state_type,
            state_start: inputData.state_start,
            state_location_id: inputData.state_location_id,
            state_description: inputData.state_description,
            state_result: inputData.state_result,
            state_end: inputData.state_end,
            updated_by_person_id: inputData.updated_by_person_id,
            assigned_by_person_id: inputData.assigned_by_person_id,
            assigned_to_person_id: inputData.assigned_to_person_id,
            device_state_tag_link_action_list:
              inputData.device_state_tag_link_action_list,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/device/state/history/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  getDeviceGroups(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          person_device_group_id: "",
          operation_uid: inputData.operation_uid || "", //"1",
          person_id: inputData.person_id || "", //"3",
          name: "",
          status: "",
          updated_timestamp: {
            f: "",
            t: "",
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/person/device/group/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetDeviceGroup(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            person_device_group_id: inputData.person_device_group_id,
            operation_uid: inputData.operation_uid,
            person_id: inputData.person_id,
            name: inputData.name,
            description: inputData.description,
            physical_address: inputData.physical_address,
            status: inputData.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/person/device/group/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  getDeviceGroupLinkedDevices(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          person_device_group_link_id: "", //empty
          person_device_group_id: inputData.deviceGroupId || "", //"1",
          device_id: "",
          dst: {
            f: "",
            t: "",
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/person/device/group/link/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  LinkDeviceToDeviceGroup(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            person_device_group_link_id: inputData.linkId || "",
            person_device_group_id: inputData.deviceGroupId,
            device_id: inputData.DeviceID,
            status: inputData.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/operation/person/device/group/link/post`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDevicesStateHistory(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_state_history_id: inputData.device_state_history_id,
          serial: inputData.serial,
          state_type: inputData.state_type,
          state_start: inputData.state_start,
          state_location_id: inputData.state_location_id,
          state_description: inputData.state_description,
          state_result: inputData.state_end,
          state_end: inputData.state_type,
          updated_by_person_id: inputData.updated_by_person_id,
          assigned_by_person_id: inputData.assigned_by_person_id,
          assigned_to_person_id: inputData.assigned_to_person_id,
          dst: inputData.dst,
          pagination: inputData.pagination,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/device/state/history/tags/device/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetStateHistoryPerDevice(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_state_history_id: inputData.device_state_history_id,
          serial: inputData.serial,
          state_type: inputData.state_type,
          state_start: inputData.state_start,
          state_location_id: inputData.state_location_id,
          state_description: inputData.state_description,
          state_result: inputData.state_end,
          state_end: inputData.state_type,
          updated_by_person_id: inputData.updated_by_person_id,
          assigned_by_person_id: inputData.assigned_by_person_id,
          assigned_to_person_id: inputData.assigned_to_person_id,
          dst: inputData.dst,
          pagination: inputData.pagination,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${window.env.REACT_APP_API_IP}/ns-core/device/state/history/tags/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetOrgnizationTags(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_state_tag_id: inputData.device_state_tag_id,
          tag_name: inputData.tag_name,
          tag_color: inputData.tag_color,
          operation_id: inputData.operation_id,
          created_by_person_id: inputData.created_by_person_id,
          updated_by_person_id: inputData.updated_by_person_id,
          status: "active",
          dst: inputData.dst,
          pagination: inputData.pagination,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/state/tag/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  setOrgnizationTag(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            device_state_tag_id: inputData.device_state_tag_id,
            tag_name: inputData.name,
            tag_description: inputData.description,
            tag_color: inputData.color,
            operation_id: inputData.operation_id,
            created_by_person_id: inputData.created_by_person_id,
            status: "active",
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/state/tag/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  deleteOrgnizationTag(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        delete: [
          {
            device_state_tag_id: inputData.device_state_tag_id,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${window.env.REACT_APP_API_IP}/ns-core/device/state/tag/delete`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}

const instance = new DevicesService();

export default instance;
