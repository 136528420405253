/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const setUserTypesData = createAsyncThunk("userTypes/setUserTypes", async (inputData) => {
    let userTypes = [];
    if (inputData === null) {
        return null;
    }
    // if (inputData.json&& inputData.json.operation_person_profile_link && inputData.json.operation_person_profile_link.length > 0) {
    // assignUsers = PopulateUsers(inputData.json);
    // }else{
    //     assignUsers = inputData.json
    // }
    userTypes = inputData.json.app_user_type
    if (inputData.newSearchText && inputData.newSearchText.length > 0 && userTypes && userTypes.length > 0) {
        const searchText = inputData.newSearchText.toLowerCase();
        return userTypes.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return userTypes;
});

const initialState = null;

const userTypesSlice = createSlice({
    name: "userTypes",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setUserTypesData.fulfilled, (state, action) => action.payload);
    }
});

export const selectUserTypes= ({userTypes}) => userTypes;

export default userTypesSlice.reducer;
