import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../common/breadcrumbs";

import Loading from "../common/loading";
import StateHistoryComponent from "../common/stateHistoryComponent";
import SearchInput from "../common/searchInput";
import DevicesService from "../api/devices";
import ProfileService from "../api/profile";
import NamedLocationsService from "../api/namedLocations";
import AddEditDeviceStateHistory from "./addEditDeviceStateHistory";
import { getUrlVars } from "../utils/systemInfo";
import { setStateHistory } from "../store/stateHistorySlice";

const initialState = {
  device_state_history_id: "",
  serial: "",
  state_type: "",
  state_description: "",
  state_result: "",
  state_start: "",
  state_end: "",
  state_location_id: "",
  assigned_to_person_id: "",
  status: "active",
};
const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "DeviceStateHistory", href: "deviceStateHistory" },
];
const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  height: "36px",
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
  float: "right",
  width: "min-content",
  whiteSpace: "nowrap",
  padding: "1rem",
}));

function DeviceStateHistoryPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [loadingValue, setLoadingValue] = useState(true);
  const [deviceStateHistoryList, setDeviceStateHistoryList] = useState([]);
  const organziation = useSelector(({ organziation }) => organziation);

  const [usersList, setUsersList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  const deviceSerial = String(getUrlVars()["serial"]);

  useEffect(() => {
    getDeviceStateHistory();
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
    ProfileService.GetUserProfile({
      profile_uid: "",
      profile_id: "",
      app_profile_uid: "",
      firstname: "",
      lastname: "",
      tel_primary: "",
      email_primary: "",
      status: "active",
      apiKey: apiKey,
    })
      .then((json) => {
        if (json && json.person_profile && json.person_profile.length > 0) {
          setUsersList(json?.person_profile);
        }
      })
      .catch(() => {});
    NamedLocationsService.GetNamedLocations({
      // id: id,
      apiKey,
    })
      .then((jsonCurrent) => {
        if (jsonCurrent?.config_operation_location_named_lookup?.length) {
          setLocationsList(jsonCurrent?.config_operation_location_named_lookup);
        }
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === "development")
          console.log("getUserTypePermissionserrorr", errorr);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getDeviceStateHistory() {
    console.log("getTags");
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");

    DevicesService.GetStateHistoryPerDevice({
      // device_state_history_id: "",
      serial: deviceSerial,
      dst: {
        f: "",
        t: "",
      },
      apiKey,
    })
      .then((jsonCurrent) => {
        if (jsonCurrent?.device_state_history?.length) {
          console.log(
            "setDeviceStateHistoryList",
            jsonCurrent?.device_state_history
          );
          setDeviceStateHistoryList(jsonCurrent?.device_state_history);
        }
        setLoadingValue(false);
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === "development")
          console.log("getUserTypePermissionserrorr", errorr);
      });
  }
  const handleAddNewState = () => {
    dispatch(setStateHistory(initialState));
    setDrawerState(true);
    //  handleNavigate(event, navigate, "/addEditNamedLocation");
  };

  function handleSearch(ev) {
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
  }
  function toggleDrawer(open) {
    setDrawerState(open);
  }

  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: `100%`,
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Box sx={{ width: "100%" }}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Typography
              sx={{
                fontSize: "35px",
                mb: 0,
                color: theme.palette.custom.text,
              }}
              variant="h2"
              gutterBottom
            >
              {t("DeviceStateHistory")}
            </Typography>
          </Box>
        </Box>
        <StyledButton variant="contained" onClick={handleAddNewState}>
          {t("AddNewState")}
        </StyledButton>
      </Box>
      <Divider
        sx={{ mb: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <SearchInput
        searchText={searchText}
        handleSearch={handleSearch}
        autoFocus
        sx={{
          width: { xs: "100%", sm: "40%" },
          borderColor: theme.palette.custom.borderColor,
          borderRadius: "11px",
          backgroundColor: theme.palette.custom.light,
        }}
      />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        deviceStateHistoryList.map((row, index) => (
          <>
            <StateHistoryComponent
              key={index}
              usersList={usersList}
              locationsList={locationsList}
              row={row}
              triggerToggleDrawer={toggleDrawer}
            />

            <Divider
              sx={{
                mb: "10px",
                backgroundColor: theme.palette.custom.borderColor,
              }}
            />
          </>
        ))
      )}

      {/* //   <StateHistoryComponent
    //     row={{
    //       type: "broken",
    //       tags: [
    //         { name: "nerohub", color: "red" },
    //         { name: "rfsasd", color: "green" },
    //       ],
    //       description: "we need to do this because it's important",
    //       assignedTo: "Amr Essam",
    //       location: "Salt District Base",
    //     }}
    //   />

    //   <Divider
    //     sx={{ mb: "10px", backgroundColor: theme.palette.custom.borderColor }}
    //   />
    //   <StateHistoryComponent
    //     row={{
    //       type: "done",
    //       tags: [
    //         { name: "nerohub", color: "blue" },
    //         { name: "rfsasd", color: "green" },
    //       ],
    //       description: "we need to do this because it's important",
    //       assignedTo: "Ali Ezz",
    //       location: "Salt District Base",
    //       device_profile: {
    //         device_profile_id: "20201111131601215_KLQHrUeHAg4h43Mmy",
    //         device_uid: 8,
    //         name: "MK36",
    //         device_type: "nerohub",
    //         device_type_id: "20210222115215106_pDAESG8J81sw7XioG",
    //         serial: "262213",
    //         device_oem_operation_profile_uid: "",
    //         machine_type: "MK35",
    //         source: "",
    //         latest_data_dst_s: "2024-11-13",
    //         dst: "2025-03-10 05:29:59.197",
    //         description: "",
    //         log_type: "1",
    //         vehicle_type: "Dump Truck",
    //         oem: "GHH",
    //         dst_last_seen: "2024-11-13 10:48:12 AM",
    //         dst_last_opsum: "2024-11-13 10:00:00 AM",
    //         dst_last_raw: "2025-01-08 3:35:48 PM",
    //         manufacturer_sn: "",
    //         mac_address: "",
    //         status: "",
    //       },
    //     }}
    //   />
    //   <Divider
    //     sx={{ mb: "10px", backgroundColor: theme.palette.custom.borderColor }}
    //   /> */}
      <SwipeableDrawer
        PaperProps={{
          sx: {
            color: theme.palette.custom.text,
            display: { xs: "none", sm: "block" },
          },
        }}
        sx={{
          "& .MuiDrawer-paper": {
            pt: "64px",
            px: "10px",
            boxSizing: "border-box",
            width: { xs: "100%", sm: "50%" },
            color: theme.palette.custom.text,
          },
        }}
        anchor={"right"}
        open={drawerState}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <AddEditDeviceStateHistory
          usersList={usersList}
          locationsList={locationsList}
          triggerToggleDrawer={toggleDrawer}
          refreshStateHistory={getDeviceStateHistory}
        />
      </SwipeableDrawer>
    </Box>
  );
}

export default DeviceStateHistoryPage;
