import React from 'react';
import VibrationIcon from "@mui/icons-material/Vibration";
import { Box, Typography, capitalize } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

function DevicesIconButton({ onClick, disabled }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: theme.palette.custom.backgroundColor,
        borderRadius: "4px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        // display: "flex",
        // justifyContent: "center",
        width: "20%",
        height: "136px",
        padding: "10px 0px",
        textAlign: "center",
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      <VibrationIcon
        sx={{
          color: theme.palette.custom.borderColor,
          fontSize: 64,
        }}
      />
      <Typography
        sx={{
          fontSize: { xs: "1rem", sm: "1.5rem" },
          color: theme.palette.custom.text,
        }}
        variant="h4"
        gutterBottom
      >
        {capitalize(t("Devices"))}
      </Typography>
    </Box>
  );
}
DevicesIconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DevicesIconButton;
