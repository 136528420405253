import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LdapService from "../api/Ldap";
import { getUrlVars } from "../utils/systemInfo";
import TemplateAddEdit from "../common/Layout/templatePages/templateAddEdit";
import { setLdapUser, initialState } from "../store/ldapUserSlice";
import TemplateTable from "../common/Layout/templatePages/templateTable";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "LdapUsers", href: "ldapUsers" },
  { id: 2, name: "View Ldap User", href: "viewLdapUser" },
];
function ViewLdapUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ldapUser = useSelector(({ ldapUser }) => ldapUser);
  const organziation = useSelector(({ organziation }) => organziation);
  const ldapUserId = String(getUrlVars()["id"]);
  const [ldapGroups, setLdapGroups] = useState([]);

  useEffect(() => {
    if (!ldapUser?.ldap_user_id && ldapUserId && ldapUserId !== "undefined") {
      const apiKey =
        organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
      LdapService.GetLdapUsers({
        ldap_user_id: ldapUserId,
        OrganizationId: organziation.uid,
        apiKey,
      })
        .then((jsonCurrent) => {
          if (jsonCurrent?.person_ldap_user?.length) {
            dispatch(setLdapUser(jsonCurrent?.person_ldap_user[0]));
          } else {
            navigate("/ldapUsers");
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("error", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldapUser]);
  useEffect(() => {
    if (ldapUser?.ldap_user_id) {
      setLdapGroups(ldapUser.person_ldap_group);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldapUser]);

  useEffect(() => {
    return () => {
      dispatch(setLdapUser(initialState));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TemplateAddEdit
      breadcrumbs={breadcrumbs}
      title="Ldap User"
      gridCol={2}
      item={ldapUser}
      readOnly
      dataToShow={[
        { key: "name" },
        { key: "user_principal_name", label: "Email" },
      ]}
      onSaveClick={() => {}}
      additionalData={
        ldapUser?.ldap_user_id && (
          <TemplateTable
            subPage
            itemsList={ldapGroups}
            headCells={[
              { id: "name" },
              {
                id: "created_timestamp",
                label: "Created",
                attributeType: "dateTime",
              },
              {
                id: "updated_timestamp",
                label: "Updated",
                attributeType: "dateTime",
              },
              // {
              //   id: "action",
              //   buttons: ["edit", "connection"],
              // },
            ]}
            // handleButton={handleButton}
            itemId="ldap_group_id"
            searchAttributes={["name", "sam_account_name"]}
            title="User Groups"
            // allowExport
            // exportTitle={t("LdapUserUsers")}
          />
        )
      }
    />
  );
}

export default ViewLdapUser;
