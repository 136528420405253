import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import StyledTypography from "../common/StyledTypography";

export default function SignalUploadTableRow({ row, labelId, iKey}) {
    const theme = useTheme();
    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={iKey} sx={{ cursor: "pointer" }}>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text }} component="th" id={labelId} scope="row" padding="none">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row" }}>
                    <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
                        {row.machineType}
                    </StyledTypography>{" "}
                </Box>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.sortIDX}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.signalDescriptorID}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.name}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.eUnit}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.color}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.min}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.max}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.lowThresh}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.highThresh}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.description}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.status}</StyledTypography>
            </TableCell>
        </TableRow>
    );
}
SignalUploadTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    iKey: PropTypes.number,
    labelId: PropTypes.string,
};
