import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DevicesService from "../api/devices";
import { useDispatch, useSelector } from "react-redux";
import TemplateTable from "../common/Layout/templatePages/templateTable";
import handleNavigate from "../common/customFunctions/handleNavigate";
import { setTag } from "../store/tagSlice";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../common/breadcrumbs";
import AlertDialog from "../common/AlertDialog";
import { showMessage } from "../store/messageSlice";
// import { GetDateTimeStringToday } from "../utils/systemInfo";
const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Tags", href: "tags" },
];

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  height: "36px",
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
  float: "right",
  width: "min-content",
  whiteSpace: "nowrap",
  padding: "1rem",
}));
function Tags() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const [loadingValue, setLoadingValue] = useState(true);
  const [tags, setTags] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteObject, setDeleteObject] = useState(false);

  useEffect(() => {
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getTags() {
    console.log("getTags");
    setLoadingValue(true);
    const defaultOrganization = localStorage.getItem("defaultOrganization");
    const orgObj = JSON.parse(defaultOrganization);

    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? orgObj?.api_key_list?.[0]?.key;
    const orgUid = organziation?.uid ?? orgObj?.uid;

    DevicesService.GetOrgnizationTags({
      device_state_tag_id: "",
      operation_id: orgUid,
      dst: {
        f: "",
        t: "",
      },
      apiKey,
    })
      .then((jsonCurrent) => {
        if (jsonCurrent?.device_state_tag?.length) {
          setTags(jsonCurrent?.device_state_tag);
        }
        setLoadingValue(false);
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === "development")
          console.log("getUserTypePermissionserrorr", errorr);
      });
  }
  const handleAddNewTag = (event) => {
    handleNavigate(event, navigate, "/addEditTag");
  };
  const handleButton = (event, buttonType, row) => {
    if (buttonType === "edit") {
      handleEditTag(event, row);
    } else if (buttonType === "delete") {
      handleOpenDeleteDialog(event, row);
    }
  };
  const handleEditTag = (event, row) => {
    dispatch(
      setTag({
        device_state_tag_id: row.device_state_tag_id,
        name: row.tag_name,
        description: row.tag_description,
        color: row.tag_color,
        operation_id: row.operation_id,
        status: row.status,
      })
    );
    event.stopPropagation();
    handleNavigate(
      event,
      navigate,
      `/addEditTag?id=${row.device_state_tag_id}`
    );
  };
  function handleClose() {
    setOpenDialog(false);
  }
  function handleOpenDeleteDialog(event, row) {
    setDeleteObject(row);
    setOpenDialog(true);
  }
  const handleDelete = () => {
    setSaveLoading(true);
    const apiKey =
      organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");

    DevicesService.deleteOrgnizationTag({
      device_state_tag_id: deleteObject.device_state_tag_id,
      apiKey,
    })
      .then(() => {
        getTags();
        dispatch(
          showMessage({
            message: t("deleted Successfully"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "success", //success error info warning null
          })
        );
        setLoadingValue(false);
      })
      .catch((errorr) => {
        dispatch(
          showMessage({
            message: t("error occurred"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
        if (process.env.NODE_ENV === "development")
          console.log("getUserTypePermissionserrorr", errorr);
      });
    setSaveLoading(false);
    setOpenDialog(false);
    setDeleteObject(null);
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        backgroundColor: theme.palette.custom.backgroundColor,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <Typography
                sx={{
                  fontSize: "45px",
                  mb: 0,
                  color: theme.palette.custom.text,
                }}
                variant="h2"
                gutterBottom
              >
                {t("Tags")}
              </Typography>
            </Box>
          </Box>
          <StyledButton variant="contained" onClick={handleAddNewTag}>
            {t("AddNewTag")}
          </StyledButton>
        </Box>
      </Box>
      <Divider
        sx={{ mb: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <TemplateTable
        headCells={[
          { id: "tag_name", label: "name" },
          { id: "tag_description", label: "description" },
          { id: "tag_color", label: "color", attributeType: "color" },
          // { id: "tag_color", label: "createdBy" },
          // { id: "tag_color", label: "updatedBy" },
          {
            id: "created_timestamp",
            label: "Created",
            attributeType: "dateTime",
          },
          {
            id: "updated_timestamp",
            label: "Updated",
            attributeType: "dateTime",
          },
          {
            id: "action",
            buttons: ["edit", "delete"],
          },
        ]}
        handleButton={handleButton}
        itemsList={tags}
        itemId="name"
        loading={loadingValue}
        searchAttributes={[
          "name",
          "description",
          "color",
          "createdBy",
          "updatedBy",
        ]}
        allowExport
        exportTitle={t("Tags")}
      />
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={handleDelete}
        saveLoading={saveLoading}
        dialogTitle={t("Are you sure you want to delete this tag")}
        cancelLabel={t("Cancel")}
        buttonLabel={t("Delete")}
      />
    </Box>
  );
}

export default Tags;
