import React, { useState, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TextField,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import PropTypes from "prop-types";
import EnhancedTableHead from "../common/EnhancedTableHead";
import { getComparator, stableSort } from "../utils/systemInfo";
import UserSigninLoggerCountTableRow from "./userSigninLoggerCountTableRow";
import StyledTypography from "../common/StyledTypography";
import Loading from "../common/loading";
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: `count`,
    numeric: false,
    disablePadding: false,
    label: "LastLoginTime",
    sort: true,
  },
  {
    id: "count",
    numeric: false,
    disablePadding: false,
    label: "Count",
    sort: true,
  },
];
const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
export default function UserSigninLoggerCountTab({
  handleSearch,
  searchText,
  signinLoggerCountList,
  loadingValue,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("count");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );

  const [selected, setSelected] = useState([]);
  const visibleRows = useMemo(
    () => {
      if (signinLoggerCountList && signinLoggerCountList.length > 0) {
        return stableSort(
          signinLoggerCountList,
          getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, signinLoggerCountList]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  return (
    <>
      <TextField
        value={searchText}
        onChange={handleSearch}
        margin="normal"
        fullWidth
        id="search"
        label={t("Search")}
        name="search"
        autoFocus
        sx={{
          width: { xs: "100%", sm: "20%" },
          // borderColor: theme.palette.custom.borderColor,
          //borderRadius: '11px',
          backgroundColor: theme.palette.custom.light,
        }}
      />

      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              hideCheck={true}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {signinLoggerCountList &&
              signinLoggerCountList.length > 0 &&
              visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <UserSigninLoggerCountTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={signinLoggerCountList ? signinLoggerCountList.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
UserSigninLoggerCountTab.propTypes = {
  handleSearch: PropTypes.func,
  searchText: PropTypes.string,
  signinLoggerCountList: PropTypes.array,
  loadingValue: PropTypes.bool,
};
