/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setLdapServer = createAsyncThunk(
  "ldapServer/setLdapServer",
  async (ldapServer) => {
    return ldapServer;
  }
);

export const initialState = {
  ldap_server_id: "",
  target_host: "",
  target_port: "",
  tls: "false",
  bind_user_name: "",
  bind_password: "",
  status: "config",
};

const ldapServerSlice = createSlice({
  name: "ldapServer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setLdapServer.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectLdapServer = ({ ldapServer }) => ldapServer;

export default ldapServerSlice.reducer;
