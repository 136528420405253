import React, { useState, useEffect, useContext } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowRight from "@mui/icons-material/ArrowRight";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LanguageIcon from "@mui/icons-material/Language";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Checkbox, Typography } from "@mui/material";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./DropDown";
import { setUser } from "../../store/userSlice";
import { setOrganization } from "../../store/organziationSlice";
import OrganizationsMenu from "./organizationsMenu";
import NotificationsMenu from "./notificationsMenu";
import { ColorModeContext } from "../../utils/themeService";
import "./toolbar.css";
import ProfileService from "../../api/profile";
import OrganizationService from "../../api/organization";
import { ContainsFileExtension } from "../../utils/systemInfo";
import { setTimezone as setTimezoneMins } from "../../store/timezoneSlice";
import usePrevious from "../cutomHooks/usePrevious";
import handleNavigate from "../customFunctions/handleNavigate";
import PropTypes from "prop-types";

const isEqual = require("lodash.isequal");
const languages = [
  { id: 0, label: "Afrikaans", value: "af" },
  { id: 1, label: "English", value: "en" },
  { id: 2, label: "French", value: "fr" },
  { id: 3, label: "German", value: "de" },
  { id: 4, label: "Italian", value: "it" },
  { id: 5, label: "Klingon", value: "kl" },
  { id: 6, label: "Mongolian", value: "mn" },
  { id: 7, label: "Russian", value: "ru" },
  { id: 8, label: "Spanish", value: "es" },
];
function GetLanguageFlagIcon(code = "") {
  const LanguageFlagIcons = [
    "af",
    "de",
    "en",
    "es",
    "fr",
    "it",
    "kl",
    "mn",
    "nl",
    "ru",
    "tlh",
  ];
  code = code.toLowerCase();
  if (LanguageFlagIcons.includes(code))
    return `${window.env.REACT_APP_PUBLIC_URL}/images/flags/${code}.png`;
  return `${window.env.REACT_APP_PUBLIC_URL}/images/flags/unknown.png`;
}
// const StyledMenu = styled((props) => <Menu  {...props} />)(
//     ({ theme }) => ({
//         "& .MuiPaper-root": {
//             backgroundColor: theme.palette.secondary.dark,
//             color: theme.palette.custom.light
//         }
//     }),
// );
const StyleSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.custom.text,
  width: "100%",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
function ToolbarComponent({ handleDrawerstate }) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const user = useSelector(({ user }) => user);
  const prevUser = usePrevious(user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organziation = useSelector(({ organziation }) => organziation);
  const [open, setOpen] = useState(false);
  const colorMode = useContext(ColorModeContext);
  const [timezone, setTimezone] = useState();
  const [pfpUrl, setPfpUrl] = useState("");

  useEffect(() => {
    if (!isEqual(prevUser, user)) {
      const defaultOrganization = localStorage.getItem("defaultOrganization");
      const orgObj = JSON.parse(defaultOrganization);
      const apiKey = orgObj?.api_key_list?.[0]?.key;
      if (orgObj && apiKey && user?.profileId) {
        ProfileService.GetUserProfileAttributes({
          userProfileID: user?.profileId,
          apiKey: apiKey,
        }).then((response) => {
          if (
            response.success &&
            response.count > 0 &&
            timezones[
              response?.profile_attribute?.find(
                (a) =>
                  a.channel === "timezone_default" &&
                  a.operation_profile_id === organziation?.uid
              )?.value
            ]
          ) {
            setTimezone(
              response?.profile_attribute?.find(
                (a) =>
                  a.channel === "timezone_default" &&
                  a.operation_profile_id === organziation.uid
              ).value
            );
          } else {
            OrganizationService.GetOperationProfileAttributes({
              uid: orgObj.uid,
              apiKey: orgObj.api_key_list?.[0]?.key,
            }).then((response) => {
              if (
                response.success &&
                response.count > 0 &&
                timezones[
                  response?.profile_attribute?.find(
                    (a) => a.channel === "timezone_default"
                  )?.value
                ]
              ) {
                setTimezone(
                  response?.profile_attribute?.find(
                    (a) => a.channel === "timezone_default"
                  )?.value
                );
              } else {
                const now = new Date();
                const offsetMinutes = now.getTimezoneOffset();
                const offsetHours = -offsetMinutes / 60;
                setTimezone(offsetHours);
              }
            });
          }
        });
        ProfileService.GetUserProfilePicture({
          userProfileID: user?.profileId,
          userProfileUID: "",
          status: "active",
          apiKey: apiKey,
        })
          .then((json) => {
            if (json.success) {
              setPfpUrl(
                json.person_profile_image
                  ?.sort(
                    (a, b) =>
                      new Date(b?.updated_timestamp) -
                      new Date(a?.updated_timestamp)
                  )
                  ?.find(
                    (img) =>
                      img.image_type === "profile_image" &&
                      img.status === "active"
                  )?.public_url
              );
            }
          })
          .catch((err) => {
            if (process.env.NODE_ENV === "development")
              console.log("err ", err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    if (timezone) {
      const timezoneMins = timezone * 60;
      dispatch(setTimezoneMins(timezoneMins));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);
  const timezones = {
    "-12": "Pacific/Kwajalein",
    "-11": "Pacific/Samoa",
    "-10": "Pacific/Honolulu",
    "-9": "America/Anchorage",
    "-8": "America/Los_Angeles",
    "-7": "America/Denver",
    "-6": "America/Chicago",
    "-5": "America/New_York",
    "-4": "America/Caracas",
    "-3": "America/Argentina/Buenos_Aires",
    "-2": "Atlantic/Azores",
    "-1": "Atlantic/Cape_Verde",
    0: "UTC",
    1: "Europe/Paris",
    2: "Europe/Berlin",
    3: "Europe/Moscow",
    4: "Asia/Dubai",
    5: "Asia/Karachi",
    6: "Asia/Dhaka",
    7: "Asia/Bangkok",
    8: "Asia/Hong_Kong",
    9: "Asia/Tokyo",
    10: "Australia/Sydney",
    11: "Pacific/Noumea",
    12: "Pacific/Fiji",
  };
  function formatTimezone() {
    const timezoneName = timezones[timezone] || "Unknown";
    const formattedTimezone = `${
      timezone > 0 ? "+" : timezone < 0 ? "-" : ""
    }${timezone.toString().padStart(2, "0")}:00 ${timezoneName}`;
    return formattedTimezone;
  }

  const handleDrawerOpen = () => {
    handleDrawerstate();
    setOpen(true);
  };

  const handleGetFullLogo = () => {
    // let imageurl = "/logo_nerospec_core.png"
    // if (organziation && organziation.operation_image_list && organziation.operation_image_list.length > 0) {
    //     const imageObj = organziation.operation_image_list.find(x => x.image_type === "full_logo")
    //     if (imageObj) {
    //         imageurl = imageObj.public_url
    //     }
    // }
    // return imageurl;
    let imageurl = "header_image_simac.png";
    if (
      organziation &&
      organziation.operation_image_list &&
      organziation.operation_image_list.length > 0
    ) {
      for (
        let index = 0;
        index < organziation.operation_image_list.length;
        index++
      ) {
        if (
          organziation.operation_image_list[index].image_type === "full_logo" &&
          organziation.operation_image_list[index].status === "active"
        )
          if (
            ContainsFileExtension(
              organziation.operation_image_list[index].public_url
            )
          ) {
            imageurl = organziation.operation_image_list[index].public_url;
            break;
          }
        //F imageurl = organziation.operation_image_list[index].public_url;
      }
    }
    return imageurl;
  };
  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
  };
  const handleLogout = () => {
    dispatch(setUser(null));
    dispatch(setOrganization(null));
    localStorage.removeItem("auth_token");
    window.dispatchEvent(new Event("authToken"));
    navigate("/");
  };

  // function UpdateURLQueryStringWithoutReload(key, value, url) {
  //   if (!url) url = window.location.href;
  //   let re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
  //     hash;

  //   if (re.test(url)) {
  //     if (typeof value !== "undefined" && value !== null)
  //       url = url.replace(re, "$1" + key + "=" + value + "$2$3");
  //     else {
  //       hash = url.split("#");
  //       url = hash[0].replace(re, "$1$3").replace(/(&|\?)$/, "");
  //       if (typeof hash[1] !== "undefined" && hash[1] !== null)
  //         url += "#" + hash[1];
  //     }
  //   } else {
  //     if (typeof value !== "undefined" && value !== null) {
  //       const separator = url.indexOf("?") !== -1 ? "&" : "?";
  //       hash = url.split("#");
  //       url = hash[0] + separator + key + "=" + value;
  //       if (typeof hash[1] !== "undefined" && hash[1] !== null)
  //         url += "#" + hash[1];
  //     }
  //   }
  //   window.history.pushState({ path: url }, "", url);
  // }
  return (
    <AppBar
      id="AppBar"
      position="fixed"
      open={open}
      sx={{
        height: "64px",
        backgroundColor: theme.palette.custom.backgroundColor,
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              [theme.breakpoints.down("sm")]: {
                marginRight: 1,
              },
              marginRight: 5,
              color: theme.palette.custom.text,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: theme.palette.custom.text,
              textDecoration: "none",
              width: "100px",
              maxHeight: "50px",
            }}
          >
            <img height="50px" src={handleGetFullLogo()} alt="logo" />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              maxHeight: "50px",
            }}
          >
            <img height="50px" src={handleGetFullLogo()} alt="logo" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />

          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            {timezone !== undefined && (
              <Typography sx={{ display: { xs: "none", md: "flex" } }}>
                {formatTimezone()}
              </Typography>
            )}
            <Tooltip title="Open settings">
              <NotificationsMenu />
            </Tooltip>
            <Tooltip title="Open settings">
              <OrganizationsMenu />
            </Tooltip>
            <Dropdown
              sx={{ color: theme.palette.custom.text }}
              trigger={
                <Button
                  variant="text"
                  startIcon={
                    <Avatar alt={`${user?.firstName?.[0]}`} src={`${pfpUrl}`} />
                  }
                >
                  <StyleSpan>
                    {user ? `${user.firstName} ${user.lastName}` : ""}
                  </StyleSpan>
                </Button>
              }
              menu={[
                <DropdownMenuItem
                  key="DropdownMenuItem"
                  onClick={() => {
                    colorMode.toggleColorMode(
                      localStorage.getItem("color_theme")
                    );
                  }}
                  sx={{ color: theme.palette.custom.text }}
                >
                  <Checkbox
                    checked={localStorage.getItem("color_theme") === "dark"}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  {t("Dark Mode")}
                </DropdownMenuItem>,
                <DropdownNestedMenuItem
                  key="DropdownNestedMenuItem"
                  sx={{ color: theme.palette.custom.text }}
                  rightAnchored
                  label={
                    <>
                      {i18n.language ? (
                        <img
                          style={{
                            width: "22px",
                            height: "22px",
                            overflow: "hidden",
                            borderRadius: "50%",
                            marginRight: "5px",
                          }}
                          src={GetLanguageFlagIcon(i18n.language)}
                          alt="LN"
                        />
                      ) : (
                        <LanguageIcon />
                      )}
                      {t("Language")}
                    </>
                  }
                  rightIcon={<ArrowRight />}
                  menu={[
                    languages.map((_item) => (
                      <DropdownMenuItem
                        key={_item.value}
                        sx={{ color: theme.palette.custom.text }}
                        onClick={() => {
                          handleChangeLanguage(_item.value);
                        }}
                      >
                        <img
                          style={{
                            width: "22px",
                            height: "22px",
                            overflow: "hidden",
                            borderRadius: "50%",
                            marginRight: "5px",
                          }}
                          src={GetLanguageFlagIcon(_item.value)}
                          alt=""
                          id="Language_Flag"
                        />
                        {_item.label}
                      </DropdownMenuItem>
                    )),
                  ]}
                />,
                <DropdownMenuItem
                  key="drop-down"
                  onClick={(event) => {
                    handleNavigate(event, navigate, "/myProfile");
                    // navigate();
                  }}
                  sx={{ color: theme.palette.custom.text }}
                >
                  <PersonOutlineIcon /> {t("Profile")}
                </DropdownMenuItem>,
                <DropdownMenuItem
                  key="drop-down-item"
                  onClick={(event) => {
                    handleNavigate(event, navigate, "/organization");
                    // navigate();
                  }}
                  sx={{ color: theme.palette.custom.text }}
                >
                  <WorkOutlineIcon /> {t("Organization")}
                </DropdownMenuItem>,
                <DropdownNestedMenuItem
                  key="helpdesk"
                  sx={{ color: theme.palette.custom.text }}
                  rightAnchored
                  label={
                    <>
                      <HelpOutlineIcon />
                      {t("Help")}
                    </>
                  }
                  rightIcon={<ArrowRight />}
                  menu={[
                    <DropdownMenuItem
                      key="drop-down-menu-item"
                      onClick={(event) => {
                        handleNavigate(event, navigate, "/about");
                        // navigate();
                      }}
                      sx={{ color: theme.palette.custom.text }}
                    >
                      {t("About")}
                    </DropdownMenuItem>,
                    <DropdownMenuItem
                      key="documentation"
                      onClick={(event) => {
                        handleNavigate(event, navigate, "/documentation");
                        // navigate();
                      }}
                      sx={{ color: theme.palette.custom.text }}
                    >
                      {t("Documentation")}
                    </DropdownMenuItem>,
                    <DropdownMenuItem
                      key="change-log"
                      onClick={(event) => {
                        handleNavigate(event, navigate, "/changelog");
                        // navigate();
                      }}
                      sx={{ color: theme.palette.custom.text }}
                    >
                      {t("Change Log")}
                    </DropdownMenuItem>,
                    <DropdownMenuItem
                      key="contact-us"
                      onClick={(event) => {
                        handleNavigate(event, navigate, "/contactus");
                        // navigate();
                      }}
                      sx={{ color: theme.palette.custom.text }}
                    >
                      {t("Contact Us")}
                    </DropdownMenuItem>,
                  ]}
                />,
                <DropdownMenuItem
                  key="log-out"
                  onClick={handleLogout}
                  sx={{ color: theme.palette.custom.text }}
                >
                  <PowerSettingsNewIcon /> {t("Log Out")}
                </DropdownMenuItem>,
              ]}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
ToolbarComponent.propTypes = {
  handleDrawerstate: PropTypes.func,
};
export default ToolbarComponent;
