import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import personService from "../api/person";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import OrganizationsTableRow from "./organizationsTableRow";
import StyledTypography from "../common/StyledTypography";

import {
  getComparator,
  GetOperationPicturesFromJSON,
  stableSort,
} from "../utils/systemInfo";

function PopulateOrganization(jsonThisWeek) {
  let OpsumArray = [];
  const orgArray = jsonThisWeek.filter(
    (x) => x.app_profile_info.full_name === window.env.REACT_APP_API_APP_NAME
  );
  for (let i in orgArray) {
    const Organization_App_Info = orgArray[i].app_profile_info;
    const Organization_Info = orgArray[i].operation_profile_info;
    let opsumDataObj = {
      id: "",
      name: "",
      emailAddress: "",
      mobileNumber: "",
      website: "",
      iconPublicURL: null,
      logoPublicURL: null,
      backgroundPublicURL: null,
      apiKey: null,
      taxNumber: null,
      vatNumber: null,
    };
    if (Organization_App_Info === undefined || Organization_Info === undefined)
      continue;
    if (Organization_Info.status === "active") {
      const Organization_ApiKeyList = Organization_Info.api_key_list;
      if (Organization_ApiKeyList === undefined) continue;
      const Organization_APIKeyObject = Organization_ApiKeyList.find((item) => {
        return (
          item.status === "active" &&
          new Date(1899, 11, 30 + item.expiry_date) > new Date()
        );
      });
      if (Organization_APIKeyObject !== undefined) {
        opsumDataObj.id = Organization_Info.uid;
        opsumDataObj.name = Organization_Info.full_name;
        opsumDataObj.emailAddress = Organization_Info.email_address;
        opsumDataObj.website = Organization_Info.website_address;
        opsumDataObj.mobileNumber = Organization_Info.tel_primary;
        opsumDataObj.taxNumber = Organization_Info.tax_number;
        opsumDataObj.vatNumber = Organization_Info.vat_number;
        opsumDataObj.apiKey = Organization_APIKeyObject.key;
        const Organization_Image_Array = Organization_Info.operation_image_list;
        const Organization_Images = GetOperationPicturesFromJSON(
          Organization_Image_Array
        );
        opsumDataObj.iconPublicURL = Organization_Images.small_logo;
        opsumDataObj.logoPublicURL = Organization_Images.full_logo;
        opsumDataObj.backgroundPublicURL =
          Organization_Images.web_dashboard_background;
      }
    }

    OpsumArray.push(opsumDataObj);
  }
  return OpsumArray;
}

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const headCells = [
  {
    id: "dropDown",
    numeric: false,
    disablePadding: true,
    label: "",
    sort: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "emailAddress",
    numeric: false,
    disablePadding: false,
    label: "Email Address",
    sort: true,
  },
  {
    id: "mobileNumber",
    numeric: false,
    disablePadding: false,
    label: "MobileNumber",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];

export default function ProfilePageOrganizations(props) {
  const organziation = useSelector(({ organziation }) => organziation);
  const user = useSelector(({ user }) => user);
  const { t } = useTranslation();
  const theme = useTheme();
  const [organizationsList, setOrganizationsList] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [loadingValue, setLoadingValue] = useState(true);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (organziation && props.userProfileId) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      personService
        .GetUserOrganizations({
          operation_uid: "",
          person_id: props.userProfileId,
          apiKey,
        })
        .then((json) => {
          setOrganizationsList(
            json?.person_operation?.[0]?.operation?.map((org) => ({
              id: org.uid,
              name: org.full_name,
              emailAddress: org.email_address,
              mobileNumber: org.tel_primary,
              userType: org.user_type?.name,
            }))
          );
          setLoadingValue(false);
        })
        .catch((errorr) => {
          setLoadingValue(false);
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    } else if (organziation && user && user.organizations) {
      setOrganizationsList(PopulateOrganization(user.organizations));
      setLoadingValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // navigate(`/device?device=${uid}&DeviceTitleDataName=${DeviceTitleDataName}`)
    setSelected(newSelected);
  };
  const visibleRows = useMemo(
    () => {
      if (organizationsList && organizationsList.length > 0) {
        return stableSort(
          organizationsList,
          getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, organizationsList]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //  size={'medium'}
          >
            <EnhancedTableHead
              hideCheck={true}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {organizationsList &&
              organizationsList.length > 0 &&
              visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <OrganizationsTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      isProfile={true}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={
          organizationsList && organizationsList.length > 0
            ? organizationsList.length
            : 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
ProfilePageOrganizations.propTypes = {
  userProfileId: PropTypes.string,
};
