import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NamedLocationsService from "../api/namedLocations";
import { getUrlVars } from "../utils/systemInfo";
import TemplateAddEdit from "../common/Layout/templatePages/templateAddEdit";
import { setNamedLocation } from "../store/namedLocationSlice";

const initialState = {
  operation_location_named_lookup_id: "",
  operation_profile_uid: 0,
  lookup: "",
  name: "",
  x: 0,
  y: 0,
  z: 0,
  description: "",
  status: "",
  created_by_person_id: "",
  updated_by_person_id: "",
  created_timestamp: "",
  updated_timestamp: "",
};

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Named Location Config", href: "namedLocationConfig" },
  { id: 2, name: "Add/Edit Named Location", href: "addEditNamedLocation" },
];
function AddEditNamedLocation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const namedLocation = useSelector(({ namedLocation }) => namedLocation);
  const organziation = useSelector(({ organziation }) => organziation);
  const namedLocationId = String(getUrlVars()["id"]);

  useEffect(() => {
    // console.log("nameeeed", namedLocation, namedLocationId);
    if (
      namedLocation.operation_location_named_lookup_id === "" &&
      namedLocationId
    ) {
      const apiKey =
        organziation?.api_key_list?.[0]?.key ?? localStorage.getItem("apiKey");
      NamedLocationsService.GetNamedLocations({
        id: namedLocationId,
        apiKey,
      })
        .then((jsonCurrent) => {
          if (jsonCurrent?.config_operation_location_named_lookup?.length) {
            dispatch(
              setNamedLocation(
                jsonCurrent?.config_operation_location_named_lookup[0]
              )
            );
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    return () => {
      dispatch(setNamedLocation(initialState));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namedLocation]);

  function handleSave({
    operation_location_named_lookup_id,
    operation_profile_uid,
    name,
    lookup,
    description,
    x,
    y,
    z,
    status,
    api_key_current,
  }) {
    NamedLocationsService.LocationNamedLookupPost({
      operation_location_named_lookup_id,
      operation_profile_uid,
      name,
      lookup,
      description,
      x,
      y,
      z,
      status,
      api_key_current,
    }).then((json) => {
      if (json.success === true && json.count > 0) {
        navigate("/namedLocationConfig");
      }
    });
  }
  return (
    <TemplateAddEdit
      breadcrumbs={breadcrumbs}
      title="Add/Edit Named Location"
      gridCol={2}
      item={namedLocation}
      dataToShow={[
        { key: "name", /*label: "Name",*/ type: "text", isRequired: true },
        { key: "description", type: "text" },
        { key: "lookup", type: "text" },
        { key: "status", type: "text" },
        { key: "x", type: "number" },
        { key: "y", type: "number" },
        { key: "z", type: "number" },
      ]}
      onSaveClick={(item) =>
        handleSave({
          ...item,
          api_key_current: organziation?.api_key_list?.[0]?.key,
        })
      }
    />
  );
}

export default AddEditNamedLocation;
