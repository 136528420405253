import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import ProjectService from "../api/project";
import CustomTextField from "../common/CustomTextField";
import {
  getUrlVars,
  UpdateURLQueryStringWithoutReload,
} from "../utils/systemInfo";
import Breadcrumbs from "../common/breadcrumbs";
import Swal from "sweetalert2";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "MachineStatus", href: "machineStatus" },
  { id: 2, name: "AddMachineDocumentation", href: "addMachineDocs" },
];
const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.borderColor,
    width: "10%",
    "&.MuiLoadingButton-loading": {
      backgroundColor: theme.palette.custom.light,
    },
  })
);

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.custom.borderColor,
  width: "10%",
  color: theme.palette.custom.text,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
function getTimestamp(seconds) {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return timestamp;
}

function AddMachineDocsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [model, setModel] = useState("");
  const [modelParamId, setModelParamId] = useState(undefined);
  const [manufacturer, setManufacturer] = useState("");
  const [manufacturerParamId, setManufacturerParamId] = useState(undefined);
  const [ugId, setUgId] = useState("");
  const [ugIdParamId, setUgIdParamId] = useState(undefined);
  const [type, setType] = useState("");
  const [typeParamId, setTypeParamId] = useState(undefined);
  const [savingLoading, setSavingLoading] = useState(false);
  const organziation = useSelector(({ organziation }) => organziation);

  useEffect(() => {
    setModelParamId(undefined);
    setManufacturerParamId(undefined);
    setTypeParamId(undefined);
    setUgId(undefined);
    const projectId = String(getUrlVars()["projectId"]);
    const groupId = String(getUrlVars()["groupId"]);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    if ((projectId, groupId)) {
      ProjectService.GetProjectProfile(
        organziation.uid,
        "",
        "",
        "Machine Documentation",
        "active",
        apiKey
      )
        .then((json) => {
          if (json.count > 0) {
            ProjectService.GetProjectTask(
              "",
              projectId,
              groupId,
              "",
              "Progress",
              "active",
              apiKey
            )
              .then((taskJson) => {
                if (taskJson.count === 0) {
                  ProjectService.SetProjectTask(
                    "",
                    projectId,
                    groupId,
                    "",
                    "Progress",
                    "",
                    "active",
                    apiKey
                  )
                    .then((setJson) => {
                      if (setJson.count > 0) {
                        UpdateURLQueryStringWithoutReload(
                          "taskId",
                          setJson.project_task[0].task_id
                        );
                      }
                    })
                    .catch((errorr) => {
                      if (process.env.NODE_ENV === "development")
                        console.log("errorr", errorr);
                    });
                } else {
                  ProjectService.GetTaskParam(
                    "",
                    taskJson.project_task[0].task_id,
                    "",
                    "",
                    "active",
                    apiKey
                  )
                    .then((paramJson) => {
                      if (paramJson.count > 0) {
                        paramJson.task_param.forEach((e) => {
                          let name = e.name;
                          let id = e.task_param_id;
                          ProjectService.GetParamVal("", id, "active", apiKey)
                            .then((valJson) => {
                              let value = "";
                              if (valJson.count > 0)
                                value = valJson.param_value[0].value;
                              if (name === "U/G ID Number") setUgId(value);
                              if (name === "Model") setModel(value);
                              if (name === "Manufacturer")
                                setManufacturer(value);
                              if (name === "Equipment Type") setType(value);
                              setModelParamId(id);
                              setManufacturerParamId(id);
                              setTypeParamId(id);
                              setUgIdParamId(id);
                            })
                            .catch((errorr) => {
                              if (process.env.NODE_ENV === "development")
                                console.log("errorr", errorr);
                            });
                        });
                      }
                    })
                    .catch((errorr) => {
                      if (process.env.NODE_ENV === "development")
                        console.log("errorr", errorr);
                    });
                  UpdateURLQueryStringWithoutReload(
                    "taskId",
                    taskJson.project_task[0].task_id
                  );
                }
              })
              .catch((errorr) => {
                if (process.env.NODE_ENV === "development")
                  console.log("errorr", errorr);
              });
          }

          // setName(json.task_group ? json.task_group[0].name : undefined);
          //   navigate(
          //     `/device?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`
          //   );
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("errorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function CreateTasks(task_id, TabName = "Documentation") {
    let Tasks = [];
    const apiKey = organziation?.api_key_list?.[0]?.key;
    // const orgID = document.getElementById("linkOrganization").getAttribute("data-profile-id");
    const orgID = "84";
    switch (orgID) {
      case "18": // Black Rock
        switch (TabName) {
          case "Status":
          default:
            Tasks = [
              ["Milestone 1 MCI Installed (10%)", "16"],
              ["Milestone 2 SBI (50%)", "16"],
              ["Milestone 3 SIMAC (80%)", "16"],
              ["Milestone 4 L9 Activation (100%)", "16"],
              ["Operational Status", "16"],
            ];
            break;

          case "Documentation":
            break;
        }
        break;
      case "65": // Amandelbult and Default
      default:
        switch (TabName) {
          case "Status":
          default:
            Tasks = [
              ["Firmware Updated (30%)", "16"],
              ["Wifi Script (60%)", "16"],
              ["SIMAC Check (100%)", "16"],
            ];
            break;

          case "Documentation":
            break;
        }
        break;
      case "30": // Shaun Roselt
      case "102": // Mototolo
        switch (TabName) {
          case "Status":
          default:
            Tasks = [
              ["Milestone 1 MCI Installed (10%)", "16"],
              ["Milestone 2 SBI (50%)", "16"],
              ["Milestone 3 SIMAC (80%)", "16"],
              ["Milestone 4 L9 Activation (100%)", "16"],
            ];
            break;

          case "Documentation":
            break;
        }
        break;
      case "84": // RioTinto Kennecott Underground Copper
        switch (TabName) {
          case "Status":
          default:
            Tasks = [
              ["Firmware Updated (30%)", "16"],
              ["Wifi Script (60%)", "16"],
              ["SIMAC Check (100%)", "16"],
            ];
            break;

          case "Documentation":
            Tasks = [
              ["U/G ID Number", "16"],
              ["Model", "16"],
              ["Manufacturer", "16"],
              ["Equipment Type", "16"],
              ["Risk", "16"],
              ["Electrical Schematics", "16"],
              ["Hydraulic Schematics", "16"],
              ["Pneumatic Schematics", "16"],
              ["Equipment Manual", "16"],
              ["Workshop Manual", "16"],
              ["Service Manual", "16"],
              ["Control Philosophy", "16"],
              ["Machine Drawings", "16"],
              ["Firmware and Software", "16"],
              ["Installation Methodology", "16"],
              ["Audit Photo", "16"],
              ["Audit Video", "16"],
              ["Audit Documents", "16"],
              ["CAN Data", "16"],
              ["Specifications", "16"],
            ];
            break;
        }
        break;
    }
    Tasks.forEach((task, index) => {
      let value = "0";
      if (task[0] === "U/G ID Number") value = ugId;
      if (task[0] === "Model") value = model;
      if (task[0] === "Manufacturer") value = manufacturer;
      if (task[0] === "Equipment Type") value = type;
      const timestamp = getTimestamp(index);
      ProjectService.SetTaskParam(
        "",
        task_id,
        task[0],
        "",
        task[1],
        timestamp,
        "active",
        apiKey
      )
        .then((jsonParam) => {
          ProjectService.SetParamVal(
            "",
            jsonParam.task_param[0].task_param_id,
            value,
            "active",
            apiKey
          )
            // .then((jsonVal) => {
            // })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    });
  }
  function handleSubmit(event) {
    setSavingLoading(true);
    event.preventDefault();
    const projectId = getUrlVars()["projectId"];
    if (projectId) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      if (ugIdParamId && modelParamId && typeParamId && manufacturerParamId) {
        ProjectService.SetParamVal("", ugIdParamId, ugId, "active", apiKey)
          // .then((jsonParam) => {
          //     // go to other page
          // })
          .catch((errorr) => {
            if (process.env.NODE_ENV === "development")
              console.log("errorr", errorr);
          });
        ProjectService.SetParamVal("", modelParamId, model, "active", apiKey)
          // .then((jsonParam) => {
          //     // go to other page
          // })
          .catch((errorr) => {
            if (process.env.NODE_ENV === "development")
              console.log("errorr", errorr);
          });
        ProjectService.SetParamVal("", typeParamId, type, "active", apiKey)
          // .then((jsonParam) => {
          //     // go to other page
          // })
          .catch((errorr) => {
            if (process.env.NODE_ENV === "development")
              console.log("errorr", errorr);
          });
        ProjectService.SetParamVal(
          "",
          manufacturerParamId,
          manufacturer,
          "active",
          apiKey
        )
          // .then((jsonParam) => {
          //     // go to other page
          // })
          .catch((errorr) => {
            if (process.env.NODE_ENV === "development")
              console.log("errorr", errorr);
          });

        // navigate(`/${item.redirectLink}`)
      } else {
        ProjectService.SetProjectGroup(
          "",
          projectId,
          "",
          `Docs_${Math.floor(Math.random() * 1000000000)}`,
          "",
          "active",
          apiKey
        )
          .then((json) => {
            if (json.count > 0) {
              UpdateURLQueryStringWithoutReload(
                "groupId",
                json.task_group[0].task_group_id
              );
              ProjectService.GetProjectTask(
                "",
                projectId,
                getUrlVars()["groupId"],
                "",
                "Progress",
                "active",
                apiKey
              )
                .then((json) => {
                  if (json.count > 0) {
                    //if organization doesn't have project task due to no machines create one
                    UpdateURLQueryStringWithoutReload(
                      "taskId",
                      json.project_task[0].task_id
                    );
                    CreateTasks(json.project_task[0].task_id, "Documentation");
                  } else {
                    ProjectService.SetProjectTask(
                      "",
                      projectId,
                      getUrlVars()["groupId"],
                      "",
                      "Progress",
                      "",
                      "active",
                      apiKey
                    )
                      .then((json) => {
                        if (json.count > 0) {
                          UpdateURLQueryStringWithoutReload(
                            "taskId",
                            json.project_task[0].task_id
                          );
                          CreateTasks(
                            json.project_task[0].task_id,
                            "Documentation"
                          );
                        }
                      })
                      .catch((errorr) => {
                        if (process.env.NODE_ENV === "development")
                          console.log("getUserTypePermissionserrorr", errorr);
                      });
                  }
                })
                .catch((errorr) => {
                  if (process.env.NODE_ENV === "development")
                    console.log("getUserTypePermissionserrorr", errorr);
                });
            }
          })
          .catch((errorr) => {
            if (process.env.NODE_ENV === "development")
              console.log("getUserTypePermissionserrorr", errorr);
          });

        // navigate(`/${item.redirectLink}`)
      }
    }

    setSavingLoading(false);
  }

  function handleRemove() {
    //remove machine from rollout list (not required yet)
    const groupId = String(getUrlVars()["groupId"]);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      showConfirmButton: true,
      color: theme.palette.custom.text,
      background: theme.palette.custom.inputBackgroundColor,
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        ProjectService.SetProjectGroup(
          groupId,
          "",
          "",
          "",
          "",
          "removed",
          apiKey
        ).then((json) => {
          if (json.count > 0) {
            navigate("/machineStatus");
          }
        });
      }
    });
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{ height: "100%" }}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.borderColor,
          }}
          gutterBottom
        >
          {t("MachineDocumentation")}:
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          width: { xs: "100%", sm: "50%" },
          flexDirection: "column",
        }}
      >
        <CustomTextField
          error={!ugId}
          value={ugId}
          onChange={(e) => {
            setUgId(e.target.value);
          }}
          id="txtUgId"
          label={t("U/G ID Number")}
          variant="filled"
          margin="normal"
        />
        <CustomTextField
          error={!model}
          value={model}
          onChange={(e) => {
            setModel(e.target.value);
          }}
          id="txtModel"
          label={t("Model")}
          variant="filled"
          margin="normal"
        />
        <CustomTextField
          error={!manufacturer}
          value={manufacturer}
          onChange={(e) => {
            setManufacturer(e.target.value);
          }}
          id="txtManu"
          label={t("Manufacturer")}
          variant="filled"
          margin="normal"
        />
        <CustomTextField
          error={!type}
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
          id="txtType"
          label={t("Equipment Type")}
          variant="filled"
          margin="normal"
        />
      </Box>
      <Box sx={{ width: "100%", alignItems: "end", textAlign: "end" }}>
        <StyledButton sx={{ mt: 3, mb: 1, mr: 1 }} onClick={handleRemove}>
          Delete
        </StyledButton>
        <StyledLoadingButton
          loading={savingLoading}
          loadingIndicator={<CircularProgress color="primary" size={16} />}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          onClick={handleSubmit}
        >
          {t("Save")}
        </StyledLoadingButton>
      </Box>
    </Box>
  );
}

export default AddMachineDocsPage;
