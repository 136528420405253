import { AppBar, Box, Button, Toolbar } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

function PublicLayout(props) {
  const navigate = useNavigate();
  return (
    <Box>
      <AppBar position="fixed" sx={{ width: "100%" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 2rem",
          }}
        >
          <img
            style={{ width: "200px", marginRight: "4px" }}
            src={`${window.env.REACT_APP_PUBLIC_URL}/logo-text-dark.png`}
            alt="logo"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ mt: "4rem" }}>{props.children}</Box>
    </Box>
  );
}
PublicLayout.propTypes = {
  children: PropTypes.element,
};

export default PublicLayout;
