import React from "react";
import PropTypes from "prop-types";

const PinSVG = ({gradientStart, gradientStop, pinPaths, width = "31px", height = "100%", type}) => {
    const translationMap = {
        "generic": "translate(0, 0)",
        "home": "translate(-934.74 -21.1)",
        "wi-fi": "translate(-934.74 -21.1)",
        "television": "translate(-934.74 -21.1)",
        "radio frequency": "translate(-934.74 -21.1)",
        "bluetooth": "translate(-934.74 -21.1)"
    };
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2226.52 4053.8" width={width} height={height}>
            <defs>
                <linearGradient id={`linear-gradient-${type}`} x1="1113.26" y1="4053.8" x2="1113.26" y2="0" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={gradientStop} />
                    <stop offset="1" stopColor={gradientStart} />
                </linearGradient>
            </defs>

            <g>
                <path fill={`url(#linear-gradient-${type})`} d="M2226.52,1884.08V698.49c0-47.13-25.14-90.67-65.95-114.24L1179.22,17.67c-40.81-23.56-91.09-23.56-131.91,0L65.95,584.26C25.14,607.82,0,651.37,0,698.49v1185.59c0,15.7,2.8,31.27,8.28,45.99l1086.58,2112.5c7.7,14.97,29.1,14.97,36.81,0l1086.58-2112.5c5.47-14.71,8.28-30.29,8.28-45.99Z" />

                <path fill="#fff" d="M1971.96,1714.72v-851.25c0-43.41-23.16-83.51-60.75-105.22l-737.21-425.63c-37.59-21.7-83.9-21.7-121.49,0l-737.21,425.63c-37.59,21.7-60.75,61.81-60.75,105.22v851.25c0,43.41,23.16,83.51,60.75,105.22l737.21,425.63c37.59,21.7,83.9,21.7,121.49,0l737.21-425.63c37.59-21.7,60.75-61.81,60.75-105.22Z" />
            </g>

            <g transform={translationMap[type] || ""}>
                {pinPaths.map((path, index) => (
                    <path key={index} fill={`url(#linear-gradient-${type})`} d={path} />
                ))}
            </g>
        </svg>
    );
};

PinSVG.propTypes = {
    gradientStart: PropTypes.string.isRequired,
    gradientStop: PropTypes.string.isRequired,
    pinPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    type: PropTypes.string.isRequired
};

export default PinSVG;
